import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications, Store } from "react-notifications-component";
import {
  ListContryCitys,
  PostupHotelView,
  postAllCompaniesRoll,
  
} from "../../utils/urls.js";
import { getListhotelsview, deleteHotelSelect } from '../../utils/urlExperience.js'
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";

function BestValueOffers({ idUser, infoUser, dataUser }) {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  const initSelec2 = { value: 0, text: "Seleccione una opcion" };
  const initCountrys = {
    country: "",
    citys: [
      {
        city: "",
        citycode: 0,
      },
    ],
  };

  const initHotels = {
    idbvo: 0,
    nombredestino: "",
    urlimagen: "",
    place: "",
    statename: "",
    show: false,
    idcliente: 0
}

  const initSelec = { value_option: 12345, name_option: "Experience B" };

  const [idCompanys, setidCompanys] = useState([initSelec2]);
  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsCompanyArray, setslsCompanyArray] = useState([initCountrys]);
  const [slsHotelsArray, setslsHotelsArray] = useState([initHotels]);
//   const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false); // Estado de carga
  const [reload, setReload] = useState(false);
  
  const initSelec3 = { name: "", lastname: "", email: "", userId: "", rol: "" };

  const [isLoaded, setIsLoaded] = useState(true);
  const [formValues, setFormValues] = useState({
    nombredestino: "",
    urlimagen: null,
    place: "",
    statename: "",
    show: true,
    idcliente: initSelec.value_option,
  });

  const [data, setData] = useState([]);

  const [slsRoll, setslsRoll] = useState([initSelec2]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);
  const [slsUsersbyComp, setslsUsersbyComp] = useState([initSelec3]);

  const [tableValues, setTableValues] = useState({ company: "" });
  const [isPanel1Visible, setIsPanel1Visible] = useState(false);

  const [cityOptions, setCityOptions] = useState([]); // Nuevo estado para las ciudades

  useEffect(() => {
    console.log('<<<<<<isLoaded>>>>>>');
    console.log(isLoaded);

    const executeFetchLoadCombos = async () => {
        try {
            if (isLoaded === true) {
                // Ejecutar fetchLoadCombos y esperar a que termine
                await fetchLoadCombos();
                console.log('fetchLoadCombos ejecutado con éxito');
            }
        } catch (error) {
            console.error('Error en fetchLoadCombos:', error);
        } finally {
            // Cambiar el estado a false, independientemente de si hubo error o no
            setIsLoaded(false);
        }
    };

    executeFetchLoadCombos();
}, [isLoaded]);

  const fetchLoadCombos = async () => {
    console.log('en funcion de combos <<<<<<<<<<<<<<')

    console.log(!isLoaded)
    try {
    //   if (!isLoaded) {
        if (token === undefined || token === null) {
          token = getFromLocalStorage("TK");
        }
        let selectCombos = [initSelec, ...(await fnPostCompaniesList())];
        let selectContrys = [initCountrys, ...(await listContrysCitys())];
        let selectHotels = [initHotels, ...(await getListHotelsavailables())];
        console.log('<<<<<<<<<<<<<<<<<<selectHotels>>>>>>>>>>>>>>>>>>')

        console.log(selectHotels)

        setslsCompany(selectCombos);
        setslsCompanyArray(selectContrys);
        setslsHotelsArray(selectHotels);

    //   }
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const getListHotelsavailables = async () => {
    try {
    //   if (!isLoaded) {
        if (token === undefined || token === null) {
          token = getFromLocalStorage("TK");
        }

        const responseJsonHotelss = await axios.get(getListhotelsview());
        console.log('<<<<<<<<<<<<<<<<<<<<<<responseJsonHotelss>>>>>>>>>>>>>>>>>>>>>>')

        console.log(responseJsonHotelss.data)
        return responseJsonHotelss.data;


    //   }
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const listContrysCitys = async () => {
    try {
      const responseJsonContrys = await axios.get(ListContryCitys());
      return responseJsonContrys.data;
    } catch (error) {
      console.error("Error al cargar los combos  11", error);
    }
  };

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol, 
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch
      };
  
      const responseJsonCompanies = await axios.post(postAllCompaniesRoll(token), requestData);

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setFormValues((prevValues) => ({
      ...prevValues,
      statename: selectedCountry,
      place: "", // Reiniciar el lugar al cambiar de país
    }));

    // Filtrar las ciudades correspondientes al país seleccionado
    const selectedCountryData = slsCompanyArray.find(
      (country) => country.country === selectedCountry
    );
    if (selectedCountryData) {
      setCityOptions(selectedCountryData.citys); // Actualizar las ciudades
    }
  };

const handleInputChange = (e) => {
  const { name, value, files } = e.target;
  if (files) {
    setFormValues(prev => ({ ...prev, [name]: files[0] }));
  } else {
    setFormValues(prev => ({ ...prev, [name]: value }));
  }
};

const validateForm = () => {
    return (
      formValues.nombredestino &&
      formValues.urlimagen &&
      formValues.statename
    );
};

const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("<<<<<<<<<<<<<<formValues>>>>>>>>>>>>>>");

    console.log(formValues);

    if (!validateForm()) {
        addNotification("danger", "Error", "Fill all required fields", "top-center");
        return;
      }

    try {

          const formData = new FormData();
          formData.append("nombredestino", formValues.nombredestino);
          formData.append("urlimagen", formValues.urlimagen); // Agrega el archivo
          formData.append("place", formValues.place);
          formData.append("statename", formValues.statename);
          formData.append("show", formValues.show);
          formData.append("idcliente", formValues.idcliente);
          
          const response = await fetch(PostupHotelView(), {
            method: "POST",
            body: formData,
          });

          console.log('<<<<response>>>>')

          console.log(response)
      
          const data = await response.json();
          console.log("Response:", data);

          if(response.status == 201){
            addNotification(
                "success",
                "Success",
                "record saved",
                "top-center"
              );


              setFormValues({nombredestino: "",
                urlimagen: null,
                place: "",
                statename: "",
                show: true,
                idcliente: initSelec.value_option})

                fetchLoadCombos();
                setIsLoaded(true);
          
            }else{
            addNotification(
                "warning",
                "Error",
                "error, some data is missing",
                "top-center",
                false
              );
          }
  
          ///////
    } catch (error) {
        console.error("Error:", error);

    }
};

const handleCheckboxChange = (idbvo, isChecked) => {
    setSelectedIds((prev) =>
      isChecked ? [...prev, idbvo] : prev.filter((id) => id !== idbvo)
    );
};

const handleDeleteSelection = async () => {
    console.log("ID seleccionados:", selectedIds);
  
    // Filtrar el array para eliminar los seleccionados
    setslsHotelsArray(slsHotelsArray.filter((hotel) => !selectedIds.includes(hotel.idbvo)));
  
    try {
      // Iterar sobre selectedIds y enviar la petición GET para cada ID
      const responses = await Promise.all(
        selectedIds.map((id) => axios.get(deleteHotelSelect(id)))
      );
  
      // Verificar que todas las respuestas sean exitosas
      const allSuccessful = responses.every((response) => response.status === 200);
  
      if (allSuccessful) {
        console.log("Registros dados de baja.");
        addNotification(
            "success",
            "Success",
            "Records deleted",
            "top-center"
          );
      } else {
        addNotification(
            "warning",
            "Error",
            "Error updating one or more recordss",
            "top-center",
            false
          );
      }
    } catch (error) {
      console.error("Error al actualizar registros:", error.message);
    }
  
    setSelectedIds([]); // Limpiar selección
};

function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
}

const toggleReload = () => {
    if (!reload) {
      setReload(true);
      setLoading(true);  // Inicia la carga cuando se hace clic en recargar

      // Restablecer slsHotelsArray a su valor inicial
    //   setslsHotelsArray([initHotels]);


      setTimeout(() => {
        setIsLoaded(true)

        setReload(false);
        setLoading(false);  // Termina la carga

      }, 2000);

    //   setTimeout(() => {
    //     // Cargar los nuevos datos
    //     fetchLoadCombos().then(() => {
    //       setIsLoaded(true);
    //       setReload(false);  // Termina el estado de recarga
    //       setLoading(false);  // Termina la carga
    //     });
    //   }, 5000); // Este tiempo puede ajustarse según la duración de la carga
    }
  };

  console.log('<<<<<<<slsHotelsArray>>>>>>>')

  console.log(slsHotelsArray)

return (
    <div>
      <div className="row mb-3">
        <div className="col-xl-6">
          <Panel>
            <PanelHeader>Add hotels to display</PanelHeader>
            <PanelBody>
              <form onSubmit={handleSubmit}>
                <div className="row mb-3 d-flex align-items-center">
                  <label
                    className="form-label col-form-label col-md-3 mb-0"
                    htmlFor="nombredestino"
                  >
                    Name destination*
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      type="text"
                      id="nombredestino"
                      name="nombredestino"
                      value={formValues.nombredestino}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-3 d-flex align-items-center">
                  <label
                    className="form-label col-form-label col-md-3 mb-0"
                    htmlFor="urlimagen"
                  >
                    Select an image*
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      type="file"
                      id="urlimagen"
                      name="urlimagen"
                    //   value={formValues.urlimagen}
                    onChange={handleInputChange} // No es necesario pasar el value porque es un campo de tipo archivo
                    />
                  </div>
                </div>

                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="statename"
                  >
                    Statename*
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="statename"
                      name="statename"
                      value={formValues.statename}
                      onChange={handleCountryChange}
                    >
                      {slsCompanyArray.map((option, index) => (
                        <option key={"form_" + index} value={option.country}>
                          {option.country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="place"
                  >
                    Place*
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="place"
                      name="place"
                      value={formValues.place}
                      onChange={handleInputChange}
                    >
                      {cityOptions.map((city, index) => (
                        <option key={index} value={city.citycode}>
                          {city.city}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="idcliente"
                  >
                    Companies*
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="idcliente"
                      name="idcliente"
                      value={formValues.idcliente || 12345}
                      onChange={handleInputChange}
                    >
                      {slsCompany.map((option, index) => (
                        <option
                          key={"form_" + index}
                          value={option.value_option}
                        >
                          {option.name_option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="lrow md-15px" style={{ margin: 10 }}>
                  <button
                    type="submit"
                    className="btn btn-green"
                    style={{ float: "right" }}
                  >
                    + Add Hotel
                  </button>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>

        <div className="col-xl-6">
          <Panel>
            <PanelHeader>
            &nbsp;&nbsp;
              <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={toggleReload}>
                <i className="fa fa-redo"></i>
              </button>
              &nbsp;&nbsp;
               List of available hotels 
               </PanelHeader>
            <PanelBody>
            <div className="lrow md-7px" style={{ margin: 2 }}>
                *The first 4 records will be displayed
</div>
            <div>
          <div className="lrow md-8px" style={{ margin: 5 }}>
            {loading ? (
              <div>Loading...</div> // Muestra un mensaje de carga mientras se obtiene la información
            ) : (
              slsHotelsArray.slice(1)
                .sort((a, b) => a.idbvo - b.idbvo)
                .map((hotel) => (
                  <div
                    key={hotel.idbvo}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                      padding: "5px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{ marginRight: "10px" }}
                      onChange={(e) =>
                        handleCheckboxChange(hotel.idbvo, e.target.checked)
                      }
                    />
                    <img
                      src={hotel.urlimagen}
                      alt={hotel.nombredestino}
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                        marginRight: "10px",
                      }}
                    />
                    <div>
                      <strong>{hotel.nombredestino}</strong>
                      <p>
                        {hotel.place} - {hotel.statename}
                      </p>
                    </div>
                  </div>
                ))
            )}
          </div>

          <div className="lrow md-15px" style={{ margin: 10 }}>
            <button
              type="button"
              className="btn btn-red"
              style={{ float: "right" }}
              onClick={handleDeleteSelection}
            >
              Delete Selections
            </button>
          </div>
        </div>
            </PanelBody>
          </Panel>{" "}
        </div>
      </div>
      <ReactNotifications />{" "}
    </div>
  );
}

export default BestValueOffers;
