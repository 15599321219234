import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useOutletContext } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "../../components/helps.js";
import {
  getInfoCompanies,
  addCompany,
  postAllCompaniesRoll,
  putCompany,
  getGroupsFacilities,
  getUrlsIcons,
  postAddurlIcon,
} from "../../utils/urls.js";
import { ReactNotifications, Store } from "react-notifications-component";
import {
  fnGetTable,
  fnValidUserPermission,
} from "../../components/objects/fnCommon.tsx";
// import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";
// import FormsDinamic from "../../components/objects/formsDinamic.tsx";
// import InfoUsers from "./dataUsers-allusers.js";
import { Modal, Button } from "react-bootstrap";

function RelationIcons() {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  // const infoUser = useOutletContext();
  // Acceder al contexto
  const { infoUser, permissions } = useOutletContext();

  // Ahora puedes acceder a los permisos y la información del usuario
  const canRead = permissions?.read ?? false;
  const canWrite = permissions?.write ?? false;
  const canUpdate = permissions?.update ?? false;
  const canDelete = permissions?.delete ?? false;

  const dataUser = infoUser;

  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };
  const initValues = {
    company_id: 2,
    tax_regime_id: null,
    name: "",
    cfdi_id: null,
    short_name: "",
    company_name: "",
    rfc: "",
    line_id: 2,
    addres: "",
    clabe: null,
    rnt: null,
    logo: null,
    status: true,
    date_register: "",
    date_unsubscribe: null,
    date_update: "",
    tax_regimen: "",
    cfdi: "",
  };
  const initFacilities = { id: 0, name: "" };
  const initIcons = { name: "", url: "" };

  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);

  const [info, setInfo] = useState([]);
  const [header, setHeader] = useState([]);
  const [headerMap, setheaderMap] = useState();
  const [formvisible, setformvisible] = useState(false);
  const [formvisibleEdit, setformvisibleEdit] = useState(false);
  const [tblvisible, settblvisible] = useState(true);
  const [formValues, setFormValues] = useState({
    tax_regime_id: 0,
    cfdi_id: 0,
    line_id: 0,
    name: "",
    short_name: "",
    company_name: "",
    rfc: "",
    addres: "",
    clabe: "",
    rnt: "",
    logo: null,
  });

  const [formValuesEdit, setFormValuesEdit] = useState({
    company_id: 0,
    tax_regime_id: 0,
    cfdi_id: 0,
    line_id: 0,
    name: "",
    short_name: "",
    company_name: "",
    rfc: "",
    addres: "",
    clabe: "",
    rnt: "",
    logo: null,
  });
  const [idRegistro, setidRegistro] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(true);
  /// variables para mostrar modal
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [slsValues, setslsValues] = useState([initValues]);
  const [reload, setreload] = useState(false);

  const [slsIcons, setslsIcons] = useState([initIcons]);
  const [slsFacilities, setslsFacilities] = useState([initFacilities]);

  const [selectedFacility, setSelectedFacility] = useState(null);
  const [selectedIcons, setSelectedIcons] = useState({});

  const [selectedFacilityId, setSelectedFacilityId] = useState(null);
  const [processedFacilities, setProcessedFacilities] = useState({});

  const handleFacilityClick = (facilityId) => {
    setSelectedFacilityId(facilityId);
  };

  const handleIconSelect = (facilityId, iconUrl) => {
    setProcessedFacilities((prev) => ({
      ...prev,
      [facilityId]: iconUrl, // Guarda el icono asignado
    }));

    setSelectedIcons((prev) => ({ ...prev, [facilityId]: iconUrl }));

    setSelectedFacilityId(null); // Desmarca la amenidad seleccionada
  };

  //////////////////////// funciones base para peticiones <<<<<<<<<<<<<<<<<<<<<<<<

  // const fnPostCompaniesList = async () => {
  //   try {
  //     const requestData = {
  //       id_rol: idUserP.rol,
  //       id_empresa: idUserP.company,
  //       id_sucursal: idUserP.branch,
  //     };

  //     const response = await axios.post(
  //       postAllCompaniesRoll(token),
  //       requestData
  //     );
  //     return response.data.data || [];
  //   } catch (error) {
  //     console.error("Error al cargar las empresas", error);
  //     return [];
  //   }
  // };

  // useEffect(() => {
  //   const fetchCompanies = async () => {
  //     const companies = await fnPostCompaniesList();
  //     setslsCompany([
  //       initSelec,
  //       ...(Array.isArray(companies) ? companies : []),
  //     ]);
  //   };
  //   fetchCompanies();
  // }, []);

  // Función para actualizar la lista de sucursales cuando cambia la empresa seleccionada
  // useEffect(() => {
  //   if (formValues.company) {
  //     const fetchBranches = async () => {
  //       try {
  //         setslsBranches([initSelec2]); // Resetea antes de cargar
  //       } catch (error) {
  //         console.error("Error obteniendo sucursales:", error);
  //         setslsBranches([initSelec2]);
  //       }
  //     };
  //     fetchBranches();
  //   }
  // }, [formValues.company]);

  /////////// termina grupo de funciones combo base <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  useEffect(() => {
    // setreload((prev) => !prev); // volver a cargar informacion de tabla

    if (reload == false) {
      const GetListAenities = async () => {
        console.log("entro a peticion de iconos ");
        const datanew = await fnGetListAenities(token);
        setslsFacilities([
          // initFacilities,
          ...(Array.isArray(datanew) ? datanew : []),
        ]);
      };

      const GetIcons = async () => {
        console.log("entro a peticion de listado facilidades  ");

        const datanew = await fnGetIcons(token);
        setslsIcons([
          // initIcons,
          ...(Array.isArray(datanew) ? datanew : []),
        ]);
      };

      GetListAenities();
      GetIcons();

      setreload((prev) => !prev);
    }
  }, [reload]);

  const fnGetListAenities = async () => {
    try {
      const response = await axios.get(getGroupsFacilities(token));
      return response.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const fnGetIcons = async () => {
    try {
      const response = await axios.get(getUrlsIcons(token));
      return response.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const fnEditRegister = async (idRegister) => {
    console.log("información para editar registro << ", idRegister);
    setformvisibleEdit(!formvisibleEdit);
    settblvisible(!tblvisible);

    const detailData = slsValues.find(
      (datainfo) => datainfo.company_id === idRegister.company_id
    );

    if (detailData) {
      setFormValuesEdit({
        company_id: detailData.company_id || 0,

        tax_regime_id: detailData.tax_regime_id || 0,
        cfdi_id: detailData.cfdi_id || 0,
        name: detailData.name || "",
        short_name: detailData.short_name || "",
        company_name: detailData.company_name || "",
        rfc: detailData.rfc || "",
        addres: detailData.addres || "",
        clabe: detailData.clabe || "",
        rnt: detailData.rnt || "",
        logo: detailData.logo || "",
      });
    }
  };

  const fnChangeView = () => {
    setformvisible(!formvisible);
    settblvisible(!tblvisible);
  };

  const fnDeleteRegister = async (idRegister) => {
    console.log("información de registro << ", idRegister);

    try {
      const requestData = new FormData();
      requestData.append("company_id", idRegister.company_id);
      requestData.append("status", false);
      // const requestData = {
      //   company_id: idRegister.company_id,
      //   status: false
      // }

      // console.log("requestData delete registro <<<<<<<<<<<<<<<<<<<<", requestData);

      const response = await axios.put(putCompany(token), requestData);

      // console.log("<<<<<<<<<<<<<<<<<<<<<<<response>>>>>>>>>>>>>>>>>>>>>>>");
      // console.log(response);
      // console.log(response.status);

      if (response.status == 200) {
        addNotification(
          "success",
          "Success",
          "Destination updated successfully.",
          "top-center"
        );

        setreload((prev) => !prev); // volver a cargar informacion de tabla
      } else {
        addNotification(
          "warning",
          "Partial Success",
          "Oops, there was an error during the update.",
          "top-center"
        );
      }
    } catch (error) {
      addNotification(
        "warning",
        "Partial Success",
        "Oops, there was an error during the update.",
        "top-center"
      );
    }
  };

  const fnCancelAddcompany = () => {
    setformvisible(false);
    settblvisible(true);
  };

  const fnViewDetail = (idRegister) => {
    console.log("informacion a mostrar en modal:  ", idRegister);
    const detailData = slsValues.find(
      (datainfo) => datainfo.company_id === idRegister.company_id
    );

    setModalData(detailData);
    setShowModal(true);
  };

  const handleCancel = () => {
    fnChangeView();
  };
  //////Formulario

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const fnUpdateUrls = async () => {
    // Convertir selectedIcons en JSON con la estructura requerida
    const formattedData = Object.entries(selectedIcons).map(([id, urlicon]) => ({
      id: parseInt(id, 10), // Convertir ID a número
      urlicon: String(urlicon), // Asegurar que urlicon sea string
    }));
  
    console.log("Datos formateados para envío:", JSON.stringify(formattedData, null, 2));
  
    // Validar que haya al menos 50 registros antes de continuar
    if (formattedData.length < 50) {
      addNotification(
        "warning",
        "Partial Success",
        "Please, you must process at least 50 records.",
        "top-center"
      );
      return; // Evita continuar con la ejecución
    }
  
    try {
      const responseJson = await axios.post(postAddurlIcon(token), formattedData);
  
      console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< responseJson >>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      console.log(responseJson);
  
      const DataResulthotels = responseJson.data.data;
  
      if (responseJson.status === 201) {
        addNotification(
          "success",
          "Success",
          responseJson.data.message,
          "top-center"
        );

        setreload((prev) => !prev);

      } else {
        addNotification(
          "warning",
          "Partial Success",
          "Oops, there was an error during the update.",
          "top-center"
        );
      }
  
      return DataResulthotels;
    } catch (error) {
      console.error("Error al cargar los módulos", error);
      addNotification(
        "warning",
        "Partial Success",
        "Oops, there was an error during the update.",
        "top-center"
      );
      return; // Evita continuar con la ejecución
    }
  };
  

  const handleSubmitEditCompany = async (e) => {
    e.preventDefault();
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormValues((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
        ...(name === "company" && { branch: "" }),
      }));
    }
  };

  const handleInputChangeEdit = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormValuesEdit((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormValuesEdit((prev) => ({
        ...prev,
        [name]: value,
        ...(name === "company" && { branch: "" }),
      }));
    }
  };

  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }

  console.log("slsFacilities <<<<<<<<<");
  console.log(slsIcons);
  console.log(slsFacilities);

  return (
    <div>
      <h1 className="page-header">Extras</h1>
      <div className="row">
        <div className="col-md-6 max-h-[750px] overflow-auto">
          <Panel>
            <PanelHeader>Lista de Amenidades</PanelHeader>
            <PanelBody>
              {/* <div className="space-y-4"> */}
              <div
                className="lrow md-8px"
                style={{
                  margin: 5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start", // Centra los elementos horizontalmente
                  maxHeight: "650px", // Altura máxima del contenedor principal
                  overflowY: "auto", // Barra de desplazamiento vertical
                  border: "1px solid #ccc", // Opcional: agregar borde para resaltar
                  padding: "10px", // Espaciado interno
                }}
              >
                {slsFacilities.map((facility) => (
                  <div key={facility.id} className="flex items-center gap-4">
                    <button
                      className={`w-64 text-left border p-2 rounded transition-colors
        ${
          selectedFacilityId === facility.id
            ? "bg-blue-300"
            : processedFacilities[facility.id]
            ? "bg-gray-200"
            : "bg-gray-100"
        }`}
                      onClick={() => handleFacilityClick(facility.id)}
                    >
                      {facility.name} {processedFacilities[facility.id] && "✔"}
                    </button>
                  </div>
                ))}
              </div>
            </PanelBody>
          </Panel>
          <button
            type="button"
            className="btn btn-green"
            style={{ float: "right" }}
            onClick={fnUpdateUrls}
          >
            Save
          </button>
        </div>

        <div className="col-md-6 max-h-[750px] overflow-auto">
          <Panel>
            <PanelHeader>Iconos disponibles</PanelHeader>
            <PanelBody>
              {/* {selectedFacility && ( */}
              <div className="p-4 border rounded bg-gray-50">
                <h3 className="text-lg font-bold mb-2">Selecciona un icono</h3>
                {/* <div className="grid grid-cols-4 gap-4"> */}
                <div
                  className="lrow md-8px"
                  style={{
                    margin: 2,
                    display: "flex",
                    flexDirection: "row",
                    maxHeight: "650px", // Altura máxima del contenedor principal
                    overflowY: "auto", // Barra de desplazamiento vertical
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    alignItems: "stretch",
                    gridTemplateColumns: "repeat(10, minmax(60px, 1fr))", // 10 iconos por fila
                    transition: "background-color 0.3s",
                  }}
                >
                  {slsIcons.map((icon) => (
                    <button
                      key={icon.name}
                      onClick={() =>
                        handleIconSelect(selectedFacilityId, icon.url)
                      }
                      className="border p-2 rounded hover:bg-gray-200"
                    >
                      <img
                        src={icon.url}
                        alt={icon.name}
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover",
                          marginRight: "15px",
                        }}
                      />
                    </button>
                  ))}
                </div>
              </div>
              {/* )} */}
            </PanelBody>
          </Panel>
        </div>
      </div>


      <h3 className="text-lg font-bold mt-6">Resultado:</h3>
      <pre className="bg-gray-100 p-4 rounded">
        {JSON.stringify(
          Object.entries(selectedIcons).map(([id, urlicon]) => ({
            id,
            urlicon,
          })),
          null,
          2
        )}
      </pre>


      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalData ? (
            <div
              // key={index}
              style={{
                borderBottom: "2px solid #ccc",
                paddingBottom: "15px",
                marginBottom: "15px",
              }}
            >
              <p>
                {" "}
                <strong>Company ID:</strong> {modalData.company_id}{" "}
              </p>
            </div>
          ) : (
            <p>Cargando...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      {/* //////// agregar compañia  */}
      <div className="row" style={!formvisible ? { display: "none" } : {}}>
        <div className="col-12">
          <Panel>
            <PanelHeader>Add </PanelHeader>
            <PanelBody>
              <form onSubmit={handleSubmit}>
                {/* <div className="row md-15px">
				  <label
					className="form-label col-form-label col-md-3"
					htmlFor="company"
				  >
					Companies*
				  </label>
				  <div className="col-md-9">
					<select
					  className="form-select mb-5px"
					  id="company"
					  name="company"
					  value={formValues.company}
					  onChange={handleInputChange}
					>
					  {slsCompany.map((option, index) => (
						<option
						  key={"form_" + index}
						  value={option.value_option}
						>
						  {option.name_option}
						</option>
					  ))}
					</select>
				  </div>
				</div> */}

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Name Company *
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      id="company_name"
                      name="company_name"
                      value={formValues.company_name}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <div
                    className="form-label col-form-label col-md-6"
                    style={{ margin: 10 }}
                  >
                    <button
                      type="submit"
                      className="btn btn-blue"
                      style={{ float: "right" }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
      {/* //////// terminar funcionalidad para agregar compañia */}
      {/* ////////////// Editar registro de Compañia  */}
      <div className="row" style={!formvisibleEdit ? { display: "none" } : {}}>
        <div className="col-12">
          <Panel>
            <PanelHeader>Edit Branch</PanelHeader>
            <PanelBody>
              <form onSubmit={handleSubmitEditCompany}>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Name Company *
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      id="company_name"
                      name="company_name"
                      value={formValuesEdit.company_name}
                      onChange={handleInputChangeEdit}
                      disabled
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <div
                    className="form-label col-form-label col-md-6"
                    style={{ margin: 10 }}
                  >
                    <button
                      type="submit"
                      className="btn btn-blue"
                      style={{ float: "right" }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
      {/* ///////////// termina Edicion  */}
      <ReactNotifications />{" "}
    </div>
  );
}

export default RelationIcons;
