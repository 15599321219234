import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useOutletContext } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "../../components/helps.js";
import {
  FormsTypeusers,
  putBranch,
  postAllCompaniesRoll,
  getInfoBranches,
  addBranch,
} from "../../utils/urls.js";
import {
  fnGetTable,
  fnValidUserPermission,
} from "../../components/objects/fnCommon.tsx";
import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";
import { ReactNotifications, Store } from "react-notifications-component";
import FormsDinamic from "../../components/objects/formsDinamic.tsx";
import { Modal, Button } from "react-bootstrap";

function InfoBranches() {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };
  const initValues = {
    banch_id: 0,
    compani_id: 0,
    name: " ",
    addres: " ",
    logo: "",
    status: false,
    date_register: "",
    date_unsubscribe: null,
    date_update: "",
    company: " ",
    country: "",
  };

  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);

  // let idCompany = getFromLocalStorage("company");
  const infoUser = useOutletContext();
  const dataUser = infoUser.infoUser;
  const [info, setInfo] = useState([]);
  const [header, setHeader] = useState([]);
  const [headerMap, setheaderMap] = useState();
  const [formvisible, setformvisible] = useState(false);
  const [formeditvisible, setformeditvisible] = useState(false);

  const [tblvisible, settblvisible] = useState(true);
  const [idRegistro, setidRegistro] = useState("");
  const [formValues, setFormValues] = useState({
    fileup: null,
    namebranch: "",
    address: "",
    company: 0,
  });

  const [reload, setreload] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [slsValues, setslsValues] = useState([initValues]);
  const [modalData, setModalData] = useState(null);
  const [formValuesEdit, setFormValuesEdit] = useState({
    branch_id: 0,
    fileupedit: null,
    namebranch: "",
    address: "",
    company: 0,
  });

  //////////////////////// funciones base para peticiones

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol,
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch,
      };

      const response = await axios.post(
        postAllCompaniesRoll(token),
        requestData
      );
      return response.data.data || [];
    } catch (error) {
      console.error("Error al cargar las empresas", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      const companies = await fnPostCompaniesList();
      setslsCompany([
        initSelec,
        ...(Array.isArray(companies) ? companies : []),
      ]);
    };
    fetchCompanies();
  }, []);

  // Función para actualizar la lista de sucursales cuando cambia la empresa seleccionada
  useEffect(() => {
    if (formValues.company) {
      const fetchBranches = async () => {
        try {
          setslsBranches([initSelec2]); // Resetea antes de cargar
            } catch (error) {
          console.error("Error obteniendo sucursales:", error);
          setslsBranches([initSelec2]);
        }
      };
      fetchBranches();
    }
  }, [formValues.company]);

  /////////// termina grupo de funciones combo base <<

  useEffect(() => {
    const fetchTable = async () => {
      try {
        if (token === undefined || token === null) {
          token = getFromLocalStorage("TK");
        }
        let idCompany = dataUser.id_company;
        let idBranch = dataUser.branch_id;
        if (fnValidUserPermission(dataUser)) {
          idCompany = 0;
          idBranch = 0;
        }
        // idCompany = 4;
        const url = getInfoBranches(idCompany, idBranch, token, idUserP.rol);
        const infoResult = await fnGetTable(url);
        setInfo(infoResult.data);
        setHeader(infoResult.head);
        setheaderMap(infoResult.headerMap);

        const renameValues = infoResult.data.map((arraydata) => ({
          ...arraydata,
          id: arraydata.banch_id,
        }));

        setslsValues(renameValues);
      } catch (error) {
        console.error("error de fetchTable", error);
      }
    };
    if (infoUser != null) {
      fetchTable();
    }

    if (reload) {
      fetchTable();
      setreload((prev) => !prev);

    }
  }, [infoUser, reload]);

  useEffect(() => {
    console.log("Valores del formulario actualizados:", formValuesEdit);
  }, [formValuesEdit]);

  const fnEditRegister = (idRegister) => {
    console.log("idRegister <<<<<<<<<<<<<<<<<<<<<<<<<");
    console.log(idRegister);

    const detailData = slsValues.find(
      (datainfo) => datainfo.banch_id === idRegister.banch_id
    );

    if (detailData) {
      setFormValuesEdit({
        branch_id: detailData.banch_id || 0,
        fileup: detailData.logo || "", 
        namebranch: detailData.name || "",
        address: detailData.addres || "",
        company: detailData.compani_id || 0,
      });
    }

    setformeditvisible((prev) => !prev); // mostrar formulario para editar registro
    settblvisible((prev) => !prev);
  };

  const fnDeleteRegister = async (idRegister) => {
    console.log("información de registro << ", idRegister);

    try {

        const requestData = new FormData();
        requestData.append("branch_id", idRegister.banch_id);
        requestData.append("status", false);
 
      console.log("requestData delete registro <<<<<<<<<<<<<<<<<<<<");
  
      console.log("FormData contenido:");
      for (let [key, value] of requestData.entries()) {
        console.log(key, value);
      }

      const response = await axios.put(putBranch(token), requestData);

      console.log("<<<<<<<<<<<<<<<<<<<<<<<response>>>>>>>>>>>>>>>>>>>>>>>");
      console.log(response);
      console.log(response.status);

      if (response.status == 200) {
        addNotification(
          "success",
          "Success",
          "Destination updated successfully.",
          "top-center"
        );

        setreload((prev) => !prev); // volver a cargar informacion de tabla
      } else {
        addNotification(
          "warning",
          "Partial Success",
          "Oops, there was an error during the update.",
          "top-center"
        );
      }
    } catch (error) {
      addNotification(
        "warning",
        "Partial Success",
        "Oops, there was an error during the update.",
        "top-center"
      );
    }
  };

  const fnChangeView = () => {
    setformvisible(!formvisible);
    settblvisible(!tblvisible);
  };

  //   const fnViewDetail = (id) => {
  //     saveToLocalStorage("company", id);
  //     return <Navigate to="/datausers/datausers-infocompany" />;
  //   };
  const fnViewDetail = (idRegister) => {
    const detailData = slsValues.find(
      (datainfo) => datainfo.banch_id === idRegister.banch_id
    );

    setModalData(detailData);
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormValues((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
        ...(name === "company" && { branch: "" }),
      }));
    }
  };

  const handleInputChangeEdit = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormValuesEdit((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormValuesEdit((prev) => ({
        ...prev,
        [name]: value,
        ...(name === "company" && { branch: "" }),
      }));
    }
  };

  const validateForm = () => {
    return formValues.namebranch && formValues.company;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(
      "<<<<<<< formValues   >>>>>>> <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<"
    );
    console.log(formValues);

    if (!validateForm()) {
      addNotification(
        "danger",
        "Error",
        "Fill all required fields",
        "top-center"
      );
      return;
    }

    try {
      const PostDataUser = async () => {
        const formData = new FormData();
        formData.append("name", formValues.namebranch); // Agrega el archivo
        formData.append("addres", formValues.address);
        formData.append("urllogo", formValues.fileup);
        formData.append("compani_id", formValues.company);

        const response = await fetch(addBranch(token), {
          method: "POST",
          body: formData,
        });
        return response;
      };

      const response = await PostDataUser();

      if (response.status === 201) {
        addNotification("success", "Success", "Record saved", "top-center");
        setformvisible(!formvisible);
        settblvisible(!tblvisible);
        setreload((prev) => !prev);
      } else {
        addNotification(
          "warning",
          "Error",
          "Error, some data is missing",
          "top-center"
        );
      }
    } catch (error) {}
  };

  const handleEditing = async (e) => {
    e.preventDefault();

    try {
      const PostDataUser = async () => {
        const formData = new FormData();
        formData.append("branch_id", formValuesEdit.branch_id);
        formData.append("name", formValuesEdit.namebranch);
        formData.append("addres", formValuesEdit.address);
        formData.append("logo", formValuesEdit.fileupedit); // Agrega el archivo
        formData.append("compani_id", formValuesEdit.company);

        console.log(
          "<<<<<<<<<<<<<<<<<<formData>>>>>>>>>>>>>>>>>> <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<"
        );

        console.log("FormData contenido:");
        for (let [key, value] of formData.entries()) {
          console.log(key, value);
        }

        const response = await fetch(putBranch(token), {
          method: "PUT",
          body: formData,
        });
        return response;
      };

      const response = await PostDataUser();

      /////////////////
      console.log("response <<<<<<<<< <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
      console.log(response);
      console.log(response.status);

      if (response.status === 200) {
        addNotification("success", "Success", "Record saved", "top-center");
        setformvisible(false);
        setformeditvisible(false);

        settblvisible((prev) => !prev);
        setreload((prev) => !prev);
      } else {
        addNotification(
          "warning",
          "Error",
          "Error, some data is missing",
          "top-center"
        );
      }
    } catch (error) {}
  };

  const handleCancel = () => {
    settblvisible((prev) => !prev);
    setformeditvisible((prev) => !prev); // mostrar formulario para editar registro
  };
  ///
  if (infoUser == null) {
    return <div> Loading..</div>;
  }

  // Notificacion
  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }

  console.log("info <<<<<<<<<<<<<<<<<<<<<<<<<");
  console.log(idUserP.rol);

  console.log(info);

  return (
    <div>
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <Link to="/form/elements">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/form/elements">Form Stuff</Link>
        </li>
        <li className="breadcrumb-item active">Sucursal</li>
      </ol>
      <h1 className="page-header">Sucursal</h1>
      <div className="row" style={!tblvisible ? { display: "none" } : {}}>
        <div className="col-12">
          <Panel>
            <PanelHeader>Informcion de sucursales</PanelHeader>
            <PanelBody>
              <div className="row col-md-3" style={{ float: "inline-end" }}>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={fnChangeView}
                >
                  Agregar Sucursal +
                </button>
              </div>
              <div className="row col-md-12">
                <DataTableConsulting
                  header={header}
                  headerMap={headerMap}
                  info={info}
                  onViewDetail={fnViewDetail}
                  onEditRegister={fnEditRegister}
                  onDelete={fnDeleteRegister}
                />
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
      <div className="row" style={!formvisible ? { display: "none" } : {}}>
        <div className="col-12">
          <Panel>
            <PanelHeader>Form Branch</PanelHeader>
            <PanelBody>
              <form onSubmit={handleSubmit}>
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="company"
                  >
                    Companies*
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="company"
                      name="company"
                      value={formValues.company}
                      onChange={handleInputChange}
                    >
                      {slsCompany.map((option, index) => (
                        <option
                          key={"form_" + index}
                          value={option.value_option}
                        >
                          {option.name_option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Name *
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      type="text"
                      id="namebranch"
                      name="namebranch"
                      value={formValues.namebranch}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Address *
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      type="text"
                      id="address"
                      name="address"
                      value={formValues.address}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Select logo
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      type="file"
                      id="fileup"
                      name="fileup"
                      // value={formValues.urlimagen}
                      onChange={handleInputChange} // No es necesario pasar el value porque es un campo de tipo archivo
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="lrow md-15px" style={{ margin: 10 }}>
                    <button
                      type="submit"
                      className="btn btn-blue"
                      style={{ float: "right" }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
      {/* edicion de registro  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */}
      <div className="row" style={!formeditvisible ? { display: "none" } : {}}>
        <div className="col-12">
          <Panel>
            <PanelHeader>Edit branch record</PanelHeader>
            <PanelBody>
              <form onSubmit={handleEditing}>
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="company"
                  >
                    Companies*
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="company"
                      name="company"
                      value={formValuesEdit.company}
                      disabled
                    //   onChange={handleInputChangeEdit}
                    >
                      {slsCompany.map((option, index) => (
                        <option
                          key={"form_" + index}
                          value={option.value_option}
                        >
                          {option.name_option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Name *
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      type="text"
                      id="namebranch"
                      name="namebranch"
                      value={formValuesEdit.namebranch}
                      onChange={handleInputChangeEdit}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Address *
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      type="text"
                      id="address"
                      name="address"
                      value={formValuesEdit.address}
                      onChange={handleInputChangeEdit}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Update logo
                  </label>
                  <div className="col-md-9">
                    {formValuesEdit.fileup && (
                      <div>
                        <img
                          src={formValuesEdit.fileup}
                          alt="Image Thumbnail"
                          style={{
                            width: "100px",
                            height: "auto",
                            marginBottom: "10px",
                          }}
                        />
                      </div>
                    )}

                    <input
                      className="form-control"
                      type="file"
                      id="fileupedit"
                      name="fileupedit"
                      onChange={handleInputChangeEdit} // No es necesario pasar el value porque es un campo de tipo archivo
                    />
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-md-12 d-flex justify-content-end"
                    style={{ marginTop: "10px" }}
                  >
                    <button
                      type="submit"
                      className="btn btn-blue"
                      style={{ marginRight: "10px" }} // Espacio entre botones
                    >
                      Update record
                    </button>

                    <button
                      type="button"
                      className="btn btn-red"
                      onClick={handleCancel} // Llamada a handleCancel
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalData ? (
            <div
              // key={index}
              style={{
                borderBottom: "2px solid #ccc",
                paddingBottom: "15px",
                marginBottom: "15px",
              }}
            >
              <p>
                {" "}
                <strong>Branch ID:</strong> {modalData.banch_id}{" "}
              </p>
              <p>
                {" "}
                <strong>Company ID:</strong> {modalData.compani_id}{" "}
              </p>
            <p> <strong>Company name:</strong> {modalData.company} </p>

              <p>
                {" "}
                <strong>Branch name :</strong> {modalData.name}{" "}
              </p>
              <p>
                {" "}
                <strong>Addres:</strong> {modalData.addres}{" "}
              </p>
              <p>
                {" "}
                <strong>country:</strong> {modalData.country}{" "}
              </p>

              {modalData.logo && (
                <img
                  src={modalData.logo}
                  alt={modalData.name}
                  width="100%"
                  style={{ borderRadius: "5px", marginTop: "10px" }}
                />
              )}
            </div>
          ) : (
            <p>Cargando...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <ReactNotifications />{" "}
      {/* Ensure ReactNotifications component is included */}
    </div>
  );
}

export default InfoBranches;
