import React, { useState, useEffect, useRef } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications, Store } from "react-notifications-component";
import {
  getBranchDataByCompany,
  PostHotelsSearch,
  PostCreateCombosHotel,
  postAllCompaniesRoll,
  PostCreateExclusiveDeals,
} from "../../utils/urls.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";
import SearchForm from "../../components/header/search/form.jsx";
import Highlight from 'react-highlight';
import DateTime from 'react-datetime';



function CreateExclusiveDeals({ idUser, infoUser, dataUser }) {
    let token = getFromLocalStorage("TK");
    let idUserP = getFromLocalStorage("Session");

    const initInfoHotels = {
    hotelid: 0,
    name: "",
    address1: "",
    city: "",
    stateprovince: "",
    postalcode: "",
    country: "",
    nomach: false,
    };
    const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
    const initSelec2 = { value: 0, text: "Seleccione una opcion" };

    const [selectedIds, setSelectedIds] = useState([]);
    const [loading, setLoading] = useState(false); // Estado de carga
    const [LoadingCreatecombo, setLoadingCreatecombo] = useState(false); // Estado de carga

    const [reload, setReload] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [slsListHotelsSerch, setslsListHotelsSerch] = useState([initInfoHotels,]);
    const [isLoaded, setIsLoaded] = useState(true);

    const [selectedHotels, setSelectedHotels] = useState([]); // Estado para los hoteles seleccionados
    const [FormValuesCombo, setFormValuesCombo] = useState({
    name: "",
    description: "",
    percentage: 0,

  });
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [slsBranch, setslsBranches] = useState([initSelec2]);
  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
    percentage: 0,
    company: "",
    branch: "",
    minDate: "",
    maxDate: "",
    // useForAllBranches: false, // Nuevo campo para el checkbox
  });

    const [slsCompany, setslsCompany] = useState([initSelec]);
    const [code3, setCode3] = useState();
    const [maxDateDisabled, setMaxDateDisabled] = useState(true);
    const editorRef = useRef(null);
    
  

  useEffect(() => {
    console.log("<<<<<<isLoaded>>>>>>");
    console.log(isLoaded);

    const executeFetchLoadCombos = async () => {
      try {
        if (isLoaded === true) {
          await fetchLoadCombos();
          console.log("fetchLoadCombos ejecutado con éxito");
        }

        let selectCombos = [initSelec, ...(await fnPostCompaniesList())];

        setslsCompany(selectCombos);

        const fetchBranches = async () => {
          const branches = await fnGetAllBranch();
          setslsBranches([initSelec2, ...branches]);
        };
        fetchBranches();
      } catch (error) {
        console.error("Error en fetchLoadCombos:", error);
      } finally {
        // Cambiar el estado a false, independientemente de si hubo error o no
        setIsLoaded(false);
      }
    };

    executeFetchLoadCombos();
  }, [isLoaded]);

  useEffect(() => {
    if (formValues.company) {
      const fetchBranches = async () => {
        const branches = await fnGetAllBranch();
        if (Array.isArray(branches) && branches.length > 0) {
          setslsBranches([initSelec2, ...branches]);
        } else {
          setslsBranches([initSelec2]); // Si no hay datos, solo asigna initSelec2
        }
      };
      fetchBranches();
    }
  }, [formValues.company]);

  const fetchLoadCombos = async () => {
    console.log("en funcion de combos <<<<<<<<<<<<<<");

    console.log(!isLoaded);
    try {
      //   if (!isLoaded) {
      if (token === undefined || token === null) {
        token = getFromLocalStorage("TK");
      }

      /// ejecucion de codigo para precargar en caso de ser necesario

      //   }
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol,
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch,
      };

      const responseJsonCompanies = await axios.post(
        postAllCompaniesRoll(token),
        requestData
      );

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

    const fnGetAllBranch = async () => {
    try {
        const requestData = {
        companyId: formValues.company || idUserP.company, // Si formValues.company es vacío o no existe, usa idUserP.company
        };

        const responseJsonCompanies = await axios.post(
        getBranchDataByCompany(token),
        requestData
        );
        return responseJsonCompanies.data.data;
    } catch (error) {
        console.error("Error al cargar los combos 333", error);
    }
    };

const handleSaveCombo = async () => {
    const bodyDeals = {
    titulo: formValues.title,
    descripcion: formValues.description,
    porcentaje_descuento: Number(formValues.percentage),
    fecha_inicio: formValues.minDate, // Aquí se utiliza el valor actualizado
    fecha_fin: formValues.maxDate,   // Aquí se utiliza el valor actualizado
    id_hotel: 283032,
    id_empresa: Number(formValues.company),
    id_sucursal: Number(formValues.branch),
    };

    console.log('<<<<<<<<<<<<<<<<bodyDeals>>>>>>>>>>>>>>>>');
    console.log(bodyDeals);

    try {
      // const result = await saveExclusiveDeals(bodyDeals);
      // console.log(`Combo guardado para la sucursal:`, result);
    } catch (error) {
    console.error(`Error guardando combo para la sucursal:`, error);
    }
    };


    function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
    }

    const toggleReload = () => {
    if (!reload) {
      setReload(true);
      setLoading(true); // Inicia la carga cuando se hace clic en recargar

      setTimeout(() => {
        setIsLoaded(true);

        setReload(false);
        setLoading(false); // Termina la carga
      }, 2000);
    }
    };

const saveExclusiveDeals = async (body) => {
    try {
    const responseJson = await axios.post(PostCreateExclusiveDeals(), body);
    const DataResulthotels = responseJson.data.data;

    console.log(DataResulthotels);

    return DataResulthotels;
    } catch (error) {
    console.error("Error al cargar los módulos", error);
    } finally {
      setLoading(false); // Desactivar el estado de carga
    }
};

  // Función para verificar si un hotel está seleccionado
  const isHotelSelected = (hotelid) =>
    selectedHotels.some((selected) => selected.hotelid === hotelid);

  const selectHotel = (hotelid) => {
    setSelectedHotels((prevHotels) =>
      prevHotels.map((hotel) => ({
        ...hotel,
        flag: hotel.hotelid === hotelid, // Solo selecciona el hotel con el hotelid dado
      }))
    );
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // Permitir soltar
  };

  const handleDrop = (index) => {
    setSelectedHotels((prevHotels) => {
      const updatedHotels = [...prevHotels];
      const [movedHotel] = updatedHotels.splice(draggedIndex, 1); // Elimina el hotel arrastrado
      updatedHotels.splice(index, 0, movedHotel); // Inserta en la nueva posición
      return updatedHotels;
    });
    setDraggedIndex(null); // Restablecer el índice arrastrado
  };

  const handleInputChangeform = (e) => {
    const { name, value } = e.target; // Obtén el nombre y valor del input
    setFormValuesCombo((prevState) => ({
      ...prevState, // Mantén los valores anteriores
      [name]: value, // Actualiza solo el campo correspondiente
    }));
  };

  const handleInputChangeCompany = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      ...(name === "company" && { branch: "" }), // Reiniciar branch si cambia la compañía
    }));
  };



    const minDateRange = (current) => {
      return current.isAfter(DateTime.moment().subtract(1, 'day'));
    };
  
    const maxDateRange = (current) => {
      return current.isAfter(editorRef.current);
    };

    const minDateChange = (value) => {
        setMaxDateDisabled(false);
        editorRef.current = value;
        setFormValues((prevValues) => ({
          ...prevValues,
          minDate: value.format("YYYY-MM-DD"), // Guardar en formato adecuado
        }));
      };
      
      const maxDateChange = (value) => {
        setFormValues((prevValues) => ({
          ...prevValues,
          maxDate: value.format("YYYY-MM-DD"),
        }));
      };
      

  console.log("<<<<<<< formValues   >>>>>>> <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
  console.log(formValues);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-xl-6">

          <Panel>
            <PanelHeader>
              &nbsp;&nbsp;
              <button
                className="btn btn-xs btn-icon btn-circle btn-success"
                onClick={toggleReload}
              >
                <i className="fa fa-redo"></i>
              </button>
              &nbsp;&nbsp; create exclusive offers
            </PanelHeader>
            <PanelBody>
              <div className="lrow md-7px" style={{ margin: 2 }}>
                *Capture all required fields
              </div>
              {/* <div> */}
              <div
                className="lrow md-8px"
                // style={{
                //   margin: 4,
                //   maxHeight: "950px", // Altura máxima del contenedor principal
                //   overflowY: "auto", // Barra de desplazamiento vertical
                //   border: "1px solid #ccc", // Opcional: agregar borde para resaltar
                //   padding: "10px", // Espaciado interno
                // }}
              >
                {LoadingCreatecombo ? (
                  <div>Loading...</div> // Muestra un mensaje de carga mientras se obtiene la información
                ) : (
                  <form>
                    <div className="row mb-3 d-flex align-items-center">
                      <label
                        className="form-label col-form-label col-md-3 mb-0"
                        htmlFor="nombredestino"
                      >
                        Title*
                      </label>
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          type="text"
                          id="nombredestino"
                          name="title" // Nombre debe coincidir con la clave en el estado
                          value={formValues.title}
                          onChange={handleInputChangeCompany}
                        />
                      </div>
                    </div>

                    <div className="row mb-3 d-flex align-items-center">
                      <label
                        className="form-label col-form-label col-md-3 mb-0"
                        htmlFor="urlimagen"
                      >
                        Description*
                      </label>
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          type="text"
                          id="urlimagen"
                          name="description" // Nombre debe coincidir con la clave en el estado
                          value={formValues.description}
                          onChange={handleInputChangeCompany}
                        />
                      </div>
                    </div>

                    <div className="row mb-3 d-flex align-items-center">
                      <label
                        className="form-label col-form-label col-md-3 mb-0"
                        htmlFor="urlimagen"
                      >
                        discount percentage*
                      </label>
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          type="text"
                          id="urlimagen"
                          name="percentage" // Nombre debe coincidir con la clave en el estado
                          value={formValues.percentage}
                          onChange={handleInputChangeCompany}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                                    <label className="col-lg-4 col-form-label">Range Pickers</label>
                                    <div className="col-lg-8">
                                        <div className="row gx-2">
                                            <div className="col-6">
                                                <DateTime isValidDate={ minDateRange } inputProps={{ placeholder: 'Min Date' }} closeOnSelect={true} onChange={ minDateChange } />
                                            </div>
                                            <div className="col-6">
                                                {/* <DateTime isValidDate={ maxDateRange } inputProps={{ placeholder: 'Max Date', disabled: maxDateDisabled }} closeOnSelect={true} /> */}
                                                <DateTime isValidDate={maxDateRange} inputProps={{ placeholder: 'Max Date', disabled: maxDateDisabled }} closeOnSelect={true}onChange={maxDateChange} />

                                            </div>
                                        </div>
                                    </div>
                                </div>

                    <div>
                    &nbsp;&nbsp;
                      {/* Condición para mostrar el div de Companies y Branches si rol es 1 o 2 */}
                      {(idUserP.rol === 1 || idUserP.rol === 2) && (
                        <div className="row md-15px">
                          <label
                            className="form-label col-form-label col-md-3"
                            htmlFor="company"
                          >
                            Companies*
                          </label>
                          <div className="col-md-9">
                            <select
                              className="form-select mb-5px"
                              id="company"
                              name="company"
                              value={formValues.company}
                              onChange={handleInputChangeCompany}
                            >
                              {slsCompany.map((option, index) => (
                                <option
                                  key={"form_" + index}
                                  value={option.value_option}
                                >
                                  {option.name_option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}

                      {/* Condición para mostrar el div de Branches si rol es 1, 2, 4 o 6 */}
                      {(idUserP.rol === 1 ||
                        idUserP.rol === 2 ||
                        idUserP.rol === 4 ||
                        idUserP.rol === 6) && (
                        <div className="row md-15px">
                          <label
                            className="form-label col-form-label col-md-3"
                            htmlFor="branch"
                          >
                            Branches
                          </label>
                          <div className="col-md-9">
                            <select
                              className="form-select mb-5px"
                              id="branch"
                              name="branch"
                              value={formValues.branch}
                              onChange={handleInputChangeCompany}
                            >
                              {slsBranch.map((option, index) => (
                                <option
                                  key={"form_" + index}
                                  value={option.value}
                                >
                                  {option.text}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}

                    </div>

                    <div className="lrow md-15px" style={{ margin: 10 }}>
                        <button
                        type="button"
                        className="btn btn-green"
                        style={{ float: "right" }}
                        onClick={handleSaveCombo}
                        >
                        Save
                        </button>
                    </div>
                    </form>
                )}
                </div>
            </PanelBody>
            </Panel>
        </div>
        
        </div>
        <ReactNotifications />{" "}
    </div>
    );
}

export default CreateExclusiveDeals;
