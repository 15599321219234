/** @format */

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel.jsx";
import Highlight from "react-highlight";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { getFromLocalStorage } from "../../components/helps.js";
import {
  getAllBranch,
  postAllCompaniesRoll,
  getBranchDataByCompany,
  getModulesAndSubModules,
  PostRolesOrder,
  getModulesByUser,
  getUserBranch,
  putUserModules,
  addModule,
  addSubModule,
} from "../../utils/urls.js";
import { ReactTags } from "react-tag-autocomplete";

function AdminAccesNew() {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  const initSelec = { value: 0, text: "Seleccione una opcion" };
  const initlst = [
    {
      idModule: 0,
      module: "",
      status: false,
      subModule: [{ idSubModule: 0, submodule: "", status: false }],
    },
  ];

  const [DataFile, setDataFile] = useState(null);

  const [startDate, setStartDate] = useState(new Date());
  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsBranch, setslsBranch] = useState([initSelec]);
  const [slsRoles, setslsRoles] = useState([initSelec]);
  const [lstModules, setlstModules] = useState([initlst]);
  const handleChange = (date) => {
    setStartDate(date);
  };
  const [tags, setTags] = useState([initSelec]);
  const [suggestions, setSuggestions] = useState([{ value: 0, label: "" }]);
  const frmFormularios = useRef(null);
  const frmAddModule = useRef(null);
  const frmAddSubModule = useRef(null);
  const [modulesSw, setModulesSw] = useState({
    swPrivate: false,
    swAutenticated: false,
    swStatus: true,
  });
  const [subModulesSw, setSubModulesSw] = useState({
    swPrivate: false,
    swStatus: true,
  });

  const handleDelete = (i) => {
    setTags([initSelec]);
  };

  const handleAdd = (newTag) => {
    setTags([newTag]);
    console.log(newTag.value);
    changeUser(newTag.value);
  };
  function handleOnChange() {}

  useEffect(() => {
    const fetchLoadCombos = async () => {
      try {
        if (token === undefined || token === null) {
          token = getFromLocalStorage("TK");
        }
        let selectCombos = [initSelec, ...(await fnPostCompaniesList())];
        console.log(selectCombos);
        setslsCompany(selectCombos);
        selectCombos = [initSelec, ...(await fnPostRollsOrder())];
        setslsRoles(selectCombos);
        let listaModulos = await fnGetModules();
        setlstModules(listaModulos);
      } catch (error) {
        console.error("Error al cargar los combos ", error);
      }
    };
    fetchLoadCombos();
  }, []);

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol,
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch,
      };

      const responseJsonCompanies = await axios.post(postAllCompaniesRoll(token), requestData);

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const fnPostRollsOrder = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol,
      };

      const responseJsonCompanies = await axios.post(PostRolesOrder(token), requestData);

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const fnGetBranch = async (idCompany) => {
    try {
      const responseJsonBranch = await axios.post(getBranchDataByCompany(token), {
        companyId: idCompany,
      });
      return responseJsonBranch.data.data;
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const fnGetUserByBranch = async (idBranch) => {
    try {
      const responseJson = await axios.post(getUserBranch(token), {
        branchId: idBranch,
      });
      console.log("respuesta de usuarios->", responseJson.data.data);
      return responseJson.data.data;
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };
  const fnGetModulesByUser = async (idUser) => {
    try {
      const responseJson = await axios.post(getModulesByUser(token), {
        userId: idUser,
      });
      //console.log("respuesta del modulo por usuario ", responseJson);
      if (responseJson.status == 201) {
        const responseDadat = responseJson.data != undefined ? responseJson.data : null;
        if (responseDadat != null && responseDadat.success != undefined && responseDadat.success != null && responseDadat.success == true) {
          if (
            responseDadat.result != undefined &&
            responseDadat.result != null &&
            responseDadat.result == "success" &&
            (responseDadat.message == undefined || responseDadat.message == null) &&
            responseDadat.data != undefined &&
            responseDadat.data != null
          ) {
            //console.log("entro a la condicion donde si hay respuesta de modulos");
            let lstModulesResult = responseJson.data.data;
            //console.log("respuesta data data", lstModulesResult);
            if (lstModulesResult !== undefined && lstModulesResult != null && lstModulesResult.length > 0) {
              console.log("lista de modulos SWICHES->", lstModules);
              console.log("lista de respuesta ->", lstModulesResult);
              const newMod = lstModules.map((item) => {
                const itemModule = lstModulesResult.filter((x) => x.id_module == item.idModule);
                console.log("modules->", item.idModule);
                console.log("resultado de busqueda por modulo ==== ", itemModule);
                if (itemModule != undefined && itemModule != null && itemModule.length > 0) {
                  console.log("tiene mas de un elemento el areglo");
                  if (item.subModule.length > 0) {
                    console.log("el submodulo de los swiches tiene mas de un elemento");
                    const newSubMod = item.subModule.map((itemSubM) => {
                      console.log("lista de submodulos?? ->", itemSubM);
                      const statusSubModule = itemModule.find((x) => x.id_submodule === itemSubM.idSubModule);
                      const statusSb = statusSubModule != undefined && statusSubModule != null ? statusSubModule.status : false;
                      console.log("statusSubModule ->", statusSubModule);
                      console.log("statusSb ->", statusSb);
                      return { ...itemSubM, status: statusSb };
                    });
                    console.log("resultado de submodulo nuevo?  ->", newSubMod);
                    if (newSubMod.some((x) => x.status == true)) {
                      return { ...item, status: true, subModule: newSubMod };
                    } else {
                      return { ...item, status: false, subModule: newSubMod };
                    }
                  } else {
                    const statusModulo = itemModule.find((x) => x.id_submodule === 0);
                    return { ...item, status: statusModulo.status };
                  }
                } else {
                  return { ...item, status: false };
                }
              });
              console.log(newMod);
              setlstModules(newMod);
            } else {
              const newMod = lstModules.map((item) => {
                return { ...item, status: false };
              });
              setlstModules(newMod);
            }
          } else {
            const newMod = lstModules.map((item) => {
              return { ...item, status: false };
            });
            setlstModules(newMod);
          }
        } else {
          const newMod = lstModules.map((item) => {
            return { ...item, status: false };
          });
          setlstModules(newMod);
        }
      } else {
        const newMod = lstModules.map((item) => {
          return { ...item, status: false };
        });
        setlstModules(newMod);
      }
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const fnGetModules = async () => {
    try {
      console.log(getModulesAndSubModules(token));
      const responseJson = await axios.get(getModulesAndSubModules(token));
      console.log(responseJson);
      return responseJson.data.data;
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  
  const changeCompany = async (e) => {
    try {
      const selectedValue = e.target.value;

      setslsBranch([initSelec, ...(await fnGetBranch(selectedValue))]);
      // console.log("Selec Branch", slsBranch);
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };
  const changeBranche = async (e) => {
    try {
      const selectedValue = e.target.value;

      setSuggestions(await fnGetUserByBranch(selectedValue));
      // console.log("Selec Branch", slsBranch);
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };
  const changeUser = async (selectedValue) => {
    try {
      //const selectedValue = e.target.value;
      await fnGetModulesByUser(selectedValue);
      // console.log("Selec Branch", slsBranch);
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const hbdSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(frmFormularios.current);
    console.log(formData);
    const resultArray = Array.from(formData.entries()).map(([name, value]) => ({
      name,
      value,
    }));

    console.log(resultArray);
    let jsonData = { data: {} };
    resultArray.forEach((value) => {
      jsonData.data[value.name] = value.value;
    });
    console.log(JSON.stringify(jsonData));
  };
  const hbdSubmitModules = async (e) => {
    e.preventDefault();
    const formData = new FormData(frmFormularios.current);
    //console.log(formData.entries());
    const resultArray = Array.from(formData.entries()).map(([name, value]) => ({
      name,
      value,
    }));

    //console.log(resultArray);
    let jsonData = { data: {} };
    // console.log(tags);
    resultArray.push({ name: "idUser", value: tags[0].value });
    resultArray.forEach((value) => {
      jsonData.data[value.name] = value.value;
    });
    // console.log("envio del data ", JSON.stringify(jsonData));
    // console.log("envio del data 2->", { body: jsonData });
    const responseJson = await axios.put(putUserModules(token), {
      body: jsonData,
    });
    console.log(responseJson);
  };

  const hbdSubmitAddModules = async (e) => {
    e.preventDefault();

    const dataName = document.getElementById("txtModule").value;
    const dataIcon = document.getElementById("txtIcono").value;
    const dataElement = document.getElementById("txtElement").value;
    //console.log(modulesSw);
    //console.log(modulesSw.swAutenticated);
    let jsonData = {
      name: dataName,
      icon: dataIcon,
      element: dataElement,
      private: modulesSw.swPrivate,
      isautenticated: modulesSw.swAutenticated,
      status: modulesSw.swStatus,
    };
    // console.log(tags);

    //console.log("envio del data ", JSON.stringify(jsonData));
    //console.log("envio del data 2->", { body: jsonData });
    const responseJson = await axios.post(addModule(token), {
      body: jsonData,
    });
    console.log(responseJson);
  };
  const hbdSubmitAddSubModules = async (e) => {
    e.preventDefault();

    const dataName = document.getElementById("txtSubModule").value;
    const dataIcon = document.getElementById("txtSubIcono").value;
    const dataElement = document.getElementById("txtSubElement").value;
    const slsModule = document.getElementById("slsModule").value;
    //console.log(modulesSw);
    //console.log(modulesSw.swAutenticated);
    let jsonData = {
      id_module: slsModule,
      name: dataName,
      icon: dataIcon,
      element: dataElement,
      public: subModulesSw.swPrivate,
      status: subModulesSw.swStatus,
    };
    // console.log(tags);

    //console.log("envio del data ", JSON.stringify(jsonData));
    //console.log("envio del data 2->", { body: jsonData });
    const responseJson = await axios.post(addSubModule(token), {
      body: jsonData,
    });
    console.log(responseJson);
  };
  // const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChangeModule = (idModule) => {
    console.log("entro a cambiar el check???", idModule);
    setlstModules((prevModules) => prevModules.map((mod) => (mod.idModule === idModule ? { ...mod, status: !mod.status } : mod)));
  };
  const handleCheckboxChangeSubModule = (idModule, idSubModule) => {
    console.log("entro a cambiar el check???", idSubModule);
    setlstModules((prevModules) =>
      prevModules.map((mod) =>
        mod.idModule === idModule
          ? {
              ...mod,
              subModule: mod.subModule.map((sub) => (sub.idSubModule === idSubModule ? { ...sub, status: !sub.status } : sub)),
            }
          : mod
      )
    );
  };
  const hdbChangeModuleSwitch = (sw) => {
    setModulesSw((prevState) => ({ ...prevState, [sw]: !prevState[sw] }));
  };
  const hdbChangeSubModuleSwitch = (sw) => {
    setSubModulesSw((prevState) => ({ ...prevState, [sw]: !prevState[sw] }));
  };

  const hdbFormIndex = (panelNumber) => {
    console.log(panelNumber);
    if (panelNumber == 3) {
      document.getElementById("panelIndex").style.display = "none";
      document.getElementById("addModule").style.display = "none";
      document.getElementById("addSubModule").style.display = "block";
    } else if (panelNumber == 2) {
      document.getElementById("panelIndex").style.display = "none";
      document.getElementById("addModule").style.display = "block";
      document.getElementById("addSubModule").style.display = "none";
    } else if (panelNumber == 1) {
      document.getElementById("panelIndex").style.display = "block";
      document.getElementById("addModule").style.display = "none";
      document.getElementById("addSubModule").style.display = "none";
    }

  };
  return (
    <div id="AdminModules" >
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <Link to="/dashboard/v0">Home</Link>
        </li>
        <li className="breadcrumb-item active">Modulos</li>
      </ol>
      <h1 className="page-header">
        Modulos <small> Administrador de modulos</small>
      </h1>

      <div className="row">
        <div className="col-12">
          <div id="panelIndex" style={{ display: "block" }}>
            <Panel>
              <PanelHeader>Administrador de Permisos</PanelHeader>
              <PanelBody>
                <div className="row md-15px">
                  <div className="col-md-12  d-flex justify-content-end  mb-3">
                    <div className="col-md-2">
                      <button type="button" className="btn btn-primary" onClick={()=>hdbFormIndex(2)}>
                        Agregar Modulo
                      </button>
                    </div>
                    <div className="col-md-2">
                      <button type="button" className="btn btn-primary" onClick={()=>hdbFormIndex(3)}>
                        Agregar SubModulo
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row md-15px">
                  <div className="col-md-12">
                    <form id="frmFormularios" ref={frmFormularios} onSubmit={hbdSubmitModules}>
                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-3">Empresa</label>
                        <div className="col-md-9">
                          <select className="form-select" onChange={changeCompany} name="idCompany">
                            {slsCompany.map((option) => (
                              <option value={option.value_option}>{option.name_option}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-3">Sucursal</label>
                        <div className="col-md-9">
                          <select className="form-select" onChange={changeBranche} name="idBranch">
                            {slsBranch.map((option) => (
                              <option value={option.value}>{option.text}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-3">Tipo de Usuari/Rol</label>
                        <div className="col-md-9">
                          <select className="form-select" name="idRole">
                            {slsRoles.map((option, index) => (
                              <option value={option.value}>{option.text}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-3">Usuario</label>
                        <div className="col-md-9" style={{ position: "relative" }}>
                          <ReactTags
                            className="form-control"
                            id="autoCompleteUser"
                            name="idUser"
                            selected={tags}
                            suggestions={suggestions}
                            onDelete={handleDelete}
                            onAdd={handleAdd}
                          />
                          <span
                            style={{
                              position: "absolute",
                              display: "block",
                              bottom: ".5rem",
                              right: "1rem",
                            }}>
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                      </div>
                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-3">Modulo/Submodulo</label>

                        <label className="form-label col-form-label col text-center">Estatus</label>
                      </div>
                      <div className="row mb-15px">
                        <div>
                          {lstModules.map((option) => (
                            <div key={option.idModule} className="row mb-15px">
                              <label className="form-label col-form-label col-md-3 " htmlFor={"sw" + option.idModule}>
                                {option.module}
                              </label>
                              <div
                                className="col-md-9 align-middle form-check form-switch"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={option.status}
                                  onChange={() => handleCheckboxChangeModule(option.idModule)}
                                  id={"sw" + option.idModule}
                                  name={"idModule_" + option.idModule}
                                />
                              </div>

                              {/* Renderizar submódulos solo si el módulo está seleccionado */}
                              {option.status && option.subModule.length > 0 && (
                                <div key={"key_" + option.idSubModule}>
                                  {option.subModule.map((submodule) => (
                                    <div key={submodule.idSubModule} className="row mb-15px">
                                      <label
                                        className="form-label col-form-label col-md-3"
                                        htmlFor={"sw" + submodule.idSubModule}
                                        style={{ textAlign: "center" }}>
                                        {submodule.submodule}
                                      </label>
                                      <div
                                        className="col-md-9 align-middle form-check form-switch"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id={"sw" + submodule.idSubModule}
                                          checked={submodule.status}
                                          onChange={() => handleCheckboxChangeSubModule(option.idModule, submodule.idSubModule)}
                                          name={"idSubModule_" + submodule.idSubModule}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>

                      </div>

                      <div className="lrow md-15px">
                        <button type="submit" className="btn btn-green" style={{ float: "right" }}>
                          Guardar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </div>
          <div id="addModule" style={{ display: "none" }}>
            <Panel>
              <PanelHeader>Agregar Modulo</PanelHeader>
              <PanelBody>
                <div className="row md-15px">
                  <div className="col-md-12">
                    <form id="frmAddModule" ref={frmAddModule} onSubmit={hbdSubmitAddModules}>
                      <div className="row md-15px">
                        <div className="col-md-12  d-flex justify-content-end  mb-3">
                          <p>
                            <button type="submit" className="btn btn-primary">
                              Guardar
                            </button>
                            <button type="button" className="btn btn-primary" onClick={()=>hdbFormIndex(1)}>
                              Cancelar
                            </button>
                          </p>
                        </div>
                      </div>
                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-3">Nombre Modulo</label>
                        <div className="col-md-9">
                          <input type="text" className="form-control mb-5px" id="txtModule" />
                        </div>
                      </div>
                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-3">Icono</label>
                        <div className="col-md-9">
                          <input type="text" className="form-control mb-5px" id="txtIcono" />
                        </div>
                      </div>
                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-3">Element</label>
                        <div className="col-md-9">
                          <input type="text" className="form-control mb-5px" id="txtElement" />
                        </div>
                      </div>
                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-3">Privado</label>
                        <div className="col-md-9 align-middle form-check form-switch" style={{ display: "flex", justifyContent: "left" }}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={"swPrivate"}
                            checked={modulesSw.swPrivate}
                            onChange={() => hdbChangeModuleSwitch("swPrivate")}
                            name={"swPrivate"}
                          />
                        </div>
                      </div>
                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-3">Autenticacion</label>
                        <div className="col-md-9 align-middle form-check form-switch" style={{ display: "flex", justifyContent: "left" }}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={"swAutenticated"}
                            checked={modulesSw.swAutenticated}
                            onChange={() => hdbChangeModuleSwitch("swAutenticated")}
                            name={"swAutenticated"}
                          />
                        </div>
                      </div>
                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-3" htmlFor={"swStatus"}>
                          Estatus
                        </label>
                        <div className="col-md-9 align-middle form-check form-switch" style={{ display: "flex", justifyContent: "left" }}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={"swStatus"}
                            checked={modulesSw.swStatus}
                            onChange={() => hdbChangeModuleSwitch("swStatus")}
                            name={"swStatus"}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </div>
          <div id="addSubModule" style={{ display: "none" }}>
            <Panel>
              <PanelHeader>Agregar SubModulo</PanelHeader>
              <PanelBody>
                <div className="row md-15px">
                  <div className="col-md-12">
                    <form id="frmAddSubModule" ref={frmAddSubModule} onSubmit={hbdSubmitAddSubModules}>
                      <div className="row md-15px">
                        <div className="col-md-12  d-flex justify-content-end  mb-3">
                          <p>
                            <button type="submit" className="btn btn-primary">
                              Guardar
                            </button>
                            <button type="button" className="btn btn-primary" onClick={()=>hdbFormIndex(1)}>
                              Cancelar
                            </button>
                          </p>
                        </div>
                      </div>
                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-3">Modulo</label>
                        <div className="col-md-9">
                          <select className="form-select" name="idModuleAdd" id="slsModule">
                            <option value="0" selected="true">Seleccione un modulo</option>
                            {lstModules.map((option) => (
                              <option value={option.idModule}>{option.module}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-3">Nombre Sub Modulo</label>
                        <div className="col-md-9">
                          <input type="text" className="form-control mb-5px" id="txtSubModule" />
                        </div>
                      </div>
                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-3">Icono</label>
                        <div className="col-md-9">
                          <input type="text" className="form-control mb-5px" id="txtSubIcono" />
                        </div>
                      </div>
                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-3">Element</label>
                        <div className="col-md-9">
                          <input type="text" className="form-control mb-5px" id="txtSubElement" />
                        </div>
                      </div>
                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-3">Privado</label>
                        <div className="col-md-9 align-middle form-check form-switch" style={{ display: "flex", justifyContent: "left" }}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={"swPrivate"}
                            checked={subModulesSw.swPrivate}
                            onChange={() => hdbChangeSubModuleSwitch("swPrivate")}
                            name={"swPrivate"}
                          />
                        </div>
                      </div>

                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-3" htmlFor={"swStatus"}>
                          Estatus
                        </label>
                        <div className="col-md-9 align-middle form-check form-switch" style={{ display: "flex", justifyContent: "left" }}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={"swStatus"}
                            checked={subModulesSw.swStatus}
                            onChange={() => hdbChangeSubModuleSwitch("swStatus")}
                            name={"swStatus"}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminAccesNew;
