import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from './contextValid'; // Asegúrate de tener un hook o contexto de autenticación
import { fnGetDataUserByToken, fnValidUserPermission } from '../components/objects/fnCommon.tsx';
import { getInfoUserByToken } from '../utils/urls.js';
import { getFromLocalStorage } from '../components/helps.js';
import axios from 'axios';

const PrivateRoute = ({ children }) => {
    const { isAuthenticated, permissions, loading } = useAuth();
    const location = useLocation();
    const currentPath = location.pathname;
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const token = getFromLocalStorage("TK");
    const getURL = getInfoUserByToken(token);
    //console.log('isAuthenticated',isAuthenticated);
    //console.log('location', location);
    //console.log('currentPath', currentPath);
    //console.log('permissions', permissions);
    //console.log('!permissions.includes(currentPath)', !permissions.includes(currentPath));
    //console.log('children', children);
    useEffect(() => {
        axios.get(getURL).then(response => {
            console.log(response);
            if (response.data.result == 'success') {
                // Guardamos el resultado de la API
                console.log('infoData->', response.data.data[0]);
                setData(response.data.data[0]);
            } else {
                setError("error en la cosulta");
            }
        }).catch(err => {
            setError(err);
        });
    }, []);


    if (loading) {
        return <div>Loading...</div>; // O cualquier spinner/loading component
    }
    if (!isAuthenticated) {
        return <Navigate to="/user/login-v1" state={{ from: location }} />;
    }
    if (!permissions.includes(currentPath)) {
        // Redirige si el usuario no tiene permisos para acceder a la ruta
        return <Navigate to="/dashboard/v0" />;
    }
   
    // return children;
    // return React.Children.map(children, (child) => {
    //     return React.cloneElement(child, { infoUser: data });
    // });
    return <Outlet context={{ infoUser: data }}></Outlet>;
};

export default PrivateRoute;