
import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useOutletContext } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel.jsx";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { getFromLocalStorage, saveToLocalStorage } from "../../components/helps.js";
import { FormsTypeusers, inputsSelected, upFileprocesss, getInfoBranches, addBranch } from "../../utils/urls.js";
import { ReactNotifications, Store } from "react-notifications-component";
import { fnGetTable, fnValidUserPermission } from "../../components/objects/fnCommon.tsx";
import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";
import FormsDinamic from "../../components/objects/formsDinamic.tsx";

function InfoBranches() {
    let token = getFromLocalStorage("TK");
    
    // let idCompany = getFromLocalStorage("company");
    const infoUser = useOutletContext();
    const dataUser = infoUser.infoUser;
    const [info, setInfo] = useState([]);
    const [header, setHeader] = useState([]);
    const [headerMap, setheaderMap] = useState();
    const [formvisible, setformvisible] = useState(false);
    const [tblvisible, settblvisible] = useState(true);
    const [formValues, setFormValues] = useState({});
    const [idRegistro, setidRegistro] = useState("");

    useEffect(() => {
        const fetchTable = async () => {
            try {
                if (token === undefined || token === null) {
                    token = getFromLocalStorage("TK");
                }
                let idCompany = dataUser.id_company;
                let idBranch = dataUser.branch_id;
                if (fnValidUserPermission(dataUser)) {
                    idCompany = 0;
                    idBranch = 0;
                }
                // idCompany = 4;
                const url = getInfoBranches(idCompany, idBranch, token);
                const infoResult = await fnGetTable(url);
                setInfo(infoResult.data);
                setHeader(infoResult.head);
                setheaderMap(infoResult.headerMap);
            } catch (error) {
                console.error("error de fetchTable", error);
            }
        };
        if (infoUser != null) {
            fetchTable();
        }
    }, []);

    const fnEditRegister = (idRegister) => {
        setidRegistro(idRegister);
        fnChangeView();
    };
    const fnChangeView = () => {
        setformvisible(!formvisible);
        settblvisible(!tblvisible);
    };

    const fnViewDetail = (id) => {
        saveToLocalStorage("company", id);
        return <Navigate to='/datausers/datausers-infocompany' />;
    };


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleSubmit = async (data) => {
        console.log('Form submitted with data:', data);
        const saveData = await axios.post(addBranch(token), {
            body: data
        });
        console.log(saveData);
        //setIsFormVisible(false); // Ocultar el formulario después de enviar
    };
    const handleCancel = () => {
        fnChangeView();
    }
    ///
    if (infoUser == null) {
        return <div> Loading..</div>
    }

    return (
        <div>
            <ol className='breadcrumb float-xl-end'>
                <li className='breadcrumb-item'>
                    <Link to='/form/elements'>Home</Link>
                </li>
                <li className='breadcrumb-item'>
                    <Link to='/form/elements'>Form Stuff</Link>
                </li>
                <li className='breadcrumb-item active'>Sucursal</li>
            </ol>
            <h1 className='page-header'>Sucursal</h1>

            <div className='row' style={!tblvisible ? { display: "none" } : {}}>
                <div className='col-12'>
                    <Panel>
                        <PanelHeader>Informcion de la Empresa</PanelHeader>
                        <PanelBody>
                            <div className='row col-md-3' style={{ float: "inline-end" }}>
                                <button type='button' className='btn btn-outline-primary' onClick={fnChangeView}>
                                    Agregar Sucursal +
                                </button>
                            </div>
                            <div className='row col-md-12'>
                                <DataTableConsulting header={header} headerMap={headerMap} info={info} onViewDetail={fnViewDetail} onEditRegister={fnEditRegister} onDelete={setidRegistro} />
                            </div>
                        </PanelBody>
                    </Panel>
                </div>
            </div>

            <div className='row' style={!formvisible ? { display: "none" } : {}}>
                <div className='col-12'>
                    <Panel>
                        <PanelHeader>Form Branch</PanelHeader>
                        <PanelBody>
                            <FormsDinamic
                                inputsData={[]}
                                dataValue={{}}
                                id_form={6}
                                onChange={handleChange}
                                token={token}
                                onSubmit={handleSubmit}
                                onCancel={handleCancel}
                            />
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    );

}

export default InfoBranches;
