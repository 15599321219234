import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications, Store } from "react-notifications-component";
import {
  FormsTypeusers,
  getBranchDataByCompany,
  PostRolesOrder,
  CreateUser,
  UsersbyCompany,
  editUser,
  InfoUser,
  PostResetPass,
  postAllCompaniesRoll,
} from "../../utils/urls.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";

function EditUsersElements({ idUser, infoUser, dataUser, iduserSelect }) {
  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };
  const initSelec3 = { name: "", lastname: "", email: "", userId: "", rol: "" };
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  const [isLoaded, setIsLoaded] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    lastname: "",
    email: "",
    password: "",
    confPassword: "", // Cambié Confpassword a confPassword
    role: "",
    company: "",
    branch: "",
  });
  const [data, setData] = useState([]);

  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsRoll, setslsRoll] = useState([initSelec2]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);
  const [slsUsersbyComp, setslsUsersbyComp] = useState([initSelec3]);

  const [tableValues, setTableValues] = useState({ company: "" });
  const [isPanel1Visible, setIsPanel1Visible] = useState(false);

  const [name, setname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [formvisibleInfo, setformvisibleInfo] = useState(false);
  const [showPasswordInputs, setShowPasswordInputs] = useState(false);
  const [formData, setFormData] = useState({
    flag: true,
    passwordnew: "",
    confirmpassword: "",
  });

  const [PassGenerate, setPassGenerate] = useState("");


  useEffect(() => {
    const fetchLoadCombos = async () => {
      try {

        if (!isLoaded) {
          if (token === undefined || token === null) {
            token = getFromLocalStorage("TK");
          }
          let selectCombos = [initSelec, ...(await fnPostCompaniesList())];
          let selectCombosRol = [initSelec2, ...(await fnPostRollsOrder())];

          setslsCompany(selectCombos);
          setslsRoll(selectCombosRol);
        }
      } catch (error) {
        console.error("Error al cargar los combos ", error);
      }
    };
    fetchLoadCombos();
    setIsLoaded(true);
  }, [isLoaded]);

  useEffect(() => {
    if (formValues.company) {
      const fetchBranches = async () => {
        const branches = await fnGetAllBranch();
        setslsBranches([initSelec2, ...branches]);
      };
      fetchBranches();
    }
  }, [formValues.company]);

  const fnGetAllBranch = async () => {
    try {
      const requestData = {
        companyId: formValues.company,
      };

      const responseJsonCompanies = await axios.post(
        getBranchDataByCompany(token),
        requestData
      );
      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  useEffect(() => {
    if (iduserSelect > 0) {
      console.log(
        "Se tiene id de información para mandar a peticion <<<<<<<<<<<<<<"
      );

      console.log(iduserSelect);

      const fetchUser = async () => {
        const resutlinfo = await fnGetInfoUser();

        console.log(resutlinfo.data.data);
        console.log(resutlinfo.data.data.compañía);
        let datauser = resutlinfo.data.data;
        console.log("<<<<<<<<<<<resutlinfo>>>>>>>>>>> <<<<<<<<<<<<<<<<<<<");

        console.log(datauser);

        setname(resutlinfo.data.data.name);
        setlastname(resutlinfo.data.data.lastname);
        setemail(resutlinfo.data.data.email);
      };

      fetchUser();
    }
  }, [iduserSelect]);


  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol, 
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch
      };
  
      const responseJsonCompanies = await axios.post(postAllCompaniesRoll(token), requestData);

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const fnPostRollsOrder = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol,  
      };
  
      const responseJsonCompanies = await axios.post(PostRolesOrder(token), requestData);

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const fnGetInfoUser = async () => {
    try {
      const requestData = {
        idAuthorizedUser: idUserP.id,
        id: iduserSelect,
      };
      console.log("<<<<<<<requestData>>>>>>>");

      console.log(requestData);
      const infoUsers = axios.post(InfoUser(), requestData);
      return infoUsers;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const PostUserbyCompan = async (companyid) => {
    try {
      const requestData = {
        companyId: companyid,
      };

      console.log("<<<<<<<requestData>>>>>>>");

      console.log(requestData);

      const responseJsonCompanies = await axios.post(
        UsersbyCompany(token),
        requestData
      );
      console.log(responseJsonCompanies.data.data);

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  //   const handleInputChange = (e) => {
  //     const { name, value } = e.target;
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       [name]: value,
  //       ...(name === "company" && { branch: "" }), // Reiniciar branch si cambia la compañía
  //     }));
  //   };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      name,
      lastname,
      email,
      password,
      confPassword,
      role,
      company,
      branch,
    } = formValues;
    console.log("name, lastname, email, password, confPassword, company");

    console.log(name, lastname, email, password, confPassword, company);

    if (
      !name ||
      !lastname ||
      !email ||
      !password ||
      !confPassword ||
      !company
    ) {
      addNotification(
        "danger",
        "Error",
        "All fields are required",
        "top-right"
      );
      return;
    }

    if (password !== confPassword) {
      addNotification("danger", "Error", "Passwords do not match", "top-right");
      return;
    }

    try {
      const response = await axios.post(CreateUser(), {
        name,
        lastname,
        email,
        pwd: password,
        id_rol: Number(role),
        compañía: company,
        id_sucursal: Number(branch),
      });
      console.log("User created successfully:", response.data);

      addNotification(
        "success",
        "Success",
        "User created successfully",
        "top-right"
      );
      setFormValues({
        name: "",
        lastname: "",
        email: "",
        password: "",
        confPassword: "",
        role: "",
        company: "",
        branch: "",
      });
    } catch (error) {
      console.error("Error creating user:", error);
      addNotification("danger", "Error", "Failed to create user", "top-right");
    }
  };

  const handleSubmitEditUser = async (e) => {
    e.preventDefault();
    const { name, lastname, email, role, company, branch } = formValues;
    console.log("name, lastname, email,  company");

    console.log(name, lastname, email, company);

    if (!name || !lastname || !email || !company) {
      addNotification(
        "danger",
        "Error",
        "All fields are required",
        "top-right"
      );
      return;
    }

    try {
      const response = await axios.post(editUser(), {
        idAuthorizedUser: idUser,
        id: 3,
        name,
        lastname,
        email,
        id_rol: Number(role),
        compañía: company,
        id_sucursal: Number(branch),
      });

      addNotification(
        "success",
        "Success",
        "User created successfully",
        "top-right"
      );
      setFormValues({
        name: "",
        lastname: "",
        role: "",
        company: "",
        branch: "",
      });
    } catch (error) {
      console.error("Error creating user:", error);
      addNotification("danger", "Error", "Failed to create user", "top-right");
    }
  };

  function addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition) {
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
    });
  }

  const handleSelectChange = (event) => {
    const selectedCompany = event.target.value;

    console.log("<<<<<<<<<<<selectedCompany>>>>>>>>>>>");
    console.log(selectedCompany);

    setTableValues(selectedCompany);
    //  setTableValues((prevValues) => ({ ...prevValues, company: selectedCompany }));

    // Llama a la función con el companyId seleccionado
    // PostUserbyCompan(selectedCompany);
  };

  const handleInputChangeReset = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleResset = async (e) => {
    e.preventDefault();
    const { passwordnew, confirmpassword, flag } = formData;

    if (!flag) {
      // Validar que los campos no estén vacíos o indefinidos
      if (!passwordnew || !confirmpassword) {
        // console.error("Error: Both password fields must be filled.");
       addNotification("danger", "Error", "Both password fields must be filled", "top-center");

        return;
      }

      // Validar la longitud máxima de 8 caracteres
      if (passwordnew.length > 10 || confirmpassword.length > 10) {
        // console.error("Error: Passwords must not exceed 8 characters.");
        addNotification("danger", "Error", "Passwords must not exceed 8 characters", "top-center");

        return;
      }

      // Validar que las contraseñas coincidan
      if (passwordnew !== confirmpassword) {
        // console.error("Error: Passwords do not match.");
        addNotification("danger", "Error", "Passwords do not match.", "top-center");

        return;
      }
    }

    // Si pasa todas las validaciones
    console.log("Form data submitted:", formData);

    var body = {
      idAuthorizedUser: idUserP.id,
      id: iduserSelect,
      pass: formData.passwordnew,
      randompass: formData.flag,
    }
    console.log('<<<<<<<<<<<<body>>>>>>>>>>>>')

    console.log(body)

    try {
      const response = await axios.post(PostResetPass(token), body);
      console.log("User created successfully:", response);

      if (response.status === 201) {
        addNotification(
          "success",
          "Success",
          "updated password:", 
          "top-center"
        );

        setPassGenerate(response.data.data.pass)
        // alert("updated password:  " + response.data.data.pass) ;

      } else {
        addNotification("danger", "Error", "error deregistering", "top-center");
      }
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  const handleViewFormsReset = () => {
    /// mostrar formulario de vista
    setformvisibleInfo(!formvisibleInfo);
  };

  const copyToClipboard = () => {
    if (PassGenerate) {
      navigator.clipboard.writeText(PassGenerate);
      addNotification(
        "success",
        "Success",
        "password save to clipboard", 
        "top-center"
      );    }
  };

  return (
    <div>
      <div className="row mb-3">
        <div className="col-xl-6">
          <Panel>
            <PanelHeader>Edit Info Users</PanelHeader>

            <PanelBody>
              <form onSubmit={handleSubmitEditUser}>
                <div className="row mb-3 d-flex align-items-center">
                  <label
                    className="form-label col-form-label col-md-3 mb-0"
                    htmlFor="name"
                  >
                    Name*
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      value={name}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-3 d-flex align-items-center">
                  <label
                    className="form-label col-form-label col-md-3 mb-0"
                    htmlFor="lastname"
                  >
                    Lastname*
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      type="text"
                      id="lastname"
                      name="lastname"
                      value={lastname}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-3 d-flex align-items-center">
                  <label
                    className="form-label col-form-label col-md-3 mb-0"
                    htmlFor="email"
                  >
                    Email*
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                {/* Resto de select de roles, compañías y sucursales */}
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="role"
                  >
                    Types of role
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="role"
                      name="role"
                      value={formValues.role}
                      onChange={handleInputChange}
                    >
                      {slsRoll
                        .filter(
                          (option) => option.value !== 8 && option.value !== 1
                        )
                        .map((option, index) => (
                          <option key={"form_" + index} value={option.value}>
                            {option.text}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="company"
                  >
                    Companies*
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="company"
                      name="company"
                      value={formValues.company}
                      onChange={handleInputChange}
                    >
                      {slsCompany.map((option, index) => {
                        // Encuentra el nombre de la compañía según el valor de compañía
                        const isSelected =
                          formValues.company === option.value_option;
                        return (
                          <option
                            key={"form_" + index}
                            value={option.value_option}
                          >
                            {isSelected
                              ? option.name_option
                              : option.name_option}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="branch"
                  >
                    Branches
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="branch"
                      name="branch"
                      value={formValues.branch}
                      onChange={handleInputChange}
                    >
                      {slsBranch.map((option, index) => (
                        <option key={"form_" + index} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* <div className="lrow md-15px" style={{ margin: 10 }}>
<button type="submit" className="btn btn-green" style={{ float: "right" }}>
Actualizar
</button>
</div> */}

                <div className="lrow md-15px">
                  <button
                    type="submit"
                    className="btn btn-blue"
                    style={{ float: "right" }}
                  >
                    Actualizar
                  </button>

                  <button
                    type="button"
                    className="btn btn-green"
                    style={{ float: "left" }}
                    onClick={handleViewFormsReset}
                  >
                    Reset Password
                  </button>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>

        {/* <div className="col-xl-6"> */}
        <div
          className="col-xl-6"
          style={!formvisibleInfo ? { display: "none" } : {}}
        >
          <Panel>
            <PanelHeader>Reset Password</PanelHeader>

            <PanelBody>
              {/* <div className="row align-items-center">
                <label
                  className="form-label col-md-3 mb-0 text-md-end"
                  htmlFor="sw1"
                >
                  Random Password
                </label>
                <div className="col-md-9 d-flex align-items-center">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="sw1"
                      name="check"
                      role="switch"

                      // onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div> */}

<form onSubmit={handleResset}>
  <div className="row align-items-center mb-3">
    <label
      className="form-label col-md-3 mb-0 text-md-end"
      htmlFor="sw1"
    >
      Random Password
    </label>
    <div className="col-md-9 d-flex align-items-center">
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          id="sw1"
          name="flag" // Cambiado a 'flag' para que coincida con el estado
          role="switch"
          defaultChecked={true} // Valor por defecto: activado
          onChange={(e) => {
            handleInputChangeReset(e); // Actualiza 'flag' en el estado
            setShowPasswordInputs(!e.target.checked); // Alterna la visibilidad de los inputs
          }}
        />
      </div>

      {PassGenerate && (
            <div className="ms-3 d-flex align-items-center">
               <input
                className="form-control me-2"
                type="text"
                value={PassGenerate}
                readOnly // Campo de solo lectura
                placeholder="Generated password"
                style={{ width: "200px" }}
              />
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                onClick={copyToClipboard}
                title="Copy to clipboard"
              >
                {/* <FaCopy /> */}
                <img
                  src="/assets/img/copy.svg" // Ruta del ícono
                  alt="Copy Icon"
                  style={{ width: "20px", height: "20px", cursor: "pointer" }} // Ajusta el tamaño
                />
              </button>
            </div>
          )}

    </div>
  </div>

  {/* Inputs de contraseña, visibles solo si el switch está desactivado */}
  {showPasswordInputs && (
    <>
      <div className="row mb-3">
        <label
          className="form-label col-md-3 text-md-end"
          htmlFor="newPassword"
        >
          Password New
        </label>
        <div className="col-md-9">
          <input
            className="form-control"
            type="password"
            id="newPassword"
            name="passwordnew" // Cambiado para que coincida con el estado
            placeholder="Enter new password"
            onChange={handleInputChangeReset}
          />
        </div>
      </div>
      <div className="row mb-3">
        <label
          className="form-label col-md-3 text-md-end"
          htmlFor="confirmPassword"
        >
          Confirm Password
        </label>
        <div className="col-md-9">
          <input
            className="form-control"
            type="password"
            id="confirmPassword"
            name="confirmpassword" // Cambiado para que coincida con el estado
            placeholder="Confirm new password"
            onChange={handleInputChangeReset}
          />
        </div>
      </div>
    </>
  )}

  <div className="row md-15px">
    <button
      type="submit"
      className="btn btn-blue"
      style={{ float: "right" }}
    >
      Actualizar
    </button>
  </div>
</form>

            </PanelBody>
          </Panel>
        </div>
      </div>
      <ReactNotifications />{" "}
    </div>
  );
}

export default EditUsersElements;
