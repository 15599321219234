import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications } from "react-notifications-component";
import {
  getBranchDataByCompany,
  PostCombosRol,
  postAllCompaniesRoll,
  PostgetOfertas,
} from "../../utils/urls.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";
import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";
import { Modal, Button } from "react-bootstrap";
import  EditDeals  from "./experience-editdeals.js";


function ExclusiveDeals({ idUser, infoUser, dataUser }) {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

//   const header = ["id", "name", "description", "available", "id_empresa", "id_sucursal"];
//   const headerMap = {id: "Id", name: "Name", description: "Description", available: "Status", id_empresa: "Company", id_sucursal: "Branch"};

const header = [
    "id_oferta",
    "titulo",
    "descripcion",
    "porcentaje_descuento",
    "fecha_inicio",
    "fecha_fin",
    "activo",
    "condiciones",
    "id_hotel",
    "id_empresa",
    "id_sucursal",
    "creado_en",
    "empresa",
    "sucursal"
  ];
  
  const headerMap = {
    id_oferta: "Offer ID",
    titulo: "Title",
    descripcion: "Description",
    porcentaje_descuento: "Discount Percentage",
    fecha_inicio: "Start Date",
    fecha_fin: "End Date",
    activo: "Active",
    condiciones: "Conditions",
    id_hotel: "Hotel ID",
    id_empresa: "Company ID",
    id_sucursal: "Branch ID",
    creado_en: "Created At",
    empresa: "Company Name",
    sucursal: "Branch Name"
  };

  
  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };

  const [slsfirsload, setslsfirsload] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);
  const [formValues, setFormValues] = useState({
    company: "",
    branch: "",
  });
  const [slsListCombos, setslsListCombos] = useState([]);

  const [slsListDeals, setslsListDeals] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    const [formvisible, setformvisible] = useState(false);
    const [idRegistro, setidRegistro] = useState("");
    const [selectedRegister, setSelectedRegister] = useState(null);
    

  useEffect(() => {
    if (!slsfirsload) {
    //   fnPostListCombos();
      executeFetchLoadCombos();
      setslsfirsload(true);

      fnPostgetOfertas();
    }
  }, [idUserP, slsfirsload]);

  useEffect(() => {
    console.log('formValues.company <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< useEffect company', formValues.company)
    if (formValues.company != "0") {
      const fetchBranches = async () => {
        const branches = await fnGetAllBranch();
        setslsBranches([initSelec2, ...branches]);
        // setslsListCombos([])
        fnPostgetOfertas([]);

      };
      fetchBranches();
    }else{
        setslsBranches([initSelec2]);
    }
  }, [formValues.company]);

  useEffect(() => {
    if (formValues.branch) {
      const updateCombos = async () => {
        await 
        // fnPostListCombos(); // Asegúrate de actualizar la lista de combos
        fnPostgetOfertas();
      };
      updateCombos();
    }
  }, [formValues.branch]);

  const executeFetchLoadCombos = async () => {
    try {
      if (isLoaded) {
        await fetchLoadCombos();
      }

      const selectCombos = [initSelec, ...(await fnPostCompaniesList())];
      setslsCompany(selectCombos);

      const branches = await fnGetAllBranch();
      setslsBranches([initSelec2, ...branches]);
    } catch (error) {
      console.error("Error en fetchLoadCombos:", error);
    } finally {
      setIsLoaded(false);
    }
  };

  const fetchLoadCombos = async () => {
    try {
      if (!token) {
        token = getFromLocalStorage("TK");
      }
    } catch (error) {
      console.error("Error al cargar los combos:", error);
    }
  };

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol, 
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch
      };
  
      const responseJsonCompanies = await axios.post(postAllCompaniesRoll(token), requestData);

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const fnGetAllBranch = async () => {
    try {
      const requestData = {
        companyId: formValues.company || idUserP.company,
      };

      const response = await axios.post(
        getBranchDataByCompany(token),
        requestData
      );
      return response.data.data;
    } catch (error) {
      console.error("Error al cargar las sucursales:", error);
    }
  };

  const fnPostListCombos = async () => {
    try {
      const requestData = {
        id_empresa: formValues.company || idUserP.company,
        id_sucursal: formValues.branch || idUserP.branch,
        id_rol: formValues.branch || idUserP.rol,
      };

      const response = await axios.post(PostCombosRol(token), requestData);
      setslsListCombos(response.data.data || []);
    } catch (error) {
      console.error("Error al cargar los combos:", error);
    }
  };


  const fnPostgetOfertas = async () => {

    console.log('entro para obtener ofertas <<< ')
    try {
      const requestData = {
        id_empresa: formValues.company || idUserP.company,
        id_sucursal: formValues.branch || idUserP.branch,
        id_rol:  idUserP.rol,
      };

      console.log('requestData <<<')
      console.log(requestData)

      const response = await axios.post(PostgetOfertas(), requestData);
      setslsListDeals(response.data.data || []);
    } catch (error) {
      console.error("Error al cargar los combos:", error);
    }
  };

  const handleInputChangeCompany = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      ...(name === "company" && { branch: "" }),
    }));
  };

  const handleDetail = (id) => {
    console.log("Detail for combo ID:", id);
    // Lógica para detalle
  };
  
  const handleEdit = (id) => {
    console.log("Edit for combo ID:", id);
    // Lógica para editar
  };

  console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<slsListCombos>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

  console.log(slsListCombos)

  const fnViewDetail = (idRegister) => {
    const detailData = slsListDeals.find(
      (datainfo) =>
        datainfo.id_oferta === idRegister.id_oferta
    );

    setModalData(detailData);
    setShowModal(true);
  };

// const fnEditRegister = (idRegister) => {
// console.log('ID recibido para editar:', idRegister); // Asegúrate de imprimir el ID aquí
// };

const fnEditRegister = (register) => {
    console.log("Registro recibido para editar:", register); // Imprime el registro completo
    setidRegistro(register.id_oferta); // Guarda el ID
    setformvisible(true);
    setSelectedRegister(register); // Guarda el registro completo
    // setformvisibleAddDestini(false);
  };

const fnDeleteRegister = async (idRegister) => {
};


// const filteredResults = slsListDeals
// .filter((item) =>
//   item[filterType]?.toLowerCase().includes(filterText.toLowerCase())
// );


const filteredResults = slsListDeals.filter((item) => {
    // Verificar filtros de texto
    // const matchesFilterText = item[filterType]
    //   ?.toLowerCase()
    //   .includes(filterText.toLowerCase());
  
    // Verificar filtros por empresa y sucursal
    const matchesCompany =
      !formValues.company || item.id_empresa === parseInt(formValues.company);
    const matchesBranch =
      !formValues.branch || item.id_sucursal === parseInt(formValues.branch);
  
    // Devolver true solo si todos los filtros coinciden
    return  matchesCompany && matchesBranch;
  });

  
// .sort((a, b) => {
//   const dateA = new Date(`${a.fecha_compra || "1970-01-01"}T${a.hora_compra || "00:00:00"}`);
//   const dateB = new Date(`${b.fecha_compra || "1970-01-01"}T${b.hora_compra || "00:00:00"}`);
//   return dateB - dateA; // Orden descendente (más reciente primero)
// });


console.log('slsListDeals <<<<<<<<<<<<<<<')
console.log(slsListDeals)

  return (
    <div>
      
      <div className="row mb-3">
        <Panel>
          <PanelHeader>Info Exclusive Deals</PanelHeader>
          <PanelBody>
            <div>
              {(idUserP.rol === 1 || idUserP.rol === 2) && (
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="company"
                  >
                    Companies*
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="company"
                      name="company"
                      value={formValues.company}
                      onChange={handleInputChangeCompany}
                    >
                      {slsCompany.map((option, index) => (
                        <option
                          key={"form_" + index}
                          value={option.value_option}
                        >
                          {option.name_option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {(idUserP.rol === 1 ||
                idUserP.rol === 2 ||
                idUserP.rol === 4 ||
                idUserP.rol === 6) && (
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="branch"
                  >
                    Branches
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="branch"
                      name="branch"
                      value={formValues.branch}
                      onChange={handleInputChangeCompany}
                    >
                      {slsBranch.map((option, index) => (
                        <option key={"branch_" + index} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              *selecciona una opción de los combos activos.
            </div>

            <div className='row col-md-12'>
			<DataTableConsulting header={header} headerMap={headerMap} info={filteredResults} onViewDetail={fnViewDetail} onEditRegister={fnEditRegister} onDelete={fnDeleteRegister} />
		</div>
          </PanelBody>
        </Panel>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Details  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalData ? (
            <div>
            <p> <strong>Offer ID:</strong> {modalData.id_oferta} </p>
            <p> <strong>Title:</strong> {modalData.titulo} </p>
            <p> <strong>Description:</strong> {modalData.descripcion} </p>
            <p> <strong>Discount Percentage:</strong> {modalData.porcentaje_descuento} </p>
            <p> <strong>Start Date:</strong> {modalData.fecha_inicio} </p>
            <p> <strong>End Date:</strong> {modalData.fecha_fin} </p>
            <p> <strong>Active:</strong> {modalData.activo} </p>
            <p> <strong>Conditions:</strong> {modalData.condiciones} </p>
            <p> <strong>Hotel ID:</strong> {modalData.id_hotel} </p>
            <p> <strong>Company ID:</strong> {modalData.id_empresa} </p>
            <p> <strong>Branch ID:</strong> {modalData.id_sucursal} </p>
            <p> <strong>Created At:</strong> {modalData.creado_en} </p>
            <p> <strong>Company Name:</strong> {modalData.empresa} </p>
            <p> <strong>Branch Name:</strong> {modalData.sucursal} </p>

            </div>
            ) : (
            <p>Cargando...</p>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>


<div className="row" style={!formvisible ? { display: "none" } : {}}>
  <div className="col-12">
    <Panel>
      <PanelHeader>Edit record</PanelHeader>
      <PanelBody>
        {formvisible && (
        <EditDeals id={idRegistro} infoRegister={selectedRegister} />
        // <></>
        )}
      </PanelBody>
    </Panel>
  </div>
</div>

      </div>
      <ReactNotifications />
    </div>
  );
}

export default ExclusiveDeals;
