import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useOutletContext } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel.jsx";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { getFromLocalStorage, saveToLocalStorage } from "../../components/helps.js";
import { FormsTypeusers, inputsSelected, upFileprocesss, getInfoBranches, getInfoUsers, getInfoCompanies } from "../../utils/urls.js";
import { ReactNotifications, Store } from "react-notifications-component";
import { fnGetTable, fnValidUserPermission } from "../../components/objects/fnCommon.tsx";
import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";
import RegUsersElements from "./datausers-reguser.js";

function InfoUsers() {
	let token = getFromLocalStorage("TK");
	const infoUser = useOutletContext();
	// console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<infoUser>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

	// console.log(infoUser)
	const dataUser = infoUser.infoUser;

	// console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<infoUser>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

	// console.log(infoUser)

	const [infoCompany, setInfoCompany] = useState([]);
	const [headerCompany, setHeaderCompany] = useState([]);
	const [headerMapCompany, setheaderMapCompany] = useState();
	const [infoBranch, setInfoBranch] = useState([]);
	const [headerBranch, setHeaderBranch] = useState([]);
	const [headerMapBranch, setheaderMapBranch] = useState();
	const [info, setInfo] = useState([]);
	const [header, setHeader] = useState([]);
	const [headerMap, setheaderMap] = useState();
	const [formvisible, setformvisible] = useState(false);
	const [tblvisible, settblvisible] = useState(true);
	const [formValues, setFormValues] = useState({});
	const [idRegistro, setidRegistro] = useState("");
	useEffect(() => {
		const fetchTable = async () => {
			try {
				if (token === undefined || token === null) {
					token = getFromLocalStorage("TK");
				}
			
				let idCompany = dataUser.id_company;
				let idBranch = dataUser.branch_id;
				if (fnValidUserPermission(dataUser)) {
					idCompany = 0;
					idBranch = 0;
				}
				// idCom
				let url = getInfoCompanies(idCompany, token);
				// console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<url>>>>>>>>>>>>>>>>>>>>>>>>>>');
				// console.log(url);
				let infoResult = await fnGetTable(url);
				setInfoCompany(infoResult.data);
				setHeaderCompany(infoResult.head);
				setheaderMapCompany(infoResult.headerMap);

				url = getInfoBranches(idCompany, idBranch, token);
				infoResult = await fnGetTable(url);
				setInfoBranch(infoResult.data);
				setHeaderBranch(infoResult.head);
				setheaderMapBranch(infoResult.headerMap);
				
				url = getInfoUsers(idCompany, idBranch, 0, token);

				// console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<getInfoUsers>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

				// console.log(url)
				infoResult = await fnGetTable(url);
				setInfo(infoResult.data);
				setHeader(infoResult.head);
				setheaderMap(infoResult.headerMap);
			} catch (error) {
				console.error("error de fetchTable", error);
			}
		};
		fetchTable();
	}, []);

	const fnEditRegister = (idRegister) => {
		setidRegistro(idRegister);
		fnChangeView();
	};
	const fnChangeView = () => {
		setformvisible(!formvisible);
		settblvisible(!tblvisible);
	};

	const fnViewDetail = (id) => {
		saveToLocalStorage("company", id);
		return <Navigate to='/datausers/datausers-infocompany' />;
	};

	

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormValues({
			...formValues,
			[name]: type === "checkbox" ? checked : value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("Form Submitted", formValues);
	};

	///
	if (infoUser == null) {
		return <div> Loading..</div>
	}

	// console.log('<<<<<<<<<<<<<<<<<<<<headerMapBranch>>>>>>>>>>>>>>>>>>>>')

	// console.log(headerMapBranch)

	return (
		<div>
			<ol className='breadcrumb float-xl-end'>
				<li className='breadcrumb-item'>
					<Link to='/form/elements'>Home</Link>
				</li>
				<li className='breadcrumb-item'>
					<Link to='/form/elements'>Form Stuff</Link>
				</li>
				<li className='breadcrumb-item active'>Users</li>
			</ol>
			<h1 className='page-header'>Users</h1>

			<div className='row' style={!tblvisible ? { display: "none" } : {}}>
				<div className='col-12'>
					<Panel>
						<PanelHeader>Informcion de la Empresa</PanelHeader>
						<PanelBody>
							<div className="row col-md-12" style={{textAlign:"center"}}>
								<h3>Informacion de la empresa</h3>
							</div>
							<div className='row col-md-12'>
								<DataTableConsulting header={headerCompany} headerMap={headerMapCompany} info={infoCompany} viewOnly={true} />
							</div>
							<div className="row col-md-12" style={{ textAlign: "center" }}>
								<h3>Informacion de la Sucursal</h3>
							</div>
							<div className='row col-md-12'>
								<DataTableConsulting header={headerBranch} headerMap={headerMapBranch} info={infoBranch} viewOnly={true} />
							</div>

							<div className="row col-md-12" style={{ textAlign: "center" }}>
								<h3>Todos los Usuarios</h3>
							</div>

							<div className='row col-md-3' style={{ float: "inline-end" }}>
								<button type='button' className='btn btn-outline-primary' onClick={fnChangeView}>
									Agregar Usuario +
								</button>
							</div>
							<div className='row col-md-12'>
								<DataTableConsulting header={header} headerMap={headerMap} info={info} onViewDetail={fnViewDetail} onEditRegister={fnEditRegister} onDelete={setidRegistro} />
							</div>
						</PanelBody>
					</Panel>
				</div>
			</div>

			<div className='row' style={!formvisible ? { display: "none" } : {}}>
				<div className='col-12'>
					<Panel>
						<PanelHeader>Form Empresa</PanelHeader>
						<PanelBody>
							<RegUsersElements/>
						</PanelBody>
					</Panel>
				</div>
			</div>
		</div>
	);
}

export default InfoUsers;
