import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications, Store } from "react-notifications-component";
import {
  getBranchDataByCompany,
  PostCombosRol,
  postAllCompaniesRoll,
  PostCombosDetailsHotels,
  PostDeleteComboHotels,
} from "../../utils/urls.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";
import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";
import { Modal, Button } from "react-bootstrap";
import EditComboHotels from './experience-editcomboHotels.js';



function ListComboViewhotels({ idUser, infoUser, dataUser }) {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  const header = ["id", "name", "description", "available", "id_empresa", "id_sucursal"];
  const headerMap = {id: "Id", name: "Name", description: "Description", available: "Status", id_empresa: "Company", id_sucursal: "Branch"};

  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };

  const [slsfirsload, setslsfirsload] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);
  const [formValues, setFormValues] = useState({
    company: "",
    branch: "",
  });
  const [slsListCombos, setslsListCombos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [slsListInfoHotels, setslsListInfoHotels] = useState([]);
  const [formvisible, setformvisible] = useState(false);
  const [idRegistro, setidRegistro] = useState("");
  const [selectedRegister, setSelectedRegister] = useState(null);
  const [selectedCombo, setSelectedCombo] = useState(null);

    
  useEffect(() => {
    if (!slsfirsload) {
      fnPostListCombos();
      executeFetchLoadCombos();
      setslsfirsload(true);
    }
  }, [idUserP, slsfirsload]);

  useEffect(() => {
    if (formValues.company) {
      const fetchBranches = async () => {
        setformvisible(false)
        const branches = await fnGetAllBranch();
        
        if (Array.isArray(branches) && branches.length > 0) {
          setslsBranches([initSelec2, ...branches]);
        } else {
          setslsBranches([initSelec2]); // Si no hay datos, solo asigna initSelec2
        }
        setslsListCombos([])
      };
      fetchBranches();
    }
  }, [formValues.company]);

  useEffect(() => {
    if (formValues.branch) {
      const updateCombos = async () => {
        setformvisible(false)

        await fnPostListCombos(); // Asegúrate de actualizar la lista de combos
      };
      updateCombos();
    }
  }, [formValues.branch]);

  const executeFetchLoadCombos = async () => {
    try {
      if (isLoaded) {
        await fetchLoadCombos();
      }

      const selectCombos = [initSelec, ...(await fnPostCompaniesList())];
      setslsCompany(selectCombos);

      const branches = await fnGetAllBranch();
      setslsBranches([initSelec2, ...branches]);
    } catch (error) {
      console.error("Error en fetchLoadCombos:", error);
    } finally {
      setIsLoaded(false);
    }
  };

  const fetchLoadCombos = async () => {
    try {
      if (!token) {
        token = getFromLocalStorage("TK");
      }
    } catch (error) {
      console.error("Error al cargar los combos:", error);
    }
  };

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol, 
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch
      };
  
      const responseJsonCompanies = await axios.post(postAllCompaniesRoll(token), requestData);

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const fnGetAllBranch = async () => {
    try {
      const requestData = {
        companyId: formValues.company || idUserP.company,
      };

      const response = await axios.post(
        getBranchDataByCompany(token),
        requestData
      );
      return response.data.data;
    } catch (error) {
      console.error("Error al cargar las sucursales:", error);
    }
  };

  const fnPostListCombos = async () => {
    try {
      const requestData = {
        id_empresa: formValues.company || idUserP.company,
        id_sucursal: formValues.branch || idUserP.branch,
        id_rol: formValues.branch || idUserP.rol,
      };

      const response = await axios.post(PostCombosRol(token), requestData);
      setslsListCombos(response.data.data || []);
    } catch (error) {
      console.error("Error al cargar los combos:", error);
    }
  };

  const handleInputChangeCompany = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      ...(name === "company" && { branch: "" }),
    }));
  };

  const fnViewDetail = async (idRegister) => {
    try {
      setModalData([]); // 🟢 Limpia los datos previos antes de hacer la petición
  
      const requestData = {
        // id_empresa: idRegister.id_empresa,
        // id_sucursal: idRegister.id_sucursal,
        id: idRegister.id,
      };
  
      console.log("requestData <<<<", requestData);
  
      const response = await axios.post(PostCombosDetailsHotels(token), requestData);
  
      console.log("response info de combo <<<<<<<<<<<<", response.data.data.hotels);
  
      const hotelsData = response.data.data.hotels || [];
  
      setslsListInfoHotels(hotelsData);
      setModalData(hotelsData); // 🟢 Asegura que la data se actualiza correctamente
    } catch (error) {
      console.error("Error al cargar los combos:", error);
      setModalData([]); // 🛑 En caso de error, deja el modal vacío
    }
  
    setShowModal(true); // 🟢 Muestra el modal después de actualizar los datos
  };
  

const fnEditRegister = async (idRegister) => {
console.log('ID recibido para editar:', idRegister); // Asegúrate de imprimir el ID aquí


try {
  setModalData([]); // 🟢 Limpia los datos previos antes de hacer la petición

  const requestData = {
    // id_empresa: idRegister.id_empresa,
    // id_sucursal: idRegister.id_sucursal,
    id: idRegister.id,
  };

  console.log("requestData <<<<", requestData);

  const response = await axios.post(PostCombosDetailsHotels(token), requestData);

  console.log("response info de combo <<<<<<<<<<<<", response.data.data.hotels);

  const hotelsData = response.data.data.hotels || [];
  const comboData = response.data.data;


  // setslsListInfoHotels(hotelsData);
  // setModalData(hotelsData); 

setidRegistro(idRegister.id); // Guarda el ID
setformvisible(true);
setSelectedRegister(hotelsData); // mandar el array de hoteles completo.
setSelectedCombo(comboData) 

} catch (error) {
  console.error("Error al cargar los combos:", error);
  setModalData([]); // 🛑 En caso de error, deja el modal vacío
}



// setidRegistro(idRegister.id); // Guarda el ID
// setformvisible(true);
// setSelectedRegister(idRegister); // mandar el array de hoteles completo. 


};

const fnDeleteRegister = async (idRegister) => {

  console.log('<<<<<<<<<<<<<<<<<<<<<<<<idRegister>>>>>>>>>>>>>>>>>>>>>>>>')
  console.log(idRegister)

  try {
    setModalData([]); // 🟢 Limpia los datos previos antes de hacer la petición

    const requestData = {
      id: idRegister.id,
    };

    const response = await axios.post(PostDeleteComboHotels(token), requestData);

    console.log("response info de combo <<<<<<<<<<<<", response);

    if(response.status == 201){
      setslsfirsload(false);
      addNotification("success", "Success", "Destination updated successfully.", "top-center");
  }else{
    setslsfirsload(false);

      addNotification(
          "warning",
          "Partial Success",
          "Oops, there was an error during the update.",
          "top-center"
        );
  }

    const hotelsData = response.data.data.hotels || [];

    
  } catch (error) {
    console.error("Error al cargar los combos:", error);
  }
};

function addNotification(
  notificationType,
  notificationTitle,
  notificationMessage,
  notificationPosition,
  notificationContent
) {
  if (notificationContent) {
    notificationContent = (
      <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
        {/* <div className="flex-1 ps-2">
          <h6 className="mb-1">Christopher Struth</h6>
          <p className="mb-0">Bank Transfer</p>
        </div> */}
      </div>
    );
  }
  Store.addNotification({
    title: notificationTitle,
    message: notificationMessage,
    type: notificationType,
    insert: "top",
    container: notificationPosition,
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: { duration: 2000 },
    dismissable: { click: true },
    content: notificationContent,
  });
}

console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<modalData>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

console.log(modalData)

  return (
    <div>
      
      <div className="row mb-3">
        <Panel>
          <PanelHeader>List Combos Hotels</PanelHeader>
          <PanelBody>
            <div>
              {(idUserP.rol === 1 || idUserP.rol === 2) && (
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="company"
                  >
                    Companies*
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="company"
                      name="company"
                      value={formValues.company}
                      onChange={handleInputChangeCompany}
                    >
                      {slsCompany.map((option, index) => (
                        <option
                          key={"form_" + index}
                          value={option.value_option}
                        >
                          {option.name_option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {(idUserP.rol === 1 ||
                idUserP.rol === 2 ||
                idUserP.rol === 4 ||
                idUserP.rol === 6) && (
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="branch"
                  >
                    Branches
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="branch"
                      name="branch"
                      value={formValues.branch}
                      onChange={handleInputChangeCompany}
                    >
                      {slsBranch.map((option, index) => (
                        <option key={"branch_" + index} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              *selecciona una opción de los combos activos.
            </div>

            <div className='row col-md-12'>
								<DataTableConsulting header={header} headerMap={headerMap} info={slsListCombos} 
                onViewDetail={fnViewDetail} onEditRegister={fnEditRegister} onDelete={fnDeleteRegister} />
							</div>
          </PanelBody>
        </Panel>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Details  </Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>
          {modalData ? (
            <div>
    <p> <strong> id </strong> {modalData.id} </p>
    <p> <strong> name </strong> {modalData.name} </p>
    <p> <strong> description </strong> {modalData.description} </p>
    <p> <strong> available </strong> {modalData.available} </p>
    <p> <strong> create_at </strong> {modalData.create_at} </p>
    <p> <strong> update_at </strong> {modalData.update_at} </p>
    <p> <strong> id_empresa </strong> {modalData.id_empresa} </p>
    <p> <strong> id_sucursal </strong> {modalData.id_sucursal} </p>
            </div>
            ) : (
            <p>Cargando...</p>
            )}
        </Modal.Body> */}
<Modal.Body>
    {modalData && modalData.length > 0 ? (
      modalData.map((hotel, index) => (
        <div
          key={index}
          style={{
            borderBottom: "2px solid #ccc",
            paddingBottom: "15px",
            marginBottom: "15px"
          }}
        >
          <p>
            <strong>ID:</strong> {hotel.hotel_id}
          </p>
          <p>
            <strong>Nombre:</strong> {hotel.name}
          </p>
          <p>
            <strong>Precio:</strong> {hotel.precio ?? "No disponible"}
          </p>
          {hotel.imagen_hotel && (
            <img
              src={hotel.imagen_hotel}
              alt={hotel.name}
              width="100%"
              style={{ borderRadius: "5px", marginTop: "10px" }}
            />
          )}
        </div>
      ))
    ) : (
      <p>No hotel information to show ...</p>
    )}
  </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>


      <div className="row" style={!formvisible ? { display: "none" } : {}}>
  <div className="col-12">
    <Panel>
      <PanelHeader>Edit record</PanelHeader>
      <PanelBody>
        {formvisible && (
        <EditComboHotels id={idRegistro} infoRegister={selectedRegister} infocombo={selectedCombo}/>
        // <></>
        )}
      </PanelBody>
    </Panel>
  </div>
</div>

      </div>
      <ReactNotifications />
    </div>
  );
}

export default ListComboViewhotels;
