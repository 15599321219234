import React, { useState, useEffect, useRef } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications, Store } from "react-notifications-component";
import {
  getBranchDataByCompany,
  PostupHotelView,
  PostCreateCombosHotel,
  postAllCompaniesRoll,
  PostCreateExclusiveDeals,
  ListContryCitys,
  ListContrys,
  ListStatenames,
  ListCitys,
  getSearchHotelsCitys,
  PostCreateEvents,
  PostCreateCombosHotelEvents,
} from "../../utils/urls.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";
import SearchForm from "../../components/header/search/form.jsx";
import Highlight from 'react-highlight';
import DateTime from 'react-datetime';



function Discoverresorts({ idUser, infoUser, dataUser }) {
    let token = getFromLocalStorage("TK");
    let idUserP = getFromLocalStorage("Session");

    const initInfoHotels = [
        {
            "hotelid": 0,
            "name": "",
            "address1": "",
            "city": "",
            "stateprovince": "",
            "postalcode": "",
            "country": "",
            "nomach": false
        }
    ];

    const initCountrys = {
      country: "",
      citys: [
        {
          city: "",
          citycode: 0,
        },
      ],
    };

    const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
    const initSelec2 = { value: 0, text: "Seleccione una opcion" };
    const initSelecCountry = { country: 0, country_name: "Seleccione una opcion" };


    const [selectedIds, setSelectedIds] = useState([]);
    const [loading, setLoading] = useState(false); // Estado de carga
    const [LoadingCreatecombo, setLoadingCreatecombo] = useState(false); // Estado de carga

    const [reload, setReload] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [slsListHotelsSerch, setslsListHotelsSerch] = useState([initInfoHotels]);
    
    const [isLoaded, setIsLoaded] = useState(true);

    const [selectedHotels, setSelectedHotels] = useState([]); // Estado para los hoteles seleccionados
    const [FormValuesCombo, setFormValuesCombo] = useState({
    name: "",
    description: "",
    percentage: 0,

  });
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [slsBranch, setslsBranches] = useState([initSelec2]);
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    urlbanner: null,
    company: 0,
    branch: 0,
    checkin: "",
    checkout: "",
    npeoples: 2,
    nrooms: 1,
    useForAllBranches: false, // Nuevo campo para el checkbox
    country: "",
    statename: "",
    city: "", 
  });

  const [formValuesSearch, setFormValuesSearch] = useState({
    country: "",
    statename: "",
    city: "", 
  });

    const [slsCompany, setslsCompany] = useState([initSelec]);
    const [code3, setCode3] = useState();
    const [maxDateDisabled, setMaxDateDisabled] = useState(true);
    const [slsCompanyArray, setslsCompanyArray] = useState([initCountrys]);
    const [cityOptions, setCityOptions] = useState([]); // Nuevo estado para las ciudades

    const [OptionsCountry, setOptionsCountry] = useState([initSelecCountry]); // Nuevo estado para las ciudades
    const [OptionsStatename, setOptionsStatename] = useState([]); // Nuevo estado para las ciudades
    const [OptionsCitys, setOptionsCitys] = useState([]); // Nuevo estado para las ciudades


    const editorRef = useRef(null);
    
  

  useEffect(() => {

    const executeFetchLoadCombos = async () => {
      try {
        if (isLoaded === true) {
          await fetchLoadCombos();
          // console.log("fetchLoadCombos ejecutado con éxito");
        }

        let selectCombos = [initSelec, ...(await fnPostCompaniesList())];
        let selectContrys = [initCountrys, ...(await listContrysCitys())];


        setslsCompany(selectCombos);
        setslsCompanyArray(selectContrys);


        const fetchBranches = async () => {
          const branches = await fnGetAllBranch();
          setslsBranches([initSelec2, ...branches]);
        };
        fetchBranches();
      } catch (error) {
        console.error("Error en fetchLoadCombos:", error);
      } finally {
        // Cambiar el estado a false, independientemente de si hubo error o no
        setIsLoaded(false);
      }
    };

    executeFetchLoadCombos();
  }, [isLoaded]);

  useEffect(() => {
    if (formValues.company) {
      const fetchBranches = async () => {
        try {
          const branches = await fnGetAllBranch();
          setslsBranches([initSelec2, ...(Array.isArray(branches) ? branches : [])]);
        } catch (error) {
          console.error("Error obteniendo sucursales:", error);
          setslsBranches([initSelec2]); // Asigna solo la opción inicial en caso de error
        }
      };
      fetchBranches();
    }
  }, [formValues.company]);



  //////// combos opciones 
  useEffect(() => {
    const fetchSelectsCountry = async () => {
      try {
        let selectCountry = await fnlistContrys();
        setOptionsCountry(selectCountry.data)
        /// agregarlos a un variabla de estado 

      } catch (error) {
        console.error("Error obteniendo sucursales:", error);
        setOptionsCountry([])

      }
    };

    const fetchOptionsStatename = async () => {
      try {
        let selectStatename = await fnlistStatenames();
        setOptionsStatename(selectStatename.data)

      } catch (error) {
        console.error("Error obteniendo sucursales:", error);
        setOptionsStatename([])

      }
    };

    const fetchOptionsCitys = async () => {
      try {
        let selectCitys = await fnlistCitys();
        setOptionsCitys(selectCitys.data)

      } catch (error) {
        console.error("Error obteniendo sucursales:", error);
        setOptionsCitys([])

      }
    };

    fetchSelectsCountry();
    
    if (formValues.country) {
      fetchOptionsStatename();
    }

    if (formValues.country && formValues.statename) {
      fetchOptionsCitys();
    }

  }, [formValues.country, formValues.statename]);
  


  ///// traer el resultado de Hoteles por ciudades que utilice pais, estado, ciudad 
  useEffect(() => {
    const fetchSearchHoteles = async () => {
      try {
        const responseJson = await axios.get(
          getSearchHotelsCitys(token, formValues.country, formValues.statename, formValues.city)
        );
        return responseJson.data?.data || []; // ✅ Retorna array vacío si falla
      } catch (error) {
        console.error("Error obteniendo hoteles:", error);
        return [];
      }
    };
  
    const fnActivacion = async () => {
      setLoading(true);
      try {
        const listHotelsInfo = [initInfoHotels, ...(await fetchSearchHoteles())];
        setslsListHotelsSerch(listHotelsInfo);
      } catch (error) {
        console.error("Error al utilizar función de activación:", error);
      } finally {
        setLoading(false);
      }
    };
  
    // ✅ Validación para evitar peticiones innecesarias
    if (
      formValues.country && formValues.statename && formValues.city && 
      formValues.country.trim() !== '' && formValues.statename.trim() !== '' && formValues.city.trim() !== ''
    ) {
      console.log("✅ Se tienen los 3 valores, ejecutando búsqueda de hoteles...");
      fnActivacion();
    } else {
      console.log("❌ Faltan valores, reseteando la lista de hoteles...");
      setslsListHotelsSerch(initInfoHotels);
    }
  }, [formValues.country, formValues.statename, formValues.city]);
  

  const fetchLoadCombos = async () => {
    // console.log("en funcion de combos <<<<<<<<<<<<<<");

    // console.log(!isLoaded);
    try {
      //   if (!isLoaded) {
      if (token === undefined || token === null) {
        token = getFromLocalStorage("TK");
      }

      /// ejecucion de codigo para precargar en caso de ser necesario

      //   }
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol,
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch,
      };

      const responseJsonCompanies = await axios.post(
        postAllCompaniesRoll(token),
        requestData
      );

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

    const fnGetAllBranch = async () => {
    try {
        const requestData = {
        companyId: formValues.company || idUserP.company, // Si formValues.company es vacío o no existe, usa idUserP.company
        };

        const responseJsonCompanies = await axios.post(
        getBranchDataByCompany(token),
        requestData
        );
        return responseJsonCompanies.data.data;
    } catch (error) {
        console.error("Error al cargar los combos 333", error);
    }
    };


    const listContrysCitys = async () => {
      try {
        const responseJsonContrys = await axios.get(ListContryCitys());
        return responseJsonContrys.data;
      } catch (error) {
        console.error("Error al cargar los combos  11", error);
      }
    };

    const fnlistContrys = async () => {
      try {
        const responseJson = await axios.get(ListContrys(token));
        return responseJson.data;
      } catch (error) {
        console.error("Error al cargar los combos  country", error);
      }
    };

    const fnlistStatenames = async () => {
      try {
        const responseJson = await axios.get(ListStatenames(token, formValues.country));
        return responseJson.data;
      } catch (error) {
        console.error("Error al cargar los combos statenames", error);
      }
    };

    const fnlistCitys = async () => {
      try {
        const responseJson = await axios.get(ListCitys(token, formValues.country, formValues.statename));
        return responseJson.data;
      } catch (error) {
        console.error("Error al cargar los combos citys", error);
      }
    };


    function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
    };

    const isHotelSelected = (hotelid) =>
      selectedHotels.some((selected) => selected.hotelid === hotelid);
  

    const toggleReload = () => {
    if (!reload) {
      setReload(true);
      setLoading(true); // Inicia la carga cuando se hace clic en recargar

      setTimeout(() => {
        setIsLoaded(true);

        setReload(false);
        setLoading(false); // Termina la carga
      }, 2000);
    }
    };

    const minDateRange = (current) => {
      return current.isAfter(DateTime.moment().subtract(1, 'day'));
    };
  
    const maxDateRange = (current) => {
      return current.isAfter(editorRef.current);
    };

    const minDateChange = (value) => {
        setMaxDateDisabled(false);
        editorRef.current = value;
        setFormValues((prevValues) => ({
          ...prevValues,
          checkin: value.format("YYYY-MM-DD"), // Guardar en formato adecuado
        }));
      };
      
      const maxDateChange = (value) => {
        setFormValues((prevValues) => ({
          ...prevValues,
          checkout: value.format("YYYY-MM-DD"),
        }));
      };

      const handleCountryChange = (e) => {
        const selectedCountry = e.target.value;
    
        // Actualizar los valores del formulario
        setFormValues((prevValues) => ({
            ...prevValues,
            statename: selectedCountry,
            place: "", // Reiniciar el lugar al cambiar de país
        }));
    
        // Filtrar las ciudades correspondientes al país seleccionado
        const selectedCountryData = slsCompanyArray.find(
            (country) => country.country === selectedCountry
        );
    
        if (selectedCountryData) {
            // Ordenar las ciudades antes de asignarlas
            const sortedCities = selectedCountryData.citys.sort((a, b) =>
                a.city.localeCompare(b.city, 'es', { sensitivity: 'base' })
            );
            
            setCityOptions(sortedCities);
        } else {
            setCityOptions([]); // Si no hay datos, limpiar las opciones
        }
    };
    

      const validateForm = () => {
        return (
          formValues.name  && formValues.description
        );
      };

      const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
          addNotification(
            "danger",
            "Error",
            "Fill all required fields",
            "top-center"
          );
          return;
        }
 

  try {

    const PostDataUser2 = async () => {
      try {
        const formData = new FormData();
    
        // Obtener IDs de hoteles seleccionados
        const hotelsArray = selectedHotels.map(hotel => ({ hotel_id: hotel.hotelid }));
    
        const eventData = {
          evento: {
            id_evento: 1
          },
          hotels: hotelsArray 
        };
    
        // Convertir `eventData` a string y agregarlo al FormData
        formData.append("eventData", JSON.stringify(eventData));
    
        // Agregar imagen si está seleccionada
        if (formValues.urlimagen) {
          formData.append("url_imagen", formValues.urlimagen); // Asumiendo que es un File
        }
    
        // console.log("FormData contenido:  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
        // for (let [key, value] of formData.entries()) {
        //   console.log(key, value);
        // }
    
        // Enviar la solicitud con Axios
        const response = await axios.post(PostCreateEvents(token), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
    
        console.log("Respuesta del servidor:  <<<<<<<<<<", response.data);
        return response.data;
    
      } catch (error) {
        console.error("Error al enviar el formulario:", error);
        throw error;
      }
    };
    

        const PostDataUser = async (branchId) => {
          const formData = new FormData();
          formData.append("titulo", formValues.name);
          formData.append("descripcion", formValues.description); 
          formData.append("pais", formValues.country);
          formData.append("id_empresa", formValues.company);
          formData.append("id_sucursal", branchId);
          formData.append("checkin", formValues.checkin);
          formData.append("checkout", formValues.checkout);
          formData.append("nohabitaciones", formValues.nrooms);
          formData.append("nopersonas", formValues.npeoples);
          formData.append("url_imagen", formValues.urlimagen);
    
          console.log('<<<<<<<<<<<<<<<<<<formData>>>>>>>>>>>>>>>>>>')
          console.log("FormData contenido:");
          for (let [key, value] of formData.entries()) {
            console.log(key, value);
          }

          const response = await fetch(PostCreateEvents(token), {
            method: "POST",
            body: formData,
          });
    
          return response;
        };

        const PostAddComboHotels = async (id) => {
          console.log("Entró a función para agregar combo de hoteles por ID:", id);
        
          // Crear el array de hoteles con la estructura requerida
          const hotelsArray = selectedHotels.map(hotel => ({ hotel_id: hotel.hotelid }));
        
          // Construir el cuerpo de la solicitud con la estructura correcta
          const eventData = {
            evento: {
              id_evento: id
            },
            hotels: hotelsArray
          };
        
          console.log("El body para generar el combo de hoteles por eventos:");
          console.log(eventData);
        
          const response = await fetch(PostCreateCombosHotelEvents(token), {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(eventData), // Convertir a JSON
          });
        
          return response;
        };
        


    if (formValues.useForAllBranches) {
      // Itera sobre todas las sucursales y envía las peticiones


  // Itera sobre todas las sucursales y envía las peticiones
  const promises = slsBranch.slice(1).map((branch) => PostDataUser(branch.value));
  const responses = await Promise.all(promises);

  const successfulResponses = responses.filter((res) => res.status === 201);

  // Ejecutar PostAddComboHotels para cada respuesta exitosa
  const comboHotelsPromises = successfulResponses.map(async (res) => {
    const data = await res.json();
    return PostAddComboHotels(data.data.id);
  });

  const responsesCombos = await Promise.all(comboHotelsPromises); // Esperar que todas las llamadas a PostAddComboHotels se completen




      if (responsesCombos.length === slsBranch.length - 1) {
        addNotification("success", "Success", "Records saved", "top-center");
      } else {
        addNotification(
          "warning",
          "Partial Success",
          "Some records were not saved",
          "top-center"
        );
      }
    } else {
      // Si no está activado "Use for all branches", realiza un solo POST
  // Si no está activado "Use for all branches", realiza un solo POST
  const response = await PostDataUser(formValues.branch);
  
  if (response.status === 201) {
    const data = await response.json();
    const responsecombos = await PostAddComboHotels(data.data.id);

    if (responsecombos.status === 201) {
      addNotification("success", "Success", "Record saved", "top-center");
    } else {
      addNotification(
        "warning",
        "Error",
        "Error, some data is missing",
        "top-center"
      );
    }

  }


    }

    setFormValues({
      nombredestino: "",
      urlimagen: null,
      place: "",
      statename: "",
      show: true,
      idcliente: "",
      checkin: "",
      checkout: "",
      npeoples: 2,
      nrooms: 1,
      company: "",
      // useForAllBranches: false, // Nuevo campo para el checkbox
    });

          fetchLoadCombos();
          setCityOptions([])
      setIsLoaded(true);
  } catch (error) {
    
  }


      };

      const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
          setFormValues((prev) => ({ ...prev, [name]: files[0] }));
        } else {
          setFormValues((prev) => ({
            ...prev,
            [name]: value,
            ...(name === "company" && { branch: "" }),
            ...(name === "country" && { statename: "", city: "" }),
            ...(name === "statename" && { city: "" }),

          }));
        }
      };

      const handleHotelSelect = (hotel) => {
        setSelectedHotels((prev) => {
          const isAlreadySelected = prev.some(
            (selected) => selected.hotelid === hotel.hotelid
          );
    
          if (isAlreadySelected) {
            // Si ya está seleccionado, lo eliminamos
            return prev.filter((selected) => selected.hotelid !== hotel.hotelid);
          } else {
            // Si no está seleccionado, verificamos el límite
            if (prev.length >= 12) {
              // alert("You can only select up to 13 hotels.");
    
              addNotification(
                "warning",
                "Error",
                "You can only select up to 13 hotels.",
                "top-center",
                false
              );
    
              return prev; // No agregar si ya alcanzó el límite
            }
            return [...prev, hotel];
          }
        });
      };

      console.log("<<<<<<< selectedHotels   >>>>>>> <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
      console.log(selectedHotels);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-xl-6">

          <Panel>
            <PanelHeader>
              &nbsp;&nbsp;
              <button
                className="btn btn-xs btn-icon btn-circle btn-success"
                onClick={toggleReload}
              >
                <i className="fa fa-redo"></i>
              </button>
              &nbsp;&nbsp; Create event resorts
            </PanelHeader>
            <PanelBody>
              <div className="lrow md-7px" style={{ margin: 2 }}>
                *Capture all required fields
              </div>
              {/* <div> */}
              <div
                className="lrow md-8px">

                {LoadingCreatecombo ? (
                  <div>Loading...</div> // Muestra un mensaje de carga mientras se obtiene la información
                ) : (
                  <form onSubmit={handleSubmit}>
                  <div className="row mb-3 d-flex align-items-center">
                    <label
                      className="form-label col-form-label col-md-3 mb-0"
                      htmlFor="name"
                    >
                      Name module*
                    </label>
                    <div className="col-md-9">
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        name="name"
                        value={formValues.name}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="row mb-3 d-flex align-items-center">
                    <label
                      className="form-label col-form-label col-md-3 mb-0"
                      htmlFor="description"
                    >
                      Description module*
                    </label>
                    <div className="col-md-9">
                      <input
                        className="form-control"
                        type="text"
                        id="description"
                        name="description"
                        value={formValues.description}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
  
                  <div className="row mb-3 d-flex align-items-center">
                    <label
                      className="form-label col-form-label col-md-3 mb-0"
                      htmlFor="urlimagen"
                    >
                      Upload section banner*
                    </label>
                    <div className="col-md-9">
                      <input
                        className="form-control"
                        type="file"
                        id="urlimagen"
                        name="urlimagen"
                        // value={formValues.urlimagen}
                        onChange={handleInputChange} // No es necesario pasar el value porque es un campo de tipo archivo
                      />
                    </div>
                  </div>
  
                  <div className="row mb-3 d-flex align-items-center">
                    <label
                      className="form-label col-form-label col-md-3"
                      htmlFor="statename"
                    >
                      Select country*
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-select mb-5px"
                        id="country"
                        name="country"
                        value={formValues.country}
                        onChange={handleInputChange}
                      >
                        <option value=""> Select a country code </option> {/* Opción base */}
                        {OptionsCountry.map((option, index) => (
                          <option key={"form_" + index} value={option.country}>
                            {option.country}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row mb-3 d-flex align-items-center">
                    <label
                      className="form-label col-form-label col-md-3"
                      htmlFor="statename"
                    >
                      Select Statename*
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-select mb-5px"
                        id="statename"
                        name="statename"
                        value={formValues.statename}
                        onChange={handleInputChange}
                      >
                    <option value=""> select country before </option> {/* Opción base */}

                      {(OptionsStatename || []).map((option, index) => (
                        <option key={"form_" + index} value={option.stateprovince}>
                          {option.stateprovince}
                        </option>
                      ))}
                      </select>
                    </div>
                  </div>
  
                  <div className="row mb-3 d-flex align-items-center">
                    <label
                      className="form-label col-form-label col-md-3"
                      htmlFor="city"
                    >
                      Select city*
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-select mb-5px"
                        id="city"
                        name="city"
                        value={formValues.city}
                        onChange={handleInputChange}
                      >
                    <option value=""> Select country and state before </option> {/* Opción base */}

                      {(OptionsCitys || []).map((city, index) => (
                        <option key={index} value={city.city}>
                          {city.city}
                        </option>
                      ))}
                      </select>
                    </div>
                  </div>
  {/* compañias */}
                  <div className="row mb-3 d-flex align-items-center">
                    <label
                      className="form-label col-form-label col-md-3"
                      htmlFor="idcliente"
                    >
                      Companies*
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-select mb-5px"
                        id="company"
                        name="company"
                        value={formValues.company || 0}
                        onChange={handleInputChange}
                      >
                        {slsCompany.map((option, index) => (
                          <option
                            key={"form_" + index}
                            value={option.value_option}
                          >
                            {option.name_option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
  {/* sucursales */}
  
                  {(idUserP.rol === 1 ||
                    idUserP.rol === 2 ||
                    idUserP.rol === 4 ||
                    idUserP.rol === 6) && (
                    <div className="row mb-3 d-flex align-items-center">
                      <label
                        className="form-label col-form-label col-md-3"
                        htmlFor="branch"
                      >
                        Branches
                      </label>
                      <div className="col-md-9">
                        <select
                          className="form-select mb-5px"
                          id="branch"
                          name="branch"
                          value={formValues.branch || 0}
                          // value={formValues.idcliente || 0 }
                          onChange={handleInputChange}
                        >
                          {slsBranch.map((option, index) => (
                            <option key={"form_" + index} value={option.value}>
                              {option.text}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
  
                      <div className="row mb-3 d-flex align-items-center">
                                      <label className="col-lg-4 col-form-label">Select Checkin - Checkout </label>
                                      <div className="col-lg-8">
                                          <div className="row gx-2">
                                              <div className="col-6">
                                                  <DateTime isValidDate={ minDateRange } inputProps={{ placeholder: 'Min Date' }} closeOnSelect={true} onChange={ minDateChange } />
                                              </div>
                                              <div className="col-6">
                                                  {/* <DateTime isValidDate={ maxDateRange } inputProps={{ placeholder: 'Max Date', disabled: maxDateDisabled }} closeOnSelect={true} /> */}
                                                  <DateTime isValidDate={maxDateRange} inputProps={{ placeholder: 'Max Date', disabled: maxDateDisabled }} closeOnSelect={true}onChange={maxDateChange} />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
  
  
                <div className="row mb-3 d-flex align-items-center">
                    <label
                      className="form-label col-form-label col-md-3"
                      htmlFor="npeoples"
                    >
                      N° people*
                    </label>
  
                    <div className="col-md-4">
                      <input
                        className="form-control"
                        type="number"
                        id="npeoples"
                        name="npeoples"
                        value={formValues.npeoples}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
  
                  <div className="row mb-3 d-flex align-items-center">
                    <label
                      className="form-label col-form-label col-md-3"
                      htmlFor="nrooms"
                    >
                      N° rooms*
                    </label>
  
                    <div className="col-md-4">
                      <input
                        className="form-control"
                        type="number"
                        id="nrooms"
                        name="nrooms"
                        value={formValues.nrooms}
                        onChange={handleInputChange}
                      />
                    </div>

                    {formValues.company && (
                  <div className="row md-15px">
                    <label
                      className="form-label col-form-label col-md-3"
                      htmlFor="useForAllBranches"
                    >
                      Use for all branches
                    </label>
                    <div className="col-md-9 d-flex align-items-center">
                      <input
                        className="form-check-input is-valid"
                        type="checkbox"
                        id="useForAllBranches"
                        name="useForAllBranches"
                        style={{ marginRight: "10px" }}
                        checked={formValues.useForAllBranches || false}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            useForAllBranches: e.target.checked,
                          })
                        }
                      />
                    </div>
                  </div>
                )}
  
                  </div>
  
  
                  <div className="lrow md-15px" style={{ margin: 10 }}>
                    <button
                      type="submit"
                      className="btn btn-green"
                      style={{ float: "right" }}
                    >
                      + Add destination
                    </button>
                  </div>
                </form>
                )}
                </div>
            </PanelBody>
            </Panel>
        </div>


        <div className="col-xl-6">

<Panel>
  <PanelHeader>
    &nbsp;&nbsp;
    <button
      className="btn btn-xs btn-icon btn-circle btn-success"
      onClick={toggleReload}
    >
      <i className="fa fa-redo"></i>
    </button>
    &nbsp;&nbsp; Search Hotels for cities
  </PanelHeader>
  <PanelBody>
    <div className="lrow md-7px" style={{ margin: 2 }}>
      *Select up to 12 hotels as limit
    </div>
    {/* <div> */}
    <div
      className="lrow md-8px">

      {LoadingCreatecombo ? (
        <div>Loading...</div> // Muestra un mensaje de carga mientras se obtiene la información
      ) : (
        <form onSubmit={handleSubmit}>
        <div
                    className="lrow md-8px"
                    style={{
                      margin: 5,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center", // Centra los elementos horizontalmente
                      maxHeight: "650px", // Altura máxima del contenedor principal
                      overflowY: "auto", // Barra de desplazamiento vertical
                      border: "1px solid #ccc", // Opcional: agregar borde para resaltar
                      padding: "10px", // Espaciado interno
                    }}
                  >
                    {loading ? (
                      <div>Loading...</div>
                    ) : (
                      slsListHotelsSerch
                        .slice(1) // Omite el inicial
                        .sort((a, b) => a.name.localeCompare(b.name)) // Ordena alfabéticamente por nombre
                        .map((hotel) => (
                          <div
                            key={hotel.hotelid}
                            onClick={() => handleHotelSelect(hotel)} // Manejar clics
                            style={{
                              display: "flex",
                              flexDirection: "column", // Ajusta flex para columnas
                              alignItems: "flex-start", // Alinea contenido a la izquierda
                              marginBottom: "10px",
                              padding: "10px",
                              border: "2px solid #ccc",
                              borderRadius: "5px",
                              backgroundColor: isHotelSelected(hotel.hotelid)
                                ? "#60adf6" // Azul para seleccionado
                                : "#fff", // Blanco para no seleccionado
                              color: isHotelSelected(hotel.hotelid)
                                ? "#fff"
                                : "#000",
                              cursor: "pointer",
                              transition: "background-color 0.3s",
                              width: "700px", // Ancho deseado
                              maxWidth: "90%", // Asegura que no sobrepase el contenedor
                              alingc: "center",
                            }}
                          >
                            <div>
                              <strong>{hotel.name}</strong>
                              <p>
                                {hotel.country} - {hotel.stateprovince} - {hotel.city}
                              </p>
                              <p>Address: {hotel.address1}</p>
                            </div>
                          </div>
                        ))
                     )
                }
                  </div>


                  <div className="lrow md-15px" style={{ margin: 10 }}>
          {/* <button
            type="submit"
            className="btn btn-blue"
            style={{ float: "right" }}
          >
            Search Hotels
          </button> */}
        </div>


      </form>
      )}
      </div>
  </PanelBody>
  </Panel>
</div>
        
        </div>
        <ReactNotifications />{" "}
    </div>
    );
}

export default Discoverresorts;
