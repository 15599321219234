import axios from "axios";
import { getFromLocalStorage, saveToLocalStorage } from "../helps";
import { getInfoUserByToken, getMenuacces } from "../../utils/urls";

const token = getFromLocalStorage("TK");

export interface userInfo {
    id_usuario: number,
    user_code: string,
    id_rol: number,
    rol_name: string,
    id_company: number,
    company_name: string,
    company_code: string,
    company_status: boolean,
    banch_id: number,
    branch_name: string,
    branch_status: boolean,
    branch_code: string
};


export const fnValidSession = (callback) => {
    axios.get(getMenuacces(token)).then((data) => {
        callback(data.data.data);
    });
}

export const fngetMenus = (callback) => {
    axios.get(getMenuacces(token)).then((data) => {
        callback(data.data.data.menu);
    });
}
export const fnGetDataUserByToken = (callback) => {
    const getURL = getInfoUserByToken(token);
    axios.get(getURL).then(response => {
        console.log(response);
        if (response.data.result == 'success') {
            // Guardamos el resultado de la API
            callback(null, response.data.data[0]);
        } else {
            callback("error en la cosulta");
        }
    }).catch(err => {
        callback(err);
    });

}

export const fnGetTable = async (url: string) => {

    const responseJson = await axios.get(url);
    const result = responseJson.data.data;
    const header = Object.keys(result.head);
    const data = result.data;
    const headerMaps = result.head;
    const response = {
        head: header,
        data: data,
        headerMap: headerMaps,
    };

    return response;
};
export const fnCreateDataTable = (data: any, headers: any) => {

    //const responseJson = await axios.get(url);
    //const result = responseJson.data.data;
    const header = Object.keys(headers);
    //const data = result.data;
    //const headerMaps = result.head;
    const response = {
        head: header,
        data: data,
        headerMap: headers,
    };

    return response;
};

export const fnValidNullOrEmpty = (value: any) => {
    console.log('validNull=>', value);
    console.log('validNull=> typeOff', typeof value);
    if (value == undefined || value == null) {
        value = typeof value == 'number' ? 0 : typeof value == 'boolean' ? false : '';
    }
    return value;
}


export const fnValidUserPermission = (infoUser: userInfo) => {
    let resultValid = false;
    if (infoUser.id_rol == 1 && infoUser.id_company == 11 && infoUser.banch_id ==7 ){
        resultValid=true;
    }


    return resultValid;
}