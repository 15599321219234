import React from 'react';
import { getFromLocalStorage } from "../../components/helps.js";


interface DataTableProps {
    header: string[];
    headerMap: Record<string, string>;
    info: any[];
    onViewDetail?: (id: any) => void;
    onEditRegister?: (id: any) => void;
    onDelete?: (id: any) => void;
    viewOnly: boolean

}

const DataTableConsulting: React.FC<DataTableProps> = ({
    header,
    headerMap,
    info,
    onViewDetail,
    onEditRegister,
    onDelete,
    viewOnly = false
}) => {
    const validHeader = Array.isArray(header) ? header : [];

    let idUserP = getFromLocalStorage("Session");

    // Verificar el rol de idUserP
    const canViewDetails = [1, 2, 4, 6, 3, 5, 7].includes(idUserP?.rol);
    const canEditDelete = [1, 2, 4, 6].includes(idUserP?.rol);

    return (
        <div className='table-responsive'>
            <table className='table table-bordered table-striped'>
                <thead>
                    <tr>
                        {validHeader.map((item, index) => (
                            // <th key={"head_" + index} style={item.includes("_id") || item.includes("id_") ? { display: "none" } : {}}>
                            //     {headerMap[item] || item}
                            // </th>
                            <th key={"head_" + index} style={["some_other_id"].includes(item) ? { display: "none" } : {}}>
                                    {headerMap[item] || item}
                            </th>
                        ))}
                        {(!viewOnly && (onViewDetail || onEditRegister || onDelete)) && <th>Acciones</th>}
                    </tr>
                </thead>
                <tbody>
                    {info.map((item, index) => (
                        <tr key={"body-" + index}>
                            {validHeader.map((headerItem, index2) => (
                                // <td key={"body_" + index + "_" + index2} style={headerItem.includes("_id") || headerItem.includes("id_") ? { display: "none" } : {}}>
                                //     {typeof item[headerItem] === "boolean" ? (item[headerItem] ? "Active" : "Inactive") : item[headerItem]}
                                // </td>

                                <td
                                    key={"body_" + index + "_" + index2}
                                    // style={headerItem.includes("_id") || headerItem.includes("id_") ? { display: "none" } : {}}
                                    title={typeof item[headerItem] === "string" ? item[headerItem] : ""}
                                >
                                    {typeof item[headerItem] === "boolean"
                                        ? (item[headerItem] ? "Active" : "Inactive")
                                        : typeof item[headerItem] === "string" && item[headerItem].length > 30
                                            ? item[headerItem].slice(0, 30) + "..."
                                            : item[headerItem]}
                                </td>

                            ))}
                            {(!viewOnly && (onViewDetail || onEditRegister || onDelete)) && (
                                <td key={"body_" + index + "_Accion"} style={{ textAlign: "center" }}>
                                    <div className='btn-group' style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
                                        {canViewDetails && onViewDetail && (
                                            <button
                                                type="button"
                                                className="btn btn-info"
                                                style={{
                                                    margin: "5px",
                                                    width: "80px",
                                                    height: "35px",
                                                    padding: "5px 10px",
                                                    borderRadius: "8px",  // Bordes redondeados
                                                    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)" // Sombreado suave
                                                }}
                                                onClick={() => onViewDetail(item)}
                                            >
                                                See Detail
                                            </button>
                                        )}
                                        {/* {canEditDelete && ( */}
                                        <>
                                            {onEditRegister && (
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    style={{
                                                        margin: "5px",
                                                        width: "80px",
                                                        height: "35px",
                                                        padding: "5px 10px",
                                                        borderRadius: "8px",  // Bordes redondeados
                                                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)" // Sombreado suave
                                                    }}
                                                    onClick={() => onEditRegister(item)}
                                                >
                                                    Edit
                                                </button>
                                            )}
                                            {onDelete && (
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    style={{
                                                        margin: "5px",
                                                        width: "80px",
                                                        height: "35px",
                                                        padding: "5px 10px",
                                                        borderRadius: "8px",  // Bordes redondeados
                                                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)" // Sombreado suave
                                                    }}
                                                    onClick={() => onDelete(item)}
                                                >
                                                    Eliminate
                                                </button>
                                            )}
                                        </>
                                        {/* // )} */}
                                    </div>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};



export default DataTableConsulting;
