import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import {
  allRatePointsCompany,
  saveGetprices,
} from "./../../utils/urls.js";

const arraycompanys = [
  { idcompany: 1, namecompany: "Tukmeintest" },
  { idcompany: 2, namecompany: "Tixstest" },
];

let arrayrequest = {
  dt_Kangoo_vs_WSentero: "30",
  dt_Kangoo_vs_WSdecimas: "30",
  dt_rate_points_max: "30",
  dt_Ponderación_Markup_points: "25",
  dt_Ponderación_Commission_points: "35",
  dt_Ponderación_Save_points: "30",
  dt_Markup_points: 7.5,
  dt_Commission_points: 10.5,
  dt_Commission_points_new: 10.5,
  dt_Save_points: 9,
  dt_USD_Markup: 7.5,
  dt_USD_Commission: 10.5,
  dt_USD_Commission_new: 10.5,
  dt_USD_Save: 9,
  dt_Internal_Room_price: 107.5,
  dt_Room_price_1: 118,
  dt_Markup_rate: 0.075,
  dt_Commission_rate: 0.08898305084745763,
  dt_Hotel_save: 0.09230769230769231,
  dt_decimal_difference: -26.7,
  dt_Markup_rate_p: "7.5 %",
  dt_Commission_rate_p: "8.9 %",
  dt_Commission_rate_p_new: "8.9 %",
  dt_Hotel_save_p: "9.2 %",
  dt_Hotel_pricefianl_p: 127,
  dt_Hotel_pricefianl_p_new: 127,
};

function ViewRatePointsUsed() {
  const [darkTheme, setDarkTheme] = useState(false);

  const [newinfotable, setnewinfotable] = useState(false);

  const [precio1, setprecio1] = useState(0);
  const [precio2, setprecio2] = useState(0);
  const [tax1, settax1] = useState(0);
  const [flagspoints, setflagspoints] = useState(false);
  const [newRatePoint, setnewRatePoint] = useState(0);
  const [defaultMarkpoints, setdefaultMarkpoints] = useState(0);
  const [defaultCompoints, setdefaultCompoints] = useState(0);
  const [arrayResult, setarrayResult] = useState({});


  useEffect(() => {
    // fetch(allRatePointsCompany(1))
    //   .then((response) => response.json())
    //   .then((data) => {
    //    //console.log("<<<<<<<<<data>>>>>>>>>");
    //    //console.log(data);
    //   })
    //   .catch((error) => console.error(error));
  }, []);

  const handleOnChangeCreateRegist = () => {
    if (
      precio1 === 0 ||
      precio2 === 0 ||
      tax1 === 0 ||
      newRatePoint === 0 ||
      defaultMarkpoints === 0 ||
      defaultCompoints === 0
    ) {
     //console.log('Todos los valores deben ser diferentes de 0');
      return;
    }

    fetch(saveGetprices(precio1, precio2, tax1, flagspoints, newRatePoint, defaultMarkpoints, defaultCompoints))
      .then((response) => response.json())
      .then((data) => {
       //console.log('respuesta de peticion get');
       //console.log(data);
        setarrayResult(data)
      })
      .catch((error) => console.error(error));
  };

  const formatKey = (key) => {
    return key
      .replace(/^dt_/, '') // Elimina el prefijo 'dt_'
      .replace(/_/g, ' '); // Reemplaza los guiones bajos por espacios
  };


 //console.log('impresion despues de consulta')
 //console.log(arrayResult)

  return (
    <div>
      <div className="row mb-3">
        <div className="col-xl-6">
          <Panel>
            <PanelHeader>VALUES</PanelHeader>
            <PanelBody>
              <form>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Price 1
                  </label>
                  <div className="col-md-9">
                    <input
                      type="number"
                      className="form-control mb-5px"
                      value={precio1}
                      onChange={(e) => setprecio1(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Price 2
                  </label>
                  <div className="col-md-9">
                    <input
                      type="number"
                      className="form-control mb-5px"
                      value={precio2}
                      onChange={(e) => setprecio2(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Tax
                  </label>
                  <div className="col-md-9">
                    <input
                      type="number"
                      className="form-control mb-5px"
                      value={tax1}
                      onChange={(e) => settax1(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mb-0">
                  <label className="form-label col-form-label col-md-3">
                    Flagspoints
                  </label>
                  <div className="col-md-9 pt-2">
                    <div className="form-check form-switch mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={flagspoints}
                        onChange={(e) => setflagspoints(e.target.checked)}
                        id="flexSwitchCheckChecked"
                      />
                    </div>
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    newRatePoin
                  </label>
                  <div className="col-md-9">
                    <input
                      type="number"
                      className="form-control mb-5px"
                      value={newRatePoint}
                      onChange={(e) => setnewRatePoint(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                  Default  Markup points
                  </label>
                  <div className="col-md-9">
                    <input
                      type="number"
                      className="form-control mb-5px"
                      value={defaultMarkpoints}
                      onChange={(e) => setdefaultMarkpoints(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                  Default Commission points
                  </label>
                  <div className="col-md-9">
                    <input
                      type="number"
                      className="form-control mb-5px"
                      value={defaultCompoints}
                      onChange={(e) => setdefaultCompoints(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-7 offset-md-3">
                    <button
                      type="button"
                      className="btn btn-primary w-100px me-5px"
                      onClick={handleOnChangeCreateRegist}
                    >
                      Calculate
                    </button>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
        <div className="col-xl-6">
        <Panel>
            <PanelHeader>Breakdown of calculated values </PanelHeader>

            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                <table className="table">
                  <thead>
                    <tr>
                      {/* <th>Point</th> */}
                      <th>Description</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                  {Object.entries(arrayResult).map(([key, value]) => (
                      <tr key={key}>
                        {/* <td>{item.id_eventweenk}</td> */}
                        <td>{formatKey(key)}</td>
                        <td>{value}</td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Panel>
        </div>
      </div>
    </div>
  );
}

export default ViewRatePointsUsed;
