import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
} from "./../../components/panel/panel.jsx";
import {
  getinfoTableMap,
} from "./../../utils/urls.js";

// Asumiendo que tienes la información del arraytest de ejemplo

function ViewCitysMaped() {

  const [arrayResult, setArrayResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCity, setSelectedCity] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Estado para la página seleccionada
  const totalPages = 206; // Total de páginas

  useEffect(() => {
    setLoading(true);
    fetch(getinfoTableMap(currentPage))
      .then((response) => response.json())
      .then((data) => {
       //console.log("<<<<<<<<<data>>>>>>>>>");
       //console.log(data);
        if (Array.isArray(data)) {
          setArrayResult(data);
        } else {
          console.error("Expected an array but received:", data);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [currentPage]);

  const openModal = (city) => {
    setSelectedCity(city);
  };

  const closeModal = () => {
    setSelectedCity(null);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const pagesToShow = 10;
    const firstPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(totalPages, firstPage + pagesToShow - 1);

    let pages = [];
    for (let i = firstPage; i <= lastPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`btn btn-sm ${currentPage === i ? "btn-primary" : "btn-secondary"}`}
        >
          {i}
        </button>
      );
    }

    const hasPrevious = firstPage > 1;
    const hasNext = lastPage < totalPages;

   //console.log('pagina para mostrar <<<<< ')

   //console.log(currentPage)

    return (
      <div className="pagination" style={{ margin: '20px' }}>
        {hasPrevious && (
          <button className="btn btn-sm btn-secondary" onClick={() => handlePageChange(firstPage - 1)}>
            &laquo; Prev
          </button>
        )}
        {pages}
        {hasNext && (
          <button className="btn btn-sm btn-secondary" onClick={() => handlePageChange(lastPage + 1)}>
            Next &raquo;
          </button>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="row mb-3">

      {renderPagination()}

        <div className="col-xl-12">
          <Panel>
            <PanelHeader>Info mapped cities</PanelHeader>
            <div style={{ maxHeight: "1000px", overflowY: "auto" }}>
              {loading ? (
                <p style={{ textAlign: 'center', fontSize: '2.5em'  }}>Loading...</p>
              ) : (
                <table className="table table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th>City</th>
                      <th>Id City</th>
                      <th>Country</th>
                      <th>N° hotels mapped</th>
                      <th>Full mapped</th>
                      <th>both providers</th>
                      <th>onlyNuitee</th>
                      <th>unmapped</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arrayResult.map((cityInfo, index) => (
                      <tr key={index}>
                        <td>{cityInfo.city}</td>
                        <td>{cityInfo.idcity}</td>
                        <td>{cityInfo.country}</td>
                        <td>
                          {cityInfo.countHotels}{" "}
                          <a href="#" onClick={() => openModal(cityInfo)}>
                            view.
                          </a>
                        </td>
                        <td>
                          {cityInfo.fullmapped ? (
                            <img
                              src="/assets/img/icons/approved.png"
                              width="25"
                              alt=""
                              className="rounded"
                            />
                          ) : (
                            <img
                              src="/assets/img/icons/rejected.png"
                              width="25"
                              alt=""
                              className="rounded"
                            />
                          )}
                        </td>
                        <td>{cityInfo.countHotelsComplete}</td>
                        <td>{cityInfo.countonlyNuitee}</td>
                        <td>{cityInfo.unmapped}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </Panel>
        </div>
      </div>

      {/* Paginación */}
      

      {/* Modal para mostrar la lista de hoteles */}
      {selectedCity && (
        <div className="modal" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header" style={{ display: "flex", justifyContent: 'space-between' }}>
                <h5 className="modal-title">Hotels in {selectedCity.city}</h5>
                <button type="button" className="close" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th>Hotel ID</th>
                        <th>Name</th>
                        <th>stateprovince</th>
                        <th>Address</th>
                        <th>Latitude</th>
                        <th>Longitude</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedCity.arrayinfohotels.map((hotel, index) => (
                        <tr key={index}>
                          <td>{hotel.hotelid}</td>
                          <td>{hotel.name}</td>
                          <td>{hotel.stateprovince}</td>
                          <td>{hotel.address1}</td>
                          <td>{hotel.latitude}</td>
                          <td>{hotel.longitude}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewCitysMaped;
