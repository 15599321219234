import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import {
  getMappinEvents,
} from "./../../utils/urls.js";

const events = [
  // Datos iniciales de eventos locales
];

function InfoEventsMapping() {
  const [data, setData] = useState(events);
  const [dataEventsGet, setDataEventsGet] = useState([]);
  const [filteredCount, setFilteredCount] = useState(0);

  const [filters, setFilters] = useState({
    nameEvent: "",
    venueName: "",
    venueAddressLine1: "",
    categoryName: "",
    datetime: "",
  });

  const consultInfoEvents = () => {
    fetch(getMappinEvents(), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())
    .then((data) => {
      //console.log('Información a mostrar:', data);
      setDataEventsGet(data);
      filterEvents(data, filters); // Filtrar los datos obtenidos
    })
    .catch((error) => console.error(error));
  };

  useEffect(() => {
    consultInfoEvents();
  }, []);

  useEffect(() => {
    filterEvents(dataEventsGet, filters);
  }, [filters]);

  const filterEvents = (eventsData, filters) => {
    const filteredData = eventsData.filter((event) => {
      return (
        event.nameEvent.toLowerCase().includes(filters.nameEvent.toLowerCase()) &&
        event.venueName.toLowerCase().includes(filters.venueName.toLowerCase()) &&
        event.categoryName.toLowerCase().includes(filters.categoryName.toLowerCase()) &&
        event.venueAddressLine1.toLowerCase().includes(filters.venueAddressLine1.toLowerCase()) &&
        (filters.datetime === "" || event.datetime.includes(filters.datetime))
      );
    });
    setData(filteredData);
    setFilteredCount(filteredData.length);
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <div className="row">
        <div>
          <Panel>
            <PanelHeader>
              Mapped events information {" "}
              <span className="badge bg-success ms-5px">{dataEventsGet.length}</span>
              <span className="badge bg-info ms-5px">Filtered: {filteredCount}</span>
            </PanelHeader>

            <PanelBody>
              <div className="filter-container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                <input
                  id="input1"
                  type="text"
                  className="bg-background border border-input rounded-lg py-2 px-4 text-foreground focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary block w-full"
                  placeholder="Name Event"
                  name="nameEvent"
                  value={filters.nameEvent}
                  onChange={handleFilterChange}
                />

                <input
                  id="input2"
                  type="text"
                  className="bg-background border border-input rounded-lg py-2 px-4 text-foreground focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary block w-full"
                  placeholder="Venue"
                  name="venueName"
                  value={filters.venueName}
                  onChange={handleFilterChange}
                />

                <input
                  id="input3"
                  type="text"
                  className="bg-background border border-input rounded-lg py-2 px-4 text-foreground focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary block w-full"
                  placeholder="Category"
                  name="categoryName"
                  value={filters.categoryName}
                  onChange={handleFilterChange}
                />
                <input
                  id="input4"
                  type="text"
                  className="bg-background border border-input rounded-lg py-2 px-4 text-foreground focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary block w-full"
                  placeholder="Address"
                  name="venueAddressLine1"
                  value={filters.venueAddressLine1}
                  onChange={handleFilterChange}
                />

                <input
                  id="input5"
                  type="date"
                  className="bg-background border border-input rounded-lg py-2 px-4 text-foreground focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary block w-full"
                  name="datetime"
                  value={filters.datetime}
                  onChange={handleFilterChange}
                />
              </div>

              <div style={{ maxHeight: "700px", overflowY: "auto" }}>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>id Event</th>
                      <th>Name Event</th>
                      <th>Datetime</th>
                      <th>Status</th>
                      <th>MapUrl</th>

                      <th>Venue Id</th>
                      <th>Venue Name</th>
                      <th>Venue AddressLine1</th>
                      <th>Venue AddressLine2</th>
                      <th>Venue City</th>
                      <th>Venue State</th>

                      <th>venue Postcode</th>
                      <th>venue CountryCode</th>
                      <th>Latitude</th>
                      <th>Longitude</th>
                      <th>categoryId</th>
                      <th>categoryName</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((event, index) => (
                      <tr key={index}>
                        <td>{event.events_id}</td>
                        <td>{event.idEvent}</td>
                        <td>{event.nameEvent}</td>
                        <td>{event.datetime}</td>
                        <td>{event.status}</td>
                        <td><a href={event.mapUrl} target="_blank" rel="noopener noreferrer">{event.mapUrl}</a></td>
                        <td>{event.venueId}</td>
                        <td>{event.venueName}</td>
                        <td>{event.venueAddressLine1}</td>
                        <td>{event.venueAddressLine2}</td>
                        <td>{event.venueCity}</td>
                        <td>{event.venueState}</td>

                        <td>{event.venuePostcode}</td>
                        <td>{event.venueCountryCode}</td>
                        <td>{event.venueLatitude}</td>
                        <td>{event.venueLongitude}</td>
                        <td>{event.categoryId}</td>
                        <td>{event.categoryName}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
}

export default InfoEventsMapping;
