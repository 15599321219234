import React, { useState, useEffect } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { infoMarkcorps } from "./../../utils/urls.js";

function Homepage() {
  const [requestLimit, setrequestLimit] = useState(0);
  const [requestUsed, setrequestUsed] = useState(0);
  const [remainingLimit, setremainingLimit] = useState(0);
  const [statusapi, setstatusapi] = useState(false);

  useEffect(() => {
    fetch(infoMarkcorps())
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        setrequestLimit(data[0].requestLimit);
        setrequestUsed(data[1].requestUsed);
        setremainingLimit(data[2].remainingLimit);
        setstatusapi(data[3].statusapi);
      })
      .catch((error) => console.error(error));
  }, []);

  //console.log("<<<<<<<<<<<<InfoMarkcorps>>>>>>>>>>>>");
  //console.log(requestLimit);
  //console.log(requestUsed);
  //console.log(remainingLimit);
  //console.log(statusapi);

  const calculatePercentage = (used, limit) => {
    if (limit === 0) {
      return 0; // Evita división por cero
    }
    return (used / limit) * 100;
  };

  const percentageUsed = calculatePercentage(requestUsed, requestLimit);

  //console.log(percentageUsed);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-xl-6">
          <div className="col-lg-4 col-sm-6">
            <div
              className="widget widget-stats bg-teal mb-7px"
              style={{ width: "500px", height: "150px", position: "relative" }}
            >
              <div className="stats-icon stats-icon-lg">
                <i className="fa fa-globe fa-fw"></i>
              </div>
              <div className="stats-content">
                <div className="stats-title">Requests Enabled Makcorps</div>
                <div className="stats-number">({remainingLimit})</div>
                <div className="stats-progress progress">
                  <div
                    className="progress-bar"
                    style={{ width: `${percentageUsed}%` }}
                  ></div>
                </div>
                <br></br>
                <div className="stats-desc">
                  Total request {requestLimit.toLocaleString()}
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: statusapi ? "green" : "red",
                }}
              >
                <i className="fa fa-circle fa-2x"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
