
import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useOutletContext } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel.jsx";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { getFromLocalStorage, saveToLocalStorage } from "../../components/helps.js";
import { getInfoCompanies, addCompany } from "../../utils/urls.js";
import { ReactNotifications, Store } from "react-notifications-component";
import { fnGetTable, fnValidUserPermission } from "../../components/objects/fnCommon.tsx";
import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";
import FormsDinamic from "../../components/objects/formsDinamic.tsx";
import InfoUsers from "./dataUsers-allusers.js";

function TableCompanies() {
	const infoUser = useOutletContext();
	
	const dataUser = infoUser.infoUser;

	let token = getFromLocalStorage("TK");
	const [info, setInfo] = useState([]);
	const [header, setHeader] = useState([]);
	const [headerMap, setheaderMap] = useState();
	const [formvisible, setformvisible] = useState(false);
	const [tblvisible, settblvisible] = useState(true);
	const [formValues, setFormValues] = useState({});
	const [idRegistro, setidRegistro] = useState("");
	const [isFormVisible, setIsFormVisible] = useState(true);
	useEffect(() => {

		const fetchTable = async () => {
			try {
				if (token === undefined || token === null) {
					token = getFromLocalStorage("TK");
				}
				let idCompany = dataUser.id_company ;
				if (fnValidUserPermission(dataUser)){
					idCompany =0;
				}
				const url = getInfoCompanies(idCompany, token);
				//console.log(url);
				const infoResult = await fnGetTable(url);
				console.log(infoResult);
				setInfo(infoResult.data);
				setHeader(infoResult.head);
				//console.log('lllll', header);
				setheaderMap(infoResult.headerMap);
			} catch (error) {
				console.error("error de fetchTable", error);
			}
		};
		if (infoUser != null) {
			fetchTable();
		}

	}, []);
	const fnEditRegister = (idRegister) => {
		//setidRegistro(idRegister);
		fnChangeView();
	};
	const fnChangeView = () => {
		setformvisible(!formvisible);
		settblvisible(!tblvisible);
	};
	const fnViewDetail = (id) => {
		saveToLocalStorage("company", id);
		return <Navigate to='/datausers/datausers-infocompany' />;
	};

	const handleCancel = () => {
		fnChangeView();
	}
	//////Formulario

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormValues({
			...formValues,
			[name]: type === "checkbox" ? checked : value,
		});
	};

	const handleSubmit = async (data) => {
		console.log('Form submitted with data:', data);
		const saveData = await axios.post(addCompany(token), {
			body: data
		});
		console.log(saveData);
		//setIsFormVisible(false); // Ocultar el formulario después de enviar
	};
	///
	if (infoUser == null) {
		return <div> loading..</div>
	} else if (dataUser != null && dataUser != undefined && !fnValidUserPermission(dataUser)) {
		console.log('dentro del if', dataUser);
		return <Navigate to="/dashboard/v0" />;
	}
	
	

	return (
		<div>
			<ol className='breadcrumb float-xl-end'>
				<li className='breadcrumb-item'>
					<Link to='/form/elements'>Home</Link>
				</li>
				<li className='breadcrumb-item'>
					<Link to='/form/elements'>Form Stuff</Link>
				</li>
				<li className='breadcrumb-item active'>Empresas</li>
			</ol>
			<h1 className='page-header'>Empresas</h1>

			<div className='row' style={!tblvisible ? { display: "none" } : {}}>
				<div className='col-12'>
					<Panel>
						<PanelHeader>Emrpesas</PanelHeader>
						<PanelBody>
							<div className='row col-md-3' style={{ float: "inline-end" }}>
								<button type='button' className='btn btn-outline-primary' onClick={fnChangeView}>
									Agregar Empresa +
								</button>
							</div>
							<div className='row col-md-12'>
								<DataTableConsulting header={header} headerMap={headerMap} info={info} onViewDetail={fnViewDetail} onEditRegister={fnEditRegister} onDelete={setidRegistro} />
							</div>
						</PanelBody>
					</Panel>
				</div>
			</div>

			<div className='row' style={!formvisible ? { display: "none" } : {}}>
				<div className='col-12'>
					<Panel>
						<PanelHeader>Form Empresa</PanelHeader>
						<PanelBody>
							<FormsDinamic
								inputsData={[]}
								dataValue={{}}
								id_form={5}
								onChange={handleChange}
								token={token}
								onSubmit={handleSubmit}
								onCancel={handleCancel}
							/>
						</PanelBody>
					</Panel>
				</div>
			</div>
		</div>
	);
}

export default TableCompanies;
