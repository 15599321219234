const Menu = [

  { path: 'dashboard', icon: 'fa fa-sitemap', title: 'Details Events',
  children: [
   { path: 'detailEvents/upfile', title: 'UP Info Events' },
   { path: 'detailEvents/viewcateg', title: 'info Categories' },
   { path: 'detailEvents/events', title: 'info Events Mapping' },
  ]
  },
  
  

  ]
  
  export default Menu;