import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useOutletContext } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel.jsx";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { getFromLocalStorage, saveToLocalStorage } from "../../components/helps.js";
import { FormsTypeusers, inputsSelected, upFileprocesss, getInfoBranches, getInfoUsers, getInfoCompanies, PostDeletUser } from "../../utils/urls.js";
import { ReactNotifications, Store } from "react-notifications-component";
import { fnGetTable, fnValidUserPermission } from "../../components/objects/fnCommon.tsx";
import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";
import RegUsersElements from "./datausers-reguser.js";
import EditUsersElements from "./dataUsers-editusers.js";
import AdminAcces from "../form/form-userAcces.js";
import AdminAccesTest from "../form/form-usersAccess.js"


function InfoUsers() {
	let token = getFromLocalStorage("TK");
	const infoUser = useOutletContext();
	  let idUserP = getFromLocalStorage("Session");
	
	// console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<infoUser>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

	// console.log(infoUser)
	const dataUser = infoUser.infoUser;

	// console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<infoUser>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

	// console.log(infoUser)

	const [infoCompany, setInfoCompany] = useState([]);
	const [headerCompany, setHeaderCompany] = useState([]);
	const [headerMapCompany, setheaderMapCompany] = useState();
	const [infoBranch, setInfoBranch] = useState([]);
	const [headerBranch, setHeaderBranch] = useState([]);
	const [headerMapBranch, setheaderMapBranch] = useState();
	const [info, setInfo] = useState([]);
	const [header, setHeader] = useState([]);
	const [headerMap, setheaderMap] = useState();
	const [formvisible, setformvisible] = useState(false);
	const [formvisible2, setformvisible2] = useState(false);
	const [formvisibleInfo, setformvisibleInfo] = useState(false);


	const [tblvisible, settblvisible] = useState(true);
	const [formValues, setFormValues] = useState({});
	const [idRegistro, setidRegistro] = useState("");
	const [dataSelect, setDataSelect] = useState({});

	const [slsreloadTable, setslsreloadTable] = useState(false);

	useEffect(() => {
		const fetchTable = async () => {
			try {
				if (token === undefined || token === null) {
					token = getFromLocalStorage("TK");
				}
			
				let idCompany = dataUser.id_company;
				let idBranch = dataUser.branch_id;
				if (fnValidUserPermission(dataUser)) {
					idCompany = 0;
					idBranch = 0;
				}
				// idCom
				let url = getInfoCompanies(idCompany, token, idUserP.rol);
				// console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<url>>>>>>>>>>>>>>>>>>>>>>>>>>');
				// console.log(url);
				let infoResult = await fnGetTable(url);
				setInfoCompany(infoResult.data);
				setHeaderCompany(infoResult.head);
				setheaderMapCompany(infoResult.headerMap);

				url = getInfoBranches(idCompany, idBranch, token, idUserP.rol);
				infoResult = await fnGetTable(url);
				setInfoBranch(infoResult.data);
				setHeaderBranch(infoResult.head);
				setheaderMapBranch(infoResult.headerMap);
				


				url = getInfoUsers(idCompany, idUserP.branch, 0, token, idUserP.rol);
				// 				console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<getInfoUsers>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
				// console.log(url)
				infoResult = await fnGetTable(url);
				setInfo(infoResult.data);
				setHeader(infoResult.head);
				setheaderMap(infoResult.headerMap);
			} catch (error) {
				console.error("error de fetchTable", error);
			}
		};
		fetchTable();

		if(slsreloadTable){
			fetchTable();
			setslsreloadTable((prev) => !prev);
		}
	}, [slsreloadTable]);

	const fnEditRegister = (idRegister) => {
		// console.log('ID recibido para editar:', idRegister); // Asegúrate de imprimir el ID aquí
		setidRegistro(idRegister.id);
	
		const dataUser = info.filter((x) => x.id_data === idRegister.id);
		setDataSelect(dataUser);
	
		// Cambia la visibilidad del formulario y tabla
		setformvisible2(!formvisible2);
		settblvisible(!tblvisible);
	
		// console.log('ID registrado después de setState: <<<<<<<<<<<', idRegister);
	};


	const fnDeleteRegister = async (idRegister) => {
		console.log('ID recibido para eliminar <<<<:', idRegister); // Asegúrate de imprimir el ID aquí
		setidRegistro(idRegister.id);

		console.log(infoUser.infoUser.id_usuario)
		console.log(idRegister)

	
		try {
			const response = await axios.post(PostDeletUser(), {
				idAuthorizedUser: infoUser.infoUser.id_usuario,
				id: idRegister.id,
			});
			console.log('User created successfully:', response.status);

			if(response.status === 201){
				console.log('User deleted successfully:');
				addNotification(
					"success",
					"Success",
					"User deleted successfully",
					"top-center"
				  );
				  
				  setslsreloadTable((prev) => !prev);

			}else{
				console.log('error deregistering:');

				addNotification("danger", "Error", "error deregistering", "top-right");
			
			}
		} catch (error) {
			console.error("Error creating user:", error);
		}
		
	};

	const fnInfousers = (idRegister) => {
		// console.log('ID recibido para editar:', idRegister); // Asegúrate de imprimir el ID aquí
		setidRegistro(idRegister);
	
		const dataUser = info.filter((x) => x.id_data === idRegister);
		setDataSelect(dataUser);
	
		// Cambia la visibilidad del formulario y tabla
		setformvisible2(!formvisible2);
		settblvisible(!tblvisible);
	
		// console.log('ID registrado después de setState: <<<<<<<<<<<', idRegister);
	};

	const fnChangeView = () => {
		setformvisible(!formvisible);
		settblvisible(!tblvisible);
	};

	const fnViewDetail = (idRegister) => {
		// saveToLocalStorage("company", id);
		// return <Navigate to='/datausers/datausers-infocompany' />;
		setformvisibleInfo(!formvisibleInfo);
		settblvisible(!tblvisible);

	};

	

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormValues({
			...formValues,
			[name]: type === "checkbox" ? checked : value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("Form Submitted", formValues);
	};

	///
	if (infoUser == null) {
		return <div> Loading..</div>
	}

	function addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition) {
		Store.addNotification({
		  title: notificationTitle,
		  message: notificationMessage,
		  type: notificationType,
		  insert: "top",
		  container: notificationPosition,
		  animationIn: ["animated", "fadeIn"],
		  animationOut: ["animated", "fadeOut"],
		  dismiss: { duration: 2000 },
		  dismissable: { click: true },
		});
	  }
	
	//   console.log('info <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<')
	//   console.log(info)

	return (
		<div>
			{/* <ol className='breadcrumb float-xl-end'>
				<li className='breadcrumb-item'>
					<Link to='/form/elements'>Home</Link>
				</li>
				<li className='breadcrumb-item'>
					<Link to='/form/elements'>Form Stuff</Link>
				</li>
				<li className='breadcrumb-item active'>Users</li>
			</ol> */}
			{/* <h1 className='page-header'>Users</h1> */}

			<div className='row' style={!tblvisible ? { display: "none" } : {}}>
				<div className='col-12'>
					<Panel>
						<PanelHeader>Informcion de General</PanelHeader>
						<PanelBody>
							<div className="row col-md-12" style={{textAlign:"center"}}>
								<h3>Company information</h3>
							</div>
							<div className='row col-md-12'>
								<DataTableConsulting header={headerCompany} headerMap={headerMapCompany} info={infoCompany} viewOnly={true} />
							</div>
							<div className="row col-md-12" style={{ textAlign: "center" }}>
								<h3>Branch Information</h3>
							</div>
							<div className='row col-md-12'>
								<DataTableConsulting header={headerBranch} headerMap={headerMapBranch} info={infoBranch} viewOnly={true} />
							</div>

							<div className="row col-md-12" style={{ textAlign: "center" }}>
								<h3>All Users</h3>
							</div>

							<div className='row col-md-3' style={{ float: "inline-end" }}>
								<button type='button' className='btn btn-outline-primary' onClick={fnChangeView}>
									Agregar Usuario +
								</button>
							</div>
							<div className='row col-md-12'>
								<DataTableConsulting 
								header={header} 
								headerMap={headerMap} 
								info={info} 
								onViewDetail={fnViewDetail} 
								onEditRegister={fnEditRegister} 
								onDelete={fnDeleteRegister} />
							</div>
						</PanelBody>
					</Panel>
				</div>
			</div>

			<div className='row' style={!formvisible ? { display: "none" } : {}}>
				<div className='col-12'>
					<Panel>
						<PanelHeader>Data Users new</PanelHeader>
						<PanelBody>
							<RegUsersElements idUser={idRegistro} infoUser={infoUser} dataUser={dataSelect} iduserSelect={0}/>
						</PanelBody>
					</Panel>
				</div>
			</div>

			<div className='row' style={!formvisible2 ? { display: "none" } : {}}>
				<div className='col-12'>
					<Panel>
						<PanelHeader>Data User Edit</PanelHeader>
						<PanelBody>
							<EditUsersElements idUser={idRegistro} iduserSelect={idRegistro}/>
						</PanelBody>
					</Panel>
				</div>
			</div>

			<div className='row' style={!formvisibleInfo ? { display: "none" } : {}}>
				<div className='col-12'>
					<Panel>
						<PanelHeader>Data User Edit</PanelHeader>
						<PanelBody>
							<AdminAccesTest idUser={idRegistro} iduserSelect={idRegistro}/>
						</PanelBody>
					</Panel>
				</div>
			</div>

			<ReactNotifications />{" "}

		</div>
	);
}

export default InfoUsers;
