import React, { useState, useEffect, useRef } from "react";
import { Panel, PanelHeader, PanelBody } from "./../../components/panel/panel.jsx";
import { upFileprocesss, postAllCompaniesRoll, getBranchDataByCompany, UpFileCategoriesEvents } from './../../utils/urls.js';
import { ReactNotifications, Store } from 'react-notifications-component';
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";

function NewFileUpElements() {
    let token = getFromLocalStorage("TK");
    let idUserP = getFromLocalStorage("Session");

  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };

  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);
  const [formValues, setFormValues] = useState({
    fileup: null,
    namecategory: "",
    company: 0,
    branch: 0,
    useForAllBranches: false,
    });

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol,
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch,
      };

      const response = await axios.post(postAllCompaniesRoll(token), requestData);
      return response.data.data || [];
    } catch (error) {
      console.error("Error al cargar las empresas", error);
      return [];
    }
  };

  const fnGetAllBranch = async () => {
    try {
        const requestData = {
        companyId: formValues.company || idUserP.company, // Si formValues.company es vacío o no existe, usa idUserP.company
        };

        const responseJsonCompanies = await axios.post(
        getBranchDataByCompany(token),
        requestData
        );
        return responseJsonCompanies.data.data;
    } catch (error) {
        console.error("Error al cargar los combos 333", error);
    }
    };

  useEffect(() => {
    const fetchCompanies = async () => {
      const companies = await fnPostCompaniesList();
      setslsCompany([initSelec, ...(Array.isArray(companies) ? companies : [])]);
    };
    fetchCompanies();
  }, []);

  // Función para actualizar la lista de sucursales cuando cambia la empresa seleccionada
  useEffect(() => {
    if (formValues.company) {
      const fetchBranches = async () => {
        try {
          setslsBranches([initSelec2]); // Resetea antes de cargar
          const branches = await fnGetAllBranch();
          setslsBranches([initSelec2, ...(Array.isArray(branches) ? branches : [])]);
        } catch (error) {
          console.error("Error obteniendo sucursales:", error);
          setslsBranches([initSelec2]);
        }
      };
      fetchBranches();
    }
  }, [formValues.company]);

  /// captura de informacion del formulario

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormValues((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
        ...(name === "company" && { branch: "" }),
      }));
    }
  };

  /////////////// 

  const validateForm = () => {
    return (
      formValues.namecategory  && formValues.fileup
    );
  };


  const handleSubmit = async (e) => {
          e.preventDefault();
  
    console.log("<<<<<<< formValues   >>>>>>> <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
    console.log(formValues);
  
          if (!validateForm()) {
            addNotification(
              "danger",
              "Error",
              "Fill all required fields",
              "top-center"
            );
            return;
          }
    try {
        const PostDataUser = async (branchId) => {
        const formData = new FormData();
        formData.append("fileup", formValues.fileup); // Agrega el archivo
        formData.append("namecategory", formValues.namecategory);
        formData.append("id_company", formValues.company);
        formData.append("id_branch", branchId);
  
  
        console.log('<<<<<<<<<<<<<<<<<<formData>>>>>>>>>>>>>>>>>>')
  
        console.log("FormData contenido:");
        for (let [key, value] of formData.entries()) {
          console.log(key, value);
        }
  
  
        const response = await fetch(UpFileCategoriesEvents(token), {
          method: "POST",
          body: formData,
        });
  
        return response;
      };
  
      
  
  
      if (formValues.useForAllBranches) {
        // Itera sobre todas las sucursales y envía las peticiones
  
  
        const promises = slsBranch
          .slice(1)
          .map((branch) => PostDataUser(branch.value));
        const responses = await Promise.all(promises);
  
        const successfulResponses = responses.filter(
          (res) => res.status === 201
        );
        if (successfulResponses.length === slsBranch.length - 1) {
          addNotification("success", "Success", "Records saved", "top-center");
        } else {
          addNotification(
            "warning",
            "Partial Success",
            "Some records were not saved",
            "top-center"
          );
        }
      } else {
        // Si no está activado "Use for all branches", realiza un solo POST
        const response = await PostDataUser(formValues.branch);
        const data = await response.json();
  
        if (response.status === 201) {
          addNotification("success", "Success", "Record saved", "top-center");
        } else {
          addNotification(
            "warning",
            "Error",
            "Error, some data is missing",
            "top-center"
          );
        }
      }
  
      setFormValues({
        fileup: null,
        namecategory: "",
        company: 0,
        branch: 0,
      });
  
    } catch (error) {
      
    }
  };

  // Notificacion
  function addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition, notificationContent) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent
    });
  }

  return (
    <div>
      <div className="row mb-3">
        <div className="col-xl-6">
          <Panel>
            <PanelHeader>Up new File register</PanelHeader>
            <PanelBody>
              <form onSubmit={handleSubmit}>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Name Category*
                  </label>
                  <div className="col-md-9">
                    <input
                      // type="text"
                      // className="form-control mb-5px"
                      // value={CategoryName}
                      // onChange={(e) => setCategoryName(e.target.value)}
                      className="form-control"
                      type="text"
                      id="namecategory"
                      name="namecategory"
                      value={formValues.namecategory}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Select File*
                  </label>
                  <div className="col-md-9">
                    <input
                      // type="file"
                      // className="form-control mb-5px"
                      // onChange={(e) => setDataFile(e.target.files[0])} // Handle file input correctly
                      className="form-control"
                      type="file"
                      id="fileup"
                      name="fileup"
                      // value={formValues.urlimagen}
                      onChange={handleInputChange} // No es necesario pasar el value porque es un campo de tipo archivo
                    />
                  </div>
                </div>

                <div className="row md-15px">
                          <label
                            className="form-label col-form-label col-md-3"
                            htmlFor="company"
                          >
                            Companies*
                          </label>
                          <div className="col-md-9">
                            <select
                              className="form-select mb-5px"
                              id="company"
                              name="company"
                              value={formValues.company}
                              onChange={handleInputChange}
                            >
                              {slsCompany.map((option, index) => (
                                <option
                                  key={"form_" + index}
                                  value={option.value_option}
                                >
                                  {option.name_option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>


                        {/* {formValues.company && ( */}
                  <div className="row md-15px">
                    <label
                      className="form-label col-form-label col-md-3"
                      htmlFor="useForAllBranches"
                    >
                      Use for all branches
                    </label>
                    <div className="col-md-9 d-flex align-items-center">
                      <input
                        className="form-check-input is-valid"
                        type="checkbox"
                        id="useForAllBranches"
                        name="useForAllBranches"
                        style={{ marginRight: "10px" }}
                        checked={formValues.useForAllBranches || false}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            useForAllBranches: e.target.checked,
                          })
                        }
                      />
                    </div>
                  </div>
                {/* // )} */}


                        <div className="row md-15px">
                          <label
                            className="form-label col-form-label col-md-3"
                            htmlFor="branch"
                          >
                            Branches*
                          </label>
                          <div className="col-md-9">
                            <select
                              className="form-select mb-5px"
                              id="branch"
                              name="branch"
                              value={formValues.branch}
                              onChange={handleInputChange}
                            >
                              {slsBranch.map((option, index) => (
                                <option
                                  key={"form_" + index}
                                  value={option.value}
                                >
                                  {option.text}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                <div className="row">

                  <div className="lrow md-15px" style={{ margin: 10 }}>
                    <button
                      type="submit"
                      className="btn btn-green"
                      style={{ float: "right" }}
                    >
                      + Create
                    </button>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
      <ReactNotifications /> {/* Ensure ReactNotifications component is included */}
    </div>
  );
}

export default NewFileUpElements;
