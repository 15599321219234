// components/DataTable.tsx

import React from 'react';

interface DataTableProps {
    header: string[];
    headerMap: Record<string, string>;
    info: any[];
    onViewDetail: (id: any) => void;
    onEditRegister: (id: any) => void;
    onDelete: (id: any) => void;
    viewOnly: boolean

}

const DataTableConsulting: React.FC<DataTableProps> = ({ header, headerMap, info, onViewDetail, onEditRegister, onDelete, viewOnly = false }) => {
    const validHeader = Array.isArray(header) ? header : [];

    //const validHeaderMap = Array.isArray(headerMap) ? headerMap : [];
    //const validInfo = Array.isArray(info) ? info : [];

    //const validHeaderMap = typeof headerMap === 'object' && headerMap !== null ? headerMap : {};
    //const validInfo = Array.isArray(info) ? info : [];

    // console.log(onViewDetail);
    // console.log(onEditRegister);
    // console.log(onDelete);
    // console.log(viewOnly);
    // console.log('????', validHeader);

    return (
        <div className='table-responsive'>
            <table className='table table-bordered table-striped'>
                <thead>
                    <tr>
                        {validHeader.map((item, index) => (
                            <th key={"head_" + index} style={item.includes("_id") || item.includes("id_") ? { display: "none" } : {}}>

                                {headerMap[item] || item} {/* Mostrar el nombre legible del encabezado */}

                            </th>
                        ))}
                        {(!viewOnly && (onViewDetail != undefined || onEditRegister != undefined || onDelete != undefined)) && <th>Acciones</th>}
                    </tr>
                </thead>
                <tbody>
                    {info.map((item, index) => (
                        <tr key={"body-" + index}>
                            {validHeader.map((headerItem, index2) => (
                                <td key={"body_" + index + "_" + index2} style={headerItem.includes("_id") || headerItem.includes("id_") ? { display: "none" } : {}}>
                                    {typeof item[headerItem] === "boolean" ? (item[headerItem] ? "Active" : "Inactive") : item[headerItem]}
                                </td>
                            ))}
                            {(!viewOnly && (onViewDetail != undefined || onEditRegister != undefined || onDelete != undefined)) && (
                                <td key={"body_" + index + "_Accion"}>
                                    <div className='btn-group'>
                                        <button type='button' className='btn btn-info' onClick={() => onViewDetail(index)} style={{display:(onViewDetail == undefined ? "none":"")}}>
                                            Ver Detalle
                                        </button>
                                        <button type='button' className='btn btn-primary' onClick={() => onEditRegister(index)} style={{ display: (onEditRegister == undefined ? "none" : "") }}>
                                            Editar
                                        </button>
                                        <button type='button' className='btn btn-danger' onClick={() => onDelete(index)} style={{ display: (onDelete == undefined ? "none" : "") }}>
                                            Eliminar
                                        </button>
                                    </div>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


export default DataTableConsulting;
