import axios from "axios";
import { getMenus } from '../utils/urls.js';
import { saveToLocalStorage, getFromLocalStorage } from '../components/helps.js';
const MenuFinal = async()=>{
  try {
    var tk = getFromLocalStorage('TK');
    const response = await axios.get(getMenus(tk)); // Reemplaza con tu URL
    return response.data;
  } catch (error) {
    console.error('Error fetching menu:', error);
    return [{
      path: 'dashboard', icon: 'fa fa-sitemap', title: 'Percentage Rate Points',
        children: [
          { path: 'rate_points/view', title: 'Ponderaciones Creadas ' },
        ]
    }]; // Devolver un arreglo vacío en caso de error
  }
}

export default MenuFinal;