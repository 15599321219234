import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import {
  viewCategoryEvents,
  UpdateCategoryEvents,
} from "./../../utils/urls.js";

function ViewCategoryEvents() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(viewCategoryEvents())
      .then((response) => response.json())
      .then((data) => {
        //console.log('información a mostrar ')
        //console.log(data.infoCategory);

        setData(data.infoCategory);
      })
      .catch((error) => console.error(error));
  }, []);


  const handleToggle = (id_eventweenk, currentStatus) => {
    //console.log(`Toggled event ${id_eventweenk} to ${!currentStatus}`);

    const data = {
        id_eventweenk: id_eventweenk,
        isactived: !currentStatus
      };

    fetch(UpdateCategoryEvents(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
      .then((response) => response.json())
      .then((data) => {
        //console.log('Estado actualizado', data);
      })
      .catch((error) => console.error(error));


    // Actualizar el estado local
    setData(prevData => prevData.map(item => 
      item.id_eventweenk === id_eventweenk ? { ...item, isactived: !currentStatus } : item
    ));
  };

  return (
    <div>
      <div className="row">
        <div>
          <Panel>
            <PanelHeader>
              Category Events{" "}
              <span className="badge bg-success ms-5px">
                {data.length}
              </span>
            </PanelHeader>

            <PanelBody>
              <div style={{ maxHeight: "600px", overflowY: "auto" }}>
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Category</th>
                      <th>Event Name</th>
                      <th>Event Date</th>
                      <th>Quantity</th>
                      <th>Revenue</th>
                      <th>ATP</th>
                      <th>Hour</th>
                      <th>isActive</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item) => (
                      <tr key={item.id_eventweenk}>
                        <td>{item.id_eventweenk}</td>
                        <td>{item.category}</td>
                        <td>{item.event_name}</td>
                        <td>{item.event_date}</td>
                        <td>{item.quantity}</td>
                        <td>{item.revenue}</td>
                        <td>{item.atp}</td>
                        <td>{item.hour}</td>
                        <td><div className="form-check form-switch mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={item.isactived}
                              onChange={() => handleToggle(item.id_eventweenk, item.isactived)}
                            />
                          </div> </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
}

export default ViewCategoryEvents;
