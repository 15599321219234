import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel.jsx";
import Highlight from "react-highlight";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { getFromLocalStorage } from "../../components/helps.js";
import { getAllBranch, postAllCompaniesRoll, getBranchDataByCompany, getModulesAndSubModules, PostRolesOrder, getModulesByUser, getUserBranch, putUserModules } from "../../utils/urls.js";
import { ReactTags } from "react-tag-autocomplete";

function AdminAccesNew() {
	let token = getFromLocalStorage("TK");
	let idUserP = getFromLocalStorage("Session");


	const initSelec = { value: 0, text: "Seleccione una opcion" };
	const initlst = [{ idModule: 0, module: "", status: false, subModule: [{ idSubModule: 0, submodule: "", status: false }] }];

	const [DataFile, setDataFile] = useState(null);

	const [startDate, setStartDate] = useState(new Date());
	const [slsCompany, setslsCompany] = useState([initSelec]);
	const [slsBranch, setslsBranch] = useState([initSelec]);
	const [slsRoles, setslsRoles] = useState([initSelec]);
	const [lstModules, setlstModules] = useState([initlst]);
	const handleChange = (date) => {
		setStartDate(date);
	};
	const [tags, setTags] = useState([initSelec]);
	const [suggestions, setSuggestions] = useState([{ value: 0, label: "" }]);
	const frmFormularios = useRef(null);

	const handleDelete = (i) => {
		setTags([initSelec]);
	};

	const handleAdd = (newTag) => {
		setTags([newTag]);
		console.log(newTag.value);
		changeUser(newTag.value);
	};
	function handleOnChange() { }

	useEffect(() => {
		const fetchLoadCombos = async () => {
			try {
				if (token === undefined || token === null) {
					token = getFromLocalStorage("TK");
				}
				let selectCombos = [initSelec, ...(await fnPostCompaniesList())];
				console.log(selectCombos);
				setslsCompany(selectCombos);
				selectCombos = [initSelec, ...(await fnPostRollsOrder())];
				setslsRoles(selectCombos);
				let listaModulos = await fnGetModules();
				setlstModules(listaModulos);
			} catch (error) {
				console.error("Error al cargar los combos ", error);
			}
		};
		fetchLoadCombos();
	}, []);

	const fnPostCompaniesList = async () => {
		try {
		  const requestData = {
			id_rol: idUserP.rol, 
			id_empresa: idUserP.company,
			id_sucursal: idUserP.branch
		  };
	  
		  const responseJsonCompanies = await axios.post(postAllCompaniesRoll(token), requestData);
	
		  return responseJsonCompanies.data.data;
		} catch (error) {
		  console.error("Error al cargar los combos 333", error);
		}
	  };

	const fnPostRollsOrder = async () => {
		try {
		  const requestData = {
			id_rol: idUserP.rol,  
		  };
	  
		  const responseJsonCompanies = await axios.post(PostRolesOrder(token), requestData);
	
		  return responseJsonCompanies.data.data;
		} catch (error) {
		  console.error("Error al cargar los combos 333", error);
		}
	  };

	const fnGetBranch = async (idCompany) => {
		try {
			const responseJsonBranch = await axios.post(getBranchDataByCompany(token), {
				companyId: idCompany,
			});
			return responseJsonBranch.data.data;
		} catch (error) {
			console.error("Error al cargar los combos ", error);
		}
	};

	const fnGetUserByBranch = async (idBranch) => {
		try {
			const responseJson = await axios.post(getUserBranch(token), {
				branchId: idBranch,
			});
			console.log("respuesta de usuarios->", responseJson.data.data);
			return responseJson.data.data;
		} catch (error) {
			console.error("Error al cargar los combos ", error);
		}
	};
	const fnGetModulesByUser = async (idUser) => {
		try {
			const responseJson = await axios.post(getModulesByUser(token), {
				userId: idUser,
			});
			//console.log("respuesta del modulo por usuario ", responseJson);
			if (responseJson.status == 201) {
				const responseDadat = responseJson.data != undefined ? responseJson.data : null;
				if (responseDadat != null && responseDadat.success != undefined && responseDadat.success != null && responseDadat.success == true) {
					if (responseDadat.result != undefined && responseDadat.result != null && responseDadat.result == "success" && (responseDadat.message == undefined || responseDadat.message == null) && responseDadat.data != undefined && responseDadat.data != null) {
						//console.log("entro a la condicion donde si hay respuesta de modulos");
						let lstModulesResult = responseJson.data.data;
						//console.log("respuesta data data", lstModulesResult);
						if (lstModulesResult !== undefined && lstModulesResult != null && lstModulesResult.length > 0) {
							console.log('lista de modulos SWICHES->', lstModules);
							console.log('lista de respuesta ->', lstModulesResult);
							const newMod = lstModules.map((item) => {
								const itemModule = lstModulesResult.filter((x) => x.id_module == item.idModule);
								console.log("modules->", item.idModule);
								console.log("resultado de busqueda por modulo ==== ", itemModule);
								if (itemModule != undefined && itemModule != null && itemModule.length > 0) {
									console.log('tiene mas de un elemento el areglo');
									if (item.subModule.length > 0) {
										console.log('el submodulo de los swiches tiene mas de un elemento');
										const newSubMod = item.subModule.map((itemSubM) => {
											console.log('lista de submodulos?? ->', itemSubM);
											const statusSubModule = itemModule.find(x => x.id_submodule === itemSubM.idSubModule);
											const statusSb = statusSubModule != undefined && statusSubModule != null ? statusSubModule.status : false;
											console.log('statusSubModule ->', statusSubModule);
											console.log('statusSb ->', statusSb);
											return { ...itemSubM, status: statusSb };
										});
										console.log('resultado de submodulo nuevo?  ->', newSubMod);
										if (newSubMod.some(x => x.status == true)) {
											return { ...item, status: true, subModule: newSubMod };
										} else {
											return { ...item, status: false, subModule: newSubMod };
										}
									} else {
										const statusModulo = itemModule.find(x => x.id_submodule === 0);
										return { ...item, status: statusModulo.status };
									}
								} else {
									return { ...item, status: false };
								}
							});
							console.log(newMod);
							setlstModules(newMod);

						} else {
							const newMod = lstModules.map((item) => {
								return { ...item, status: false }
							});
							setlstModules(newMod);
						}
					} else {
						const newMod = lstModules.map((item) => {
							return { ...item, status: false }
						});
						setlstModules(newMod);
					}
				} else {
					const newMod = lstModules.map((item) => {
						return { ...item, status: false }
					});
					setlstModules(newMod);
				}
			} else {
				const newMod = lstModules.map((item) => {
					return { ...item, status: false }
				});
				setlstModules(newMod);
			}
		} catch (error) {
			console.error("Error al cargar los combos ", error);
		}
	};

	const fnGetModules = async () => {
		try {
			console.log(getModulesAndSubModules(token));
			const responseJson = await axios.get(getModulesAndSubModules(token));
			console.log(responseJson);
			return responseJson.data.data;
		} catch (error) {
			console.error("Error al cargar los combos ", error);
		}
	};

	const changeCompany = async (e) => {
		try {
			const selectedValue = e.target.value;

			setslsBranch([initSelec, ...(await fnGetBranch(selectedValue))]);
			// console.log("Selec Branch", slsBranch);
		} catch (error) {
			console.error("Error al cargar los combos ", error);
		}
	};
	const changeBranche = async (e) => {
		try {
			const selectedValue = e.target.value;

			setSuggestions(await fnGetUserByBranch(selectedValue));
			// console.log("Selec Branch", slsBranch);
		} catch (error) {
			console.error("Error al cargar los combos ", error);
		}
	};
	const changeUser = async (selectedValue) => {
		try {
			//const selectedValue = e.target.value;
			await fnGetModulesByUser(selectedValue);
			// console.log("Selec Branch", slsBranch);
		} catch (error) {
			console.error("Error al cargar los combos ", error);
		}
	};

	const hbdSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(frmFormularios.current);
		console.log(formData);
		const resultArray = Array.from(formData.entries()).map(([name, value]) => ({
			name,
			value,
		}));

		console.log(resultArray);
		let jsonData = { data: {} };
		resultArray.forEach((value) => {
			jsonData.data[value.name] = value.value;
		});
		console.log(JSON.stringify(jsonData));
	};
	const hbdSubmitModules = async (e) => {
		e.preventDefault();
		const formData = new FormData(frmFormularios.current);
		console.log(formData.entries());
		const resultArray = Array.from(formData.entries()).map(([name, value]) => ({
			name,
			value,
		}));

		// console.log(resultArray);
		let jsonData = { data: {} };
		// console.log(tags);
		resultArray.push({ name: "idUser", value: tags[0].value });
		resultArray.forEach((value) => {
			jsonData.data[value.name] = value.value;
		});
		// console.log("envio del data ", JSON.stringify(jsonData));
		// console.log("envio del data 2->", { body: jsonData });
		const responseJson = await axios.put(putUserModules(token), {
			body: jsonData,
		});
		console.log(responseJson);
	};
	// const [isChecked, setIsChecked] = useState(false);

	const handleCheckboxChangeModule = (idModule) => {
		console.log("entro a cambiar el check???", idModule);
		setlstModules((prevModules) => prevModules.map((mod) => (mod.idModule === idModule ? { ...mod, status: !mod.status } : mod)));
	};
	const handleCheckboxChangeSubModule = (idModule, idSubModule) => {
		console.log("entro a cambiar el check???", idSubModule);
		setlstModules((prevModules) =>
			prevModules.map((mod) =>
				mod.idModule === idModule
					? {
						...mod,
						subModule: mod.subModule.map((sub) => (sub.idSubModule === idSubModule ? { ...sub, status: !sub.status } : sub)),
					}
					: mod
			)
		);
	};
	return (
		<div>
			<ol className="breadcrumb float-xl-end">
				<li className="breadcrumb-item">
					<Link to="/form/elements">Home</Link>
				</li>
				<li className="breadcrumb-item">
					<Link to="/form/elements">Form Stuff</Link>
				</li>
				<li className="breadcrumb-item active">Form Elements</li>
			</ol>
			<h1 className="page-header">
				Form Elements <small>header small text goes here...</small>
			</h1>
			<div className="row" style={{ display: "none" }}>
				<Panel>
					<PanelHeader>Formulario de alta de campos </PanelHeader>
					<PanelBody>
						<form id="frmFormularios" ref={frmFormularios} onSubmit={hbdSubmit}>
							<div className="row md-15px">
								<label className="form-label col-form-label col-md-3" for="slForms">
									Formulario
								</label>
								<div className="col-md-9">
									<select className="form-select mb-5px" id="slForms" name="type">
										<option>Seleccione una opcion ...</option>
										<option>Usuario</option>
										<option>Empresa</option>
										<option>Sucursales</option>
										<option>Roles</option>
										<option>Permisos</option>
										<option>Modulos</option>
									</select>
								</div>
							</div>
							<div className="row md-15px">
								<label className="form-label col-form-label col-md-3" for="txtLabel">
									Label
								</label>
								<div className="col-md-9">
									<input className="form-control mb-5px" type="text" id="txtLabel" name="label"></input>
								</div>
							</div>
							<div className="row md-15px">
								<label className="form-label col-form-label col-md-3" for="txtIdentifi">
									Identificador
								</label>
								<div className="col-md-9">
									<input className="form-control mb-5px" type="text" id="txtIdentifi" name="id_input"></input>
								</div>
							</div>
							<div className="row md-15px">
								<label className="form-label col-form-label col-md-3" for="slType">
									Tipo
								</label>
								<div className="col-md-9">
									<select className="form-select mb-5px" id="slType" name="type">
										<option>Seleccione una opcion ...</option>
										<option>Input Text</option>
										<option>Input Number</option>
										<option>Input Date</option>
										<option>Input Email</option>
										<option>Select</option>
										<option>Multi Select</option>
										<option>Switch</option>
									</select>
								</div>
							</div>

							<div className="row md-15px">
								<label className="form-label col-form-label col-md-3" for="swMandatory">
									Obligatorio
								</label>
								<div className="col-md-9 form-check form-switch">{/* <input class='form-check-input' type='checkbox' checked={isChecked} onChange={handleCheckboxChange} id='swMandatory' name='mandatory'></input> */}</div>
							</div>

							<div className="row md-15px">
								<label className="form-label col-form-label col-md-3" for="txtStyle">
									Estilo
								</label>
								<div className="col-md-9">
									<input className="form-control mb-5px" type="text" id="txtStyle" name="style"></input>
								</div>
							</div>
							<div className="row md-15px">
								<label className="form-label col-form-label col-md-3" for="txtClass">
									Clases
								</label>
								<div className="col-md-9">
									<input className="form-control mb-5px" type="text" id="txtClass" name="className"></input>
								</div>
							</div>
							<div className="row md-15px">
								<label className="form-label col-form-label col-md-3" for="txtOpciones">
									Opciones
								</label>
								<div className="col-md-9">
									<input className="form-control mb-5px" type="text" id="txtOpciones" placeholder="elemento1,elemento2,elemento3,..." name="options"></input>
								</div>
							</div>
							<div className="row md-15px">
								<label className="form-label col-form-label col-md-3" for="txtModel">
									Modelo
								</label>
								<div className="col-md-9">
									<input className="form-control mb-5px" type="text" id="txtModel" name="model"></input>
								</div>
							</div>
							<div className="row md-15px">
								<label className="form-label col-form-label col-md-3" for="txtAtributes">
									Atributos
								</label>
								<div className="col-md-9">
									<input className="form-control mb-5px" placeholder="[atributo='']" type="text" id="txtModtxtAtributesel" name="atributos"></input>
								</div>
							</div>
							<div className="lrow md-15px">
								<button type="submit" className="btn btn-green" style={{ float: "right" }}>
									Guardar
								</button>
							</div>
						</form>
					</PanelBody>
				</Panel>
			</div>
			<div className="row" style={{ display: "none" }}>
				<div className="col-12">
					<Panel>
						<PanelHeader>usuariosDashboard</PanelHeader>
						<PanelBody>
							<div className="row mb-15px">
								<label className="form-label col-form-label col-md-3">Rol</label>
								<div className="col-md-9">
									<select className="form-select">
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</select>
								</div>
							</div>
							<div className="row mb-15px">
								<label className="form-label col-form-label col-md-3">Empresa</label>
								<div className="col-md-9">
									<select className="form-select">
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</select>
								</div>
							</div>
							<div className="row mb-15px">
								<label className="form-label col-form-label col-md-3">Nombre</label>
								<div className="col-md-9">
									<input type="text" className="form-control mb-5px" placeholder="Nombres (s)" />
								</div>
							</div>
							<div className="row mb-15px">
								<label className="form-label col-form-label col-md-3">Apeliido Paterno</label>
								<div className="col-md-9">
									<input type="text" className="form-control mb-5px" placeholder="Apellido Paterno" />
								</div>
							</div>
							<div className="row mb-15px">
								<label className="form-label col-form-label col-md-3">Apellido Materno</label>
								<div className="col-md-9">
									<input type="text" className="form-control mb-5px" placeholder="Apellido Materno" />
								</div>
							</div>
							<div className="row mb-15px">
								<label className="form-label col-form-label col-md-3">email</label>
								<div className="col-md-9">
									<input type="email" className="form-control mb-5px" placeholder="email@example.com" />
								</div>
							</div>
							<div className="row mb-15px">
								<label className="form-label col-form-label col-md-3">Telefono fijo (lada)</label>
								<div className="col-md-9">
									<input type="number" className="form-control mb-5px" placeholder="(55) 9999999999" maxLength={10} min={0} />
								</div>
							</div>
							<div className="row mb-15px">
								<label className="form-label col-form-label col-md-3">Telefono celular (lada)</label>
								<div className="col-md-9">
									<input type="number" className="form-control mb-5px" placeholder="(55) 9999999999" maxLength={10} min={0} />
								</div>
							</div>
							<div className="row mb-15px">
								<label className="form-label col-form-label col-md-3">Pais</label>
								<div className="col-md-9">
									<select className="form-select">
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</select>
								</div>
							</div>
							<div className="row mb-15px">
								<label className="form-label col-form-label col-md-3">Estado</label>
								<div className="col-md-9">
									<select className="form-select">
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</select>
								</div>
							</div>
							<div className="row mb-15px">
								<label className="form-label col-form-label col-md-3">Codigo Postal</label>
								<div className="col-md-9">
									<select className="form-select">
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</select>
								</div>
							</div>
							<div className="row mb-15px">
								<label className="form-label col-form-label col-md-3">Direccion </label>
								<div className="col-md-9">
									<input type="text" className="form-control mb-5px" placeholder="calle, no. exterior, no. interior , localidad" maxLength={10} min={0} />
								</div>
							</div>
							<div className="lrow md-15px">
								<button type="submit" className="btn btn-green" style={{ float: "right" }}>
									Guardar
								</button>
							</div>
						</PanelBody>
					</Panel>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					{/* <Panel style={{ display: "none" }}>
						<PanelHeader>Empresa</PanelHeader>
						<PanelBody>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Nombre Largo</label>
								<div className='col-md-9'>
									<input type='text' className='form-control mb-5px' placeholder='Nombre Largo' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Nommbre Corto</label>
								<div className='col-md-9'>
									<input type='text' className='form-control mb-5px' placeholder='Nombre Corto' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Razon Social</label>
								<div className='col-md-9'>
									<input type='text' className='form-control mb-5px' placeholder='Razon Social' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>RFC</label>
								<div className='col-md-9'>
									<input type='text' className='form-control mb-5px' placeholder='RFC' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Pais</label>
								<div className='col-md-9'>
									<select className='form-select'>
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</select>
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Fecha de Alta</label>
								<div className='col-md-9'>
									<DatePicker selected={startDate} onChange={handleChange} className='form-control' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Fecha de Actualizacion</label>
								<div className='col-md-9'>
									<DatePicker selected={startDate} onChange={handleChange} className='form-control' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Fecha de Baja</label>
								<div className='col-md-9'>
									<DatePicker selected={startDate} onChange={handleChange} className='form-control' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Giro</label>
								<div className='col-md-9'>
									<select className='form-select'>
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</select>
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Direccion</label>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Calle y numero</label>
								<div className='col-md-9'>
									<input type='text' className='form-control mb-5px' placeholder='Calle, numero exterior y numero interior' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Estado</label>
								<div className='col-md-9'>
									<select className='form-select'>
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</select>
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Codigo Postal</label>
								<div className='col-md-9'>
									<select className='form-select'>
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</select>
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Archivo CLABE</label>
								<div className='col-md-9'>
									<input
										type='file'
										className='form-control mb-5px'
										onChange={(e) => setDataFile(e.target.files[0])} // Handle file input correctly
									/>
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>CLABE</label>
								<div className='col-md-9'>
									<input type='text' className='form-control mb-5px' placeholder='CLABE' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Archivo RFC</label>
								<div className='col-md-9'>
									<input
										type='file'
										className='form-control mb-5px'
										onChange={(e) => setDataFile(e.target.files[0])} // Handle file input correctly
									/>
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>RFC</label>
								<div className='col-md-9'>
									<input type='text' className='form-control mb-5px' placeholder='RFC' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Archivo RNT</label>
								<div className='col-md-9'>
									<input
										type='file'
										className='form-control mb-5px'
										onChange={(e) => setDataFile(e.target.files[0])} // Handle file input correctly
									/>
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>RNT</label>
								<div className='col-md-9'>
									<input type='text' className='form-control mb-5px' placeholder='RNT' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Logo</label>
								<div className='col-md-9'>
									<input
										type='file'
										className='form-control mb-5px'
										accept='mage/png, image/jpeg'
										onChange={(e) => setDataFile(e.target.files[0])} // Handle file input correctly
									/>
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>CFDI</label>
								<div className='col-md-9'>
									<select className='form-select'>
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</select>
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Regimen Fiscal</label>
								<div className='col-md-9'>
									<select className='form-select'>
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</select>
								</div>
							</div>
							<div className='lrow md-15px'>
								<button type='submit' className='btn btn-green' style={{ float: "right" }}>
									Guardar
								</button>
							</div>
						</PanelBody>
					</Panel>
					<Panel style={{ display: "none" }}>
						<PanelHeader>Franquicia</PanelHeader>
						<PanelBody>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Empresa</label>
								<div className='col-md-9'>
									<select className='form-select'>
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</select>
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Nombre</label>
								<div className='col-md-9'>
									<input type='text' className='form-control mb-5px' placeholder='Nombre' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Usuario</label>
								<div className='col-md-9'>
									<select className='form-select'>
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</select>
								</div>
							</div>

							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Fecha de Alta</label>
								<div className='col-md-9'>
									<DatePicker selected={startDate} onChange={handleChange} className='form-control' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Fecha de Actualizacion</label>
								<div className='col-md-9'>
									<DatePicker selected={startDate} onChange={handleChange} className='form-control' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Fecha de Baja</label>
								<div className='col-md-9'>
									<DatePicker selected={startDate} onChange={handleChange} className='form-control' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Logo</label>
								<div className='col-md-9'>
									<input
										type='file'
										className='form-control mb-5px'
										accept='mage/png, image/jpeg'
										onChange={(e) => setDataFile(e.target.files[0])} // Handle file input correctly
									/>
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Direccion</label>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Calle y numero</label>
								<div className='col-md-9'>
									<input type='text' className='form-control mb-5px' placeholder='Calle, numero exterior y numero interior' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Estado</label>
								<div className='col-md-9'>
									<select className='form-select'>
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</select>
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Codigo Postal</label>
								<div className='col-md-9'>
									<select className='form-select'>
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</select>
								</div>
							</div>
							<div className='lrow md-15px'>
								<button type='submit' className='btn btn-green' style={{ float: "right" }}>
									Guardar
								</button>
							</div>
						</PanelBody>
					</Panel>
					<Panel style={{ display: "none" }}>
						<PanelHeader>CatPermisos</PanelHeader>
						<PanelBody>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Nombre</label>
								<div className='col-md-9'>
									<input type='text' className='form-control mb-5px' placeholder='Nombre' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Descripcion</label>
								<div className='col-md-9'>
									<input type='text' className='form-control mb-5px' placeholder='Descripcion' />
								</div>
							</div>
							<div className='lrow md-15px'>
								<button type='submit' className='btn btn-green' style={{ float: "right" }}>
									Guardar
								</button>
							</div>
						</PanelBody>
					</Panel>
					<Panel style={{ display: "none" }}>
						<PanelHeader>CatRol</PanelHeader>
						<PanelBody>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Nombre</label>
								<div className='col-md-9'>
									<input type='text' className='form-control mb-5px' placeholder='Nombre' />
								</div>
							</div>
							<div className='lrow md-15px'>
								<button type='submit' className='btn btn-green' style={{ float: "right" }}>
									Guardar
								</button>
							</div>
						</PanelBody>
					</Panel>
					<Panel style={{ display: "none" }}>
						<PanelHeader>CatAccesorios</PanelHeader>
						<PanelBody>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Nombre Modulo</label>
								<div className='col-md-9'>
									<input type='text' className='form-control mb-5px' placeholder='Nombre Modulo' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>Ruta</label>
								<div className='col-md-9'>
									<input type='text' className='form-control mb-5px' placeholder='Ruta' />
								</div>
							</div>
							<div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3'>SubModulo</label>
								<div className='col-md-9'>
									<select className='form-select'>
										<option>1</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
									</select>
								</div>
							</div>
							<input className='form-check-input' type='checkbox' onChange={handleOnChange} defaultValue='' id='flexCheckDefault' />
							<label className='form-check-label' htmlFor='flexCheckDefault'>
								Default checkbox
							</label>
							<div className='lrow md-15px'>
								<button type='submit' className='btn btn-green' style={{ float: "right" }}>
									Guardar
								</button>
							</div>
						</PanelBody>
					</Panel> */}
					<Panel>
						<PanelHeader>Administrador de Permisos</PanelHeader>
						<PanelBody>
							<form id="frmFormularios" ref={frmFormularios} onSubmit={hbdSubmitModules}>
								<div className="row mb-15px">
									<label className="form-label col-form-label col-md-3">Empresa</label>
									<div className="col-md-9">
										<select className="form-select" onChange={changeCompany} name="idCompany">
											{slsCompany.map((option) => (
												<option value={option.value_option}>{option.name_option}</option>
											))}
										</select>
									</div>
								</div>
								<div className="row mb-15px">
									<label className="form-label col-form-label col-md-3">Sucursal</label>
									<div className="col-md-9">
										<select className="form-select" onChange={changeBranche} name="idBranch">
											{slsBranch.map((option) => (
												<option value={option.value}>{option.text}</option>
											))}
										</select>
									</div>
								</div>
								<div className="row mb-15px">
									<label className="form-label col-form-label col-md-3">Tipo de Usuari/Rol</label>
									<div className="col-md-9">
										<select className="form-select" name="idRole">
											{slsRoles.map((option, index) => (
												<option value={option.value}>{option.text}</option>
											))}
										</select>
									</div>
								</div>
								<div className="row mb-15px">
									<label className="form-label col-form-label col-md-3">Usuario</label>
									<div className="col-md-9" style={{ position: "relative" }}>
										<ReactTags className="form-control" id="autoCompleteUser" name="idUser" selected={tags} suggestions={suggestions} onDelete={handleDelete} onAdd={handleAdd} />
										<span style={{ position: "absolute", display: "block", bottom: ".5rem", right: "1rem" }}>
											<i className="fas fa-search"></i>
										</span>
									</div>
								</div>
								<div className="row mb-15px">
									<label className="form-label col-form-label col-md-3">Modulo/Submodulo</label>

									<label className="form-label col-form-label col text-center">Estatus</label>
								</div>
								<div className="row mb-15px">
									<div>
										{lstModules.map((option) => (
											<div key={option.idModule} className="row mb-15px">
												<label className="form-label col-form-label col-md-3 " htmlFor={"sw" + option.idModule}>
													{option.module}
												</label>
												<div className="col-md-9 align-middle form-check form-switch" style={{ display: "flex", justifyContent: "center" }}>
													<input className="form-check-input" type="checkbox" checked={option.status} onChange={() => handleCheckboxChangeModule(option.idModule)} id={"sw" + option.idModule} name={"idModule_" + option.idModule} />
												</div>

												{/* Renderizar submódulos solo si el módulo está seleccionado */}
												{option.status && option.subModule.length > 0 && (
													<div key={"key_" + option.idSubModule}>
														{option.subModule.map((submodule) => (
															<div key={submodule.idSubModule} className="row mb-15px">
																<label className="form-label col-form-label col-md-3" htmlFor={"sw" + submodule.idSubModule} style={{ textAlign: "center" }}>
																	{submodule.submodule}
																</label>
																<div className="col-md-9 align-middle form-check form-switch" style={{ display: "flex", justifyContent: "center" }}>
																	<input className="form-check-input" type="checkbox" id={"sw" + submodule.idSubModule} checked={submodule.status} onChange={() => handleCheckboxChangeSubModule(option.idModule, submodule.idSubModule)} name={"idSubModule_" + submodule.idSubModule} />
																</div>
															</div>
														))}
													</div>
												)}
											</div>
										))}
									</div>
									{/* <label className='form-label col-form-label col-md-3' for='swMandatory2'>
									Mapping
								</label> 
								<div className='col align-middle form-check form-switch ' style={{ display: "flex", justifyContent: "center" }}>
									<input class='form-check-input' type='checkbox' checked={isChecked} onChange={handleCheckboxChange} id='swMandatory2' name='mandatory2'></input>
								</div>
								<div className='col align-middle form-check form-switch ' style={{display: "flex", justifyContent: "center"}}>
									<input class='form-check-input' type='checkbox' checked={isChecked} onChange={handleCheckboxChange} id='swMandatory3' name='mandatory2'></input>
								</div> */}
								</div>
								{/* <div className='row mb-15px'>
								<label className='form-label col-form-label col-md-3 text-center' for='swMandatory1'>
									Inf Mapping
								</label>
								<div className='col align-middle form-check form-switch ' style={{ display: "flex", justifyContent: "center" }}>
									<input class='form-check-input' type='checkbox' checked={isChecked} onChange={handleCheckboxChange} id='swMandatory4' name='mandatory1'></input>
								</div>
								<div className='col align-middle form-check form-switch ' style={{ display: "flex", justifyContent: "center" }}>
									<input class='form-check-input' type='checkbox' checked={isChecked} onChange={handleCheckboxChange} id='swMandatory5' name='mandatory1'></input>
								</div>
							</div> */}
								<div className="lrow md-15px">
									<button type="submit" className="btn btn-green" style={{ float: "right" }}>
										Guardar
									</button>
								</div>
							</form>
						</PanelBody>
					</Panel>
				</div>
			</div>
		</div>
	);
}

export default AdminAccesNew;
