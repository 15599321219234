const urlBackExperi = 'https://www.testunit00.co/';


export function getListhotelsview(idbranch) {
    return `${urlBackExperi}bestvalueoffers/bvobyidclient?idcliente=${idbranch}`;
  }

  export function deleteHotelSelect(idbvo) {
    return `${urlBackExperi}bestvalueoffers/updatebvo?idbvo=${idbvo}`;
  
  }


