import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications } from "react-notifications-component";
import {
  getBranchDataByCompany,
  PostCombosRol,
  postAllCompaniesRoll,
  postRequesReserv,
} from "../../utils/urls.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";
import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";
import { Modal, Button } from "react-bootstrap";


function ReqReservations({ idUser, infoUser, dataUser }) {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  const header = [
    "id_reservacion",
    "ciudad",
    "check_in",
    "check_out",
    "grupo",
    "numero_habitaciones",
    "estrellas",
    "nombre_evento",
    // "presupuesto",
    // "correo_electronico",
    // "nombre",
    // "telefono",
    // "descripcion",
    // "id_empresa",
    // "id_sucursal",
    // "usuario",
    // "created_at",
    // "updated_at",
];
const headerMap = {
    id_reservacion: "ID Reservación",
    ciudad: "Ciudad",
    check_in: "Check In",
    check_out: "Check Out",
    grupo: "Grupo",
    numero_habitaciones: "No. Habitaciones",
    estrellas: "Estrellas",
    nombre_evento: "Nombre Evento",
    // presupuesto: "Presupuesto",
    // correo_electronico: "Correo Electrónico",
    // nombre: "Nombre",
    // telefono: "Teléfono",
    // descripcion: "Descripción",
    // id_empresa: "ID Empresa",
    // id_sucursal: "ID Sucursal",
    // usuario: "Usuario",
    // created_at: "Creado en",
    // updated_at: "Actualizado en",
};

  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };

  const [slsfirsload, setslsfirsload] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);
  const [formValues, setFormValues] = useState({
    company: "",
    branch: "",
  });
  const [slsListCombos, setslsListCombos] = useState([]);
  const [slsListReservations, setslsListReservations] = useState([]);
  const [showModal, setShowModal] = useState(false);
const [modalData, setModalData] = useState(null);


  useEffect(() => {
    if (!slsfirsload) {
      fnPostListCombos();
      executeFetchLoadCombos();
      setslsfirsload(true);
      console.log("Invocando fnPostInfoReqReserv");
      fnPostInfoReqReserv([]);
    }
  }, [idUserP, slsfirsload]);

  useEffect(() => {
    if (formValues.company) {
      const fetchBranches = async () => {
        const branches = await fnGetAllBranch();
        if (Array.isArray(branches) && branches.length > 0) {
          setslsBranches([initSelec2, ...branches]);
        } else {
          setslsBranches([initSelec2]); // Si no hay datos, solo asigna initSelec2
        }
        fnPostInfoReqReserv([]);

      };
      fetchBranches();
    }
  }, [formValues.company]);
  

  useEffect(() => {
    if (formValues.branch) {
      const updateCombos = async () => {
        await fnPostListCombos();
        fnPostInfoReqReserv([]);

      };
      updateCombos();
    }
  }, [formValues.branch]);

  const executeFetchLoadCombos = async () => {
    try {
      if (isLoaded) {
        await fetchLoadCombos();
      }

      const selectCombos = [initSelec, ...(await fnPostCompaniesList())];
      setslsCompany(selectCombos);

      const branches = await fnGetAllBranch();
      setslsBranches([initSelec2, ...branches]);
    } catch (error) {
      console.error("Error en fetchLoadCombos:", error);
    } finally {
      setIsLoaded(false);
    }
  };

  const fetchLoadCombos = async () => {
    try {
      if (!token) {
        token = getFromLocalStorage("TK");
      }
    } catch (error) {
      console.error("Error al cargar los combos:", error);
    }
  };

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol, 
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch
      };
  
      const responseJsonCompanies = await axios.post(postAllCompaniesRoll(token), requestData);

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const fnGetAllBranch = async () => {
    try {
      const requestData = {
        companyId: formValues.company || idUserP.company,
      };

      const response = await axios.post(
        getBranchDataByCompany(token),
        requestData
      );
      return response.data.data;
    } catch (error) {
      console.error("Error al cargar las sucursales:", error);
    }
  };

  const fnPostListCombos = async () => {
    try {
      const requestData = {
        id_empresa: formValues.company || idUserP.company,
        id_sucursal: formValues.branch || idUserP.branch,
        id_rol: formValues.branch || idUserP.rol,
      };

      const response = await axios.post(PostCombosRol(token), requestData);
      setslsListCombos(response.data.data || []);
    } catch (error) {
      console.error("Error al cargar los combos:", error);
    }
  };

  const handleInputChangeCompany = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      ...(name === "company" && { branch: "" }),
    }));
  };

  const handleDetail = (id) => {
    console.log("Detail for combo ID:", id);
    // Lógica para detalle
  };
  
  const handleEdit = (id) => {
    console.log("Edit for combo ID:", id);
    // Lógica para editar
  };

  const fnViewDetail = (idRegister) => {
    // console.log('<<<<<<<<<<<<<idRegister>>>>>>>>>>>>>')
    // console.log(idRegister)

    // Aquí puedes buscar el registro con el ID y pasar los datos al modal
    const detailData = slsListReservations.find((reservation) => reservation.id_reservacion === idRegister.id_reservacion);
    setModalData(detailData);  // Guardamos los datos del registro
    setShowModal(true);  // Abrimos el modal
  };


const fnEditRegister = (idRegister) => {
// console.log('ID recibido para editar:', idRegister.id_reservacion); // Asegúrate de imprimir el ID aquí
};

const fnDeleteRegister = async (idRegister) => {
    // console.log('ID recibido para elminar:', idRegister.id_reservacion); // Asegúrate de imprimir el ID aquí

};

const fnPostInfoReqReserv = async () => {
    try {
        console.log('datos para traer listado sobre reservaciones ')
      const requestData = {
        id_empresa: formValues.company || idUserP.company,
        id_sucursal: formValues.branch || idUserP.branch,
        id_rol: formValues.branch || idUserP.rol,
        // id_empresa: 1,
        // id_sucursal: 2,
      };

      console.log(requestData)


      const response = await axios.post(postRequesReserv(), requestData);
    //   console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<response>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
    //   console.log(response.data.data)

         setslsListReservations(response.data.data || []);

    } catch (error) {
      console.error("Error al cargar los combos:", error);
    }
  };

// console.log('<<<<<<<slsListReservations>>>>>>> ')

// console.log(slsListReservations)

  return (
    <div>
      
      <div className="row mb-3">
        <Panel>
          <PanelHeader>List Combos Hotels</PanelHeader>
          <PanelBody>
            <div>
              {(idUserP.rol === 1 || idUserP.rol === 2) && (
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="company"
                  >
                    Companies*
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="company"
                      name="company"
                      value={formValues.company}
                      onChange={handleInputChangeCompany}
                    >
                      {slsCompany.map((option, index) => (
                        <option
                          key={"form_" + index}
                          value={option.value_option}
                        >
                          {option.name_option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {(idUserP.rol === 1 ||
                idUserP.rol === 2 ||
                idUserP.rol === 4 ||
                idUserP.rol === 6) && (
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="branch"
                  >
                    Branches
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="branch"
                      name="branch"
                      value={formValues.branch}
                      onChange={handleInputChangeCompany}
                    >
                      {slsBranch.map((option, index) => (
                        <option key={"branch_" + index} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              
            </div>

            <div className='row col-md-12'>
				<DataTableConsulting header={header} headerMap={headerMap} info={slsListReservations} onViewDetail={fnViewDetail} onEditRegister={fnEditRegister} onDelete={fnDeleteRegister} />
			</div>
          </PanelBody>
        </Panel>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reservation Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalData ? (
            <div>
              <p><strong>ID Reservación:</strong> {modalData.id_reservacion}</p>
              <p><strong>Ciudad:</strong> {modalData.ciudad}</p>
              <p><strong>Check In:</strong> {modalData.check_in}</p>
              <p><strong>Check Out:</strong> {modalData.check_out}</p>
              <p><strong>Grupo:</strong> {modalData.grupo}</p>
              <p><strong>No. Habitaciones:</strong> {modalData.numero_habitaciones}</p>
              <p><strong>Estrellas:</strong> {modalData.estrellas}</p>
              <p><strong>Nombre Evento:</strong> {modalData.nombre_evento}</p>
              <p><strong>Budget:</strong> {modalData.presupuesto}</p>
              <p><strong>Email:</strong> {modalData.correo_electronico}</p>
              <p><strong>Name:</strong> {modalData.nombre}</p>
              <p><strong>Phone:</strong> {modalData.telefono}</p>
              <p><strong>Description:</strong> {modalData.descripcion}</p>
              <p><strong>Company:</strong> {modalData.id_empresa}</p>
              <p><strong>Branch:</strong> {modalData.id_sucursal}</p>
              <p><strong>User:</strong> {modalData.usuario}</p>
            </div>
          ) : (
            <p>Cargando...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <ReactNotifications />
    </div>
  );
}

export default ReqReservations;
