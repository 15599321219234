import React, { useState, useEffect, useRef } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications, Store } from "react-notifications-component";
import {
  ListContryCitys,
  postAllCompaniesRoll,
  getBranchDataByCompany,
  GetInfoDestinos,
  PostEditinfoDestin,
  PostEditupdateOferta,
} from "../../utils/urls.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";
import DateTime from 'react-datetime';



function EditBestValueOffers({ id, infoRegister }) {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");
  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };

    const [isLoaded, setIsLoaded] = useState(true);
    const [isEditMode, setIsEditMode] = React.useState(false); // Control de visibilidad del componente
    const [maxDateDisabled, setMaxDateDisabled] = useState(true);
    const [slsCompany, setslsCompany] = useState([initSelec]);
    const [slsBranch, setslsBranches] = useState([initSelec2]);

    const [formValues, setFormValues] = useState({
        title: infoRegister.titulo,
        description: infoRegister.descripcion,
        percentage: Number(infoRegister.porcentaje_descuento),
        company: infoRegister.id_empresa,
        branch: infoRegister.id_sucursal,
        minDate: infoRegister.fecha_inicio,
        maxDate: infoRegister.fecha_fin,
        hotelid: 0,
        // useForAllBranches: false, // Nuevo campo para el checkbox
      });
    const editorRef = useRef(null);


      useEffect(() => {
        console.log("<<<<<<isLoaded>>>>>>");
        console.log(isLoaded);
    
        const executeFetchLoadCombos = async () => {
          try {
            if (isLoaded === true) {
              await fetchLoadCombos();
              console.log("fetchLoadCombos ejecutado con éxito");
            }
    
            let selectCombos = [initSelec, ...(await fnPostCompaniesList())];
    
            setslsCompany(selectCombos);
    
            const fetchBranches = async () => {
              const branches = await fnGetAllBranch();
              setslsBranches([initSelec2, ...branches]);
            };
            fetchBranches();
          } catch (error) {
            console.error("Error en fetchLoadCombos:", error);
          } finally {
            // Cambiar el estado a false, independientemente de si hubo error o no
            setIsLoaded(false);
          }
        };
    
        executeFetchLoadCombos();
      }, [isLoaded]);

        useEffect(() => {
          if (formValues.company) {
            const fetchBranches = async () => {
              const branches = await fnGetAllBranch();
              if (Array.isArray(branches) && branches.length > 0) {
                setslsBranches([initSelec2, ...branches]);
              } else {
                setslsBranches([initSelec2]); // Si no hay datos, solo asigna initSelec2
              }
            };
            fetchBranches();
          }
        }, [formValues.company]);

        const fetchLoadCombos = async () => {
          console.log("en funcion de combos <<<<<<<<<<<<<<");
      
          console.log(!isLoaded);
          try {
            //   if (!isLoaded) {
            if (token === undefined || token === null) {
              token = getFromLocalStorage("TK");
            }
      
            /// ejecucion de codigo para precargar en caso de ser necesario
      
            //   }
          } catch (error) {
            console.error("Error al cargar los combos ", error);
          }
        };

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol,
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch,
      };

      const responseJsonCompanies = await axios.post(
        postAllCompaniesRoll(token),
        requestData
      );

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const fnGetAllBranch = async () => {
    try {
        const requestData = {
        companyId: formValues.company || idUserP.company, // Si formValues.company es vacío o no existe, usa idUserP.company
        };

        const responseJsonCompanies = await axios.post(
        getBranchDataByCompany(token),
        requestData
        );
        return responseJsonCompanies.data.data || [];
    } catch (error) {
        console.error("Error al cargar los combos 333", error);
    }
    };

  // const handleCountryChange = (e) => {
  //   const selectedCountry = e.target.value;
  //   setFormValues((prevValues) => ({
  //     ...prevValues,
  //     statename: selectedCountry,
  //     place: "", // Reiniciar el lugar al cambiar de país
  //   }));

  //   const selectedCountryData = slsCompanyArray.find(
  //     (country) => country.country === selectedCountry
  //   );

  //   setCityOptions(selectedCountryData ? selectedCountryData.citys : []);
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {


    e.preventDefault();
    
    console.log("Form data submitted:" );
    console.log(formValues );


    
    const { title, description, percentage, company,branch, minDate, maxDate } = formValues;

    if (!title || !description || !percentage || !company || !branch || !minDate || !maxDate) {
      Store.addNotification({
        title: "Warning",
        message: "All fields are required!",
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
      return;
    }

    console.log("Form data submitted:", formValues);

    const formData = new FormData();

    formData.append("id_oferta", id);
    formData.append("titulo", formValues.title);
    formData.append("descripcion", formValues.description);
    formData.append("porcentaje_descuento", formValues.percentage);
    formData.append("fecha_inicio", formValues.minDate);
    formData.append("fecha_fin", formValues.maxDate);
    formData.append("id_hotel", formValues.hotelid);
    formData.append("id_empresa", formValues.company);
    formData.append("id_sucursal", formValues.branch);



    const bodydata = {

      id_oferta: id,
      titulo: formValues.title,
      descripcion: formValues.description,
      porcentaje_descuento: formValues.percentage,
      fecha_inicio: formValues.minDate,
      fecha_fin: formValues.maxDate,
      id_hotel: formValues.hotelid,
      id_empresa: formValues.company,
      id_sucursal: formValues.branch,
    }

    console.log('<<<<<<<<<<<<<<<<<<formData>>>>>>>>>>>>>>>>>>')
    console.log(bodydata)

    const response = await axios.post(PostEditupdateOferta(),bodydata);


    console.log('<<<<<<<<response>>>>>>>>')

    console.log(response)
    if(response.status == 201){

        addNotification("success", "Success", "Destination updated successfully.", "top-center");
    }else{
        addNotification(
            "warning",
            "Partial Success",
            "Oops, there was an error during the update.",
            "top-center"
          );
    }


    // Add logic for form submission here
  };


  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          {/* <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div> */}
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }

      const minDateRange = (current) => {
        return current.isAfter(DateTime.moment().subtract(1, 'day'));
      };
    
      const maxDateRange = (current) => {
        return current.isAfter(editorRef.current);
      };
  
  
      const minDateChange = (value) => {
          setMaxDateDisabled(false);
          editorRef.current = value;
          setFormValues((prevValues) => ({
            ...prevValues,
            minDate: value.format("YYYY-MM-DD"), // Guardar en formato adecuado
          }));
        };
        
        const maxDateChange = (value) => {
          setFormValues((prevValues) => ({
            ...prevValues,
            maxDate: value.format("YYYY-MM-DD"),
          }));
        };


  console.log('formValues <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<')
  console.log(formValues)

  return (
    <Panel>
      {/* <PanelHeader>Manage Destinations</PanelHeader> */}
      <PanelBody>
        <form >

                    <div className="row mb-3 d-flex align-items-center">
                      <label
                        className="form-label col-form-label col-md-3 mb-0"
                        htmlFor="nombredestino"
                      >
                        Title*
                      </label>
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          type="text"
                          id="nombredestino"
                          name="title" // Nombre debe coincidir con la clave en el estado
                          value={formValues.title}
                          onChange={handleInputChange}
                          placeholder={infoRegister.titulo}

                        />
                      </div>
                    </div>

                    <div className="row mb-3 d-flex align-items-center">
                      <label
                        className="form-label col-form-label col-md-3 mb-0"
                        htmlFor="urlimagen"
                      >
                        Description*
                      </label>
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          type="text"
                          id="urlimagen"
                          name="description" // Nombre debe coincidir con la clave en el estado
                          // value={formValues.description}
                          value={formValues.description}
                          onChange={handleInputChange}
                          placeholder={infoRegister.descripcion}

                        />
                      </div>
                    </div>

                    <div className="row mb-3 d-flex align-items-center">
                      <label
                        className="form-label col-form-label col-md-3 mb-0"
                        htmlFor="urlimagen"
                      >
                        discount percentage*
                      </label>
                      <div className="col-md-2">
                        <input
                          className="form-control"
                          type="number"
                          id="urlimagen"
                          name="percentage" // Nombre debe coincidir con la clave en el estado
                          value={formValues.percentage}
                          onChange={handleInputChange}
                          placeholder={Number(infoRegister.porcentaje_descuento)}

                        />
                      </div>
                    </div>

                    <div className="form-group row">
                                    <label className="col-lg-4 col-form-label">Range Pickers</label>
                                    <div className="col-lg-8">
                                        <div className="row gx-2">
                                            <div className="col-6">
                                                <DateTime isValidDate={ minDateRange } inputProps={{ placeholder: 'Min Date' }} closeOnSelect={true} onChange={ minDateChange } />
                                            </div>
                                            <div className="col-6">
                                                {/* <DateTime isValidDate={ maxDateRange } inputProps={{ placeholder: 'Max Date', disabled: maxDateDisabled }} closeOnSelect={true} /> */}
                                                <DateTime isValidDate={maxDateRange} inputProps={{ placeholder: 'Max Date', disabled: maxDateDisabled }} closeOnSelect={true}onChange={maxDateChange} />

                                            </div>
                                        </div>
                                    </div>
                                </div>

                    <div>
                    &nbsp;&nbsp;
                      {/* Condición para mostrar el div de Companies y Branches si rol es 1 o 2 */}
                      {(idUserP.rol === 1 || idUserP.rol === 2) && (
                        <div className="row md-15px">
                          <label
                            className="form-label col-form-label col-md-3"
                            htmlFor="company"
                          >
                            Companies*
                          </label>
                          <div className="col-md-9">
                            <select
                              className="form-select mb-5px"
                              id="company"
                              name="company"
                              value={formValues.company}
                              onChange={handleInputChange}
                            >
                              {slsCompany.map((option, index) => (
                                <option
                                  key={"form_" + index}
                                  value={option.value_option}
                                >
                                  {option.name_option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}

                      {/* Condición para mostrar el div de Branches si rol es 1, 2, 4 o 6 */}
                      {(idUserP.rol === 1 ||
                        idUserP.rol === 2 ||
                        idUserP.rol === 4 ||
                        idUserP.rol === 6) && (
                        <div className="row md-15px">
                          <label
                            className="form-label col-form-label col-md-3"
                            htmlFor="branch"
                          >
                            Branches
                          </label>
                          <div className="col-md-9">
                            <select
                              className="form-select mb-5px"
                              id="branch"
                              name="branch"
                              value={formValues.branch}
                              onChange={handleInputChange}
                            >
                              {slsBranch.map((option, index) => (
                                <option
                                  key={"form_" + index}
                                  value={option.value}
                                >
                                  {option.text}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}

                    </div>

                    <div className="lrow md-15px" style={{ margin: 10 }}>
                        <button
                        type="button"
                        className="btn btn-green"
                        style={{ float: "right" }}
                        onClick={handleSubmit}
                        >
                        Save
                        </button>
                    </div>
                    </form>

      </PanelBody>
    </Panel>
  );
}

export default EditBestValueOffers;
