import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import { createnewfilter } from "./../../utils/urls.js";
import { viewInfoValuesTax, updateinfoValueTax } from "./../../utils/urls.js";

function ValuesTaxFormElements() {
  const [data, setData] = useState([]); // Cambiado a un array
  const [placeholderValues, setPlaceholderValues] = useState([]);
  const [inputValues, setInputValues] = useState([]);

  useEffect(() => {
    fetch(viewInfoValuesTax())
      .then((response) => response.json())
      .then((data) => {
        //console.log("información a mostrar ");
        //console.log(data);

        setData(data); // Guardar todo el array en lugar de un solo objeto

        // Inicializar los valores de placeholder e input para cada objeto en data
        setPlaceholderValues(
          data.map((item) => ({
            value1: item?.value1 !== null ? item?.value1 : 0,
            value2: item?.value2 !== null ? item?.value2 : 0,
            value3: item?.value3 !== null ? item?.value3 : 0,
            value4: item?.value4 !== null ? item?.value4 : 0,
            value5: item?.value5 !== null ? item?.value5 : 0,
          }))
        );

        setInputValues(
          data.map(() => ({
            value1: '',
            value2: '',
            value3: '',
            value4: '',
            value5: '',
          }))
        );
      })
      .catch((error) => console.error(error));
  }, []);

  function handleClick(index, nameInput) {
    const valueNew = inputValues[index][nameInput];
   //console.log('Entró en función para actualizar. <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ')
   /*console.log(JSON.stringify({
        id_valuetax: data[index].id_valuetax, // Usar el ID obtenido del objeto correspondiente en data
        [nameInput]: valueNew // Usar el nombre del input como clave en el cuerpo de la solicitud
    }))*/
    fetch(updateinfoValueTax(), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id_valuetax: data[index].id_valuetax, // Usar el ID obtenido del objeto correspondiente en data
            [nameInput]: valueNew // Usar el nombre del input como clave en el cuerpo de la solicitud
        }), 
    })
        .then(response => response.json())
        .then(data => {
           //console.log('Respuesta de crear registro');
           //console.log(data);
            alert("Actualización exitosa.");
        })
        .catch(error => {
            console.error(error);
            alert("Hubo un error al crear el filtro. Por favor, intente nuevamente.");
        });
  }

  function handleChange(index, event) {
    const { name, value } = event.target;
    setInputValues(prevValues => {
      const updatedValues = [...prevValues];
      updatedValues[index][name] = value;
      return updatedValues;
    });
  }

  return (
    <div>
      <div className="row mb-3">
        {data.map((item, index) => (
          <div className="col-xl-6" key={index}>
            <Panel>
              <PanelHeader> Info Values Tax </PanelHeader>
              <PanelBody>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Stage{" "}
                  </label>
                  <label className="form-label col-form-label col-md-3">
                    {item.name}
                  </label>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Description{" "}
                  </label>
                  <label className="form-label col-form-label col-md-3">
                    {item.description}
                  </label>
                </div>

                {['value1', 'value2', 'value3', 'value4', 'value5'].map((value, i) => (
                  <div className="row mb-15px" key={i}>
                    <label className="form-label col-form-label col-md-3">
                      Value {i + 1}
                    </label>
                    <div className="col-md-9">
                      <input
                        type="number"
                        name={value}
                        placeholder={placeholderValues[index][value]}
                        style={{ marginRight: "10px" }}
                        value={inputValues[index][value]}
                        onChange={(event) => handleChange(index, event)}
                      />
                      <button
                        type="button"
                        className="btn btn-primary w-100px me-5px"
                        onClick={() => handleClick(index, value)}
                      >
                        {" "}
                        Update{" "}
                      </button>
                    </div>
                  </div>
                ))}
              </PanelBody>
            </Panel>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ValuesTaxFormElements;
