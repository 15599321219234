import React, { useState, useEffect, useMemo   } from "react";
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel.jsx";
import { ReactNotifications } from "react-notifications-component";
import { getFromLocalStorage } from "../../components/helps.js";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Moment from 'moment';
import Chart from 'react-apexcharts';
import {postInfoSales} from "../../utils/urls.js";


function TransactionsView({ idUser, infoUser, dataUser }) {
  
  ////////////////////////////////////////////////////////////////////////////////     LET
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  ////////////////////////////////////////////////////////////////////////////////     VAR
  var startDate = Moment().subtract(7, 'days');
  var endDate = Moment();
  var dateRange = {
    currentWeek: Moment().subtract('days', 7).format('D MMMM YYYY') + ' - ' + Moment().format('D MMMM YYYY'),
    prevWeek: Moment().subtract('days', 15).format('D MMMM') + ' - ' + Moment().subtract('days', 8).format('D MMMM YYYY')
  }
  var visitorChartOptions = {
		colors: ['#00acac', '#348fe2'],
		fill: { opacity: .75, type: 'solid' },
		legend: {
			position: 'top',
			horizontalAlign: 'right',
			offsetY: 15,
			offsetX: 500,
			labels: { colors: '#ffffff' }
		},
		xaxis: {
			type: 'datetime',
			tickAmount: 6,
			labels: { style: { colors: '#ffffff' } }
		},
		yaxis: { labels: { style: { colors: '#ffffff' } } },
		tooltip: { y: { formatter: function (val) { return "$ " + val + " thousands" } } },
		chart: { height: '100%', type: 'area', toolbar: { show: false }, stacked: true },
		plotOptions: { bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded' } },
		dataLabels: { enabled: false },
		grid: { 
			show: true, borderColor: 'rgba(255,255,255, .15)',
			xaxis: { lines: { show: true } },   
			yaxis: { lines: { show: true } },
			padding: { top: -40, right: 3, bottom: 0, left: 10 }
		},
		stroke: {  show: false, curve: 'straight' }
	};
  var visitorChartSeries = [{ 
		name: 'Unique Visitors', 
		data: [
			[handleGetDate(77), 13], [handleGetDate(76), 13], [handleGetDate(75), 6 ], 
			[handleGetDate(73), 6 ], [handleGetDate(72), 6 ], [handleGetDate(71), 5 ], [handleGetDate(70), 5 ], 
			[handleGetDate(69), 5 ], [handleGetDate(68), 6 ], [handleGetDate(67), 7 ], [handleGetDate(66), 6 ], 
			[handleGetDate(65), 9 ], [handleGetDate(64), 9 ], [handleGetDate(63), 8 ], [handleGetDate(62), 10], 
			[handleGetDate(61), 10], [handleGetDate(60), 10], [handleGetDate(59), 10], [handleGetDate(58), 9 ], 
			[handleGetDate(57), 9 ], [handleGetDate(56), 10], [handleGetDate(55), 9 ], [handleGetDate(54), 9 ], 
			[handleGetDate(53), 8 ], [handleGetDate(52), 8 ], [handleGetDate(51), 8 ], [handleGetDate(50), 8 ], 
			[handleGetDate(49), 8 ], [handleGetDate(48), 7 ], [handleGetDate(47), 7 ], [handleGetDate(46), 6 ], 
			[handleGetDate(45), 6 ], [handleGetDate(44), 6 ], [handleGetDate(43), 6 ], [handleGetDate(42), 5 ], 
			[handleGetDate(41), 5 ], [handleGetDate(40), 4 ], [handleGetDate(39), 4 ], [handleGetDate(38), 5 ], 
			[handleGetDate(37), 5 ], [handleGetDate(36), 5 ], [handleGetDate(35), 7 ], [handleGetDate(34), 7 ], 
			[handleGetDate(33), 7 ], [handleGetDate(32), 10], [handleGetDate(31), 9 ], [handleGetDate(30), 9 ], 
			[handleGetDate(29), 10], [handleGetDate(28), 11], [handleGetDate(27), 11], [handleGetDate(26), 8 ], 
			[handleGetDate(25), 8 ], [handleGetDate(24), 7 ], [handleGetDate(23), 8 ], [handleGetDate(22), 9 ], 
			[handleGetDate(21), 8 ], [handleGetDate(20), 9 ], [handleGetDate(19), 10], [handleGetDate(18), 9 ], 
			[handleGetDate(17), 10], [handleGetDate(16), 16], [handleGetDate(15), 17], [handleGetDate(14), 16], 
			[handleGetDate(13), 17], [handleGetDate(12), 16], [handleGetDate(11), 15], [handleGetDate(10), 14], 
			[handleGetDate(9) , 24], [handleGetDate(8) , 18], [handleGetDate(7) , 15], [handleGetDate(6) , 14], 
			[handleGetDate(5) , 16], [handleGetDate(4) , 16], [handleGetDate(3) , 17], [handleGetDate(2) , 7 ], 
			[handleGetDate(1) , 7 ], [handleGetDate(0) , 7 ]
		]
	}, { 
		name: 'Page Views', 
		data: [
			[handleGetDate(77), 14], [handleGetDate(76), 13], [handleGetDate(75), 15], 
			[handleGetDate(73), 14], [handleGetDate(72), 13], [handleGetDate(71), 15], [handleGetDate(70), 16], 
			[handleGetDate(69), 16], [handleGetDate(68), 14], [handleGetDate(67), 14], [handleGetDate(66), 13], 
			[handleGetDate(65), 12], [handleGetDate(64), 13], [handleGetDate(63), 13], [handleGetDate(62), 15], 
			[handleGetDate(61), 16], [handleGetDate(60), 16], [handleGetDate(59), 17], [handleGetDate(58), 17], 
			[handleGetDate(57), 18], [handleGetDate(56), 15], [handleGetDate(55), 15], [handleGetDate(54), 15], 
			[handleGetDate(53), 19], [handleGetDate(52), 19], [handleGetDate(51), 18], [handleGetDate(50), 18], 
			[handleGetDate(49), 17], [handleGetDate(48), 16], [handleGetDate(47), 18], [handleGetDate(46), 18], 
			[handleGetDate(45), 18], [handleGetDate(44), 16], [handleGetDate(43), 14], [handleGetDate(42), 14], 
			[handleGetDate(41), 13], [handleGetDate(40), 14], [handleGetDate(39), 13], [handleGetDate(38), 10], 
			[handleGetDate(37), 9 ], [handleGetDate(36), 10], [handleGetDate(35), 11], [handleGetDate(34), 11], 
			[handleGetDate(33), 11], [handleGetDate(32), 10], [handleGetDate(31), 9 ], [handleGetDate(30), 10], 
			[handleGetDate(29), 13], [handleGetDate(28), 14], [handleGetDate(27), 14], [handleGetDate(26), 13], 
			[handleGetDate(25), 12], [handleGetDate(24), 11], [handleGetDate(23), 13], [handleGetDate(22), 13], 
			[handleGetDate(21), 13], [handleGetDate(20), 13], [handleGetDate(19), 14], [handleGetDate(18), 13], 
			[handleGetDate(17), 13], [handleGetDate(16), 19], [handleGetDate(15), 21], [handleGetDate(14), 22],
			[handleGetDate(13), 25], [handleGetDate(12), 24], [handleGetDate(11), 24], [handleGetDate(10), 22], 
			[handleGetDate(9) , 16], [handleGetDate(8) , 15], [handleGetDate(7) , 12], [handleGetDate(6) , 12], 
			[handleGetDate(5) , 15], [handleGetDate(4) , 15], [handleGetDate(3) , 15], [handleGetDate(2) , 18], 
			[handleGetDate(2) , 18], [handleGetDate(0) , 17]
		] 
	}];
  var pieChartOptions = {
		labels: ['New Clients', 'Return Clients'],
		chart: { type: 'donut' },
		dataLabels: { dropShadow: { enabled: false }, style: { colors: ['#fff'] } },
		stroke: { show: false },
		colors: [ '#3e99ed', '#026e6e' ],
		legend: { show: false }
	};
  var barChartOptions = {
    chart: {
      type: 'bar',
      height: 180
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'rounded',
        columnWidth: '40%'
      }
    },
    colors: ['#348fe2', '#00acac'], // Puedes cambiar los colores de las barras si lo deseas
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: ['New Visitors', 'Return Visitors'], // Categorías de las barras
    },
    yaxis: {
      title: {
        text: 'Visitors Count'
      }
    },
    legend: {
      show: false
    }
  };
  var barChartSeries = [{
    name: 'Visitors',
    data: [416747, 784466] // Los nuevos valores para las barras
  }];
  var lineChartOptions = {
    chart: {
      type: 'line',
      height: 180,
      zoom: {
        enabled: false
      }
    },
    stroke: {
      curve: 'smooth', // Esto le da la forma suave a la línea
      width: 2
    },
    colors: ['#348fe2'], // Color de la línea
    markers: {
      size: 5,
      colors: ['#fff'],
      strokeColors: '#348fe2',
      strokeWidth: 2,
      hover: {
        size: 7
      }
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'], // Meses o categorías
      title: {
        text: 'Months'
      }
    },
    yaxis: {
      title: {
        text: 'Sales'
      }
    },
    series: [{
      name: 'Sales',
      data: [2000, 2500, 3000, 3500, 4200, 5000] // Datos de ventas o cualquier valor que quieras mostrar
    }],
    trendline: {
      show: true,
      type: 'linear', // Tipo de tendencia (lineal)
      color: '#00acac', // Color de la línea de tendencia
      width: 2,
      opacity: 0.5
    },
    legend: {
      show: false
    }
  };

  ////////////////////////////////////////////////////////////////////////////////     CONST
  const [formValues, setFormValues] = useState({company: "", branch: ""});
  const [filterType, setFilterType] = useState("option1");
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [topHotels, setTopHotels] = useState([]);
  
  // Estado para almacenar los valores calculados
  const [stats, setStats] = useState({
    totalReservas: 0,         // Número total de reservas
    ingresoTotal: 0,          // Suma total de ingresos
    totalPagos: 0,            // Cantidad de pagos confirmados
    totalCancelaciones: 0,    // Cantidad de cancelaciones
    totalReembolsos: 0        // Cantidad de reembolsos
  });

  const totalSalesChartOptions = {
    chart: { sparkline: { enabled: true } },
    stroke: { curve: 'smooth', width: 2 },
    colors: ['#28a745'],
    tooltip: { enabled: false }
  };

  const totalSalesChartSeries = [
    { name: 'Sales', data: [10, 20, 15, 30, 40, 50, 60] }
  ];

  const [clientsStats, setClientsStats] = useState({
    nuevos: 0,
    recurrentes: 0,
  });
  

  const requestBody = useMemo(() => ({
    id_empresa: Number(formValues?.company) || Number(idUserP?.company),
    id_sucursal: formValues?.branch || idUserP?.branch,
    id_rol: formValues?.rol || idUserP?.rol,
  }), [formValues?.company, formValues?.branch, formValues?.rol, idUserP?.company, idUserP?.branch, idUserP?.rol]);
  ////////////////////////////////////////////////////////////////////////////////     EFFECT
  
  useEffect(() => {
    let isMounted = true;
  
    async function fetchData() {
      setLoading(true);
      setError(null);
  
      try {
        const response = await fetch(postInfoSales(), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });
  
        if (!response.ok) {
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
  
        const result = await response.json();
  
        if (isMounted) {
          if (result.success) {
            setApiData(result.data || []);
  
            // 🔹 Calcular total de reservas
            const totalReservas = result.data.length;
  
            // 🔹 Calcular ingreso total
            const ingresoTotal = result.data.reduce((acc, item) => acc + parseFloat(item.precio_noche || 0), 0);
  
            // 🔹 Contar total de pagos, cancelaciones y reembolsos según el estado de cada reservación
            const totalPagos = result.data.filter(item => item.status === "pagado").length;
            const totalCancelaciones = result.data.filter(item => item.status === "cancelado").length;
            const totalReembolsos = result.data.filter(item => item.status === "reembolsado").length;
  
            // 🔹 Guardar en el estado de estadísticas
            setStats({
              totalReservas,
              ingresoTotal,
              totalPagos,
              totalCancelaciones,
              totalReembolsos,
            });
  
            // 🔹 Agrupar hoteles por código y contar reservaciones
            const groupedHotels = result.data.reduce((acc, item) => {
              const hotelCode = item.hotelcode;
  
              if (!acc[hotelCode]) {
                acc[hotelCode] = {
                  name: item.name_hotel,
                  image: item.url_hotel,
                  totalReservations: 0,
                };
              }
  
              // Contar la cantidad de veces que aparece cada hotelcode (representa las reservaciones)
              acc[hotelCode].totalReservations += 1;
  
              return acc;
            }, {});
  
            // 🔹 Ordenar hoteles por número de reservaciones y guardar el top 5
            const sortedHotels = Object.values(groupedHotels).sort((a, b) => b.totalReservations - a.totalReservations);
  
            setTopHotels(sortedHotels.slice(0, 5));

            // 🔹 Lógica para contar clientes nuevos y recurrentes
            const clientesAgrupados = result.data.reduce((acc, item) => {
              const clienteKey = `${item.nombre}-${item.email}`;

              if (!acc[clienteKey]) {
                acc[clienteKey] = {
                  nombre: item.nombre,
                  email: item.email,
                  count: 0,
                };
              }

              acc[clienteKey].count += 1;

              return acc;
            }, {});

            // 🔹 Contar cuántos clientes son nuevos y cuántos recurrentes
            let nuevos = 0;
            let recurrentes = 0;

            Object.values(clientesAgrupados).forEach(cliente => {
              if (cliente.count === 1) {
                nuevos += 1;
              } else {
                recurrentes += 1;
              }
            });

            // 🔹 Actualizar UI con la información de clientes nuevos y recurrentes
            setClientsStats({
              nuevos,
              recurrentes,
            });

          } else {
            throw new Error("La API devolvió un error.");
          }
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }
  
    fetchData();
  
    return () => {
      isMounted = false;
    };
  }, [requestBody]);
  
  ////////////////////////////////////////////////////////////////////////////////     FUNCTION
  function handleDateApplyEvent(event, picker) {
    var startDate = picker.startDate;
    var endDate = picker.endDate;
    var gap = endDate.diff(startDate, 'days');
    
    var currentWeek = startDate.format('D MMMM YYYY') + ' - ' + endDate.format('D MMMM YYYY');
    var prevWeek = Moment(startDate).subtract('days', gap).format('D MMMM') + ' - ' + Moment(startDate).subtract('days', 1).format('D MMMM YYYY');
    
    dateRange.currentWeek = currentWeek;
    dateRange.prevWeek = prevWeek;
    
    dateRange = {
      currentWeek: currentWeek,
      prevWeek: prevWeek
    };
  }
	function convertNumberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};
  function handleGetDate(minusDate) {
		var d = new Date();
				d = d.setDate(d.getDate() - minusDate);
		return d;
	};
  ////////////////////////////////////////////////////

  return (
    <div>
      <div className="row mb-3">
        <Panel>
          <PanelHeader>Transactions</PanelHeader>
          <PanelBody>


            {/* ################################### FILTROS ################################### */}
            <div className="d-flex align-items-center mb-3">
              <DateRangePicker startDate={startDate} endDate={endDate} onApply={handleDateApplyEvent}>
                <button className="btn btn-inverse me-2 text-truncate">
                  <i className="fa fa-calendar fa-fw text-white text-opacity-50 ms-n1 me-1"></i> 
                  <span>{dateRange.currentWeek}</span>
                  <b className="caret ms-1 opacity-5"></b>
                </button>
              </DateRangePicker>              

              <select className="form-select mb-0 ms-3" value={filterType} onChange={(e) => setFilterType(e.target.value)}>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
                <option value="option4">Option 4</option>
              </select>
            </div>


            {/* #################################### DATOS #################################### */}
            
            <div className="row">
              <div className="col-xl-7">                                            {/* General */}
                <div className="card border-0 mb-3 overflow-hidden bg-gray-800 text-white">
                  <div className="card-body">
                    <div className="mb-3 text-gray-500">
                      <b>Transactions</b>
                    </div>

                    <div className="row">
                      <div className="col-xl-6 col-lg-8">
                        <div className="d-flex mb-1">
                          <h2 className="mb-0">{stats.totalReservas}</h2>
                          <div className="ms-auto mt-n1 mb-n1">
                            <Chart type="line" height={'36px'} width="90" options={totalSalesChartOptions} series={totalSalesChartSeries} />
                          </div>
                        </div>
                        <div className="mb-0 text-gray-500">
                          <i className="fa fa-caret-up"></i> Total Reservations
                        </div>
                      </div>

                      <div className="col-xl-6 col-lg-8">
                        <div className="d-flex mb-1">
                          <h2 className="mb-0">${stats.ingresoTotal.toFixed(2)}</h2>
                          <div className="ms-auto mt-n1 mb-n1">
                            <Chart type="line" height={'36px'} width="90" options={totalSalesChartOptions} series={totalSalesChartSeries} />
                          </div>
                        </div>
                        <div className="mb-0 text-gray-500">
                          <i className="fa fa-caret-up"></i> Total Revenue
                        </div>
                      </div>
                    </div>

                    <hr className="bg-white-transparent-2" />

                    <div className="row">
                      <div className="col-xl-12 col-lg-8">
                        <div className="row text-truncate">
                          <div className="col-4">
                            <div className="fs-12px text-gray-500">Total Payments</div>
                            <div className="fs-18px mb-5px fw-bold">{stats.totalPagos}</div>
                            <div className="progress h-5px rounded-3 bg-gray-900 mb-5px">
                              <div className="progress-bar progress-bar-striped rounded-right bg-teal" style={{width: `${stats.totalPagos * 10}%`}}></div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="fs-12px text-gray-500">Cancellations</div>
                            <div className="fs-18px mb-5px fw-bold">{stats.totalCancelaciones}</div>
                            <div className="progress h-5px rounded-3 bg-gray-900 mb-5px">
                              <div className="progress-bar progress-bar-striped rounded-right" style={{width: `${stats.totalCancelaciones * 10}%`}}></div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="fs-12px text-gray-500">Refunds</div>
                            <div className="fs-18px mb-5px fw-bold">{stats.totalReembolsos}</div>
                            <div className="progress h-5px rounded-3 bg-gray-900 mb-5px">
                              <div className="progress-bar progress-bar-striped rounded-right" style={{width: `${stats.totalReembolsos * 10}%`}}></div>
                            </div>
                          </div>
                        </div>
                      </div>  
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-5 col-lg-6">                               {/* Top Hoteles */}
                <div className="card bg-gray-800 border-0 text-white mb-3">
                  <div className="card-body">
                    <div className="mb-3 text-gray-500">
                      <b>Top Hotels</b>
                    </div>
                    <div>
                      {topHotels.map((hotel, index) => (
                        <div key={index} className="d-flex align-items-center text-white mb-2">
                          <div
                            className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px"
                            style={{ backgroundImage: `url(${hotel.image})`, backgroundSize: "cover" }}
                          ></div>
                          <div className="d-flex w-100">
                          <div>{index + 1}. {hotel.name}</div>
                            <div className="ms-auto text-gray-500">{hotel.totalReservations} reservations</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            

            <div className="row">
              <div className="col-xl-8 col-lg-6">                                 {/* Precios */}
                <div className="card border-0 mb-3 bg-gray-800 text-white">
                  <div className="card-body">
                    <div className="mb-3 text-gray-500">
                      <b>Precios</b>                      
                    </div>
                    <div className="row">
                      <div className="col-xl-4 col-4">
                        <h3 className="mb-1">127.1</h3>
                        <div>Proveedor 1</div>                        
                      </div>
                      <div className="col-xl-4 col-4">
                        <h3 className="mb-1">179.9</h3>
                        <div>Proveedor 2</div>                        
                      </div>
                      <div className="col-xl-4 col-4">
                        <h3 className="mb-1">766.8</h3>
                        <div>Nosotros</div>                        
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">                    
                    <div style={{height: '300px'}}>                    
                      <div className="widget-chart-full-width pe-4" data-bs-theme="dark" style={{height: '254px'}}>
                        <Chart type="area" height="254" width="100%" options={visitorChartOptions} series={visitorChartSeries} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
              <div className="col-xl-4">
                <div className="row">                                            {/* Clientes */}
                  <div className="card border-0 mb-2 overflow-hidden bg-gray-800 text-white">
                    <div className="card-body">
                      <div className="mb-1 text-gray-500 d-flex align-items-center">
                        <b className="me-2">Total Clients: </b> 
                        <h3 className="mb-0">{clientsStats.nuevos + clientsStats.recurrentes}</h3>
                      </div>                        

                      <div className="row">
                        <div className="col-xl-7 col-lg-8" style={{ margin: 0, padding: 0 }}>
                          <div className="flex-grow-1 d-flex align-items-center dark-mode" style={{ margin: 0, padding: 0 }}>
                            <Chart type="donut" height={'180'} options={pieChartOptions} series={[clientsStats.nuevos, clientsStats.recurrentes]} />
                          </div>
                        </div>
                        <div className="col-xl-5 col-lg-4 align-items-center d-flex justify-content-center flex-column">
                          <span className="me-5px mb-3">
                            <i className="fa fa-circle fa-fw fs-9px me-5px t-minus-1" style={{ color: '#3e99ed' }}></i> 
                            {((clientsStats.nuevos / (clientsStats.nuevos + clientsStats.recurrentes)) * 100).toFixed(1)}% <span>New Clients</span>
                          </span>
                          <span className="me-5px">
                            <i className="fa fa-circle fa-fw fs-9px me-5px t-minus-1" style={{ color: '#026e6e' }}></i> 
                            {((clientsStats.recurrentes / (clientsStats.nuevos + clientsStats.recurrentes)) * 100).toFixed(1)}% <span>Return Clients</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">                                         {/* Plataformas */}
                  <div className="card border-0 mb-3 overflow-hidden bg-gray-800 text-white">
                    <div className="card-body">
                      <div className="mb-3 text-gray-500">
                        <b>Plataformas</b>
                        <span className="ms-2">
                          <i className="fa fa-info-circle" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-title="Total sales" data-bs-placement="top" data-bs-content="Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels."></i>
                        </span>
                      </div>

                      <div className="row">
                        <div className="col-xl-7 col-lg-8" style={{ margin: 0, padding: 0 }}>
                          <div className="flex-grow-1 d-flex align-items-center dark-mode" style={{ margin: 0, padding: 0 }}>
                            <Chart type="bar" height={'180'} options={barChartOptions} series={barChartSeries} />
                          </div>
                        </div>
                        <div className="col-xl-5 col-lg-4 align-items-center d-flex justify-content-center flex-column">
                          <span className="me-5px mb-1">
                            <i className="fa fa-circle fa-fw text-blue fs-9px me-5px t-minus-1"></i> 34.0% <span>New Visitors</span>
                          </span>
                          <span className="me-5px">
                            <i className="fa fa-circle fa-fw text-teal fs-9px me-5px t-minus-1"></i> 56.0% <span>Return Visitors</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>                
              </div>
            </div>


            <div className="row">
              <div className="col-xl-12"> {/* General - ahora ocupa todo el espacio disponible */}
                <div className="card border-0 mb-3 overflow-hidden bg-gray-800 text-white">
                  <div className="card-body">
                    <div className="mb-3 text-gray-500">
                      <b>Reservas</b>
                      <span className="ms-2">
                        <i className="fa fa-info-circle" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-title="Sales trend" data-bs-placement="top" data-bs-content="Shows the sales trend over the past months."></i>
                      </span>
                    </div>

                    <div className="row" style={{ margin: 0, padding: 0, height: '100%' }}>
                      <div className="col-12" style={{ height: '100%' }}>
                        <div className="w-100" style={{ height: '100%' }}>
                          <Chart type="line" height="100%" options={lineChartOptions} series={lineChartOptions.series} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


                                                                          {/* Tabla de Datos */}
                                                                          {loading ? (
        <p>Cargando datos...</p>
      ) : error ? (
        <p style={{ color: "red" }}>Error: {error}</p>
      ) : apiData.length > 0 ? (
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th></th>
                <th>Hotel</th>
                <th>Descripción</th>
                <th>Precio por Noche</th>
                <th>Confirmación</th>
                <th>Cliente</th>
                <th>Correo</th>
              </tr>
            </thead>
            <tbody>
              {apiData.map((item, index) => (
                <tr key={index}>
                  <td>{item.no_total_reservaciones}</td>
                  <td>{item.name_hotel}</td>
                  <td>{item.roomdescription}</td>
                  <td>${parseFloat(item.precio_noche).toFixed(2)}</td>
                  <td>{item.confirmation_number}</td>
                  <td>{`${item.nombre} ${item.last_name}`}</td>
                  <td>{item.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No hay datos disponibles.</p>
      )}

          </PanelBody>
        </Panel>
      </div>

      <ReactNotifications />
    </div>

  );
}

export default TransactionsView;
