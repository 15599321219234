// components/DataTable.tsx

import React from 'react';
import { getFromLocalStorage } from "../../components/helps.js";


interface DataTableProps {
    header: string[];
    headerMap: Record<string, string>;
    info: any[];
    onViewDetail: (id: any) => void;
    onEditRegister: (id: any) => void;
    onDelete: (id: any) => void;
    viewOnly: boolean

}

const DataTableConsulting: React.FC<DataTableProps> = ({ header, headerMap, info, onViewDetail, onEditRegister, onDelete, viewOnly = false }) => {
    const validHeader = Array.isArray(header) ? header : [];

    let idUserP = getFromLocalStorage("Session");

    // Verificar el rol de idUserP
    const canViewDetails = [1, 2, 4, 6, 3, 5, 7].includes(idUserP?.rol);
    const canEditDelete = [1, 2, 4, 6].includes(idUserP?.rol);

    return (
        <div className='table-responsive'>
            <table className='table table-bordered table-striped'>
                <thead>
                    <tr>
                        {validHeader.map((item, index) => (
                            <th key={"head_" + index} style={item.includes("_id") || item.includes("id_") ? { display: "none" } : {}}>
                                {headerMap[item] || item}
                            </th>
                        ))}
                        {(!viewOnly && (onViewDetail != undefined || onEditRegister != undefined || onDelete != undefined)) && <th>Acciones</th>}
                    </tr>
                </thead>
                <tbody>
                    {info.map((item, index) => (
                        <tr key={"body-" + index}>
                            {validHeader.map((headerItem, index2) => (
                                <td key={"body_" + index + "_" + index2} style={headerItem.includes("_id") || headerItem.includes("id_") ? { display: "none" } : {}}>
                                    {typeof item[headerItem] === "boolean" ? (item[headerItem] ? "Active" : "Inactive") : item[headerItem]}
                                </td>
                            ))}
                            {(!viewOnly && (onViewDetail != undefined || onEditRegister != undefined || onDelete != undefined)) && (
                                <td key={"body_" + index + "_Accion"}>
                                    <div className='btn-group'>
                                        {canViewDetails && (
                                            <button
                                                type="button"
                                                className="btn btn-info"
                                                onClick={() => onViewDetail(item.id)}
                                                style={{ display: onViewDetail == undefined ? "none" : "" }}
                                                aria-label="View Details"
                                            >
                                                Ver Detalle
                                            </button>
                                        )}
                                        {canEditDelete && (
                                            <>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => onEditRegister(item.id)} // Llama correctamente a la función onEditRegister
                                                    style={{ display: onEditRegister == undefined ? "none" : "" }}
                                                >
                                                    Editar
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => onDelete(item.id)} // Llama correctamente a la función onDelete
                                                    style={{ display: onDelete == undefined ? "none" : "" }}
                                                >
                                                    Eliminar
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};



export default DataTableConsulting;
