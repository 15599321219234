import React, { useState, useEffect, useRef } from "react";
import { Link, useOutletContext } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";

import Highlight from "react-highlight";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { getFromLocalStorage } from "../../components/helps.js";
import {
  getAllBranch,
  getAllCompanies,
  getBranchDataByCompany,
  getfFormDataById,
  getForms,
  getFormsByCompany,
  getModulesAndSubModules,
  getModulesByUser,
  getRoles,
  getUserBranch,
  putUserModules,
  setForms,
  updateInfoInputForm,
} from "../../utils/urls.js";
import { ReactTags } from "react-tag-autocomplete";
import { AutoComplete } from "../../components/objects/Autocomplete.tsx";
import {
  UpUpdateform,
  SelectFormCompani,
  ListInputsTypes,
  CreateNewInput,
} from "./../../utils/urls.js";
import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";
import { fnCreateDataTable, fnGetDataUserByToken, fnValidNullOrEmpty } from "../../components/objects/fnCommon.tsx";

function FormInputs({ }) {
  let token = getFromLocalStorage("TK");
  const infoUser = useOutletContext();
  console.log('infoUser', infoUser);

  //const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  //const initSelec2 = { value_option: 0, name_option: "Seleccione una opcion", class: "" };
  const initSelection = { value: 0, text: "Seleccione una opcion", class: "" };
  // let inputsValue = { idInput: 0, company_id: 0, form_id: 0, label: '', identify: '', type_id: 0, requiere: false, style: '', inputClass: '', option_id: 0, model: '', attributes: '', company: '', formName: '' };
  let inputsValue = {
    input_id: 0,
    formulario: "",
    form_status: "",
    form_company: "",
    orden: 0,
    label: "",
    id_input_text: "",
    obligatorio: false,
    type: 0,
    classname: "",
    attributes: "",
    id_cat_options: 0,
    table: "",
    cat_name: "",
    column_value: "",
    column_label: "",
    endpoint: "",
    available: false
  }
  const initlst = [
    {
      idModule: 0,
      module: "",
      status: false,
      subModule: [{ idSubModule: 0, submodule: "", status: false }],
    },
  ];
  const initAutoComplete = [{}];

  const [DataFile, setDataFile] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [slsCompany, setslsCompany] = useState([initSelection]);
  const [slsBranch, setslsBranch] = useState([initSelection]);
  const [slsFormsCompany, setslsFormsCompany] = useState([initSelection]);
  const [slsRoles, setslsRoles] = useState([initSelection]);
  const [lstForms, setlstForms] = useState([initSelection]);
  const [slsForms, setslsForms] = useState([initSelection]);
  const [slsFormsCompan, setslsFormsCompan] = useState([initSelection]);
  const [lstTypesInp, setlstTypesInp] = useState([initSelection]);

  const [lstModules, setlstModules] = useState([initlst]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");

  const [isCheckedbyu, setIsCheckedbyu] = useState(false);
  const [isCheckedhotel, setIsCheckedhotel] = useState(false);
  const [isCheckedexperience, setIsCheckedexperience] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [IdSelectForm, setIdSelectForm] = useState(0);

  const [isEdited, setIsEdited] = useState(false);
  const [titleForms, setTitleForms] = useState({ title: "", id: "", visible: false });
  const [info, setInfo] = useState([]);
  const [header, setHeader] = useState([]);
  const [headerMap, setheaderMap] = useState();
  const [valueForInputs, setValueForInputs] = useState(inputsValue);



  const handleChange = (date) => {
    setStartDate(date);
  };
  const [tags, setTags] = useState([initSelection]);
  const [suggestions, setSuggestions] = useState([{ value: 0, label: "" }]);
  const frmFormularios = useRef(null);
  const frmInputs = useRef(null);
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchLoadCombos = async () => {
      try {
        if (!isLoaded) {
          if (token === undefined || token === null) {
            token = getFromLocalStorage("TK");
          }

          let selectCombos = [initSelection, ...(await fnGetCompanies())];
          //console.log("<<<<selectCombos>>>>");
          //console.log(selectCombos);
          setslsCompany(selectCombos);
          //selectCombos = [initSelec, ...(await fnGetRoles())];
          //setslsRoles(selectCombos);
          let listaModulos = await fnGetModules();
          setlstModules(listaModulos);
          let listaFormularios = await fnGetForms();
          setlstForms(listaFormularios);
          setslsForms([initSelection, ...listaFormularios]);

          let listTypesInputs = await fnGetListInputs();
          setlstTypesInp(listTypesInputs);
          setlstTypesInp([initSelection, ...listTypesInputs]);
        }
      } catch (error) {
        console.error("Error al cargar los combos ", error);
      }
    };
    if (infoUser != null && infoUser != undefined) {
      console.log("console.log", infoUser);
      fetchLoadCombos();
      setIsLoaded(true);
      setData(infoUser);
    }

    //console.log(isLoaded);
  }, [isLoaded]);

  const fnGetCompanies = async () => {
    try {
      const responseJsonCompanies = await axios.get(getAllCompanies(token));
      // console.log("token->", token);
      //console.log("Respuesta de Compania1s", responseJsonCompanies);
      // console.log("Respuesta de Companias2", responseJsonCompanies.data);
      //console.log("Respuesta de Companias3", responseJsonCompanies.data.data);
      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };
  const fnGetRoles = async () => {
    try {
      const responseJson = await axios.get(getRoles(token));
      return responseJson.data.data;
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };
  const fnGetBranch = async (idCompany) => {
    try {
      const responseJsonBranch = await axios.post(
        getBranchDataByCompany(token),
        {
          companyId: idCompany,
        }
      );
      return responseJsonBranch.data.data;
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const fnGetUserByBranch = async (idBranch) => {
    try {
      const responseJson = await axios.post(getUserBranch(token), {
        branchId: idBranch,
      });
      console.log("respuesta de usuarios->", responseJson.data.data);
      return responseJson.data.data;
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const fnGetModules = async () => {
    try {
      // console.log(getModulesAndSubModules(token));
      const responseJson = await axios.get(getModulesAndSubModules(token));
      //console.log(responseJson);
      return responseJson.data.data;
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const fnGetForms = async () => {
    try {
      // console.log(getForms(token));
      const responseJson = await axios.get(getForms(token));
      // console.log('<<<<<<<<<<<<<<<responseJson>>>>>>>>>>>>>>>');

      // console.log(responseJson);
      return responseJson.data.data;
    } catch (error) {
      console.error("Error al cargar AutoComplete ", error);
    }
  };

  const fnGetListInputs = async () => {
    try {
      // console.log(getForms(token));
      const responseJson = await axios.get(ListInputsTypes(token));
      //console.log('<<<<<<<<<<<<<<<responseJson>>>>>>>>>>>>>>>');

      //console.log(responseJson);
      return responseJson.data.data;
    } catch (error) {
      console.error("Error al cargar AutoComplete ", error);
    }
  };

  const fnGetModulesByUser = async (idUser) => {
    try {
      const responseJson = await axios.post(getModulesByUser(token), {
        userId: idUser,
      });
      //console.log("respuesta del modulo por usuario ", responseJson);
      if (responseJson.status == 201) {
        const responseDadat =
          responseJson.data != undefined ? responseJson.data : null;
        if (
          responseDadat != null &&
          responseDadat.success != undefined &&
          responseDadat.success != null &&
          responseDadat.success == true
        ) {
          if (
            responseDadat.result != undefined &&
            responseDadat.result != null &&
            responseDadat.result == "success" &&
            (responseDadat.message == undefined ||
              responseDadat.message == null) &&
            responseDadat.data != undefined &&
            responseDadat.data != null
          ) {
            //console.log("entro a la condicion donde si hay respuesta de modulos");
            let lstModulesResult = responseJson.data.data;
            //console.log("respuesta data data", lstModulesResult);
            if (
              lstModulesResult !== undefined &&
              lstModulesResult != null &&
              lstModulesResult.length > 0
            ) {
              console.log("lista de modulos SWICHES->", lstModules);
              console.log("lista de respuesta ->", lstModulesResult);
              const newMod = lstModules.map((item) => {
                const itemModule = lstModulesResult.filter(
                  (x) => x.id_module == item.idModule
                );
                console.log("modules->", item.idModule);
                console.log(
                  "resultado de busqueda por modulo ==== ",
                  itemModule
                );
                if (
                  itemModule != undefined &&
                  itemModule != null &&
                  itemModule.length > 0
                ) {
                  console.log("tiene mas de un elemento el areglo");
                  if (item.subModule.length > 0) {
                    console.log(
                      "el submodulo de los swiches tiene mas de un elemento"
                    );
                    const newSubMod = item.subModule.map((itemSubM) => {
                      console.log("lista de submodulos?? ->", itemSubM);
                      const statusSubModule = itemModule.find(
                        (x) => x.id_submodule === itemSubM.idSubModule
                      );
                      const statusSb =
                        statusSubModule != undefined && statusSubModule != null
                          ? statusSubModule.status
                          : false;
                      console.log("statusSubModule ->", statusSubModule);
                      console.log("statusSb ->", statusSb);
                      return { ...itemSubM, status: statusSb };
                    });
                    console.log("resultado de submodulo nuevo?  ->", newSubMod);
                    if (newSubMod.some((x) => x.status == true)) {
                      return { ...item, status: true, subModule: newSubMod };
                    } else {
                      return { ...item, status: false, subModule: newSubMod };
                    }
                  } else {
                    const statusModulo = itemModule.find(
                      (x) => x.id_submodule === 0
                    );
                    return { ...item, status: statusModulo.status };
                  }
                } else {
                  return { ...item, status: false };
                }
              });
              console.log(newMod);
              setlstModules(newMod);
            } else {
              const newMod = lstModules.map((item) => {
                return { ...item, status: false };
              });
              setlstModules(newMod);
            }
          } else {
            const newMod = lstModules.map((item) => {
              return { ...item, status: false };
            });
            setlstModules(newMod);
          }
        } else {
          const newMod = lstModules.map((item) => {
            return { ...item, status: false };
          });
          setlstModules(newMod);
        }
      } else {
        const newMod = lstModules.map((item) => {
          return { ...item, status: false };
        });
        setlstModules(newMod);
      }
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const changeCompany = async (e) => {
    try {
      const selectedValue = e.target.value;

      setslsBranch([initSelection, ...(await fnGetBranch(selectedValue))]);
      // console.log("Selec Branch", slsBranch);
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };
  const changeBranche = async (e) => {
    try {
      const selectedValue = e.target.value;

      setSuggestions(await fnGetUserByBranch(selectedValue));
      // console.log("Selec Branch", slsBranch);
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };
  const changeUser = async (selectedValue) => {
    try {
      //const selectedValue = e.target.value;
      await fnGetModulesByUser(selectedValue);
      // console.log("Selec Branch", slsBranch);
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const hbdSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(frmFormularios.current);
    //console.log(formData);
    const resultArray = Array.from(formData.entries()).map(([name, value]) => ({
      name,
      value,
    }));

    //console.log(resultArray);
    let jsonData = { data: {} };
    resultArray.forEach((value) => {
      jsonData.data[value.name] = value.value;
    });
    //console.log(JSON.stringify(jsonData));
  };
  const hbdSubmitForms = async (e) => {
    e.preventDefault();
    const formData = new FormData(frmFormularios.current);
    //console.log("<<<<<<<<<formData.entries()>>>>>>>>>");

    //console.log(formData.entries());
    let resultArray = Array.from(formData.entries()).map(([name, value]) => ({
      name,
      value,
    }));

    //console.log("<<<resultArray>>>");

    //console.log(resultArray);
    let jsonData = { data: {} };

    resultArray = resultArray.filter((item) => {
      //console.log("foreach->item", item);
      if (item.name === "form_Autocomplete") {
        const match = lstForms.find((x) => x.text === item.value);
        console.log("match???", match);
        jsonData.data["form_id"] =
          match !== undefined && match != null ? match.value : 0;
        jsonData.data["form_name"] =
          match !== undefined && match != null ? match.text : item.value;
        return false;
      }
      return true;
    });

    resultArray.forEach((value) => {
      jsonData.data[value.name] = value.value;
    });
    // console.log(JSON.stringify(jsonData));
    // const responseJson = await axios.post(setForms(token), {
    //   body: jsonData,
    // });
    // console.log(responseJson);
  };
  const hbdSubmitModules = async (e) => {
    e.preventDefault();
    const formData = new FormData(frmFormularios.current);
    //console.log(formData.entries());
    const resultArray = Array.from(formData.entries()).map(([name, value]) => ({
      name,
      value,
    }));

    // console.log(resultArray);
    let jsonData = { data: {} };
    // console.log(tags);
    resultArray.push({ name: "idUser", value: tags[0].value });
    resultArray.forEach((value) => {
      jsonData.data[value.name] = value.value;
    });
    // console.log("envio del data ", JSON.stringify(jsonData));
    // console.log("envio del data 2->", { body: jsonData });
    const responseJson = await axios.put(putUserModules(token), {
      body: jsonData,
    });
    //console.log(responseJson);
  };
  // const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChangeModule = (idModule) => {
    //console.log("entro a cambiar el check???", idModule);
    setlstModules((prevModules) =>
      prevModules.map((mod) =>
        mod.idModule === idModule ? { ...mod, status: !mod.status } : mod
      )
    );
  };
  const handleCheckboxChangeSubModule = (idModule, idSubModule) => {
    //console.log("entro a cambiar el check???", idSubModule);
    setlstModules((prevModules) =>
      prevModules.map((mod) =>
        mod.idModule === idModule
          ? {
            ...mod,
            subModule: mod.subModule.map((sub) =>
              sub.idSubModule === idSubModule
                ? { ...sub, status: !sub.status }
                : sub
            ),
          }
          : mod
      )
    );
  };

  const handleCheckboxChange = (value) => {
    setSelectedCompanies((prevState) => {
      if (prevState.includes(value)) {
        // Si ya está seleccionado, lo eliminamos
        return prevState.filter((company) => company !== value);
      } else {
        // Si no está seleccionado, lo agregamos
        return [...prevState, value];
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Evita la recarga de la página

    // Obtener datos del formulario
    const formName = document.querySelector(
      'input[name="form_Autocomplete"]'
    ).value;
    const description = document.querySelector(
      'textarea[name="description"]'
    ).value;

    // Construir el array de compañías seleccionadas
    const selectedCompaniesArray = slsCompany
      .filter((option) => option.value !== 0)
      .map((option) => ({
        id: option.value,
        name: option.text,
        statuscompany: selectedCompanies.includes(option.value),
      }));

    // Crear el objeto final con los valores capturados
    const formData = {
      name_form: formName,
      description: description,
      arraycompany: selectedCompaniesArray,
    };

    // Enviar la solicitud fetch
    try {
      fetch(UpUpdateform(token), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Error en la respuesta del servidor");
          }
          return response.json();
        })
        .then((data) => {
          //console.log("Formulario guardado exitosamente:", data);

          // Limpiar valores del formulario después de la respuesta exitosa
          document.querySelector('input[name="form_Autocomplete"]').value = "";
          document.querySelector('textarea[name="description"]').value = "";
          setSelectedCompanies([]); // Reinicia el estado de las compañías seleccionadas
        })
        .catch((error) => console.error("Fetch error:", error));
    } catch (error) {
      console.log("se genera error en try:", error);
    }
  };

  const handleSelectChange = (event) => {
    const selectedText = event.target.options[event.target.selectedIndex].text;
    inputChangeValue(event);
    const formData = {
      nameCompany: selectedText,
    };

    fetch(SelectFormCompani(token), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error en la respuesta del servidor");
        }
        return response.json();
      })
      .then((data) => {
        // console.log("Respuesta del servidor:", data);
        // setslsFormsCompan(data);
        setslsFormsCompan(data.data);
        setslsFormsCompan([initSelection, ...data.data]);
      })
      .catch((error) => console.error("Error en la petición fetch:", error));
  };

  const HandClickSaveInput = (e) => {
    e.preventDefault();

    //console.log("funcion para construir array para guardar input de formulario << ");

    // Capturando los valores del formulario
    const label = document.getElementById("txtLabel").value;
    const idInputText = document.getElementById("txtIdentifi").value;
    const type = document.getElementById("slForms").value;
    const mandatory = document.getElementById("name_swMandatory").checked;
    const style = document.getElementById("txtStyle").value;
    const classname = selectedClass; // Valor del estado
    const selectedOptionElement = document.getElementById("slForms"); // Capturamos el elemento select
    const selectedOptionValue = selectedOptionElement.value; // Valor seleccionado
    const selectedOptionText = selectedOptionElement.options[selectedOptionElement.selectedIndex].text; // Texto de la opción seleccionada
    const modelo = document.getElementById("txtModel").value;
    const attributes = document.getElementById("txtModtxtAtributesel").value;

    // Construir el array para "options" con el valor y texto seleccionados
    const options = [
      {
        id: parseInt(selectedOptionValue, 10), // Convertir el valor a entero si es necesario
        name: selectedOptionText
      }
    ];

    // Construir el array con la estructura solicitada
    const formData = {
      label: label || "",
      id_input_text: idInputText || "",
      type: type || "",
      mandatory: mandatory,
      style: style || "",
      classname: classname || "",
      options: selectedOption,
      modelo: modelo || "",
      attributes: attributes || "",
      basic: false, // Valor predeterminado
      idformulario: IdSelectForm
    };

    console.log("Datos del formulario guardados: ", formData);
    if (valueForInputs.idInput == 0) {

      fetch(CreateNewInput(token), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Error en la respuesta del servidor");
          }
          alert("se guardo el registro");
          return response.json();
        })
        .then((data) => {
          console.log("Respuesta del servidor:", data);
          alert("Respuesta del servidor:", data);
        })
        .catch((error) => {
          alert("Error en la petición fetch:", error);
          console.error("Error en la petición fetch:", error)
        });
    } else {
      fetch(updateInfoInputForm(token), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(valueForInputs),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Error en la respuesta del servidor");
          }
          alert("se actualizo el registro");
          return response.json();
        })
        .then((data) => {
          console.log("Respuesta del servidor:", data);
          alert("Respuesta del servidor:", data);
        })
        .catch((error) => {
          alert("Error en la petición fetch:", error);
          console.error("Error en la petición fetch:", error)
        });
    }

  };


  const handleSelectChangeSelectOptions = (e) => {
    const selectedValue = e.target.value; // Obtiene el valor seleccionado
    const selectedText = e.target.options[e.target.selectedIndex].text; // Obtiene el texto del option

    const formattedObject = {
      id: parseInt(selectedValue),
      name: selectedText,

    };
    inputChangeValue(e);
    setSelectedOption(formattedObject);
  };

  const handleSelectChangeTypeInputs = (event) => {
    //console.log("Evento onChange disparado:", event.target.value); // Ver si la función se ejecuta
    const selectedValue = event.target.value;

    const selectedOption = lstTypesInp.find(
      (option) => option.value === parseInt(selectedValue, 10)
    );
    //console.log('selectedOption')
    inputChangeValue(event);
    //console.log(selectedOption)
    if (selectedOption) {
      setSelectedClass(selectedOption.class);
      //console.log("Clase seleccionada en el cambio:", selectedOption.class);
    }
  };

  const handleIdFormulary = (event) => {
    //console.log("Evento onChange disparado:", event.target.value); // Ver si la función se ejecuta
    const selectedValue = event.target.value;
    inputChangeValue(event);
    setIdSelectForm(selectedValue)

  };

  //console.log('slsCompany///////////////////')
  //console.log(slsCompany)

  const handleSelectCompany = async (event) => {
    const selected = event.target.options[event.target.selectedIndex].text;
    const selectedValue = event.target.options[event.target.selectedIndex].value;
    console.log('company select ->', selected);
    console.log('company select value->', selectedValue);
    setslsFormsCompany([initSelection]);
    setTitleForms({ title: selected, id: selectedValue + ',', visible: false });
    if (selectedValue != 0) {
      var listForms = await axios.post(getFormsByCompany(token), {
        companyId: selectedValue
      });
      console.log('lista forms', listForms);
      console.log(listForms.data);
      console.log(listForms.data.success);
      //console.log(listForms.data.data);
      if (listForms.data != undefined && listForms.data.success && listForms.data.data != undefined && listForms.data.data.length > 0) {
        var dataResult = listForms.data.data;
        setslsFormsCompany([initSelection, ...dataResult]);
      }
    }

    //setslsFormsCompany
  }
  const handleSelectForms = async (event) => {
    const selected = event.target.options[event.target.selectedIndex].text;
    const selectedValue = event.target.options[event.target.selectedIndex].value;
    console.log('company select ->', selected);
    console.log('company select value->', selectedValue);
    if (selectedValue != 0) {
      var listInputs = await axios.post(getfFormDataById(token), { formID: selectedValue });
      console.log('listInputs', listInputs.data);
      if (listInputs.data != undefined && listInputs.data.success && listInputs.data.data != undefined) {
        let infoInputs = listInputs.data.data;
        let title = titleForms.title + " - " + infoInputs[0].formulario;
        let ids = titleForms.id + selectedValue;
        setTitleForms({ title: title, id: ids, visible: true });
        const headers = {
          "input_id": "input_id",
          "id_cat_options": "id_cat_options",
          "label": "Etiqueta",
          "id_input_text": "Identificador",
          "type": "Tipo",
          "obligatorio": "Obligarorio",
          "classname": "Clase",
          "attributes": "Atributos adicionales",
          "table": "Tabla de origen",
          "cat_name": "Catalogo de origen",
          "column_value": "Columna de valores",
          "column_label": "Columna de texto",
          "endpoint": "Ruta de acceso",
          "form_status": "Estatus de formulario",
          "orden": "Orden de aparicion"
        };
        const infoResult = fnCreateDataTable(infoInputs, headers);
        console.log(infoResult);
        setInfo(infoResult.data);
        setHeader(infoResult.head);
        setheaderMap(infoResult.headerMap);

      };
    }
    //setslsFormsCompany
  }
  const handleEdithInput = (index) => {
    console.log(index);

    const resultFind = info[index];
    console.log(resultFind);
    const filters = titleForms.id.split(",");
    const filtersLabel = titleForms.title.split("-");
    const idType = resultFind["type"] != null ? lstTypesInp.find(x => x.text == resultFind["type"]).value : 0;
    console.log(lstTypesInp);
    console.log(idType);
    //const idOptions = resultFind["id_cat_options"] != null ? slsCompany.find(x => x.name_option == resultFind["id_cat_options"]).value_option : 0;
    const editInput = {
      idInput: resultFind["input_id"],
      company_id: filters[0],
      form_id: filters[1],
      label: fnValidNullOrEmpty(resultFind["label"]),
      identify: fnValidNullOrEmpty(resultFind["id_input_text"]),
      type_id: idType,
      requiere: fnValidNullOrEmpty(resultFind["obligatorio"]),
      style: '',
      inputClass: fnValidNullOrEmpty(resultFind["classname"]),
      option_id: 0,
      model: fnValidNullOrEmpty(resultFind["table"]),
      attributes: fnValidNullOrEmpty(resultFind["attributes"]),
      company: filtersLabel[0],
      formName: filtersLabel[1]
    };

    setValueForInputs(editInput);
    setIsEdited(true);
  }

  const inputChangeValue = (e) => {
    const { id, name, type, value } = e.target;
    // console.log('targuet->', e.target);
    // console.log('targuet->', id);
    // console.log('targuet->', name);
    // console.log('targuet->', type);
    // console.log('targuet->', value);
    inputsValue = valueForInputs;
    var nameInput = ''
    
    switch (id) {
      case 'slCompany':
        nameInput = 'company_id';
        break;
      case 'slForms':
        nameInput = "form_id";
        break;
      case 'txtLabel':
        nameInput = 'label';
        break;
      case 'txtIdentifi':
        nameInput = "identify";
        break;
      case 'slTypes':
        nameInput = "type_id";
        break;
      case 'name_swMandatory':
        nameInput = "requiere";
        break;
      case 'txtStyle':
        nameInput = "style";
        break;
      case 'txtClase':
        nameInput = "inputClass";
        break;
      case 'slOpciones':
        nameInput = "option_id";
        break;
      case 'txtModel':
        nameInput = "model";
        break;
      case 'txtModtxtAtributesel':
        nameInput = "attributes";
        break;
    }

    setValueForInputs((prev) => ({
      ...prev,
      [nameInput]: (type === "checkbox" ? e.target.checked : value)
    }));
  };
  if (!data) {
    return <div>Loading Dashboard...</div>;
  }
  return (
    <div>
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <Link to="/form/elements">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/form/elements">Form Stuff</Link>
        </li>
        <li className="breadcrumb-item active">Form Elements</li>
      </ol>
      <h1 className="page-header">
        Form Elements <small>header small text goes here...</small>
      </h1>
      <div className="row">
        <div className="col-md-12">
          <Panel>
            <PanelHeader>Formularios</PanelHeader>

            <PanelBody>
              <div className="row md-15px">
                <label className="form-label col-form-label col-md-3" htmlFor="slsCompany"> Compañia</label>
                <div className="col-md-9">
                  <select className="form-select mb-5px" id="slsCompany" name="company" onChange={handleSelectCompany} >
                    {slsCompany.map((option, index) => (
                      <option key={"form_" + index} value={option.value_option}>
                        {option.name_option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row md-15px">
                <label className="form-label col-form-label col-md-3" htmlFor="slsFormulari"> Formulario</label>
                <div className="col-md-9">
                  <select className="form-select mb-5px" id="slsFormulari" name="formulario" onChange={handleSelectForms} >
                    {slsFormsCompany.map((option, index) => (
                      <option key={"form_" + index} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {titleForms.visible && (
                <div className="row">
                  <div className="row md-15px col-md-11">
                    <h3>{titleForms.title}</h3>
                  </div>
                  <div className="row md-15px">
                    <div className="col-md-12">
                      <DataTableConsulting header={header} headerMap={headerMap} info={info} onEditRegister={handleEdithInput} />
                    </div>
                  </div>
                </div>
              )
              }
            </PanelBody>
          </Panel>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Panel>
            <PanelHeader>Alta de Formularios</PanelHeader>

            <PanelBody>
              <form onSubmit={handleSubmit}>
                <div className="row md-15px">
                  <label className="form-label col-form-label col-md-3" htmlFor="txtForms">
                    Nombre del Formulario
                  </label>
                  <div className="col-md-9">
                    <AutoComplete
                      inputStyle={{ backgroundColor: "white" }}
                      optionsStyle={{ backgroundColor: "white" }}
                      data={lstForms}
                      name="form_Autocomplete"
                      id="form_id"
                    />
                  </div>
                </div>

                <div className="row md-15px">
                  <label className="form-label col-form-label col-md-3" htmlFor="slCompany">
                    Add Companis
                  </label>
                  <div className="col-md-9">
                    {slsCompany
                      .filter((option) => option.value_option !== 0 || option.value) // Filtra tanto por `value` como por `value_option`
                      .map((option, index) => (
                        <div
                          key={"company_" + index}
                          className="form-check form-switch"
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`company_${index}`}
                            value={option.value || option.value_option} // Accede a `value` o `value_option`
                            checked={selectedCompanies.includes(option.value || option.value_option)} // Verifica `value` o `value_option`
                            onChange={() => handleCheckboxChange(option.value || option.value_option)}
                            name={`company_${option.value || option.value_option}`} // Usa `value` o `value_option`
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`company_${index}`}
                          >
                            {option.text || option.name_option} {/* Muestra `text` o `name_option` */}
                          </label>
                        </div>
                      ))}
                  </div>

                </div>

                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="txtDescription"
                  >
                    Descripcion
                  </label>
                  <div className="col-md-9">
                    <textarea
                      className="form-control"
                      rows="3"
                      name="description"
                      id="txtDescription"
                    ></textarea>
                  </div>
                </div>

                <div className="lrow md-15px" style={{ margin: 10 }}>
                  <button
                    type="submit"
                    className="btn btn-green"
                    style={{ float: "right" }}
                  >
                    Guardar
                  </button>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
        <div className="col-md-6">
          <Panel>
            <PanelHeader>Formulario de alta de campos</PanelHeader>
            <PanelBody>
              <form id="frmInputs">
                <div className="row md-15px">

                  <label
                    className="form-label col-form-label col-md-3"
                    for="slCompany"
                  >
                    Empresa
                  </label>
                  <div className="col-md-9" style={isEdited ? { display: "none" } : {}}>
                    <select
                      className="form-select mb-5px"
                      id="slCompany"
                      name="type"
                      onChange={handleSelectChange} // Evento para manejar el cambio
                      value={valueForInputs.company_id}
                    >
                      {slsCompany.map((option, index) => (
                        <option key={"form_" + index} value={option.value_option}>
                          {option.name_option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-9" style={!isEdited ? { display: "none" } : {}}>
                    <input type="text"
                      className="form-control mb-5px"
                      id="txtCompany"
                      name="company"
                      readOnly
                      value={valueForInputs.company}>
                    </input>
                  </div>
                </div>
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    for="slForms"
                  >
                    Formulario
                  </label>
                  <div className="col-md-9" style={isEdited ? { display: "none" } : {}}>
                    <select
                      className="form-select mb-5px"
                      id="slForms"
                      name="type"
                      onChange={handleIdFormulary} // Asigna el evento de cambio
                      value={valueForInputs.form_id}
                    >
                      {slsFormsCompan.map((option, index) => (
                        <option key={"form_" + index} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-9" style={!isEdited ? { display: "none" } : {}}>
                    <input type="text"
                      className="form-control mb-5px"
                      id="txtFormName"
                      name="formName"
                      readOnly
                      value={valueForInputs.formName}>
                    </input>
                  </div>
                </div>
                <div className="row md-15px">
                  <label className="form-label col-form-label col-md-3" for="txtLabel">
                    Campo
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control mb-5px"
                      type="text"
                      id="txtLabel"
                      name="label"
                      value={valueForInputs.label}
                      onChange={inputChangeValue}
                    ></input>
                  </div>
                </div>
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    for="txtIdentifi"
                  >
                    Identificador
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control mb-5px"
                      type="text"
                      id="txtIdentifi"
                      name="id_input_text"
                      value={valueForInputs.identify}
                      onChange={inputChangeValue}
                    ></input>
                  </div>
                </div>

                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="slTypes"
                  >
                    Tipo
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="slTypes"
                      name="type"
                      onChange={handleSelectChangeTypeInputs} // Asigna el evento de cambio
                      value={valueForInputs.type_id}
                    >
                      {lstTypesInp.map((option, index) => (
                        <option key={"form_" + index} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="swMandatory"
                  >
                    Obligatorio
                  </label>
                  <div className="col-md-9 form-check form-switch d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="name_swMandatory"
                      checked={valueForInputs.requiere}
                      onChange={inputChangeValue}
                    />
                  </div>
                </div>

                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    for="txtStyle"
                  >
                    Estilo
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control mb-5px"
                      type="text"
                      id="txtStyle"
                      name="style"
                      value={valueForInputs.style}
                      onChange={inputChangeValue}
                    ></input>
                  </div>
                </div>
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="txtClase"
                  >
                    Clases
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control mb-5px"
                      type="text"
                      id="txtClase"
                      name="style"
                      value={selectedClass || valueForInputs.inputClass} // El valor del input será el estado seleccionado
                      readOnly // Hacer que el campo sea de solo lectura
                      onChange={inputChangeValue}
                    />
                  </div>
                </div>
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="txtOpciones"
                  >
                    Opciones
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="slOpciones" // Cambia el id a algo único
                      name="opciones" // Cambia el name a algo representativo
                      onChange={handleSelectChangeSelectOptions} // Evento para manejar el cambio
                      value={valueForInputs.option_id}
                    >
                      {slsCompany.map((option, index) => (
                        <option key={option.id} value={option.value_option}> {/* Usa el id como key */}
                          {option.name_option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    for="txtModel"
                  >
                    Modelo
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control mb-5px"
                      type="text"
                      id="txtModel"
                      name="model"
                      value={valueForInputs.model}
                      onChange={inputChangeValue}
                    ></input>
                  </div>
                </div>
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    for="txtAtributes"
                  >
                    Atributos
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control mb-5px"
                      placeholder="[atributo='']"
                      type="text"
                      id="txtModtxtAtributesel"
                      name="atributos"
                      value={valueForInputs.attributes}
                      onChange={inputChangeValue}
                    ></input>
                  </div>
                </div>


                <div className="lrow md-15px">
                  <button
                    type="submit"
                    className="btn btn-green"
                    style={{ float: "right" }}
                    onClick={HandClickSaveInput}
                  >
                    Guardar
                  </button>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>


      <div className="row" style={{ display: "none" }}>
        <div className="col-12">
          <Panel>
            <PanelHeader>usuariosDashboard</PanelHeader>
            <PanelBody>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">
                  Rol
                </label>
                <div className="col-md-9">
                  <select className="form-select">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                </div>
              </div>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">
                  Empresa
                </label>
                <div className="col-md-9">
                  <select className="form-select">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                </div>
              </div>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">
                  Nombre
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control mb-5px"
                    placeholder="Nombres (s)"
                  />
                </div>
              </div>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">
                  Apeliido Paterno
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control mb-5px"
                    placeholder="Apellido Paterno"
                  />
                </div>
              </div>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">
                  Apellido Materno
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control mb-5px"
                    placeholder="Apellido Materno"
                  />
                </div>
              </div>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">
                  email
                </label>
                <div className="col-md-9">
                  <input
                    type="email"
                    className="form-control mb-5px"
                    placeholder="email@example.com"
                  />
                </div>
              </div>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">
                  Telefono fijo (lada)
                </label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control mb-5px"
                    placeholder="(55) 9999999999"
                    maxLength={10}
                    min={0}
                  />
                </div>
              </div>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">
                  Telefono celular (lada)
                </label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control mb-5px"
                    placeholder="(55) 9999999999"
                    maxLength={10}
                    min={0}
                  />
                </div>
              </div>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">
                  Pais
                </label>
                <div className="col-md-9">
                  <select className="form-select">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                </div>
              </div>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">
                  Estado
                </label>
                <div className="col-md-9">
                  <select className="form-select">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                </div>
              </div>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">
                  Codigo Postal
                </label>
                <div className="col-md-9">
                  <select className="form-select">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                </div>
              </div>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">
                  Direccion{" "}
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control mb-5px"
                    placeholder="calle, no. exterior, no. interior , localidad"
                    maxLength={10}
                    min={0}
                  />
                </div>
              </div>
              <div className="lrow md-15px">
                <button
                  type="submit"
                  className="btn btn-green"
                  style={{ float: "right" }}
                >
                  Guardar
                </button>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
      <div className="row">
        <div className="col-12"></div>
      </div>
    </div>
  );
}

export default FormInputs;

