import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications, Store } from "react-notifications-component";
import { gettSearchCountryCode,} from "../../utils/urls.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";
import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";
import EditupdateCity from "./mapping-editdatecity.js";

const Countrys = [
  "AD",
  "AE",
  "AG",
  "AI",
  "AL",
  "AM",
  "AO",
  "AR",
  "AS",
  "AT",
  "AU",
  "AW",
  "AX",
  "AZ",
  "BA",
  "BB",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BL",
  "BM",
  "BN",
  "BO",
  "BQ",
  "BR",
  "BS",
  "BT",
  "BV",
  "BW",
  "BY",
  "BZ",
  "CA",
  "CC",
  "CD",
  "CG",
  "CH",
  "CI",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CR",
  "CU",
  "CV",
  "CW",
  "CY",
  "CZ",
  "DE",
  "DJ",
  "DK",
  "DM",
  "DO",
  "DZ",
  "EC",
  "EE",
  "EG",
  "ER",
  "ES",
  "ET",
  "FI",
  "FJ",
  "FM",
  "FO",
  "FR",
  "GA",
  "GB",
  "GD",
  "GE",
  "GF",
  "GG",
  "GH",
  "GI",
  "GL",
  "GM",
  "GN",
  "GP",
  "GQ",
  "GR",
  "GT",
  "GU",
  "GW",
  "GY",
  "HK",
  "HN",
  "HR",
  "HT",
  "HU",
  "ID",
  "IE",
  "IL",
  "IM",
  "IN",
  "IS",
  "IT",
  "JE",
  "JM",
  "JO",
  "JP",
  "KE",
  "KG",
  "KH",
  "KI",
  "KM",
  "KN",
  "KR",
  "KW",
  "KY",
  "KZ",
  "LA",
  "LB",
  "LC",
  "LI",
  "LK",
  "LR",
  "LS",
  "LT",
  "LU",
  "LV",
  "MA",
  "MC",
  "MD",
  "ME",
  "MF",
  "MG",
  "MK",
  "ML",
  "MM",
  "MN",
  "MO",
  "MP",
  "MQ",
  "MR",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NC",
  "NE",
  "NF",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NU",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PF",
  "PG",
  "PH",
  "PK",
  "PL",
  "PR",
  "PS",
  "PT",
  "PW",
  "PY",
  "QA",
  "RE",
  "RO",
  "RS",
  "RU",
  "RW",
  "SA",
  "SB",
  "SC",
  "SD",
  "SE",
  "SG",
  "SH",
  "SI",
  "SJ",
  "SK",
  "SL",
  "SM",
  "SN",
  "SR",
  "SS",
  "ST",
  "SV",
  "SX",
  "SZ",
  "TC",
  "TG",
  "TH",
  "TJ",
  "TN",
  "TO",
  "TR",
  "TT",
  "TW",
  "TZ",
  "UA",
  "UG",
  "US",
  "UY",
  "UZ",
  "VC",
  "VE",
  "VG",
  "VI",
  "VN",
  "VU",
  "WF",
  "WS",
  "YT",
  "ZA",
  "ZM",
  "ZW",
];

function Mysearch({ idUser, infoUser, dataUser }) {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  const header = [
    "country_id",
    "country_type",
    "country_name",
    "country_coordinates",
    "country_name_google",
    "country_name_pr",
    "country_name_stateprovince",
    "country_idestado",
    "country_idagrupacion",
    "id_country",
    "name_full",
    "country_code",
  ];
  
  const headerMap = {
    country_id: "Country ID",
    country_type: "Country Type",
    country_name: "Country Name",
    country_coordinates: "Coordinates",
    country_name_google: "Google Name",
    country_name_pr: "PR Name",
    country_name_stateprovince: "State/Province Name",
    country_idestado: "State ID",
    country_idagrupacion: "Grouping ID",
    id_country: "Country Internal ID",
    name_full: "Full Name",
    country_code: "Country Code",
  };
  

  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };

  const [slsfirsload, setslsfirsload] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);
  const [formValues, setFormValues] = useState({
    // company: "",
    // branch: "",
    city: "",
    codeCountry: "",
  });
  const [slsListCombos, setslsListCombos] = useState([]);
  const [Countries, setCountries] = useState(Countrys);
  const [country, setCountry] = useState("");
  const [isCodeMode, setIsCodeMode] = useState(false);
  const [isCountry, setIsCountry] = useState(false);
  const [slsListResultGet, setslsListResultGet] = useState([]);
  const [formvisible, setformvisible] = useState(false);
  const [idRegistro, setidRegistro] = useState("");
  const [selectedRegister, setSelectedRegister] = useState(null);


  useEffect(() => {
    if (!slsfirsload) {
      setslsfirsload(true);
    }
  }, [slsfirsload]);

  const handleInputChangeCompany = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      // ...(name === "company" && { branch: "" }),
    }));
  };

  const handleGetInfo = () => {
    // console.log("Entro para generar la petición de información <<<<<<<");
  
    let query = "";
  
    // Validación para asegurarse de que los valores no estén vacíos
    if (isCountry) {
      if (!formValues.codeCountry) {
        // console.log("Error: El código de país no puede estar vacío.");
        addNotification(
          "warning",
          "Partial Success",
          "Oops, a country code must be selected.",
          "top-center"
        );
         return;  // Detenemos la ejecución si no hay código de país

      }
  
      // console.log("Se debe mandar el valor en true para país");
      query = `countrycode=${formValues.codeCountry}`;
      const result = fnGetRequest(query);
      // setslsListResultGet(result)  // Aquí puedes guardar el resultado si es necesario
    } else {
      if (!formValues.city) {
        // console.log("Error: El nombre de la ciudad no puede estar vacío.");
        addNotification(
          "warning",
          "Partial Success",
          "Oops, The name of a city must be inserted.",
          "top-center"
        );
       return;  // Detenemos la ejecución si no hay ciudad
      }
  
      console.log("Se debe mandar el valor en false");
      query = `namecity=${formValues.city}`;
      const result = fnGetRequest(query);
      // setslsListResultGet(result)  // Aquí puedes guardar el resultado si es necesario
    }
  
    // console.log("Consulta generada:", query);
  };
  
  const handleclean = () => {

    setslsListResultGet([])
formValues.codeCountry = ''
formValues.city = ''

  }

  const fnGetRequest = async (params) => {
    // console.log('<<<<< entro a peticion de get de Angel>>>>>')

    try {

      const response = await axios.get(gettSearchCountryCode(params)  );

      // console.log('<<<<<response.data>>>>>')
      // console.log(response.data)
      setslsListResultGet(response.data)


      return response.data;
    } catch (error) {
      console.error("Error al cargar las sucursales:", error);
    }
  };

  const fnViewDetail = (idRegister) => {};

  // const fnEditRegister = (idRegister) => {
  //   console.log("ID recibido para editar:", idRegister); // Asegúrate de imprimir el ID aquí
  // };

  const fnEditRegister = (register) => {
    console.log("Registro recibido para editar:", register); // Imprime el registro completo
    setidRegistro(register.country_id); // Guarda el ID
    setformvisible(true);
    setSelectedRegister(register); // Guarda el registro completo
    // setformvisibleAddDestini(false);
  };

  const fnDeleteRegister = async (idRegister) => {};

  const handleToggle = () => {
    setIsCountry((prev) => !prev);
  };

  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }

  const sortedResults = slsListResultGet.sort((a, b) => {
    // Compara los valores de 'country_name' de manera alfabética (ascendente)
    if (a.country_name < b.country_name) return -1;
    if (a.country_name > b.country_name) return 1;
    return 0; // Si son iguales
  });



  return (
    <div>
      <div className="row mb-3">
  <Panel>
    <PanelHeader>List of city information</PanelHeader>
    <PanelBody>
      <div className="row mb-3">

        <label className="form-label col-form-label col-md-3" htmlFor="toggleSwitch">
          Select type Search.
        </label>
        <div className="col-md-9 d-flex align-items-center">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="toggleSwitch"
              checked={isCountry}
              onChange={handleToggle}
            />
            <label className="form-check-label ms-2" htmlFor="toggleSwitch">
              {isCountry ? "Country" : "City"}
            </label>
          </div>
        </div>
      </div>

      {/* Input o Select según el estado */}
      <div className="row mb-3">
        <label className="form-label col-form-label col-md-3">
          {isCountry ? "Select Country" : "Enter City"}
        </label>
        <div className="col-md-9">
          {isCountry ? (
            <select
              className="form-select"
              name="codeCountry"
              value={formValues.codeCountry}
              onChange={handleInputChangeCompany}
            >
              <option value="" disabled>
                Select a country
              </option>
              {Countries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </select>
          ) : (
            <input
              className="form-control"
              type="text"
              id="city"
              name="city"
              value={formValues.city}
              onChange={handleInputChangeCompany}
              placeholder="Enter your city"
            />
          )}
        </div>
      </div>

      {/* Botón Search */}
{/* <div> */}

      <div className="d-flex justify-content-end">
      <button type="button" className="btn btn-blue" onClick={handleclean}>
        Clean
        </button>

        <button type="button" className="btn btn-green" onClick={handleGetInfo}>
          Search
        </button>
      </div>
    {/* </div> */}

      {/* Tabla de datos (comentada) */}
      <div className="row col-md-12"
              style={{
                margin: 5,
                maxHeight: "250px", // Altura máxima del contenedor principal
                overflowY: "auto", // Barra de desplazamiento vertical
                border: "1px solid #ccc", // Opcional: agregar borde para resaltar
                padding: "10px", // Espaciado interno
              }}
              >
        <DataTableConsulting header={header} headerMap={headerMap} info={sortedResults} 
        // onViewDetail={fnViewDetail} 
        onEditRegister={fnEditRegister} 
        // onDelete={fnDeleteRegister} 
        />
      </div>
    </PanelBody>
  </Panel>

  <div className="row" style={!formvisible ? { display: "none" } : {}}>
  <div className="col-12">
    <Panel>
      <PanelHeader>Edit city</PanelHeader>
      <PanelBody>
        {formvisible && (
        <EditupdateCity id={idRegistro} infoRegister={selectedRegister} />
        // <></>
        )}
      </PanelBody>
    </Panel>
  </div>
</div>

</div>
<ReactNotifications />
</div>

  );
}

export default Mysearch;
