// const urlsend = 'https://tixtestback.co/';
const urlsend = 'https://nhsa.travel/';
const urlbackAngel = 'https://www.testunit00.co/';
const backTixtest = 'https://tixtestback.co/'

// const urlsend = 'http://localhost:3000/';
const redurlsend = 'http://192.168.31.235:3000/';

/// Valida Sesion

export function getSession(token) {
  //console.log('getSession ');
  //return `${urlsend}sessionPermiss/validSession?token=${token}`;
  // return `https://auth.nhsa.travel/auth/validSession?token=${token}`;
  return `${urlsend}sessionPermiss/validSession?token=${token}`;
}

export function getMenuacces(token) {
  //console.log('getSession ');
  //return `${urlsend}sessionPermiss/validSession?token=${token}`;
  return `${urlsend}sessionPermiss/accesMenu?token=${token}`;
}

// recuperacion Contraseña
export function recoverAcces() {
  return `${urlsend}Authent/recoverAcces`
  // return `${urlsend}Authent/recoverAcces`
}
export function resetPasswor() {
  return `${urlsend}Authent/resetPasswor`
  // return `${urlsend}Authent/resetPasswor`
}

// obtener Menus
export function getMenus(tk) {
  //return `${urlsend}Authent/resetPasswor`
  return `${urlsend}Authent/menus?tk=${tk}`;
}
// obtener lista de modulos y submodulos
export function getModulesAndSubModules(tk) {
  return `${urlsend}admin_modules/allModules?token=${tk}`;
}
// obtener lista roles
export function getRoles(tk) {
  return `${urlsend}admin_roles/allRoles?token=${tk}`;
}

export function PostRolesOrder(tk) {
  return `${urlsend}admin_roles/allRolesOrden?token=${tk}`;
}
export function getModulesByUser(tk) {
  return `${urlsend}admin_modules/userModules?token=${tk}`;
}
export function putUserModules(tk) {
  return `${urlsend}admin_modules/userModules?token=${tk}`;
}

//Obtener todas las sucursales
export function getAllBranch(token) {
  return `${urlsend}admin_branches/allBranches?token=${token}`;
}

//Obtener todas las Companies
export function getAllCompanies(token) {
  return `${urlsend}admin_companies/allCompanies?token=${token}`;
}

export function postAllCompaniesRoll(token) {
  return `${urlsend}admin_companies/allCompaniesRol?token=${token}`;
}

//Obtener Informacion de todas las Companies
export function getInfoCompanies(id = 0, token) {
  return `${urlsend}admin_companies/infoCompanies?id=${id}&token=${token}`;
}
//Obtener Informacion de todas las Sucursales
export function getInfoBranches(idCompany, id = 0, token) {
  return `${urlsend}admin_branches/infoBranches?idCompany=${idCompany}&id=${id}&token=${token}`;
}
//Obtener Informacion de todas las Companies
export function getInfoUsers(idCompany, idBranch, id = 0, token) {
  /// ya apunta a la tabla nueva de data_session_users
  return `${urlsend}admin_data_warehouse/getInfoUsers?idBranch=${idBranch}&idCompany=${idCompany}&id=${id}&token=${token}`;
}
//Editar Sucursal
export function putBranch(token) {
  return `${urlsend}admin_branches/putBranch?token=${token}`;
}

//Editar Compania 
export function putCompany(token) {
  return `${urlsend}admin_companies/putCompany?token=${token}`;
}

//Insertar Sucursal 
export function addBranch(token) {
  return `${urlsend}admin_branches/addBranch?token=${token}`;
}

//Insertar Compania 
export function addCompany(token) {
  return `${urlsend}admin_companies/addCompany?token=${token}`;
}

//Obtener sucursales de compania
export function getBranchDataByCompany(token) {
  return `${urlsend}admin_branches/branchDataByCompany?token=${token}`;
}

//Consulta 
export function getBranch(token) {
  return `${urlsend}admin_branches/branchData?token=${token}`;
}

//Consulta compania 
export function getCompany(token) {
  return `${urlsend}admin_companies/companyData?token=${token}`;
}

//Consulta Usuarios por sucursal 
export function getUserBranch(token) {
  return `${urlsend}admin_data_warehouse/userByBranches?token=${token}`;
}

//Consultar Lista de Formularios

export function getForms(token) {
  return `${urlsend}datausers/forms?token=${token}`;
}
//Guardar Nombre Formulario

export function setForms(token) {
  return `${urlsend}datausers/forms?token=${token}`;
}

/// consultar filtros 
export function getgetfiltersbyidtype() {

  //console.log('getfiltersbyidtype ');
  return `${urlsend}tixtix/getfiltersbyidtype`;
}



/// crear nuevo filtro filtros 

export function createnewfilter(tipofiltro, comentariotipe, namefiltro, activofiltro, idevento) {

  //console.log('getfiltersbyidtype ');
  return `${urlsend}tixtix/setnewtypefiltro?tipofiltro=${tipofiltro}&comentariotipe=${comentariotipe}&namefiltro=${namefiltro}&activofiltro=${activofiltro}&idevento=${idevento}`;
}



/// desactivar filtro 
export function desactivivatefilter(idfiltro) {

  //console.log('getfiltersbyidtype ');
  return `${urlsend}tixtix/desactivivatefilter?idfiltro=${idfiltro}`;
}


/// Rate Points

export function allRatePointsCompany() {
  return `${urlsend}crtRatePoints/rdAllratePoints?idcompay`;
}

export function NewRatePoint() {
  return `${urlsend}crtRatePoints/ratePointsCreat`;
}

export function EditRatePoint() {
  return `${urlsend}crtRatePoints/updatePoints`;
}

export function DeleteRatePoint(idratep) {
  return `${urlsend}crtRatePoints/deletratePoint?idratepoint=${idratep}`;
}

export function saveGetprices(precio1, precio2, tax1, flagspoints, newRatePoint, defaultMarkpoints, defaultCompoints) {
  return `${urlbackAngel}happypath/calculadora?precio1=${precio1}&precio2=${precio2}&tax1=${tax1}&flagspoints=${flagspoints}&newRatePoint=${newRatePoint}&defaultMarkpoints=${defaultMarkpoints}&defaultCompoints=${defaultCompoints}`;
}


/////// files csv
export function upFileprocesss() {
  return `${urlsend}crtCsv/process`;
}


export function viewCategoryEvents() {
  return `${urlsend}crtCsv/rdAllinfotable`;
}

export function UpdateCategoryEvents() {
  return `${urlsend}crtCsv/UpdCategory`;
}


//// info peticiones markcorps
export function infoMarkcorps() {
  return `${urlsend}happyPath/getstatusapi`;
}

export function infoMapping() {
  return `${urlsend}crtMapeo/infomapping`;
}

export function executerequestMapping() {
  return `${urlsend}crtMapeo/MapedMarkcorps`;
}

export function listCityStateCountry() {
  return `${urlsend}crtMapeo/listTextSearch`;
}

export function getinfoTableMap(valuepage) {
  return `${urlsend}crtMapeo/infoTableMap?vauepage=${valuepage}`;
}

export function getMappinEvents(valuepage) {
  return `${backTixtest}tixstockservices/geteventos`;
}

export function validAccessLogn() {
  //return `${urlsend}Authent/SignIn`;
  return `${urlsend}Authent/SignIn`;
}



///// Autenticacion ////////////////////////////////////////
export function LoginNewAuth() {
  //return `${urlsend}Authent/SignIn`;
  return `https://auth.nhsa.travel/auth/loginUsers`;
}

export function CreateUser() {
  return `https://auth.nhsa.travel/auth/newuser`;
  // return `${local}datausers/InputsNew?token=${token}`;
}

export function editUser(token) {
  return `https://auth.nhsa.travel/auth/editUser?token=${token}`;
  // return `${local}datausers/InputsNew?token=${token}`;
}

export function InfoUser() {
  return `https://auth.nhsa.travel/auth/infoUserSelec`;
  // return `${local}datausers/InputsNew?token=${token}`;
}


export function UnicInfoUser() {
  return `https://auth.nhsa.travel/auth/infoUserSelec`;
  // return `${local}datausers/InputsNew?token=${token}`;
}

export function PostDeletUser() {
  return `https://auth.nhsa.travel/auth/AuthDeletUser`;
  // return `${local}datausers/InputsNew?token=${token}`;
}

export function PostResetPass(token) {
  return `https://auth.nhsa.travel/auth/AuthresetPsw?token=${token}`;
  // return `${local}datausers/InputsNew?token=${token}`;
}



///// Autenticacion ////////////////////////////////////////


export function validLogn() {
  return `${urlsend}Authent/AuthSession`;
}

export function infoStates(valuecountry) {
  return `${urlsend}crtMapeo/infoStatename?valuecountry=${valuecountry}`;
}

export function infoCityes(valueStateProv) {
  return `${urlsend}crtMapeo/infoCitys?valueStateProv=${valueStateProv}`;
}


export function infoStatestrue(valuecountry) {
  return `${urlsend}crtMapeo/infoStatenamemap?valuecountry=${valuecountry}`;
}

export function infoCityestrue(valueStateProv) {
  return `${urlsend}crtMapeo/infoCitysmap?valueStateProv=${valueStateProv}`;
}


export function postInfoHotelsMaped() {
  return `${urlsend}crtMapeo/mappStateCity`;
}

export function viewInfoValuesTax(valuestage) {
  return `${urlsend}crtValueTax/rdAllValueTax`;
}

export function updateinfoValueTax(valuestage) {
  return `${urlsend}crtValueTax/updateValueTax`;
}


export function FormsTypeusers() {
  console.log(`${urlsend}datausers/listforms`)
  return `${urlsend}datausers/listforms`;
}

export function inputsSelected(idform, token = '') {
  return `${urlsend}datausers/InputsForms?idforms=${idform}&token=${token}`;
  //return `${local}datausers/InputsForms?idforms=${idform}&token=${token}`;
}
export function inputsByIdForm(token) {
  return `${urlsend}datausers/inputsByIdForm?token=${token}`;
  //return `${local}datausers/inputsByIdForm?token=${token}`;
}

export function UpUpdateform(token) {
  return `${urlsend}datausers/addforms?token=${token}`;
  // return `${local}datausers/addforms?token=${token}`;
}

export function SelectFormCompani(token) {
  return `${urlsend}datausers/SelectForms?token=${token}`;
  //return `${local}datausers/SelectForms?token=${token}`;
}

export function ListInputsTypes(token) {
  return `${urlsend}datausers/typesInputs?token=${token}`;
  //return `${local}datausers/typesInputs?token=${token}`;
}


export function CreateNewInput(token) {
  return `${urlsend}datausers/InputsNew?token=${token}`;
  // return `${local}datausers/InputsNew?token=${token}`;
}

export function UsersbyCompany(token) {
  return `${urlsend}sessionPermiss/UsersbyCompany?token=${token}`;
  // return `${local}datausers/InputsNew?token=${token}`;
}
// obtiene lista de formularios por empresa
export function getFormsByCompany(token) {
  return `${urlsend}datausers/formsbyCompany?token=${token}`;

}
// obtiene el detalle del los elementos del formulario pod Id del formulario
export function getfFormDataById(token) {
  return `${urlsend}datausers/inputsbyForms?token=${token}`;

}
// obtiene el detalle del usuario por token
export function getInfoUserByToken(token) {
  return `${urlsend}datausers/dataUsers?token=${token}`;

}
// actualiza la informacion de un campo del formulario
export function updateInfoInputForm(token) {
  return `${urlsend}datausers/updateInputs?token=${token}`;

}
export function getUserByRole(token) {
  return `${urlsend}admin_data_warehouse/UsersbyCompSucRol?token=${token}`;

}
export function getPermissionByUser(token) {
  return `${urlsend}admin_data_warehouse/listModulSubModul?token=${token}`;

}

export function getPermissionByUserNewEndpoint(token) {
  return `${urlsend}admin_modules/InfoPermisosUsers?token=${token}`;

}

export function putModuleacces(token) {
  return `${urlsend}admin_modules/UpdUserModules?token=${token}`;

}
export function putPermissionUser(token) {
  return `${urlsend}admin_modules/PermissionUsers?token=${token}`;

}

export function ListContryCitys(token) {
  return `${urlsend}crtMapeo/listInfoContryandCity`;

}

export function PostupHotelView() {
  return `${urlsend}crtMapeo/upHotelView`;

}


export function PostHotelsSearch() {
  return `${urlsend}crtMapeo/searchHotelsCombos`;

}


export function PostCreateCombosHotel(token) {
  return `${urlsend}crtMapeo/createHotelCombo?token=${token}`;

}

export function PostCombosRol(token) {
  return `${urlsend}crtMapeo/combosRol?token=${token}`;

}

