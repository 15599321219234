import React, { useState, useEffect } from 'react';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Highlight from 'react-highlight';
import { Icon } from '@iconify/react';

function UIIconDuotone() {
	const [code1, setCode1] = useState();
	
	useEffect(() => {
		fetch('/assets/data/ui-icon-duotone/code-1.json').then(function(response) { return response.text(); }).then((html) => { setCode1(html); });
	});
	
	return (
		<div>
			<ol className="breadcrumb float-xl-end">
				<li className="breadcrumb-item"><a href="#/">Home</a></li>
				<li className="breadcrumb-item"><a href="#/">UI Elements</a></li>
				<li className="breadcrumb-item active">Duotone Icons</li>
			</ol>
			<h1 className="page-header">Duotone Icons <small>1200+ duotone icons from Iconify Solr</small></h1>
			
			<Panel>
				<PanelHeader>Installation</PanelHeader>
				<PanelBody className="panel-body">
					<PerfectScrollbar className="h-500px" options={{suppressScrollX: true}}>
						<div className="row gx-3" id="icon">
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:4k-bold-duotone" /><span>solar:4k-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:accessibility-bold-duotone" /><span>solar:accessibility-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:accumulator-bold-duotone" /><span>solar:accumulator-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:add-circle-bold-duotone" /><span>solar:add-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:add-folder-bold-duotone" /><span>solar:add-folder-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:add-square-bold-duotone" /><span>solar:add-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:adhesive-plaster-2-bold-duotone" /><span>solar:adhesive-plaster-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:adhesive-plaster-bold-duotone" /><span>solar:adhesive-plaster-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:airbuds-bold-duotone" /><span>solar:airbuds-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:airbuds-case-bold-duotone" /><span>solar:airbuds-case-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:airbuds-case-charge-bold-duotone" /><span>solar:airbuds-case-charge-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:airbuds-case-minimalistic-bold-duotone" /><span>solar:airbuds-case-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:airbuds-case-open-bold-duotone" /><span>solar:airbuds-case-open-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:airbuds-charge-bold-duotone" /><span>solar:airbuds-charge-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:airbuds-check-bold-duotone" /><span>solar:airbuds-check-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:airbuds-left-bold-duotone" /><span>solar:airbuds-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:airbuds-remove-bold-duotone" /><span>solar:airbuds-remove-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:airbuds-right-bold-duotone" /><span>solar:airbuds-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:alarm-add-bold-duotone" /><span>solar:alarm-add-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:alarm-bold-duotone" /><span>solar:alarm-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:alarm-pause-bold-duotone" /><span>solar:alarm-pause-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:alarm-play-bold-duotone" /><span>solar:alarm-play-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:alarm-remove-bold-duotone" /><span>solar:alarm-remove-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:alarm-sleep-bold-duotone" /><span>solar:alarm-sleep-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:alarm-turn-off-bold-duotone" /><span>solar:alarm-turn-off-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:album-bold-duotone" /><span>solar:album-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:align-bottom-bold-duotone" /><span>solar:align-bottom-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:align-horizonta-spacing-bold-duotone" /><span>solar:align-horizonta-spacing-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:align-horizontal-center-bold-duotone" /><span>solar:align-horizontal-center-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:align-left-bold-duotone" /><span>solar:align-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:align-right-bold-duotone" /><span>solar:align-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:align-top-bold-duotone" /><span>solar:align-top-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:align-vertical-center-bold-duotone" /><span>solar:align-vertical-center-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:align-vertical-spacing-bold-duotone" /><span>solar:align-vertical-spacing-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:alt-arrow-down-bold-duotone" /><span>solar:alt-arrow-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:alt-arrow-left-bold-duotone" /><span>solar:alt-arrow-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:alt-arrow-right-bold-duotone" /><span>solar:alt-arrow-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:alt-arrow-up-bold-duotone" /><span>solar:alt-arrow-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:archive-bold-duotone" /><span>solar:archive-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:archive-check-bold-duotone" /><span>solar:archive-check-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:archive-down-bold-duotone" /><span>solar:archive-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:archive-down-minimlistic-bold-duotone" /><span>solar:archive-down-minimlistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:archive-minimalistic-bold-duotone" /><span>solar:archive-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:archive-up-bold-duotone" /><span>solar:archive-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:archive-up-minimlistic-bold-duotone" /><span>solar:archive-up-minimlistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:armchair-2-bold-duotone" /><span>solar:armchair-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:armchair-bold-duotone" /><span>solar:armchair-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:arrow-down-bold-duotone" /><span>solar:arrow-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:arrow-left-bold-duotone" /><span>solar:arrow-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:arrow-left-down-bold-duotone" /><span>solar:arrow-left-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:arrow-left-up-bold-duotone" /><span>solar:arrow-left-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:arrow-right-bold-duotone" /><span>solar:arrow-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:arrow-right-down-bold-duotone" /><span>solar:arrow-right-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:arrow-right-up-bold-duotone" /><span>solar:arrow-right-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:arrow-to-down-left-bold-duotone" /><span>solar:arrow-to-down-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:arrow-to-down-right-bold-duotone" /><span>solar:arrow-to-down-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:arrow-to-top-left-bold-duotone" /><span>solar:arrow-to-top-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:arrow-to-top-right-bold-duotone" /><span>solar:arrow-to-top-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:arrow-up-bold-duotone" /><span>solar:arrow-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:asteroid-bold-duotone" /><span>solar:asteroid-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:atom-bold-duotone" /><span>solar:atom-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:augmented-reality-bold-duotone" /><span>solar:augmented-reality-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:backpack-bold-duotone" /><span>solar:backpack-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:backspace-bold-duotone" /><span>solar:backspace-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bacteria-bold-duotone" /><span>solar:bacteria-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bag-2-bold-duotone" /><span>solar:bag-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bag-3-bold-duotone" /><span>solar:bag-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bag-4-bold-duotone" /><span>solar:bag-4-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bag-5-bold-duotone" /><span>solar:bag-5-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bag-bold-duotone" /><span>solar:bag-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bag-check-bold-duotone" /><span>solar:bag-check-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bag-cross-bold-duotone" /><span>solar:bag-cross-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bag-heart-bold-duotone" /><span>solar:bag-heart-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bag-music-2-bold-duotone" /><span>solar:bag-music-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bag-music-bold-duotone" /><span>solar:bag-music-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bag-smile-bold-duotone" /><span>solar:bag-smile-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:balloon-bold-duotone" /><span>solar:balloon-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:balls-bold-duotone" /><span>solar:balls-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:banknote-2-bold-duotone" /><span>solar:banknote-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:banknote-bold-duotone" /><span>solar:banknote-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bar-chair-bold-duotone" /><span>solar:bar-chair-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:basketball-bold-duotone" /><span>solar:basketball-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bath-bold-duotone" /><span>solar:bath-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:battery-charge-bold-duotone" /><span>solar:battery-charge-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:battery-charge-minimalistic-bold-duotone" /><span>solar:battery-charge-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:battery-full-bold-duotone" /><span>solar:battery-full-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:battery-full-minimalistic-bold-duotone" /><span>solar:battery-full-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:battery-half-bold-duotone" /><span>solar:battery-half-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:battery-half-minimalistic-bold-duotone" /><span>solar:battery-half-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:battery-low-bold-duotone" /><span>solar:battery-low-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:battery-low-minimalistic-bold-duotone" /><span>solar:battery-low-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bed-bold-duotone" /><span>solar:bed-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bedside-table-2-bold-duotone" /><span>solar:bedside-table-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bedside-table-3-bold-duotone" /><span>solar:bedside-table-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bedside-table-4-bold-duotone" /><span>solar:bedside-table-4-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bedside-table-bold-duotone" /><span>solar:bedside-table-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bell-bing-bold-duotone" /><span>solar:bell-bing-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bell-bold-duotone" /><span>solar:bell-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bell-off-bold-duotone" /><span>solar:bell-off-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:benzene-ring-bold-duotone" /><span>solar:benzene-ring-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bicycling-bold-duotone" /><span>solar:bicycling-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bicycling-round-bold-duotone" /><span>solar:bicycling-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bill-bold-duotone" /><span>solar:bill-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bill-check-bold-duotone" /><span>solar:bill-check-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bill-cross-bold-duotone" /><span>solar:bill-cross-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bill-list-bold-duotone" /><span>solar:bill-list-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:black-hole-2-bold-duotone" /><span>solar:black-hole-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:black-hole-3-bold-duotone" /><span>solar:black-hole-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:black-hole-bold-duotone" /><span>solar:black-hole-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bluetooth-bold-duotone" /><span>solar:bluetooth-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bluetooth-circle-bold-duotone" /><span>solar:bluetooth-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bluetooth-square-bold-duotone" /><span>solar:bluetooth-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bluetooth-wave-bold-duotone" /><span>solar:bluetooth-wave-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:body-bold-duotone" /><span>solar:body-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:body-shape-bold-duotone" /><span>solar:body-shape-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:body-shape-minimalistic-bold-duotone" /><span>solar:body-shape-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bolt-bold-duotone" /><span>solar:bolt-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bolt-circle-bold-duotone" /><span>solar:bolt-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bomb-bold-duotone" /><span>solar:bomb-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bomb-emoji-bold-duotone" /><span>solar:bomb-emoji-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bomb-minimalistic-bold-duotone" /><span>solar:bomb-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bone-bold-duotone" /><span>solar:bone-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bone-broken-bold-duotone" /><span>solar:bone-broken-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bone-crack-bold-duotone" /><span>solar:bone-crack-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bones-bold-duotone" /><span>solar:bones-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bonfire-bold-duotone" /><span>solar:bonfire-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:book-2-bold-duotone" /><span>solar:book-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:book-bold-duotone" /><span>solar:book-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:book-bookmark-bold-duotone" /><span>solar:book-bookmark-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:book-bookmark-minimalistic-bold-duotone" /><span>solar:book-bookmark-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:book-minimalistic-bold-duotone" /><span>solar:book-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bookmark-bold-duotone" /><span>solar:bookmark-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bookmark-circle-bold-duotone" /><span>solar:bookmark-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bookmark-opened-bold-duotone" /><span>solar:bookmark-opened-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bookmark-square-bold-duotone" /><span>solar:bookmark-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bookmark-square-minimalistic-bold-duotone" /><span>solar:bookmark-square-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:boombox-bold-duotone" /><span>solar:boombox-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bottle-bold-duotone" /><span>solar:bottle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bowling-bold-duotone" /><span>solar:bowling-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:box-bold-duotone" /><span>solar:box-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:box-minimalistic-bold-duotone" /><span>solar:box-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:branching-paths-down-bold-duotone" /><span>solar:branching-paths-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:branching-paths-up-bold-duotone" /><span>solar:branching-paths-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:broom-bold-duotone" /><span>solar:broom-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bug-bold-duotone" /><span>solar:bug-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bug-minimalistic-bold-duotone" /><span>solar:bug-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:buildings-2-bold-duotone" /><span>solar:buildings-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:buildings-3-bold-duotone" /><span>solar:buildings-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:buildings-bold-duotone" /><span>solar:buildings-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:bus-bold-duotone" /><span>solar:bus-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:calculator-bold-duotone" /><span>solar:calculator-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:calculator-minimalistic-bold-duotone" /><span>solar:calculator-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:calendar-add-bold-duotone" /><span>solar:calendar-add-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:calendar-bold-duotone" /><span>solar:calendar-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:calendar-date-bold-duotone" /><span>solar:calendar-date-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:calendar-mark-bold-duotone" /><span>solar:calendar-mark-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:calendar-minimalistic-bold-duotone" /><span>solar:calendar-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:calendar-search-bold-duotone" /><span>solar:calendar-search-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:call-cancel-bold-duotone" /><span>solar:call-cancel-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:call-cancel-rounded-bold-duotone" /><span>solar:call-cancel-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:call-chat-bold-duotone" /><span>solar:call-chat-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:call-chat-rounded-bold-duotone" /><span>solar:call-chat-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:call-dropped-bold-duotone" /><span>solar:call-dropped-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:call-dropped-rounded-bold-duotone" /><span>solar:call-dropped-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:call-medicine-bold-duotone" /><span>solar:call-medicine-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:call-medicine-rounded-bold-duotone" /><span>solar:call-medicine-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:camera-add-bold-duotone" /><span>solar:camera-add-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:camera-bold-duotone" /><span>solar:camera-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:camera-minimalistic-bold-duotone" /><span>solar:camera-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:camera-rotate-bold-duotone" /><span>solar:camera-rotate-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:camera-square-bold-duotone" /><span>solar:camera-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:card-2-bold-duotone" /><span>solar:card-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:card-bold-duotone" /><span>solar:card-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:card-recive-bold-duotone" /><span>solar:card-recive-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:card-search-bold-duotone" /><span>solar:card-search-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:card-send-bold-duotone" /><span>solar:card-send-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:card-transfer-bold-duotone" /><span>solar:card-transfer-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cardholder-bold-duotone" /><span>solar:cardholder-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cart-2-bold-duotone" /><span>solar:cart-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cart-3-bold-duotone" /><span>solar:cart-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cart-4-bold-duotone" /><span>solar:cart-4-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cart-5-bold-duotone" /><span>solar:cart-5-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cart-bold-duotone" /><span>solar:cart-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cart-check-bold-duotone" /><span>solar:cart-check-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cart-cross-bold-duotone" /><span>solar:cart-cross-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cart-large-2-bold-duotone" /><span>solar:cart-large-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cart-large-3-bold-duotone" /><span>solar:cart-large-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cart-large-4-bold-duotone" /><span>solar:cart-large-4-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cart-large-bold-duotone" /><span>solar:cart-large-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cart-large-minimalistic-bold-duotone" /><span>solar:cart-large-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cart-plus-bold-duotone" /><span>solar:cart-plus-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:case-bold-duotone" /><span>solar:case-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:case-minimalistic-bold-duotone" /><span>solar:case-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:case-round-bold-duotone" /><span>solar:case-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:case-round-minimalistic-bold-duotone" /><span>solar:case-round-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cash-out-bold-duotone" /><span>solar:cash-out-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cassette-2-bold-duotone" /><span>solar:cassette-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cassette-bold-duotone" /><span>solar:cassette-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cat-bold-duotone" /><span>solar:cat-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chair-2-bold-duotone" /><span>solar:chair-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chair-bold-duotone" /><span>solar:chair-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chandelier-bold-duotone" /><span>solar:chandelier-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chart-2-bold-duotone" /><span>solar:chart-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chart-bold-duotone" /><span>solar:chart-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chart-square-bold-duotone" /><span>solar:chart-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-dots-bold-duotone" /><span>solar:chat-dots-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-line-bold-duotone" /><span>solar:chat-line-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-round-bold-duotone" /><span>solar:chat-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-round-call-bold-duotone" /><span>solar:chat-round-call-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-round-check-bold-duotone" /><span>solar:chat-round-check-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-round-dots-bold-duotone" /><span>solar:chat-round-dots-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-round-like-bold-duotone" /><span>solar:chat-round-like-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-round-line-bold-duotone" /><span>solar:chat-round-line-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-round-money-bold-duotone" /><span>solar:chat-round-money-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-round-unread-bold-duotone" /><span>solar:chat-round-unread-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-round-video-bold-duotone" /><span>solar:chat-round-video-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-square-2-bold-duotone" /><span>solar:chat-square-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-square-arrow-bold-duotone" /><span>solar:chat-square-arrow-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-square-bold-duotone" /><span>solar:chat-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-square-call-bold-duotone" /><span>solar:chat-square-call-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-square-check-bold-duotone" /><span>solar:chat-square-check-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-square-code-bold-duotone" /><span>solar:chat-square-code-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-square-like-bold-duotone" /><span>solar:chat-square-like-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chat-unread-bold-duotone" /><span>solar:chat-unread-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:check-circle-bold-duotone" /><span>solar:check-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:check-read-bold-duotone" /><span>solar:check-read-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:check-square-bold-duotone" /><span>solar:check-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:checklist-bold-duotone" /><span>solar:checklist-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:checklist-minimalistic-bold-duotone" /><span>solar:checklist-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chef-hat-bold-duotone" /><span>solar:chef-hat-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chef-hat-heart-bold-duotone" /><span>solar:chef-hat-heart-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:chef-hat-minimalistic-bold-duotone" /><span>solar:chef-hat-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:circle-bottom-down-bold-duotone" /><span>solar:circle-bottom-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:circle-bottom-up-bold-duotone" /><span>solar:circle-bottom-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:circle-top-down-bold-duotone" /><span>solar:circle-top-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:circle-top-up-bold-duotone" /><span>solar:circle-top-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:city-bold-duotone" /><span>solar:city-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:clapperboard-bold-duotone" /><span>solar:clapperboard-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:clapperboard-edit-bold-duotone" /><span>solar:clapperboard-edit-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:clapperboard-open-bold-duotone" /><span>solar:clapperboard-open-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:clapperboard-open-play-bold-duotone" /><span>solar:clapperboard-open-play-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:clapperboard-play-bold-duotone" /><span>solar:clapperboard-play-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:clapperboard-text-bold-duotone" /><span>solar:clapperboard-text-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:clipboard-add-bold-duotone" /><span>solar:clipboard-add-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:clipboard-bold-duotone" /><span>solar:clipboard-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:clipboard-check-bold-duotone" /><span>solar:clipboard-check-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:clipboard-heart-bold-duotone" /><span>solar:clipboard-heart-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:clipboard-list-bold-duotone" /><span>solar:clipboard-list-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:clipboard-remove-bold-duotone" /><span>solar:clipboard-remove-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:clipboard-text-bold-duotone" /><span>solar:clipboard-text-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:clock-circle-bold-duotone" /><span>solar:clock-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:clock-square-bold-duotone" /><span>solar:clock-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:close-circle-bold-duotone" /><span>solar:close-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:close-square-bold-duotone" /><span>solar:close-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:closet-2-bold-duotone" /><span>solar:closet-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:closet-bold-duotone" /><span>solar:closet-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-bold-duotone" /><span>solar:cloud-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-bolt-bold-duotone" /><span>solar:cloud-bolt-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-bolt-minimalistic-bold-duotone" /><span>solar:cloud-bolt-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-check-bold-duotone" /><span>solar:cloud-check-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-download-bold-duotone" /><span>solar:cloud-download-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-file-bold-duotone" /><span>solar:cloud-file-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-minus-bold-duotone" /><span>solar:cloud-minus-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-plus-bold-duotone" /><span>solar:cloud-plus-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-rain-bold-duotone" /><span>solar:cloud-rain-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-snowfall-bold-duotone" /><span>solar:cloud-snowfall-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-snowfall-minimalistic-bold-duotone" /><span>solar:cloud-snowfall-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-storage-bold-duotone" /><span>solar:cloud-storage-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-storm-bold-duotone" /><span>solar:cloud-storm-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-sun-2-bold-duotone" /><span>solar:cloud-sun-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-sun-bold-duotone" /><span>solar:cloud-sun-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-upload-bold-duotone" /><span>solar:cloud-upload-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-waterdrop-bold-duotone" /><span>solar:cloud-waterdrop-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloud-waterdrops-bold-duotone" /><span>solar:cloud-waterdrops-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:clouds-bold-duotone" /><span>solar:clouds-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cloudy-moon-bold-duotone" /><span>solar:cloudy-moon-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:clound-cross-bold-duotone" /><span>solar:clound-cross-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:code-2-bold-duotone" /><span>solar:code-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:code-bold-duotone" /><span>solar:code-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:code-circle-bold-duotone" /><span>solar:code-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:code-file-bold-duotone" /><span>solar:code-file-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:code-scan-bold-duotone" /><span>solar:code-scan-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:code-square-bold-duotone" /><span>solar:code-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:colour-tuneing-bold-duotone" /><span>solar:colour-tuneing-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:command-bold-duotone" /><span>solar:command-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:compass-big-bold-duotone" /><span>solar:compass-big-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:compass-bold-duotone" /><span>solar:compass-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:compass-square-bold-duotone" /><span>solar:compass-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:condicioner-2-bold-duotone" /><span>solar:condicioner-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:condicioner-bold-duotone" /><span>solar:condicioner-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:confetti-bold-duotone" /><span>solar:confetti-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:confetti-minimalistic-bold-duotone" /><span>solar:confetti-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:confounded-circle-bold-duotone" /><span>solar:confounded-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:confounded-square-bold-duotone" /><span>solar:confounded-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:copy-bold-duotone" /><span>solar:copy-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:copyright-bold-duotone" /><span>solar:copyright-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:corkscrew-bold-duotone" /><span>solar:corkscrew-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cosmetic-bold-duotone" /><span>solar:cosmetic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:course-down-bold-duotone" /><span>solar:course-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:course-up-bold-duotone" /><span>solar:course-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cpu-bold-duotone" /><span>solar:cpu-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cpu-bolt-bold-duotone" /><span>solar:cpu-bolt-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:creative-commons-bold-duotone" /><span>solar:creative-commons-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:crop-bold-duotone" /><span>solar:crop-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:crop-minimalistic-bold-duotone" /><span>solar:crop-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:crown-bold-duotone" /><span>solar:crown-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:crown-line-bold-duotone" /><span>solar:crown-line-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:crown-minimalistic-bold-duotone" /><span>solar:crown-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:crown-star-bold-duotone" /><span>solar:crown-star-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cup-bold-duotone" /><span>solar:cup-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cup-first-bold-duotone" /><span>solar:cup-first-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cup-hot-bold-duotone" /><span>solar:cup-hot-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cup-music-bold-duotone" /><span>solar:cup-music-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cup-paper-bold-duotone" /><span>solar:cup-paper-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cup-star-bold-duotone" /><span>solar:cup-star-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cursor-bold-duotone" /><span>solar:cursor-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:cursor-square-bold-duotone" /><span>solar:cursor-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:danger-bold-duotone" /><span>solar:danger-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:danger-circle-bold-duotone" /><span>solar:danger-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:danger-square-bold-duotone" /><span>solar:danger-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:danger-triangle-bold-duotone" /><span>solar:danger-triangle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:database-bold-duotone" /><span>solar:database-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:delivery-bold-duotone" /><span>solar:delivery-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:devices-bold-duotone" /><span>solar:devices-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:diagram-down-bold-duotone" /><span>solar:diagram-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:diagram-up-bold-duotone" /><span>solar:diagram-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:dialog-2-bold-duotone" /><span>solar:dialog-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:dialog-bold-duotone" /><span>solar:dialog-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:diploma-bold-duotone" /><span>solar:diploma-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:diploma-verified-bold-duotone" /><span>solar:diploma-verified-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:diskette-bold-duotone" /><span>solar:diskette-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:dislike-bold-duotone" /><span>solar:dislike-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:display-bold-duotone" /><span>solar:display-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:dna-bold-duotone" /><span>solar:dna-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:document-add-bold-duotone" /><span>solar:document-add-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:document-bold-duotone" /><span>solar:document-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:document-medicine-bold-duotone" /><span>solar:document-medicine-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:document-text-bold-duotone" /><span>solar:document-text-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:documents-bold-duotone" /><span>solar:documents-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:documents-minimalistic-bold-duotone" /><span>solar:documents-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:dollar-bold-duotone" /><span>solar:dollar-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:dollar-minimalistic-bold-duotone" /><span>solar:dollar-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:donut-bitten-bold-duotone" /><span>solar:donut-bitten-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:donut-bold-duotone" /><span>solar:donut-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:double-alt-arrow-down-bold-duotone" /><span>solar:double-alt-arrow-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:double-alt-arrow-left-bold-duotone" /><span>solar:double-alt-arrow-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:double-alt-arrow-right-bold-duotone" /><span>solar:double-alt-arrow-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:double-alt-arrow-up-bold-duotone" /><span>solar:double-alt-arrow-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:download-bold-duotone" /><span>solar:download-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:download-minimalistic-bold-duotone" /><span>solar:download-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:download-square-bold-duotone" /><span>solar:download-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:download-twice-square-bold-duotone" /><span>solar:download-twice-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:dropper-2-bold-duotone" /><span>solar:dropper-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:dropper-3-bold-duotone" /><span>solar:dropper-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:dropper-bold-duotone" /><span>solar:dropper-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:dropper-minimalistic-2-bold-duotone" /><span>solar:dropper-minimalistic-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:dropper-minimalistic-bold-duotone" /><span>solar:dropper-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:dumbbell-bold-duotone" /><span>solar:dumbbell-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:dumbbell-large-bold-duotone" /><span>solar:dumbbell-large-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:dumbbell-large-minimalistic-bold-duotone" /><span>solar:dumbbell-large-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:dumbbell-small-bold-duotone" /><span>solar:dumbbell-small-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:dumbbells-2-bold-duotone" /><span>solar:dumbbells-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:dumbbells-bold-duotone" /><span>solar:dumbbells-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:earth-bold-duotone" /><span>solar:earth-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:electric-refueling-bold-duotone" /><span>solar:electric-refueling-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:emoji-funny-circle-bold-duotone" /><span>solar:emoji-funny-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:emoji-funny-square-bold-duotone" /><span>solar:emoji-funny-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:end-call-bold-duotone" /><span>solar:end-call-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:end-call-rounded-bold-duotone" /><span>solar:end-call-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:eraser-bold-duotone" /><span>solar:eraser-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:eraser-circle-bold-duotone" /><span>solar:eraser-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:eraser-square-bold-duotone" /><span>solar:eraser-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:euro-bold-duotone" /><span>solar:euro-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:exit-bold-duotone" /><span>solar:exit-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:explicit-bold-duotone" /><span>solar:explicit-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:export-bold-duotone" /><span>solar:export-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:expressionless-circle-bold-duotone" /><span>solar:expressionless-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:expressionless-square-bold-duotone" /><span>solar:expressionless-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:eye-bold-duotone" /><span>solar:eye-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:eye-closed-bold-duotone" /><span>solar:eye-closed-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:eye-scan-bold-duotone" /><span>solar:eye-scan-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:face-scan-circle-bold-duotone" /><span>solar:face-scan-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:face-scan-square-bold-duotone" /><span>solar:face-scan-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:facemask-circle-bold-duotone" /><span>solar:facemask-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:facemask-square-bold-duotone" /><span>solar:facemask-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:feed-bold-duotone" /><span>solar:feed-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ferris-wheel-bold-duotone" /><span>solar:ferris-wheel-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:figma-bold-duotone" /><span>solar:figma-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:figma-file-bold-duotone" /><span>solar:figma-file-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:file-bold-duotone" /><span>solar:file-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:file-check-bold-duotone" /><span>solar:file-check-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:file-corrupted-bold-duotone" /><span>solar:file-corrupted-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:file-download-bold-duotone" /><span>solar:file-download-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:file-favourite-bold-duotone" /><span>solar:file-favourite-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:file-left-bold-duotone" /><span>solar:file-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:file-remove-bold-duotone" /><span>solar:file-remove-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:file-right-bold-duotone" /><span>solar:file-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:file-send-bold-duotone" /><span>solar:file-send-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:file-smile-bold-duotone" /><span>solar:file-smile-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:file-text-bold-duotone" /><span>solar:file-text-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:filter-bold-duotone" /><span>solar:filter-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:filters-bold-duotone" /><span>solar:filters-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:fire-bold-duotone" /><span>solar:fire-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:fire-minimalistic-bold-duotone" /><span>solar:fire-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:fire-square-bold-duotone" /><span>solar:fire-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:flag-2-bold-duotone" /><span>solar:flag-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:flag-bold-duotone" /><span>solar:flag-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:flame-bold-duotone" /><span>solar:flame-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:flash-drive-bold-duotone" /><span>solar:flash-drive-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:flashlight-bold-duotone" /><span>solar:flashlight-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:flashlight-on-bold-duotone" /><span>solar:flashlight-on-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:flip-horizontal-bold-duotone" /><span>solar:flip-horizontal-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:flip-vertical-bold-duotone" /><span>solar:flip-vertical-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:floor-lamp-bold-duotone" /><span>solar:floor-lamp-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:floor-lamp-minimalistic-bold-duotone" /><span>solar:floor-lamp-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:fog-bold-duotone" /><span>solar:fog-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:folder-2-bold-duotone" /><span>solar:folder-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:folder-bold-duotone" /><span>solar:folder-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:folder-check-bold-duotone" /><span>solar:folder-check-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:folder-cloud-bold-duotone" /><span>solar:folder-cloud-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:folder-error-bold-duotone" /><span>solar:folder-error-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:folder-favourite-bookmark-bold-duotone" /><span>solar:folder-favourite-bookmark-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:folder-favourite-star-bold-duotone" /><span>solar:folder-favourite-star-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:folder-open-bold-duotone" /><span>solar:folder-open-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:folder-path-connect-bold-duotone" /><span>solar:folder-path-connect-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:folder-security-bold-duotone" /><span>solar:folder-security-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:folder-with-files-bold-duotone" /><span>solar:folder-with-files-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:football-bold-duotone" /><span>solar:football-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:forbidden-bold-duotone" /><span>solar:forbidden-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:forbidden-circle-bold-duotone" /><span>solar:forbidden-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:forward-2-bold-duotone" /><span>solar:forward-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:forward-bold-duotone" /><span>solar:forward-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:fridge-bold-duotone" /><span>solar:fridge-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:fuel-bold-duotone" /><span>solar:fuel-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:full-screen-bold-duotone" /><span>solar:full-screen-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:full-screen-circle-bold-duotone" /><span>solar:full-screen-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:full-screen-square-bold-duotone" /><span>solar:full-screen-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gallery-add-bold-duotone" /><span>solar:gallery-add-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gallery-bold-duotone" /><span>solar:gallery-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gallery-check-bold-duotone" /><span>solar:gallery-check-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gallery-circle-bold-duotone" /><span>solar:gallery-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gallery-download-bold-duotone" /><span>solar:gallery-download-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gallery-edit-bold-duotone" /><span>solar:gallery-edit-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gallery-favourite-bold-duotone" /><span>solar:gallery-favourite-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gallery-minimalistic-bold-duotone" /><span>solar:gallery-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gallery-remove-bold-duotone" /><span>solar:gallery-remove-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gallery-round-bold-duotone" /><span>solar:gallery-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gallery-send-bold-duotone" /><span>solar:gallery-send-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gallery-wide-bold-duotone" /><span>solar:gallery-wide-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gameboy-bold-duotone" /><span>solar:gameboy-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gamepad-bold-duotone" /><span>solar:gamepad-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gamepad-charge-bold-duotone" /><span>solar:gamepad-charge-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gamepad-minimalistic-bold-duotone" /><span>solar:gamepad-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gamepad-no-charge-bold-duotone" /><span>solar:gamepad-no-charge-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gamepad-old-bold-duotone" /><span>solar:gamepad-old-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:garage-bold-duotone" /><span>solar:garage-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gas-station-bold-duotone" /><span>solar:gas-station-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ghost-bold-duotone" /><span>solar:ghost-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ghost-smile-bold-duotone" /><span>solar:ghost-smile-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gift-bold-duotone" /><span>solar:gift-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:glasses-bold-duotone" /><span>solar:glasses-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:global-bold-duotone" /><span>solar:global-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:globus-bold-duotone" /><span>solar:globus-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:golf-bold-duotone" /><span>solar:golf-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:gps-bold-duotone" /><span>solar:gps-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:graph-bold-duotone" /><span>solar:graph-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:graph-down-bold-duotone" /><span>solar:graph-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:graph-down-new-bold-duotone" /><span>solar:graph-down-new-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:graph-new-bold-duotone" /><span>solar:graph-new-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:graph-new-up-bold-duotone" /><span>solar:graph-new-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:graph-up-bold-duotone" /><span>solar:graph-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hamburger-menu-bold-duotone" /><span>solar:hamburger-menu-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hand-heart-bold-duotone" /><span>solar:hand-heart-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hand-money-bold-duotone" /><span>solar:hand-money-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hand-pills-bold-duotone" /><span>solar:hand-pills-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hand-shake-bold-duotone" /><span>solar:hand-shake-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hand-stars-bold-duotone" /><span>solar:hand-stars-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hanger-2-bold-duotone" /><span>solar:hanger-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hanger-bold-duotone" /><span>solar:hanger-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hashtag-bold-duotone" /><span>solar:hashtag-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hashtag-chat-bold-duotone" /><span>solar:hashtag-chat-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hashtag-circle-bold-duotone" /><span>solar:hashtag-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hashtag-square-bold-duotone" /><span>solar:hashtag-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:headphones-round-bold-duotone" /><span>solar:headphones-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:headphones-round-sound-bold-duotone" /><span>solar:headphones-round-sound-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:headphones-square-bold-duotone" /><span>solar:headphones-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:headphones-square-sound-bold-duotone" /><span>solar:headphones-square-sound-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:health-bold-duotone" /><span>solar:health-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:heart-angle-bold-duotone" /><span>solar:heart-angle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:heart-bold-duotone" /><span>solar:heart-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:heart-broken-bold-duotone" /><span>solar:heart-broken-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:heart-lock-bold-duotone" /><span>solar:heart-lock-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:heart-pulse-2-bold-duotone" /><span>solar:heart-pulse-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:heart-pulse-bold-duotone" /><span>solar:heart-pulse-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:heart-shine-bold-duotone" /><span>solar:heart-shine-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:heart-unlock-bold-duotone" /><span>solar:heart-unlock-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hearts-bold-duotone" /><span>solar:hearts-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:help-bold-duotone" /><span>solar:help-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:high-definition-bold-duotone" /><span>solar:high-definition-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:high-quality-bold-duotone" /><span>solar:high-quality-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hiking-bold-duotone" /><span>solar:hiking-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hiking-minimalistic-bold-duotone" /><span>solar:hiking-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hiking-round-bold-duotone" /><span>solar:hiking-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:history-2-bold-duotone" /><span>solar:history-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:history-3-bold-duotone" /><span>solar:history-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:history-bold-duotone" /><span>solar:history-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:home-2-bold-duotone" /><span>solar:home-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:home-add-angle-bold-duotone" /><span>solar:home-add-angle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:home-add-bold-duotone" /><span>solar:home-add-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:home-angle-2-bold-duotone" /><span>solar:home-angle-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:home-angle-bold-duotone" /><span>solar:home-angle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:home-bold-duotone" /><span>solar:home-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:home-smile-angle-bold-duotone" /><span>solar:home-smile-angle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:home-smile-bold-duotone" /><span>solar:home-smile-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:home-wifi-angle-bold-duotone" /><span>solar:home-wifi-angle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:home-wifi-bold-duotone" /><span>solar:home-wifi-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hospital-bold-duotone" /><span>solar:hospital-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hourglass-bold-duotone" /><span>solar:hourglass-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:hourglass-line-bold-duotone" /><span>solar:hourglass-line-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:import-bold-duotone" /><span>solar:import-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:inbox-archive-bold-duotone" /><span>solar:inbox-archive-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:inbox-bold-duotone" /><span>solar:inbox-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:inbox-in-bold-duotone" /><span>solar:inbox-in-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:inbox-line-bold-duotone" /><span>solar:inbox-line-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:inbox-out-bold-duotone" /><span>solar:inbox-out-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:inbox-unread-bold-duotone" /><span>solar:inbox-unread-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:incognito-bold-duotone" /><span>solar:incognito-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:incoming-call-bold-duotone" /><span>solar:incoming-call-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:incoming-call-rounded-bold-duotone" /><span>solar:incoming-call-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:infinity-bold-duotone" /><span>solar:infinity-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:info-circle-bold-duotone" /><span>solar:info-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:info-square-bold-duotone" /><span>solar:info-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:iphone-bold-duotone" /><span>solar:iphone-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:jar-of-pills-2-bold-duotone" /><span>solar:jar-of-pills-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:jar-of-pills-bold-duotone" /><span>solar:jar-of-pills-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:key-bold-duotone" /><span>solar:key-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:key-minimalistic-2-bold-duotone" /><span>solar:key-minimalistic-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:key-minimalistic-bold-duotone" /><span>solar:key-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:key-minimalistic-square-2-bold-duotone" /><span>solar:key-minimalistic-square-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:key-minimalistic-square-3-bold-duotone" /><span>solar:key-minimalistic-square-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:key-minimalistic-square-bold-duotone" /><span>solar:key-minimalistic-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:key-square-2-bold-duotone" /><span>solar:key-square-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:key-square-bold-duotone" /><span>solar:key-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:keyboard-bold-duotone" /><span>solar:keyboard-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:kick-scooter-bold-duotone" /><span>solar:kick-scooter-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ladle-bold-duotone" /><span>solar:ladle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:lamp-bold-duotone" /><span>solar:lamp-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:laptop-2-bold-duotone" /><span>solar:laptop-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:laptop-3-bold-duotone" /><span>solar:laptop-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:laptop-bold-duotone" /><span>solar:laptop-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:laptop-minimalistic-bold-duotone" /><span>solar:laptop-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:layers-bold-duotone" /><span>solar:layers-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:layers-minimalistic-bold-duotone" /><span>solar:layers-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:leaf-bold-duotone" /><span>solar:leaf-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:letter-bold-duotone" /><span>solar:letter-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:letter-opened-bold-duotone" /><span>solar:letter-opened-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:letter-unread-bold-duotone" /><span>solar:letter-unread-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:library-bold-duotone" /><span>solar:library-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:lightbulb-bold-duotone" /><span>solar:lightbulb-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:lightbulb-bolt-bold-duotone" /><span>solar:lightbulb-bolt-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:lightbulb-minimalistic-bold-duotone" /><span>solar:lightbulb-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:lightning-bold-duotone" /><span>solar:lightning-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:like-bold-duotone" /><span>solar:like-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:link-bold-duotone" /><span>solar:link-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:link-broken-bold-duotone" /><span>solar:link-broken-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:link-broken-minimalistic-bold-duotone" /><span>solar:link-broken-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:link-circle-bold-duotone" /><span>solar:link-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:link-minimalistic-2-bold-duotone" /><span>solar:link-minimalistic-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:link-minimalistic-bold-duotone" /><span>solar:link-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:link-round-angle-bold-duotone" /><span>solar:link-round-angle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:link-round-bold-duotone" /><span>solar:link-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:link-square-bold-duotone" /><span>solar:link-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:list-arrow-down-bold-duotone" /><span>solar:list-arrow-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:list-arrow-down-minimalistic-bold-duotone" /><span>solar:list-arrow-down-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:list-arrow-up-bold-duotone" /><span>solar:list-arrow-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:list-arrow-up-minimalistic-bold-duotone" /><span>solar:list-arrow-up-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:list-bold-duotone" /><span>solar:list-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:list-check-bold-duotone" /><span>solar:list-check-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:list-check-minimalistic-bold-duotone" /><span>solar:list-check-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:list-cross-bold-duotone" /><span>solar:list-cross-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:list-cross-minimalistic-bold-duotone" /><span>solar:list-cross-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:list-down-bold-duotone" /><span>solar:list-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:list-down-minimalistic-bold-duotone" /><span>solar:list-down-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:list-heart-bold-duotone" /><span>solar:list-heart-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:list-heart-minimalistic-bold-duotone" /><span>solar:list-heart-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:list-up-bold-duotone" /><span>solar:list-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:list-up-minimalistic-bold-duotone" /><span>solar:list-up-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:lock-bold-duotone" /><span>solar:lock-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:lock-keyhole-bold-duotone" /><span>solar:lock-keyhole-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:lock-keyhole-minimalistic-bold-duotone" /><span>solar:lock-keyhole-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:lock-keyhole-minimalistic-unlocked-bold-duotone" /><span>solar:lock-keyhole-minimalistic-unlocked-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:lock-keyhole-unlocked-bold-duotone" /><span>solar:lock-keyhole-unlocked-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:lock-password-bold-duotone" /><span>solar:lock-password-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:lock-password-unlocked-bold-duotone" /><span>solar:lock-password-unlocked-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:lock-unlocked-bold-duotone" /><span>solar:lock-unlocked-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:login-2-bold-duotone" /><span>solar:login-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:login-3-bold-duotone" /><span>solar:login-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:login-bold-duotone" /><span>solar:login-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:logout-2-bold-duotone" /><span>solar:logout-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:logout-3-bold-duotone" /><span>solar:logout-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:logout-bold-duotone" /><span>solar:logout-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:magic-stick-2-bold-duotone" /><span>solar:magic-stick-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:magic-stick-3-bold-duotone" /><span>solar:magic-stick-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:magic-stick-bold-duotone" /><span>solar:magic-stick-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:magnet-bold-duotone" /><span>solar:magnet-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:magnet-wave-bold-duotone" /><span>solar:magnet-wave-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:magnifer-bold-duotone" /><span>solar:magnifer-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:magnifer-bug-bold-duotone" /><span>solar:magnifer-bug-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:magnifer-zoom-in-bold-duotone" /><span>solar:magnifer-zoom-in-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:magnifer-zoom-out-bold-duotone" /><span>solar:magnifer-zoom-out-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:mailbox-bold-duotone" /><span>solar:mailbox-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:map-arrow-down-bold-duotone" /><span>solar:map-arrow-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:map-arrow-left-bold-duotone" /><span>solar:map-arrow-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:map-arrow-right-bold-duotone" /><span>solar:map-arrow-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:map-arrow-square-bold-duotone" /><span>solar:map-arrow-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:map-arrow-up-bold-duotone" /><span>solar:map-arrow-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:map-bold-duotone" /><span>solar:map-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:map-point-add-bold-duotone" /><span>solar:map-point-add-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:map-point-bold-duotone" /><span>solar:map-point-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:map-point-favourite-bold-duotone" /><span>solar:map-point-favourite-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:map-point-hospital-bold-duotone" /><span>solar:map-point-hospital-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:map-point-remove-bold-duotone" /><span>solar:map-point-remove-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:map-point-rotate-bold-duotone" /><span>solar:map-point-rotate-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:map-point-school-bold-duotone" /><span>solar:map-point-school-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:map-point-search-bold-duotone" /><span>solar:map-point-search-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:map-point-wave-bold-duotone" /><span>solar:map-point-wave-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:mask-happly-bold-duotone" /><span>solar:mask-happly-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:mask-sad-bold-duotone" /><span>solar:mask-sad-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:masks-bold-duotone" /><span>solar:masks-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:maximize-bold-duotone" /><span>solar:maximize-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:maximize-square-2-bold-duotone" /><span>solar:maximize-square-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:maximize-square-3-bold-duotone" /><span>solar:maximize-square-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:maximize-square-bold-duotone" /><span>solar:maximize-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:maximize-square-minimalistic-bold-duotone" /><span>solar:maximize-square-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:medal-ribbon-bold-duotone" /><span>solar:medal-ribbon-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:medal-ribbon-star-bold-duotone" /><span>solar:medal-ribbon-star-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:medal-ribbons-star-bold-duotone" /><span>solar:medal-ribbons-star-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:medal-star-bold-duotone" /><span>solar:medal-star-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:medal-star-circle-bold-duotone" /><span>solar:medal-star-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:medal-star-square-bold-duotone" /><span>solar:medal-star-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:medical-kit-bold-duotone" /><span>solar:medical-kit-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:meditation-bold-duotone" /><span>solar:meditation-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:meditation-round-bold-duotone" /><span>solar:meditation-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:men-bold-duotone" /><span>solar:men-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:mention-circle-bold-duotone" /><span>solar:mention-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:mention-square-bold-duotone" /><span>solar:mention-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:menu-dots-bold-duotone" /><span>solar:menu-dots-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:menu-dots-circle-bold-duotone" /><span>solar:menu-dots-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:menu-dots-square-bold-duotone" /><span>solar:menu-dots-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:microphone-2-bold-duotone" /><span>solar:microphone-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:microphone-3-bold-duotone" /><span>solar:microphone-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:microphone-bold-duotone" /><span>solar:microphone-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:microphone-large-bold-duotone" /><span>solar:microphone-large-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:minimalistic-magnifer-bold-duotone" /><span>solar:minimalistic-magnifer-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:minimalistic-magnifer-bug-bold-duotone" /><span>solar:minimalistic-magnifer-bug-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:minimalistic-magnifer-zoom-in-bold-duotone" /><span>solar:minimalistic-magnifer-zoom-in-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:minimalistic-magnifer-zoom-out-bold-duotone" /><span>solar:minimalistic-magnifer-zoom-out-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:minimize-bold-duotone" /><span>solar:minimize-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:minimize-square-2-bold-duotone" /><span>solar:minimize-square-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:minimize-square-3-bold-duotone" /><span>solar:minimize-square-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:minimize-square-bold-duotone" /><span>solar:minimize-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:minimize-square-minimalistic-bold-duotone" /><span>solar:minimize-square-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:minus-circle-bold-duotone" /><span>solar:minus-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:minus-square-bold-duotone" /><span>solar:minus-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:mirror-bold-duotone" /><span>solar:mirror-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:mirror-left-bold-duotone" /><span>solar:mirror-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:mirror-right-bold-duotone" /><span>solar:mirror-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:money-bag-bold-duotone" /><span>solar:money-bag-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:monitor-bold-duotone" /><span>solar:monitor-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:monitor-camera-bold-duotone" /><span>solar:monitor-camera-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:monitor-smartphone-bold-duotone" /><span>solar:monitor-smartphone-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:moon-bold-duotone" /><span>solar:moon-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:moon-fog-bold-duotone" /><span>solar:moon-fog-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:moon-sleep-bold-duotone" /><span>solar:moon-sleep-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:moon-stars-bold-duotone" /><span>solar:moon-stars-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:mouse-bold-duotone" /><span>solar:mouse-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:mouse-circle-bold-duotone" /><span>solar:mouse-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:mouse-minimalistic-bold-duotone" /><span>solar:mouse-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:move-to-folder-bold-duotone" /><span>solar:move-to-folder-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:multiple-forward-left-bold-duotone" /><span>solar:multiple-forward-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:multiple-forward-right-bold-duotone" /><span>solar:multiple-forward-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:music-library-2-bold-duotone" /><span>solar:music-library-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:music-library-bold-duotone" /><span>solar:music-library-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:music-note-2-bold-duotone" /><span>solar:music-note-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:music-note-3-bold-duotone" /><span>solar:music-note-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:music-note-4-bold-duotone" /><span>solar:music-note-4-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:music-note-bold-duotone" /><span>solar:music-note-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:music-note-slider-2-bold-duotone" /><span>solar:music-note-slider-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:music-note-slider-bold-duotone" /><span>solar:music-note-slider-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:music-notes-bold-duotone" /><span>solar:music-notes-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:muted-bold-duotone" /><span>solar:muted-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:notebook-bold-duotone" /><span>solar:notebook-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:notebook-bookmark-bold-duotone" /><span>solar:notebook-bookmark-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:notebook-minimalistic-bold-duotone" /><span>solar:notebook-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:notebook-square-bold-duotone" /><span>solar:notebook-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:notes-bold-duotone" /><span>solar:notes-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:notes-minimalistic-bold-duotone" /><span>solar:notes-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:notification-lines-remove-bold-duotone" /><span>solar:notification-lines-remove-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:notification-remove-bold-duotone" /><span>solar:notification-remove-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:notification-unread-bold-duotone" /><span>solar:notification-unread-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:notification-unread-lines-bold-duotone" /><span>solar:notification-unread-lines-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:object-scan-bold-duotone" /><span>solar:object-scan-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:outgoing-call-bold-duotone" /><span>solar:outgoing-call-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:outgoing-call-rounded-bold-duotone" /><span>solar:outgoing-call-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:oven-mitts-bold-duotone" /><span>solar:oven-mitts-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:oven-mitts-minimalistic-bold-duotone" /><span>solar:oven-mitts-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:paint-roller-bold-duotone" /><span>solar:paint-roller-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:palette-bold-duotone" /><span>solar:palette-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:palette-round-bold-duotone" /><span>solar:palette-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pallete-2-bold-duotone" /><span>solar:pallete-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:panorama-bold-duotone" /><span>solar:panorama-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:paper-bin-bold-duotone" /><span>solar:paper-bin-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:paperclip-2-bold-duotone" /><span>solar:paperclip-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:paperclip-bold-duotone" /><span>solar:paperclip-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:paperclip-rounded-2-bold-duotone" /><span>solar:paperclip-rounded-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:paperclip-rounded-bold-duotone" /><span>solar:paperclip-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:paragraph-spacing-bold-duotone" /><span>solar:paragraph-spacing-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:passport-bold-duotone" /><span>solar:passport-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:passport-minimalistic-bold-duotone" /><span>solar:passport-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:password-bold-duotone" /><span>solar:password-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:password-minimalistic-bold-duotone" /><span>solar:password-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:password-minimalistic-input-bold-duotone" /><span>solar:password-minimalistic-input-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pause-bold-duotone" /><span>solar:pause-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pause-circle-bold-duotone" /><span>solar:pause-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:paw-bold-duotone" /><span>solar:paw-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pen-2-bold-duotone" /><span>solar:pen-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pen-bold-duotone" /><span>solar:pen-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pen-new-round-bold-duotone" /><span>solar:pen-new-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pen-new-square-bold-duotone" /><span>solar:pen-new-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:people-nearby-bold-duotone" /><span>solar:people-nearby-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:perfume-bold-duotone" /><span>solar:perfume-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:phone-bold-duotone" /><span>solar:phone-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:phone-calling-bold-duotone" /><span>solar:phone-calling-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:phone-calling-rounded-bold-duotone" /><span>solar:phone-calling-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:phone-rounded-bold-duotone" /><span>solar:phone-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pie-chart-2-bold-duotone" /><span>solar:pie-chart-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pie-chart-3-bold-duotone" /><span>solar:pie-chart-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pie-chart-bold-duotone" /><span>solar:pie-chart-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pill-bold-duotone" /><span>solar:pill-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pills-2-bold-duotone" /><span>solar:pills-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pills-3-bold-duotone" /><span>solar:pills-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pills-bold-duotone" /><span>solar:pills-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pin-bold-duotone" /><span>solar:pin-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pin-circle-bold-duotone" /><span>solar:pin-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pin-list-bold-duotone" /><span>solar:pin-list-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pip-2-bold-duotone" /><span>solar:pip-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pip-bold-duotone" /><span>solar:pip-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pipette-bold-duotone" /><span>solar:pipette-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:plaaylist-minimalistic-bold-duotone" /><span>solar:plaaylist-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:plain-2-bold-duotone" /><span>solar:plain-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:plain-3-bold-duotone" /><span>solar:plain-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:plain-bold-duotone" /><span>solar:plain-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:planet-2-bold-duotone" /><span>solar:planet-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:planet-3-bold-duotone" /><span>solar:planet-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:planet-4-bold-duotone" /><span>solar:planet-4-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:planet-bold-duotone" /><span>solar:planet-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:plate-bold-duotone" /><span>solar:plate-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:play-bold-duotone" /><span>solar:play-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:play-circle-bold-duotone" /><span>solar:play-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:play-stream-bold-duotone" /><span>solar:play-stream-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:playback-speed-bold-duotone" /><span>solar:playback-speed-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:playlist-2-bold-duotone" /><span>solar:playlist-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:playlist-bold-duotone" /><span>solar:playlist-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:playlist-minimalistic-2-bold-duotone" /><span>solar:playlist-minimalistic-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:playlist-minimalistic-3-bold-duotone" /><span>solar:playlist-minimalistic-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:plug-circle-bold-duotone" /><span>solar:plug-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:plus-minus-bold-duotone" /><span>solar:plus-minus-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:podcast-bold-duotone" /><span>solar:podcast-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:point-on-map-bold-duotone" /><span>solar:point-on-map-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:point-on-map-perspective-bold-duotone" /><span>solar:point-on-map-perspective-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:posts-carousel-horizontal-bold-duotone" /><span>solar:posts-carousel-horizontal-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:posts-carousel-vertical-bold-duotone" /><span>solar:posts-carousel-vertical-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:power-bold-duotone" /><span>solar:power-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:presentation-graph-bold-duotone" /><span>solar:presentation-graph-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:printer-2-bold-duotone" /><span>solar:printer-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:printer-bold-duotone" /><span>solar:printer-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:printer-minimalistic-bold-duotone" /><span>solar:printer-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:programming-bold-duotone" /><span>solar:programming-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:projector-bold-duotone" /><span>solar:projector-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pulse-2-bold-duotone" /><span>solar:pulse-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:pulse-bold-duotone" /><span>solar:pulse-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:qr-code-bold-duotone" /><span>solar:qr-code-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:question-circle-bold-duotone" /><span>solar:question-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:question-square-bold-duotone" /><span>solar:question-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:quit-full-screen-bold-duotone" /><span>solar:quit-full-screen-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:quit-full-screen-circle-bold-duotone" /><span>solar:quit-full-screen-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:quit-full-screen-square-bold-duotone" /><span>solar:quit-full-screen-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:quit-pip-bold-duotone" /><span>solar:quit-pip-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:radar-2-bold-duotone" /><span>solar:radar-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:radar-bold-duotone" /><span>solar:radar-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:radial-blur-bold-duotone" /><span>solar:radial-blur-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:radio-bold-duotone" /><span>solar:radio-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:radio-minimalistic-bold-duotone" /><span>solar:radio-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ranking-bold-duotone" /><span>solar:ranking-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:recive-square-bold-duotone" /><span>solar:recive-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:recive-twice-square-bold-duotone" /><span>solar:recive-twice-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:record-bold-duotone" /><span>solar:record-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:record-circle-bold-duotone" /><span>solar:record-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:record-minimalistic-bold-duotone" /><span>solar:record-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:record-square-bold-duotone" /><span>solar:record-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:reel-2-bold-duotone" /><span>solar:reel-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:reel-bold-duotone" /><span>solar:reel-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:refresh-bold-duotone" /><span>solar:refresh-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:refresh-circle-bold-duotone" /><span>solar:refresh-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:refresh-square-bold-duotone" /><span>solar:refresh-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:remote-controller-2-bold-duotone" /><span>solar:remote-controller-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:remote-controller-bold-duotone" /><span>solar:remote-controller-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:remote-controller-minimalistic-bold-duotone" /><span>solar:remote-controller-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:remove-folder-bold-duotone" /><span>solar:remove-folder-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:reorder-bold-duotone" /><span>solar:reorder-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:repeat-bold-duotone" /><span>solar:repeat-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:repeat-one-bold-duotone" /><span>solar:repeat-one-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:repeat-one-minimalistic-bold-duotone" /><span>solar:repeat-one-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:reply-2-bold-duotone" /><span>solar:reply-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:reply-bold-duotone" /><span>solar:reply-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:restart-bold-duotone" /><span>solar:restart-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:restart-circle-bold-duotone" /><span>solar:restart-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:restart-square-bold-duotone" /><span>solar:restart-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:revote-bold-duotone" /><span>solar:revote-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rewind-10-seconds-back-bold-duotone" /><span>solar:rewind-10-seconds-back-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rewind-10-seconds-forward-bold-duotone" /><span>solar:rewind-10-seconds-forward-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rewind-15-seconds-back-bold-duotone" /><span>solar:rewind-15-seconds-back-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rewind-15-seconds-forward-bold-duotone" /><span>solar:rewind-15-seconds-forward-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rewind-5-seconds-back-bold-duotone" /><span>solar:rewind-5-seconds-back-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rewind-5-seconds-forward-bold-duotone" /><span>solar:rewind-5-seconds-forward-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rewind-back-bold-duotone" /><span>solar:rewind-back-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rewind-back-circle-bold-duotone" /><span>solar:rewind-back-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rewind-forward-bold-duotone" /><span>solar:rewind-forward-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rewind-forward-circle-bold-duotone" /><span>solar:rewind-forward-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rocket-2-bold-duotone" /><span>solar:rocket-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rocket-bold-duotone" /><span>solar:rocket-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rolling-pin-bold-duotone" /><span>solar:rolling-pin-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-alt-arrow-down-bold-duotone" /><span>solar:round-alt-arrow-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-alt-arrow-left-bold-duotone" /><span>solar:round-alt-arrow-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-alt-arrow-right-bold-duotone" /><span>solar:round-alt-arrow-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-alt-arrow-up-bold-duotone" /><span>solar:round-alt-arrow-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-arrow-down-bold-duotone" /><span>solar:round-arrow-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-arrow-left-bold-duotone" /><span>solar:round-arrow-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-arrow-left-down-bold-duotone" /><span>solar:round-arrow-left-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-arrow-left-up-bold-duotone" /><span>solar:round-arrow-left-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-arrow-right-bold-duotone" /><span>solar:round-arrow-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-arrow-right-down-bold-duotone" /><span>solar:round-arrow-right-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-arrow-right-up-bold-duotone" /><span>solar:round-arrow-right-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-arrow-up-bold-duotone" /><span>solar:round-arrow-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-double-alt-arrow-down-bold-duotone" /><span>solar:round-double-alt-arrow-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-double-alt-arrow-left-bold-duotone" /><span>solar:round-double-alt-arrow-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-double-alt-arrow-right-bold-duotone" /><span>solar:round-double-alt-arrow-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-double-alt-arrow-up-bold-duotone" /><span>solar:round-double-alt-arrow-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-graph-bold-duotone" /><span>solar:round-graph-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-sort-horizontal-bold-duotone" /><span>solar:round-sort-horizontal-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-sort-vertical-bold-duotone" /><span>solar:round-sort-vertical-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-transfer-diagonal-bold-duotone" /><span>solar:round-transfer-diagonal-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-transfer-horizontal-bold-duotone" /><span>solar:round-transfer-horizontal-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:round-transfer-vertical-bold-duotone" /><span>solar:round-transfer-vertical-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rounded-magnifer-bold-duotone" /><span>solar:rounded-magnifer-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rounded-magnifer-bug-bold-duotone" /><span>solar:rounded-magnifer-bug-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rounded-magnifer-zoom-in-bold-duotone" /><span>solar:rounded-magnifer-zoom-in-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rounded-magnifer-zoom-out-bold-duotone" /><span>solar:rounded-magnifer-zoom-out-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:route-bold-duotone" /><span>solar:route-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:routing-2-bold-duotone" /><span>solar:routing-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:routing-3-bold-duotone" /><span>solar:routing-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:routing-bold-duotone" /><span>solar:routing-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ruble-bold-duotone" /><span>solar:ruble-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:rugby-bold-duotone" /><span>solar:rugby-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ruler-angular-bold-duotone" /><span>solar:ruler-angular-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ruler-bold-duotone" /><span>solar:ruler-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ruler-cross-pen-bold-duotone" /><span>solar:ruler-cross-pen-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ruler-pen-bold-duotone" /><span>solar:ruler-pen-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:running-2-bold-duotone" /><span>solar:running-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:running-bold-duotone" /><span>solar:running-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:running-round-bold-duotone" /><span>solar:running-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sad-circle-bold-duotone" /><span>solar:sad-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sad-square-bold-duotone" /><span>solar:sad-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:safe-2-bold-duotone" /><span>solar:safe-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:safe-circle-bold-duotone" /><span>solar:safe-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:safe-square-bold-duotone" /><span>solar:safe-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sale-bold-duotone" /><span>solar:sale-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sale-square-bold-duotone" /><span>solar:sale-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:satellite-bold-duotone" /><span>solar:satellite-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:scale-bold-duotone" /><span>solar:scale-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:scanner-2-bold-duotone" /><span>solar:scanner-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:scanner-bold-duotone" /><span>solar:scanner-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:scissors-bold-duotone" /><span>solar:scissors-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:scissors-square-bold-duotone" /><span>solar:scissors-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:scooter-bold-duotone" /><span>solar:scooter-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:screen-share-bold-duotone" /><span>solar:screen-share-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:screencast-2-bold-duotone" /><span>solar:screencast-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:screencast-bold-duotone" /><span>solar:screencast-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sd-card-bold-duotone" /><span>solar:sd-card-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:send-square-bold-duotone" /><span>solar:send-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:send-twice-square-bold-duotone" /><span>solar:send-twice-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:server-2-bold-duotone" /><span>solar:server-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:server-bold-duotone" /><span>solar:server-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:server-minimalistic-bold-duotone" /><span>solar:server-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:server-path-bold-duotone" /><span>solar:server-path-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:server-square-bold-duotone" /><span>solar:server-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:server-square-cloud-bold-duotone" /><span>solar:server-square-cloud-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:server-square-update-bold-duotone" /><span>solar:server-square-update-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:settings-bold-duotone" /><span>solar:settings-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:settings-minimalistic-bold-duotone" /><span>solar:settings-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:share-bold-duotone" /><span>solar:share-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:share-circle-bold-duotone" /><span>solar:share-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shield-bold-duotone" /><span>solar:shield-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shield-check-bold-duotone" /><span>solar:shield-check-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shield-cross-bold-duotone" /><span>solar:shield-cross-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shield-keyhole-bold-duotone" /><span>solar:shield-keyhole-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shield-keyhole-minimalistic-bold-duotone" /><span>solar:shield-keyhole-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shield-minimalistic-bold-duotone" /><span>solar:shield-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shield-minus-bold-duotone" /><span>solar:shield-minus-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shield-network-bold-duotone" /><span>solar:shield-network-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shield-plus-bold-duotone" /><span>solar:shield-plus-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shield-star-bold-duotone" /><span>solar:shield-star-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shield-up-bold-duotone" /><span>solar:shield-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shield-user-bold-duotone" /><span>solar:shield-user-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shield-warning-bold-duotone" /><span>solar:shield-warning-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shock-absorber-bold-duotone" /><span>solar:shock-absorber-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shop-2-bold-duotone" /><span>solar:shop-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shop-bold-duotone" /><span>solar:shop-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shop-minimalistic-bold-duotone" /><span>solar:shop-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:shuffle-bold-duotone" /><span>solar:shuffle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sidebar-code-bold-duotone" /><span>solar:sidebar-code-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sidebar-minimalistic-bold-duotone" /><span>solar:sidebar-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:siderbar-bold-duotone" /><span>solar:siderbar-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:signpost-2-bold-duotone" /><span>solar:signpost-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:signpost-bold-duotone" /><span>solar:signpost-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sim-card-bold-duotone" /><span>solar:sim-card-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sim-card-minimalistic-bold-duotone" /><span>solar:sim-card-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sim-cards-bold-duotone" /><span>solar:sim-cards-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:siren-bold-duotone" /><span>solar:siren-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:siren-rounded-bold-duotone" /><span>solar:siren-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:skateboard-bold-duotone" /><span>solar:skateboard-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:skateboarding-bold-duotone" /><span>solar:skateboarding-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:skateboarding-round-bold-duotone" /><span>solar:skateboarding-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:skip-next-bold-duotone" /><span>solar:skip-next-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:skip-previous-bold-duotone" /><span>solar:skip-previous-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:skirt-bold-duotone" /><span>solar:skirt-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:slash-circle-bold-duotone" /><span>solar:slash-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:slash-square-bold-duotone" /><span>solar:slash-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sledgehammer-bold-duotone" /><span>solar:sledgehammer-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sleeping-bold-duotone" /><span>solar:sleeping-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sleeping-circle-bold-duotone" /><span>solar:sleeping-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sleeping-square-bold-duotone" /><span>solar:sleeping-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:slider-horizontal-bold-duotone" /><span>solar:slider-horizontal-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:slider-minimalistic-horizontal-bold-duotone" /><span>solar:slider-minimalistic-horizontal-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:slider-vertical-bold-duotone" /><span>solar:slider-vertical-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:slider-vertical-minimalistic-bold-duotone" /><span>solar:slider-vertical-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:smart-home-angle-bold-duotone" /><span>solar:smart-home-angle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:smart-home-bold-duotone" /><span>solar:smart-home-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:smart-speaker-2-bold-duotone" /><span>solar:smart-speaker-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:smart-speaker-bold-duotone" /><span>solar:smart-speaker-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:smart-speaker-minimalistic-bold-duotone" /><span>solar:smart-speaker-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:smart-vacuum-cleaner-2-bold-duotone" /><span>solar:smart-vacuum-cleaner-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:smart-vacuum-cleaner-bold-duotone" /><span>solar:smart-vacuum-cleaner-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:smartphone-2-bold-duotone" /><span>solar:smartphone-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:smartphone-bold-duotone" /><span>solar:smartphone-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:smartphone-rotate-2-bold-duotone" /><span>solar:smartphone-rotate-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:smartphone-rotate-angle-bold-duotone" /><span>solar:smartphone-rotate-angle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:smartphone-rotate-orientation-bold-duotone" /><span>solar:smartphone-rotate-orientation-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:smartphone-update-bold-duotone" /><span>solar:smartphone-update-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:smartphone-vibration-bold-duotone" /><span>solar:smartphone-vibration-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:smile-circle-bold-duotone" /><span>solar:smile-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:smile-square-bold-duotone" /><span>solar:smile-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:snowflake-bold-duotone" /><span>solar:snowflake-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:socket-bold-duotone" /><span>solar:socket-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sofa-2-bold-duotone" /><span>solar:sofa-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sofa-3-bold-duotone" /><span>solar:sofa-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sofa-bold-duotone" /><span>solar:sofa-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sort-bold-duotone" /><span>solar:sort-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sort-by-alphabet-bold-duotone" /><span>solar:sort-by-alphabet-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sort-by-time-bold-duotone" /><span>solar:sort-by-time-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sort-from-bottom-to-top-bold-duotone" /><span>solar:sort-from-bottom-to-top-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sort-from-top-to-bottom-bold-duotone" /><span>solar:sort-from-top-to-bottom-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sort-horizontal-bold-duotone" /><span>solar:sort-horizontal-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sort-vertical-bold-duotone" /><span>solar:sort-vertical-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:soundwave-bold-duotone" /><span>solar:soundwave-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:soundwave-circle-bold-duotone" /><span>solar:soundwave-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:soundwave-square-bold-duotone" /><span>solar:soundwave-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:speaker-bold-duotone" /><span>solar:speaker-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:speaker-minimalistic-bold-duotone" /><span>solar:speaker-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:special-effects-bold-duotone" /><span>solar:special-effects-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:spedometer-low-bold-duotone" /><span>solar:spedometer-low-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:spedometer-max-bold-duotone" /><span>solar:spedometer-max-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:spedometer-middle-bold-duotone" /><span>solar:spedometer-middle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-academic-cap-2-bold-duotone" /><span>solar:square-academic-cap-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-academic-cap-bold-duotone" /><span>solar:square-academic-cap-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-alt-arrow-down-bold-duotone" /><span>solar:square-alt-arrow-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-alt-arrow-left-bold-duotone" /><span>solar:square-alt-arrow-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-alt-arrow-right-bold-duotone" /><span>solar:square-alt-arrow-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-alt-arrow-up-bold-duotone" /><span>solar:square-alt-arrow-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-arrow-down-bold-duotone" /><span>solar:square-arrow-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-arrow-left-bold-duotone" /><span>solar:square-arrow-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-arrow-left-down-bold-duotone" /><span>solar:square-arrow-left-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-arrow-left-up-bold-duotone" /><span>solar:square-arrow-left-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-arrow-right-bold-duotone" /><span>solar:square-arrow-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-arrow-right-down-bold-duotone" /><span>solar:square-arrow-right-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-arrow-right-up-bold-duotone" /><span>solar:square-arrow-right-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-arrow-up-bold-duotone" /><span>solar:square-arrow-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-bottom-down-bold-duotone" /><span>solar:square-bottom-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-bottom-up-bold-duotone" /><span>solar:square-bottom-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-double-alt-arrow-down-bold-duotone" /><span>solar:square-double-alt-arrow-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-double-alt-arrow-left-bold-duotone" /><span>solar:square-double-alt-arrow-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-double-alt-arrow-right-bold-duotone" /><span>solar:square-double-alt-arrow-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-double-alt-arrow-up-bold-duotone" /><span>solar:square-double-alt-arrow-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-forward-bold-duotone" /><span>solar:square-forward-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-share-line-bold-duotone" /><span>solar:square-share-line-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-sort-horizontal-bold-duotone" /><span>solar:square-sort-horizontal-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-sort-vertical-bold-duotone" /><span>solar:square-sort-vertical-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-top-down-bold-duotone" /><span>solar:square-top-down-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-top-up-bold-duotone" /><span>solar:square-top-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-transfer-horizontal-bold-duotone" /><span>solar:square-transfer-horizontal-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:square-transfer-vertical-bold-duotone" /><span>solar:square-transfer-vertical-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ssd-round-bold-duotone" /><span>solar:ssd-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ssd-square-bold-duotone" /><span>solar:ssd-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:star-angle-bold-duotone" /><span>solar:star-angle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:star-bold-duotone" /><span>solar:star-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:star-circle-bold-duotone" /><span>solar:star-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:star-fall-2-bold-duotone" /><span>solar:star-fall-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:star-fall-bold-duotone" /><span>solar:star-fall-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:star-fall-minimalistic-2-bold-duotone" /><span>solar:star-fall-minimalistic-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:star-fall-minimalistic-bold-duotone" /><span>solar:star-fall-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:star-rainbow-bold-duotone" /><span>solar:star-rainbow-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:star-ring-bold-duotone" /><span>solar:star-ring-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:star-rings-bold-duotone" /><span>solar:star-rings-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:star-shine-bold-duotone" /><span>solar:star-shine-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:stars-bold-duotone" /><span>solar:stars-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:stars-line-bold-duotone" /><span>solar:stars-line-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:stars-minimalistic-bold-duotone" /><span>solar:stars-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:station-bold-duotone" /><span>solar:station-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:station-minimalistic-bold-duotone" /><span>solar:station-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:stethoscope-bold-duotone" /><span>solar:stethoscope-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sticker-circle-bold-duotone" /><span>solar:sticker-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sticker-smile-circle-2-bold-duotone" /><span>solar:sticker-smile-circle-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sticker-smile-circle-bold-duotone" /><span>solar:sticker-smile-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sticker-smile-square-bold-duotone" /><span>solar:sticker-smile-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sticker-square-bold-duotone" /><span>solar:sticker-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:stop-bold-duotone" /><span>solar:stop-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:stop-circle-bold-duotone" /><span>solar:stop-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:stopwatch-bold-duotone" /><span>solar:stopwatch-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:stopwatch-pause-bold-duotone" /><span>solar:stopwatch-pause-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:stopwatch-play-bold-duotone" /><span>solar:stopwatch-play-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:stream-bold-duotone" /><span>solar:stream-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:streets-bold-duotone" /><span>solar:streets-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:streets-map-point-bold-duotone" /><span>solar:streets-map-point-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:streets-navigation-bold-duotone" /><span>solar:streets-navigation-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:stretching-bold-duotone" /><span>solar:stretching-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:stretching-round-bold-duotone" /><span>solar:stretching-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:structure-bold-duotone" /><span>solar:structure-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:subtitles-bold-duotone" /><span>solar:subtitles-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:suitcase-bold-duotone" /><span>solar:suitcase-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:suitcase-lines-bold-duotone" /><span>solar:suitcase-lines-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:suitcase-tag-bold-duotone" /><span>solar:suitcase-tag-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sun-2-bold-duotone" /><span>solar:sun-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sun-bold-duotone" /><span>solar:sun-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sun-fog-bold-duotone" /><span>solar:sun-fog-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sunrise-bold-duotone" /><span>solar:sunrise-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:sunset-bold-duotone" /><span>solar:sunset-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:suspension-bold-duotone" /><span>solar:suspension-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:suspension-bolt-bold-duotone" /><span>solar:suspension-bolt-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:suspension-cross-bold-duotone" /><span>solar:suspension-cross-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:swimming-bold-duotone" /><span>solar:swimming-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:syringe-bold-duotone" /><span>solar:syringe-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:t-shirt-bold-duotone" /><span>solar:t-shirt-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:tablet-bold-duotone" /><span>solar:tablet-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:tag-bold-duotone" /><span>solar:tag-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:tag-horizontal-bold-duotone" /><span>solar:tag-horizontal-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:tag-price-bold-duotone" /><span>solar:tag-price-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:target-bold-duotone" /><span>solar:target-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:tea-cup-bold-duotone" /><span>solar:tea-cup-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:telescope-bold-duotone" /><span>solar:telescope-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:temperature-bold-duotone" /><span>solar:temperature-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:tennis-2-bold-duotone" /><span>solar:tennis-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:tennis-bold-duotone" /><span>solar:tennis-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:test-tube-bold-duotone" /><span>solar:test-tube-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:test-tube-minimalistic-bold-duotone" /><span>solar:test-tube-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-bold-bold-duotone" /><span>solar:text-bold-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-bold-circle-bold-duotone" /><span>solar:text-bold-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-bold-duotone" /><span>solar:text-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-bold-square-bold-duotone" /><span>solar:text-bold-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-circle-bold-duotone" /><span>solar:text-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-cross-bold-duotone" /><span>solar:text-cross-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-cross-circle-bold-duotone" /><span>solar:text-cross-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-cross-square-bold-duotone" /><span>solar:text-cross-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-field-bold-duotone" /><span>solar:text-field-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-field-focus-bold-duotone" /><span>solar:text-field-focus-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-italic-bold-duotone" /><span>solar:text-italic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-italic-circle-bold-duotone" /><span>solar:text-italic-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-italic-square-bold-duotone" /><span>solar:text-italic-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-selection-bold-duotone" /><span>solar:text-selection-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-square-2-bold-duotone" /><span>solar:text-square-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-square-bold-duotone" /><span>solar:text-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-underline-bold-duotone" /><span>solar:text-underline-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-underline-circle-bold-duotone" /><span>solar:text-underline-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:text-underline-cross-bold-duotone" /><span>solar:text-underline-cross-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:thermometer-bold-duotone" /><span>solar:thermometer-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:three-squares-bold-duotone" /><span>solar:three-squares-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ticker-star-bold-duotone" /><span>solar:ticker-star-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ticket-bold-duotone" /><span>solar:ticket-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ticket-sale-bold-duotone" /><span>solar:ticket-sale-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:to-pip-bold-duotone" /><span>solar:to-pip-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:tornado-bold-duotone" /><span>solar:tornado-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:tornado-small-bold-duotone" /><span>solar:tornado-small-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:traffic-bold-duotone" /><span>solar:traffic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:traffic-economy-bold-duotone" /><span>solar:traffic-economy-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:tram-bold-duotone" /><span>solar:tram-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:transfer-horizontal-bold-duotone" /><span>solar:transfer-horizontal-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:transfer-vertical-bold-duotone" /><span>solar:transfer-vertical-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:translation-2-bold-duotone" /><span>solar:translation-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:translation-bold-duotone" /><span>solar:translation-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:transmission-bold-duotone" /><span>solar:transmission-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:transmission-circle-bold-duotone" /><span>solar:transmission-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:transmission-square-bold-duotone" /><span>solar:transmission-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:trash-bin-2-bold-duotone" /><span>solar:trash-bin-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:trash-bin-minimalistic-2-bold-duotone" /><span>solar:trash-bin-minimalistic-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:trash-bin-minimalistic-bold-duotone" /><span>solar:trash-bin-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:trash-bin-trash-bold-duotone" /><span>solar:trash-bin-trash-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:treadmill-bold-duotone" /><span>solar:treadmill-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:treadmill-round-bold-duotone" /><span>solar:treadmill-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:trellis-bold-duotone" /><span>solar:trellis-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:tuning-2-bold-duotone" /><span>solar:tuning-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:tuning-3-bold-duotone" /><span>solar:tuning-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:tuning-4-bold-duotone" /><span>solar:tuning-4-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:tuning-bold-duotone" /><span>solar:tuning-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:tuning-square-2-bold-duotone" /><span>solar:tuning-square-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:tuning-square-bold-duotone" /><span>solar:tuning-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:turntable-bold-duotone" /><span>solar:turntable-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:turntable-minimalistic-bold-duotone" /><span>solar:turntable-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:turntable-music-note-bold-duotone" /><span>solar:turntable-music-note-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:tv-bold-duotone" /><span>solar:tv-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ufo-2-bold-duotone" /><span>solar:ufo-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ufo-3-bold-duotone" /><span>solar:ufo-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:ufo-bold-duotone" /><span>solar:ufo-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:umbrella-bold-duotone" /><span>solar:umbrella-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:undo-left-bold-duotone" /><span>solar:undo-left-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:undo-left-round-bold-duotone" /><span>solar:undo-left-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:undo-left-round-square-bold-duotone" /><span>solar:undo-left-round-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:undo-left-square-bold-duotone" /><span>solar:undo-left-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:undo-right-bold-duotone" /><span>solar:undo-right-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:undo-right-round-bold-duotone" /><span>solar:undo-right-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:undo-right-round-square-bold-duotone" /><span>solar:undo-right-round-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:undo-right-square-bold-duotone" /><span>solar:undo-right-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:unread-bold-duotone" /><span>solar:unread-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:upload-bold-duotone" /><span>solar:upload-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:upload-minimalistic-bold-duotone" /><span>solar:upload-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:upload-square-bold-duotone" /><span>solar:upload-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:upload-track-2-bold-duotone" /><span>solar:upload-track-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:upload-track-bold-duotone" /><span>solar:upload-track-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:upload-twice-square-bold-duotone" /><span>solar:upload-twice-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:usb-bold-duotone" /><span>solar:usb-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:usb-circle-bold-duotone" /><span>solar:usb-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:usb-square-bold-duotone" /><span>solar:usb-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-block-bold-duotone" /><span>solar:user-block-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-block-rounded-bold-duotone" /><span>solar:user-block-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-bold-duotone" /><span>solar:user-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-check-bold-duotone" /><span>solar:user-check-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-check-rounded-bold-duotone" /><span>solar:user-check-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-circle-bold-duotone" /><span>solar:user-circle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-cross-bold-duotone" /><span>solar:user-cross-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-cross-rounded-bold-duotone" /><span>solar:user-cross-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-hand-up-bold-duotone" /><span>solar:user-hand-up-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-hands-bold-duotone" /><span>solar:user-hands-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-heart-bold-duotone" /><span>solar:user-heart-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-heart-rounded-bold-duotone" /><span>solar:user-heart-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-id-bold-duotone" /><span>solar:user-id-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-minus-bold-duotone" /><span>solar:user-minus-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-minus-rounded-bold-duotone" /><span>solar:user-minus-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-plus-bold-duotone" /><span>solar:user-plus-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-plus-rounded-bold-duotone" /><span>solar:user-plus-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-rounded-bold-duotone" /><span>solar:user-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-speak-bold-duotone" /><span>solar:user-speak-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:user-speak-rounded-bold-duotone" /><span>solar:user-speak-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:users-group-rounded-bold-duotone" /><span>solar:users-group-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:users-group-two-rounded-bold-duotone" /><span>solar:users-group-two-rounded-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:verified-check-bold-duotone" /><span>solar:verified-check-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:video-frame-2-bold-duotone" /><span>solar:video-frame-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:video-frame-bold-duotone" /><span>solar:video-frame-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:video-frame-cut-2-bold-duotone" /><span>solar:video-frame-cut-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:video-frame-cut-bold-duotone" /><span>solar:video-frame-cut-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:video-frame-play-horizontal-bold-duotone" /><span>solar:video-frame-play-horizontal-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:video-frame-play-vertical-bold-duotone" /><span>solar:video-frame-play-vertical-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:video-frame-replace-bold-duotone" /><span>solar:video-frame-replace-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:video-library-bold-duotone" /><span>solar:video-library-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:videocamera-add-bold-duotone" /><span>solar:videocamera-add-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:videocamera-bold-duotone" /><span>solar:videocamera-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:videocamera-record-bold-duotone" /><span>solar:videocamera-record-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:vinyl-bold-duotone" /><span>solar:vinyl-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:vinyl-record-bold-duotone" /><span>solar:vinyl-record-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:virus-bold-duotone" /><span>solar:virus-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:volleyball-2-bold-duotone" /><span>solar:volleyball-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:volleyball-bold-duotone" /><span>solar:volleyball-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:volume-bold-duotone" /><span>solar:volume-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:volume-cross-bold-duotone" /><span>solar:volume-cross-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:volume-knob-bold-duotone" /><span>solar:volume-knob-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:volume-loud-bold-duotone" /><span>solar:volume-loud-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:volume-small-bold-duotone" /><span>solar:volume-small-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:wad-of-money-bold-duotone" /><span>solar:wad-of-money-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:walking-bold-duotone" /><span>solar:walking-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:walking-round-bold-duotone" /><span>solar:walking-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:wallet-2-bold-duotone" /><span>solar:wallet-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:wallet-bold-duotone" /><span>solar:wallet-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:wallet-money-bold-duotone" /><span>solar:wallet-money-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:wallpaper-bold-duotone" /><span>solar:wallpaper-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:washing-machine-bold-duotone" /><span>solar:washing-machine-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:washing-machine-minimalistic-bold-duotone" /><span>solar:washing-machine-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:watch-round-bold-duotone" /><span>solar:watch-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:watch-square-bold-duotone" /><span>solar:watch-square-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:watch-square-minimalistic-bold-duotone" /><span>solar:watch-square-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:watch-square-minimalistic-charge-bold-duotone" /><span>solar:watch-square-minimalistic-charge-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:water-bold-duotone" /><span>solar:water-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:water-sun-bold-duotone" /><span>solar:water-sun-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:waterdrop-bold-duotone" /><span>solar:waterdrop-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:waterdrops-bold-duotone" /><span>solar:waterdrops-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:weigher-bold-duotone" /><span>solar:weigher-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:wheel-angle-bold-duotone" /><span>solar:wheel-angle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:wheel-bold-duotone" /><span>solar:wheel-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:whisk-bold-duotone" /><span>solar:whisk-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:wi-fi-router-bold-duotone" /><span>solar:wi-fi-router-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:wi-fi-router-minimalistic-bold-duotone" /><span>solar:wi-fi-router-minimalistic-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:wi-fi-router-round-bold-duotone" /><span>solar:wi-fi-router-round-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:widget-2-bold-duotone" /><span>solar:widget-2-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:widget-3-bold-duotone" /><span>solar:widget-3-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:widget-4-bold-duotone" /><span>solar:widget-4-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:widget-5-bold-duotone" /><span>solar:widget-5-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:widget-6-bold-duotone" /><span>solar:widget-6-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:widget-add-bold-duotone" /><span>solar:widget-add-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:widget-bold-duotone" /><span>solar:widget-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:wind-bold-duotone" /><span>solar:wind-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:window-frame-bold-duotone" /><span>solar:window-frame-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:wineglass-bold-duotone" /><span>solar:wineglass-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:wineglass-triangle-bold-duotone" /><span>solar:wineglass-triangle-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:winrar-bold-duotone" /><span>solar:winrar-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:wireless-charge-bold-duotone" /><span>solar:wireless-charge-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:women-bold-duotone" /><span>solar:women-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:xxx-bold-duotone" /><span>solar:xxx-bold-duotone</span></div>
							<div className="col-lg-3 col-md-4 col-6 pb-3 d-flex align-items-center fw-bold"><Icon className="iconify me-2 display-6" icon="solar:zip-file-bold-duotone" /><span>solar:zip-file-bold-duotone</span></div>
						</div>
					</PerfectScrollbar>
				</PanelBody>
				<div className="hljs-wrapper">
					<Highlight className='html'>{code1}</Highlight>
				</div>
			</Panel>
		</div>
	)
}

export default UIIconDuotone;