import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications, Store } from "react-notifications-component";
import {
  getBranchDataByCompany,
  PostCombosRol,
  postAllCompaniesRoll,
  PostgetOfertas,
  GetInfoDestinos,
  PostDeleteDestin,
} from "../../utils/urls.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";
import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";
import { Modal, Button } from "react-bootstrap";
import EditDestinations from '../experience/experience-editdestinations.js'
import  EditBestValueOffers  from "./experience-editbestvalue.js";

function BestvalueoffersView({ idUser, infoUser, dataUser }) {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  const initDestin = {
    id: 0,
    nombre: "",
    // urlimagen: "",
    place: "",
    statename: "",
    show: false,
    // id_empresa: 0,
    // id_sucursal: 0,
    // checkin: "",
    // checkout: "",
    npeoples: 2,
    nrooms: 0,
  };

  const header = [
    "id_destino",
    "nombre",
    // "urlimagen",
    "place",
    "statename",
    "show",
    // "id_empresa",
    // "id_sucursal",
    // "create_at",
    // "update_at",
    "checkin",
    "checkout",
    "npeoples",
    "nrooms",
    "id"
  ];
  
  const headerMap = {
    id_destino: "Destination ID",
    nombre: "Name",
    urlimagen: "Image URL",
    place: "Place",
    statename: "State Name",
    show: "Show",
    id_empresa: "Company ID",
    id_sucursal: "Branch ID",
    create_at: "Created At",
    update_at: "Updated At",
    checkin: "Check-In",
    checkout: "Check-Out",
    npeoples: "Number of People",
    nrooms: "Number of Rooms",
    id: "ID"
  };

  
  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };

  const [slsfirsload, setslsfirsload] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [isReLoaded, setIsReLoaded] = useState(true);

  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);
  const [formValues, setFormValues] = useState({
    company: "",
    branch: "",
  });
  const [slsListCombos, setslsListCombos] = useState([]);

  const [slsListDeals, setslsListDeals] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    const [formvisible, setformvisible] = useState(false);
    const [idRegistro, setidRegistro] = useState("");
    const [selectedRegister, setSelectedRegister] = useState(null);
      const [slsDataDestinos, setslsDataDestinos] = useState([initDestin]);
    

  useEffect(() => {
    if (!slsfirsload) {
    //   fnPostListCombos();
      executeFetchLoadCombos();
      setslsfirsload(true);

      fnPostgetOfertas();
    }
  }, [idUserP, slsfirsload]);

  useEffect(() => {
    // console.log('formValues.company <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< useEffect company', formValues.company)
    if (formValues.company != "0") {
      const fetchBranches = async () => {
        // const branches = await fnGetAllBranch();
        // setslsBranches([initSelec2, ...branches]);
        // setslsListCombos([])

        try {
          const branches = await fnGetAllBranch();
          setslsBranches([initSelec2, ...(Array.isArray(branches) ? branches : [])]);
        } catch (error) {
          console.error("Error obteniendo sucursales:", error);
          setslsBranches([initSelec2]); // Asigna solo la opción inicial en caso de error
        }



        fnPostgetOfertas([]);

      };
      fetchBranches();
    }else{
        setslsBranches([initSelec2]);
    }
  }, [formValues.company]);

  useEffect(() => {
    if (formValues.branch) {
      const updateCombos = async () => {
        await 
        // fnPostListCombos(); // Asegúrate de actualizar la lista de combos
        fnPostgetOfertas();
      };
      updateCombos();
    }

    if (formValues.branch) {
        const fetchInfoDestinos = async () => {
          const destinations = await fnPostInfoDestios();
  
          const renamedDestinations = destinations.map((destino) => ({
            ...destino,
            id: destino.id_destino,
          }));
  
          setslsDataDestinos(renamedDestinations);
        };
        fetchInfoDestinos();
      }
      if(isReLoaded){
        const fetchInfoDestinos = async () => {
          const destinations = await fnPostInfoDestios();
  
          const renamedDestinations = destinations.map((destino) => ({
            ...destino,
            id: destino.id_destino,
          }));
  
          setslsDataDestinos(renamedDestinations);

          setIsReLoaded(false)
        };
        fetchInfoDestinos();


      }
  }, [formValues.branch, isReLoaded]);

  const executeFetchLoadCombos = async () => {
    try {
      if (isLoaded) {
        await fetchLoadCombos();
      }

      const selectCombos = [initSelec, ...(await fnPostCompaniesList())];
      setslsCompany(selectCombos);

      // const branches = await fnGetAllBranch();
      // setslsBranches([initSelec2, ...branches]);
//////////////////
try {
  const branches = await fnGetAllBranch();
  setslsBranches([initSelec2, ...(Array.isArray(branches) ? branches : [])]);
} catch (error) {
  console.error("Error obteniendo sucursales:", error);
  setslsBranches([initSelec2]); // Asigna solo la opción inicial en caso de error
}

//////////////////


    } catch (error) {
      console.error("Error en fetchLoadCombos:", error);
    } finally {
      setIsLoaded(false);
    }
  };

  const fetchLoadCombos = async () => {
    try {
      if (!token) {
        token = getFromLocalStorage("TK");
      }
    } catch (error) {
      console.error("Error al cargar los combos:", error);
    }
  };

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol, 
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch
      };
  
      const responseJsonCompanies = await axios.post(postAllCompaniesRoll(token), requestData);

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const fnGetAllBranch = async () => {
    try {
      const requestData = {
        companyId: formValues.company || idUserP.company,
      };

      const response = await axios.post(
        getBranchDataByCompany(token),
        requestData
      );
      return response.data.data;
    } catch (error) {
      console.error("Error al cargar las sucursales:", error);
    }
  };


  const fnPostgetOfertas = async () => {

    console.log('entro para obtener ofertas <<< ')
    try {
      const requestData = {
        id_empresa: formValues.company || idUserP.company,
        id_sucursal: formValues.branch || idUserP.branch,
        id_rol:  idUserP.rol,
      };

      console.log('requestData <<<')
      console.log(requestData)

      const response = await axios.post(PostgetOfertas(), requestData);
      setslsListDeals(response.data.data || []);
    } catch (error) {
      console.error("Error al cargar los combos:", error);
    }
  };

  const handleInputChangeCompany = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      ...(name === "company" && { branch: "" }),
    }));
  };

  const fnPostInfoDestios = async () => {
    try {
      const requestData = {
        id_empresa: formValues.company || idUserP.company,
        id_sucursal: formValues.branch || 0,
      };

      const responseJsonCompanies = await axios.post(
        GetInfoDestinos(token),
        requestData
      );
      return responseJsonCompanies.data.data || [];
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
      return [];
    }
  };

  const handleDetail = (id) => {
    console.log("Detail for combo ID:", id);
    // Lógica para detalle
  };
  
  const handleEdit = (id) => {
    console.log("Edit for combo ID:", id);
    // Lógica para editar
  };

  // console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<slsListCombos>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

  // console.log(slsListCombos)

  const fnViewDetail = (idRegister) => {
    const detailData = slsDataDestinos.find(
      (datainfo) =>
        datainfo.id_destino === idRegister.id_destino
    );

    setModalData(detailData);
    setShowModal(true);
  };

// const fnEditRegister = (idRegister) => {
// console.log('ID recibido para editar:', idRegister); // Asegúrate de imprimir el ID aquí
// };

const fnEditRegister = (register) => {
    console.log("Registro recibido para editar:", register); // Imprime el registro completo
    setidRegistro(register.id_destino); // Guarda el ID
    setformvisible(true);
    setSelectedRegister(register); // Guarda el registro completo
    // setformvisibleAddDestini(false);
  };

const fnDeleteRegister = async (idRegister) => {
// console.log('entro para eliminar registro de destinos ')
// console.log(idRegister)

try {
      const requestData = {
        id_destino: idRegister.id_destino
      };
  
      const response = await axios.post(PostDeleteDestin(token), requestData);

      if(response.status == 201){

        addNotification("success", "Success", "Destination updated successfully.", "top-center");
        setIsReLoaded(true)
        setformvisible(false);


    }else{
        addNotification(
            "warning",
            "Partial Success",
            "Oops, there was an error during the update.",
            "top-center"
          );
          setIsReLoaded(true)
          setformvisible(false);


    }
  
} catch (error) {
  
}

};


// const filteredResults = slsListDeals
// .filter((item) =>
//   item[filterType]?.toLowerCase().includes(filterText.toLowerCase())
// );


const filteredResults = slsListDeals.filter((item) => {
    // Verificar filtros de texto
    // const matchesFilterText = item[filterType]
    //   ?.toLowerCase()
    //   .includes(filterText.toLowerCase());
  
    // Verificar filtros por empresa y sucursal
    const matchesCompany =
      !formValues.company || item.id_empresa === parseInt(formValues.company);
    const matchesBranch =
      !formValues.branch || item.id_sucursal === parseInt(formValues.branch);
  
    // Devolver true solo si todos los filtros coinciden
    return  matchesCompany && matchesBranch;
  });

  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }

  
// .sort((a, b) => {
//   const dateA = new Date(`${a.fecha_compra || "1970-01-01"}T${a.hora_compra || "00:00:00"}`);
//   const dateB = new Date(`${b.fecha_compra || "1970-01-01"}T${b.hora_compra || "00:00:00"}`);
//   return dateB - dateA; // Orden descendente (más reciente primero)
// });


// console.log('slsDataDestinos <<<<<<<<<<<<<<<')
// console.log(slsCompany)

  return (
    <div>
      
      <div className="row mb-3">
        <Panel>
          <PanelHeader>Info Bestvalue Offers</PanelHeader>
          <PanelBody>
            <div>
              {(idUserP.rol === 1 || idUserP.rol === 2) && (
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="company"
                  >
                    Companies*
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="company"
                      name="company"
                      value={formValues.company}
                      onChange={handleInputChangeCompany}
                    >
                      {slsCompany.slice(1).map((option, index) => (
                        <option
                          key={"form_" + index}
                          value={option.value_option}
                        >
                          {option.name_option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {(idUserP.rol === 1 ||
                idUserP.rol === 2 ||
                idUserP.rol === 4 ||
                idUserP.rol === 6) && (
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="branch"
                  >
                    Branches
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="branch"
                      name="branch"
                      value={formValues.branch}
                      onChange={handleInputChangeCompany}
                    >
                      {slsBranch.map((option, index) => (
                        <option key={"branch_" + index} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              *selecciona una opción de los combos activos.
            </div>

            <div className='row col-md-12'>
			<DataTableConsulting header={header} headerMap={headerMap} info={slsDataDestinos} onViewDetail={fnViewDetail} onEditRegister={fnEditRegister} onDelete={fnDeleteRegister} />
		</div>
          </PanelBody>
        </Panel>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Details  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalData ? (
            <div>
            <p> <strong>Destination ID:</strong> {modalData.id_destino} </p>
          {modalData.urlimagen && (
            <img
              src={modalData.urlimagen}
              alt={modalData.nombre}
              width="100%"
              style={{ borderRadius: "5px", marginTop: "10px", marginBottom: "10px" }}
            />
          )}
            <p> <strong>Name:</strong> {modalData.nombre} </p>
            {/* <p> <strong>Image URL:</strong> {modalData.urlimagen} </p> */}
            <p> <strong>Place:</strong> {modalData.place} </p>
            <p> <strong>State Name:</strong> {modalData.statename} </p>
            <p> <strong>Show:</strong> {modalData.show} </p>
            <p> <strong>Company ID:</strong> {modalData.id_empresa} </p>
            <p> <strong>Branch ID:</strong> {modalData.id_sucursal} </p>
            <p> <strong>Check-In:</strong> {modalData.checkin} </p>
            <p> <strong>Check-Out:</strong> {modalData.checkout} </p>
            <p> <strong>Number of People:</strong> {modalData.npeoples} </p>
            <p> <strong>Number of Rooms:</strong> {modalData.nrooms} </p>
            </div>
            ) : (
            <p>Cargando...</p>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>


<div className="row" style={!formvisible ? { display: "none" } : {}}>
  <div className="col-12">
    <Panel>
      <PanelHeader>Edit record</PanelHeader>
      <PanelBody>
        {formvisible && (
        // <EditBestValueOffers id={idRegistro} infoRegister={selectedRegister} />
        <EditDestinations id={idRegistro} infoRegister={selectedRegister} />

        
        // <></>
        )}
      </PanelBody>
    </Panel>
  </div>
</div>

      </div>
      <ReactNotifications />
    </div>
  );
}

export default BestvalueoffersView;
