// import React, { useEffect, useState } from "react";
// import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
// import { useAuth } from "./contextValid";
// import { getInfoUserByToken, getSession } from "../utils/urls.js";
// import { getFromLocalStorage } from "../components/helps.js";
// import axios from "axios";

// const PrivateRoute = ({ children }) => {
//   const { isAuthenticated, permissions, loading } = useAuth();
//   const location = useLocation();
//   const navigate = useNavigate();
//   const currentPath = location.pathname;
//   const [data, setData] = useState(null);
//   const [error, setError] = useState(null);
//   const token = getFromLocalStorage("TK");
//   const getURL = getInfoUserByToken(token);
//   const getURLSesion = getSession(token);

//   useEffect(() => {
//     axios
//       .get(getURL)
//       .then((response) => {
//         console.log("<<<<<<<<<<<<<<<<response>>>>>>>>>>>>>>>>", response);
//         if (response.data.result === "success") {
//           setData(response.data.data[0]);
//         } else {
//           setError("Error en la consulta");
//         }
//       })
//       .catch((err) => {
//         setError(err);
//       });
//   }, []);

//   useEffect(() => {
//     const controller = new AbortController();

//     const fetchUserData = async () => {
//       try {
//         const responseSesion = await axios.get(getURLSesion, {
//           signal: controller.signal,
//         });

//         // console.log('Validación de login:', responseSesion);
//       } catch (err) {
//         if (!axios.isCancel(err)) {
//           setError("Error de conexión");
//           console.log("Error de autenticación:", err);
//           navigate("/user/login-v1");
//           setData(null);
//         }
//       }
//     };

//     fetchUserData(); // Llamada inicial

//     const interval = setInterval(fetchUserData, 10 * 1000); // Llamada cada 10 segundos

//     return () => {
//       clearInterval(interval); // Limpieza del intervalo al desmontar
//       controller.abort();
//     };
//   }, [navigate]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (!isAuthenticated) {
//     return <Navigate to="/user/login-v1" state={{ from: location }} />;
//   }

//   // if (!permissions.includes(currentPath)) {
//   //     return <Navigate to="/dashboard/v0" />;
//   // }

//   return <Outlet context={{ infoUser: data }} />;
// };

// export default PrivateRoute;


/////////////////////////////////////////////   Segunda opcion para validaciones 

import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './contextValid';
import { getInfoUserByToken, getSession } from '../utils/urls.js';
import { getFromLocalStorage } from '../components/helps.js';
import axios from 'axios';
// import menus from './../../config/app-menu.jsx';
import menus from '../config/app-menu.jsx'



const PrivateRoute = ({ children }) => {
    const { isAuthenticated, loading } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const currentPath = location.pathname;
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const token = getFromLocalStorage("TK");

    const getURL = getInfoUserByToken(token);
    const getURLSesion = getSession(token);

    // Obtener los menús guardados en el LocalStorage
    // const menusToShow = getFromLocalStorage("menus") ?? [];
    // const storedMenus = getFromLocalStorage("menus");

const storedMenus = getFromLocalStorage("menus");
const menusToShow = Array.isArray(storedMenus) ? storedMenus : menus;

    // console.log("storedMenus: <<<<<<<<<<", storedMenus);
    

    // const menusToShow = storedMenus ? storedMenus : [];

    const findActiveMenu = (menus, path) => {
        for (const menu of menus) {
            if (path.startsWith(menu.path)) {
                return menu; // Si coincide con un menú principal
            }
            if (menu.children) {
                const subMenu = menu.children.find(child => path.startsWith(child.path));
                if (subMenu) {
                    return subMenu; // Si coincide con un submenú
                }
            }
        }
        return null;
    };
    
    const activeMenu = findActiveMenu(menusToShow, currentPath) || {};
    
    const permissions = {
        read: activeMenu.read ?? false,
        write: activeMenu.write ?? false,
        update: activeMenu.update ?? false,
        delete: activeMenu.delete ?? false
    };
    
    console.log('activeMenu:', activeMenu);

    useEffect(() => {
        axios.get(getURL).then(response => {
            if (response.data.result === 'success') {
                setData(response.data.data[0]);
            } else {
                setError("Error en la consulta");
            }
        }).catch(err => {
            setError(err);
        });
    }, []);

    useEffect(() => {
        const controller = new AbortController();

        const fetchUserData = async () => {
            try {
                const responseSesion = await axios.get(getURLSesion, { signal: controller.signal });
                // Validación de login en intervalos de tiempo
            } catch (err) {
                if (!axios.isCancel(err)) {
                    setError("Error de conexión");
                    console.log('Error de autenticación:', err);
                    navigate("/user/login-v1");
                    setData(null);
                }
            }
        };

        fetchUserData();

        const interval = setInterval(fetchUserData, 10 * 1000);

        return () => {
            clearInterval(interval);
            controller.abort();
        };
    }, [navigate]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/user/login-v1" state={{ from: location }} />;
    }

    // Bloquear el acceso si el usuario no tiene permisos de lectura (read)
    if (!permissions.read) {
        return <Navigate to="/dashboard/v0" />;
    }

    return <Outlet context={{ infoUser: data, permissions}} />;
};

export default PrivateRoute;


/////////////////////////////////////////////   Tercera opcion para validaciones 

