import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import {
  allRatePointsCompany,
  NewRatePoint,
  EditRatePoint,
  DeleteRatePoint,
} from "./../../utils/urls.js";

const arraycompanys = [
  { idcompany: 1, namecompany: "Tukmeintest" },
  { idcompany: 2, namecompany: "Tixstest" },
];

function ViewRatePoints() {
  const [NameCompany, setNameCompany] = useState(
    arraycompanys[0].idcompany.toString()
  );
  const [Percentage, setPercentage] = useState(0);
  const [Description, setDescription] = useState("");
  const [RatePointsCompany, setRatePointsCompany] = useState([]);
  const [statusChanges, setStatusChanges] = useState([]);

  useEffect(() => {
    fetch(allRatePointsCompany(1))
      .then((response) => response.json())
      .then((data) => {
        setRatePointsCompany(data);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleOnChange = (idRatePoints, companyId) => {
    const newRatePointsCompany = RatePointsCompany.map((company) => {
      if (company.id_company === companyId) {
        return {
          ...company,
          isactive: company.id_rate_points === idRatePoints ? true : false,
        };
      }
      return company;
    });

    setRatePointsCompany(newRatePointsCompany);

    const newStatusChanges = newRatePointsCompany
      .filter((company) => company.id_company === companyId)
      .map((company) => ({
        id_rate_points: company.id_rate_points,
        isactive: company.id_rate_points === idRatePoints ? true : false,
      }));

    setStatusChanges(newStatusChanges);
  };

  const handleUpdate = (companyId, newdateflags) => {
    const changes = RatePointsCompany.filter(
      (company) => company.id_company === companyId
    ); // Filtrar solo los cambios de la misma compañía
  
    // Iterar sobre cada cambio y enviarlo individualmente
    newdateflags.forEach((change) => {

      const url = EditRatePoint();

      const data = {
        id_rate_points: change.id_rate_points,
        isActive: change.isactive
      };

     //console.log('valores de actualizacion <<<')
     //console.log(data)

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
         //console.log(data);
        })
        .catch((error) => console.error("Error:", error));
    });
  };
  

  const handleDelete = (id) => {
    setRatePointsCompany((prevState) =>
      prevState.filter((company) => company.id_rate_points !== id)
    );

    fetch(DeleteRatePoint(id))
      .then((response) => response.json())
      .then((data) => {
       //console.log(data);
      })
      .catch((error) => console.error(error));
  };

  const handleOnChangeCreateRegist = () => {
    const selectedCompany = arraycompanys.find(
      (company) => company.idcompany.toString() === NameCompany
    );

    if (!selectedCompany) {
      console.error("Company not found");
      return;
    }

    const datanewReg = {
      namecompany: selectedCompany.namecompany,
      id_company: selectedCompany.idcompany,
      percentage: Percentage,
      descriptionuse: Description,
    };

    const urlnew = NewRatePoint();

    fetch(urlnew, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datanewReg),
    })
      .then((response) => response.json())
      .then((data) => {
       //console.log("respuesta de insert");
       //console.log(data);

        setRatePointsCompany((prevState) => [...prevState, data]);

        setNameCompany(arraycompanys[0].idcompany.toString());
        setPercentage(0);
        setDescription("");
      })
      .catch((error) => console.error("Error:", error));
  };

  // Agrupar por id_company
  const groupedRatePoints = RatePointsCompany.reduce((acc, curr) => {
    (acc[curr.id_company] = acc[curr.id_company] || []).push(curr);
    return acc;
  }, {});


 //console.log('banderas a actualizar ')

 //console.log(statusChanges)

  return (
    <div>
      <div className="row mb-3">
        <div className="col-xl-6">
          <Panel>
            <PanelHeader>Add New Percentage</PanelHeader>
            <PanelBody>
              <form>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Name Company
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select"
                      value={NameCompany}
                      onChange={(e) => setNameCompany(e.target.value)}
                    >
                      {arraycompanys.map((company) => (
                        <option
                          key={company.idcompany}
                          value={company.idcompany}
                        >
                          {company.namecompany}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    new Value %
                  </label>
                  <div className="col-md-9">
                    <input
                      type="number"
                      className="form-control mb-5px"
                      value={Percentage}
                      onChange={(e) => setPercentage(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    description use
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control mb-5px"
                      value={Description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-7 offset-md-3">
                    <button
                      type="button"
                      className="btn btn-primary w-100px me-5px"
                      onClick={handleOnChangeCreateRegist}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>

      <div className="row">
        {Object.entries(groupedRatePoints).map(([companyId, ratePoints]) => (
          <Panel key={companyId}>
            <PanelHeader>
              Percentage Rate Points for Company {companyId}
              <button
                type="submit"
                className="badge bg-success ms-7px"
                onClick={() => handleUpdate(companyId, statusChanges)} // Pasar el ID de la compañía a handleUpdate
              >
                Save Update
              </button>
            </PanelHeader>
            <PanelBody>
              <div className="table-responsive">
                <table className="table table-striped mb-0 align-middle">
                  <thead>
                    <tr>
                      <th>id</th>
                      <th>Company</th>
                      <th>percentage value</th>
                      <th>description use</th>
                      <th>isActive</th>
                      <th> </th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ratePoints.map((company) => (
                      <tr key={company.id_rate_points}>
                        <td>{company.id_rate_points}</td>
                        <td>{company.namecompany}</td>
                        <td>{company.percentage}%</td>
                        <td>{company.descriptionuse}</td>
                        <td>
                          <div className="form-check form-switch mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={() =>
                                handleOnChange(
                                  company.id_rate_points,
                                  company.id_company
                                )
                              }
                              id={`flexSwitchCheckDefault-${company.id_rate_points}`}
                              checked={company.isactive}
                            />
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <div className="col-md-7 offset-md-3">
                            <button
                              type="button"
                              className="btn btn-sm btn-white w-60px"
                              onClick={() =>
                                handleDelete(company.id_rate_points)
                              }
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </PanelBody>
          </Panel>
        ))}
      </div>
    </div>
  );
}

export default ViewRatePoints;
