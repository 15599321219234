import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import Chart from "react-apexcharts";
import { infoMapping, executerequestMapping } from "./../../utils/urls.js";
import { ReactNotifications, Store } from "react-notifications-component";
import { Countrys } from "../../components/arraysinfo.jsx";
import axios from "axios";
import { infoCityes, infoStates } from "../../utils/urls.js";

function InfoMapping() {
  const [tipoFiltro, setTipoFiltro] = useState("0");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [amount, setAmount] = useState(500);

  const [Totalnuitee, setTotalnuitee] = useState(0);
  const [successfullMark, setsuccessfullMark] = useState(0);
  const [FailedMark, setFailedMark] = useState(0);
  const [pendingProcess, setpendingProcess] = useState(false);

  const [Citys, setCitys] = useState([]);
  const [States, setStates] = useState([]);
  const [Countries, setCountries] = useState(Countrys);

  const [selectcountry, setselectcountry] = useState("");
  const [selectstate, setselectstate] = useState("");
  const [selectcity, setselectcity] = useState("");


  useEffect(() => {
    fetch(infoMapping())
      .then((response) => response.json())
      .then((data) => {
        setTotalnuitee(data.nuiteetotal);
        setsuccessfullMark(data.successfulMark);
        setFailedMark(data.faileMark);
        setpendingProcess(data.pendingProcess);
      })
      .catch((error) => console.error(error));
  }, []);

  // Agrupar por id_company
  var pieChartSeries = [successfullMark, FailedMark, pendingProcess];

  var pieChartOptions = {
    labels: ["successfull MarkCorps", "failed MarkCorps", "pending process"],
    chart: { type: "donut" },
    dataLabels: { dropShadow: { enabled: false }, style: { colors: ["#fff"] } },
    stroke: { show: false },
    colors: ["#3DDA71", "#B91407", "#6774FA"],
    legend: { show: false },
  };

  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 4000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }

  const executerequest = async (typesearch, amount) => {
    const urlmaping = executerequestMapping();
    const typeSearchNum = Number(typesearch);

    let data = {
      typesearch: 0,
      textsearch: '',
      quantity: 0,
    };

    switch (typesearch) {
      case "1":
        data = {
          typesearch: typeSearchNum,
          textsearch: selectstate,
          quantity: amount,
        };
        break;
        case "2":
          data = {
            typesearch: typeSearchNum,
            textsearch: selectcountry,
            quantity: amount,
          };
          break;
          case "3":
            data = {
              typesearch: typeSearchNum,
              textsearch: selectcity,
              quantity: amount,
            };
            break;
    
      default:
        break;
    }

    try {

     //console.log("body para peticion <<<<<<<<<<<<<<<<");
       //console.log(data);

        const response = await fetch(urlmaping, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        addNotification(
          "success",
          "Success",
          "Mapping has started",
          "top-center",
          false
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
         await response.json();
       //console.log(responseData);

      
    } catch (error) {
      console.error("Error during the request:", error);

    }
  };

  const handleCountry = async (value) => {
    setCountry(value);
   //console.log("Country selected:", value);
    setselectcountry(value);
  
    if (tipoFiltro === "1" || tipoFiltro === "3") {
      try {
        const response = await axios.get(infoStates(value));
        const arrayinfoStates = response.data;
        setStates(arrayinfoStates);
      } catch (error) {
        console.error('Error fetching states:', error);
        setStates([]); // Manejar el error adecuadamente según tu lógica
      }
    } else {
      // Limpiar el listado de estados si no es necesario
      setStates([]);
    }
  };

  const handleStateProvince = async (value) => {
    setState(value);
   //console.log("Stateprovince selected:", value);
    setselectstate(value); 
  
    if (tipoFiltro === "3") {
      try {
        const response = await axios.get(infoCityes(value));
        const arrayCities = response.data;
        setCitys(arrayCities);
      } catch (error) {
        console.error('Error fetching cities:', error);
        setCitys([]); // Manejar el error adecuadamente según tu lógica
      }
    } else {
      // Limpiar el listado de ciudades si no es necesario
      setCitys([]);
    }
  };

  const handleCity = (value) => {
    setCity(value);
   //console.log("city select ", value);
    setselectcity(value);

  };

  console.log('Countries <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<')
  console.log(Countries)

  console.log('States <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<')
  console.log(States)

  console.log('Citys <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<')
  console.log(Citys)

  return (
    <div>
      <div className="row mb-3">
        <div className="col-xl-6">
          <Panel>
            <PanelHeader>Run mapping</PanelHeader>

            <PanelBody>
              <form>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Type Search
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select"
                      value={tipoFiltro}
                      onChange={(e) => setTipoFiltro(e.target.value)}
                    >
                      <option value="0"> </option>
                      <option value="1">stateprovince</option>
                      <option value="2">country</option>
                      <option value="3">city</option>
                      <option value="4">ordenado asc</option>
                    </select>
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Country
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select"
                      value={country}
                      onChange={(e) => handleCountry(e.target.value)}
                      disabled={
                        tipoFiltro !== "1" &&
                        tipoFiltro !== "2" &&
                        tipoFiltro !== "3"
                      }
                    >
                      <option value="" disabled>
                        Select a country
                      </option>
                      {Countries.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Stateprovince
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select"
                      value={state}
                      onChange={(e) => handleStateProvince(e.target.value)}
                      disabled={tipoFiltro !== "1" && tipoFiltro !== "3"}
                    >
                      <option value="" disabled>
                        Select StateProvince
                      </option>
                      {States.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    City
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select"
                      value={city}
                      onChange={(e) => handleCity(e.target.value)}
                      disabled={tipoFiltro !== "3"}
                    >
                      <option value="" disabled>
                        Select City
                      </option>
                      {Citys.map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Amount
                  </label>
                  <div className="col-md-9">
                    <input
                      type="number"
                      className="form-control mb-5px"
                      value={amount}
                      onChange={(e) => setAmount(Number(e.target.value))}
                      disabled={tipoFiltro !== "1" &&
                        tipoFiltro !== "2" &&
                        tipoFiltro !== "3" && tipoFiltro !== "4"}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-7 offset-md-3">
                    <button
                      type="button"
                      className="btn btn-primary w-100px me-5px"
                      onClick={() =>
                        executerequest(tipoFiltro, amount)
                      }
                    >
                      Run
                    </button>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
          <br></br>
          <div className="col-xl-8">
            <div className="widget-chart with-sidebar inverse-mode">
              <div className="widget-chart-sidebar bg-gray-900">
                <div className="chart-number">
                  {Totalnuitee.toLocaleString()}
                  <small>Total hotels Nuitee</small>
                </div>
                <div className="flex-grow-1 d-flex align-items-center dark-mode">
                  <Chart
                    type="donut"
                    height={"180"}
                    options={pieChartOptions}
                    series={pieChartSeries}
                  />
                </div>
                <ul className="chart-legend fs-11px">
                  <li>
                    <i
                      className="fa fa-circle fa-fw fs-9px me-5px t-minus-1"
                      style={{ color: "#3DDA71" }}
                    ></i>{" "}
                    <span>successfull MarkCorps</span>
                  </li>
                  <li>
                    <i
                      className="fa fa-circle fa-fw fs-9px me-5px t-minus-1"
                      style={{ color: "#B91407" }}
                    ></i>{" "}
                    <span>failed MarkCorps</span>
                  </li>
                  <li>
                    <i
                      className="fa fa-circle fa-fw fs-9px me-5px t-minus-1"
                      style={{ color: "#6774FA" }}
                    ></i>{" "}
                    <span>pending process</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactNotifications />{" "}
      {/* Ensure ReactNotifications component is included */}
    </div>
  );
}

export default InfoMapping;
