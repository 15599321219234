import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { createnewfilter } from "./../../utils/urls.js";

function NewFilterElements() {
    // const [code1, setCode1] = useState();
    // const [code2, setCode2] = useState();
    // const [code3, setCode3] = useState();
    // const [code4, setCode4] = useState();
    // const [code5, setCode5] = useState();
    // const [code6, setCode6] = useState();
    // const [code7, setCode7] = useState();
    // const [code8, setCode8] = useState();
    // const [code9, setCode9] = useState();
    // const [code10, setCode10] = useState();
    // const [code11, setCode11] = useState();
    // const [code12, setCode12] = useState();
    // const [code13, setCode13] = useState();
    const [tipoFiltro, setTipoFiltro] = useState("1");
    const [comentarioTipo, setComentarioTipo] = useState("");
    const [nameFiltro, setNameFiltro] = useState("");
    const [isChecked, setIsChecked] = useState(true);
    const [idEvento, setIdEvento] = useState("");

    const handleOnChange = (e) => {
        const newValue = e.target.checked;
        setIsChecked(newValue);
       //console.log("Switch value:", newValue);
    };

    // useEffect(() => {
    //     fetch('/assets/data/form-elements/code-1.json').then(response => response.text()).then(html => { setCode1(html); });
    //     fetch('/assets/data/form-elements/code-2.json').then(response => response.text()).then(html => { setCode2(html); });
    //     fetch('/assets/data/form-elements/code-3.json').then(response => response.text()).then(html => { setCode3(html); });
    //     fetch('/assets/data/form-elements/code-4.json').then(response => response.text()).then(html => { setCode4(html); });
    //     fetch('/assets/data/form-elements/code-5.json').then(response => response.text()).then(html => { setCode5(html); });
    //     fetch('/assets/data/form-elements/code-6.json').then(response => response.text()).then(html => { setCode6(html); });
    //     fetch('/assets/data/form-elements/code-7.json').then(response => response.text()).then(html => { setCode7(html); });
    //     fetch('/assets/data/form-elements/code-8.json').then(response => response.text()).then(html => { setCode8(html); });
    //     fetch('/assets/data/form-elements/code-9.json').then(response => response.text()).then(html => { setCode9(html); });
    //     fetch('/assets/data/form-elements/code-10.json').then(response => response.text()).then(html => { setCode10(html); });
    //     fetch('/assets/data/form-elements/code-11.json').then(response => response.text()).then(html => { setCode11(html); });
    //     fetch('/assets/data/form-elements/code-12.json').then(response => response.text()).then(html => { setCode12(html); });
    //     fetch('/assets/data/form-elements/code-13.json').then(response => response.text()).then(html => { setCode13(html); });
    // }, []);

    function handleOnChangeCreateRegist(e) {
        e.preventDefault(); // Prevenir el comportamiento por defecto del formulario
       //console.log("entro en la funcion");

        fetch(createnewfilter(tipoFiltro, comentarioTipo, nameFiltro, isChecked, idEvento))
            .then(response => response.json())
            .then(data => {
               //console.log('respuesta de crear registro');
               //console.log(data);

                // Resetear las variables de estado
                setTipoFiltro("1");
                setComentarioTipo("");
                setNameFiltro("");
                setIsChecked(true);
                setIdEvento("");

                // Mostrar mensaje de éxito al usuario
                alert("El filtro se ha creado exitosamente.");
            })
            .catch(error => {
                console.error(error);
                alert("Hubo un error al crear el filtro. Por favor, intente nuevamente.");
            });
    }

    return (
        <div>
            <ol className="breadcrumb float-xl-end">
                <li className="breadcrumb-item"><Link to="/form/elements">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/form/elements">Form Stuff</Link></li>
                <li className="breadcrumb-item active">Form Elements</li>
            </ol>
            <h1 className="page-header">Form Elements <small>header small text goes here...</small></h1>
        
            <div className="row mb-3">
                <div className="col-xl-6">
                    <Panel>
                        <PanelHeader>Crear nuevo filtro</PanelHeader>
                        <PanelBody>
                            <form onSubmit={handleOnChangeCreateRegist}>
                                <div className="row mb-15px">
                                    <label className="form-label col-form-label col-md-3">Tipo filtro</label>
                                    <div className="col-md-9">
                                        <select
                                            className="form-select"
                                            value={tipoFiltro}
                                            onChange={(e) => setTipoFiltro(e.target.value)}
                                        >
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="row mb-15px">
                                    <label className="form-label col-form-label col-md-3">comentario tipe</label>
                                    <div className="col-md-9">
                                        <input
                                            type="text"
                                            className="form-control mb-5px"
                                            placeholder="comentariotipe"
                                            value={comentarioTipo}
                                            onChange={(e) => setComentarioTipo(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-15px">
                                    <label className="form-label col-form-label col-md-3">name filtro</label>
                                    <div className="col-md-9">
                                        <input
                                            type="text"
                                            className="form-control mb-5px"
                                            placeholder="namefiltro"
                                            value={nameFiltro}
                                            onChange={(e) => setNameFiltro(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <label className="form-label col-form-label col-md-3">activo filtro</label>

                                <div className="form-check form-switch mb-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        onChange={handleOnChange}
                                        id="flexSwitchCheckDefault"
                                        checked={isChecked}
                                    />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Active</label>
                                </div>

                                <div className="row mb-15px">
                                    <label className="form-label col-form-label col-md-3">idevento</label>
                                    <div className="col-md-9">
                                        <input
                                            type="text"
                                            className="form-control mb-5px"
                                            placeholder="idevento"
                                            value={idEvento}
                                            onChange={(e) => setIdEvento(e.target.value)}
                                        />
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-7 offset-md-3">
                                        <button type="submit" className="btn btn-primary w-100px me-5px">Crear</button>
                                        {/* <button type="button" className="btn btn-default w-100px">Cancel</button> */}
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    );
}

export default NewFilterElements;
