import React, { useEffect, useContext, useState } from 'react';
import { saveToLocalStorage } from '../../components/helps.js';
import { AppSettings } from './../../config/app-settings.js';
import axios from 'axios';
import { recoverAcces } from '../../utils/urls.js';
import ReCAPTCHA from "react-google-recaptcha";
import { ReactNotifications, Store } from 'react-notifications-component';
import CryptoJS from 'crypto-js';
import { Navigate } from 'react-router-dom';


const PasswordRecovery = () => {
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [captcha, setCaptcha] = useState('');
    const context = useContext(AppSettings);
    useEffect(() => {
        context.handleSetAppHeaderNone(true);
        context.handleSetAppSidebarNone(true);
        context.handleSetAppContentClass('p-0');
        saveToLocalStorage('TK', '');
    }, []);



    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const encryptData = (data) => {
        return CryptoJS.AES.encrypt(data, secretKey).toString();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (!email || !company || !captcha) {
                addNotification('error', 'Error', 'Todos los campos son obligatorios', 'top-center', false);
                return;
            }
            const emailRex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRex.test(email)) {
                addNotification('warning', 'Atencion', 'Por favor, ingresa un correo electrónico válido.', 'top-center', false);
                return;
            }

            const response = await axios.post(recoverAcces(), {
                email: encryptData(email),
                company: encryptData(company),
                captcha: encryptData(captcha)
            });
           //console.log(response);
            if(response.status=== 201){
                addNotification('warning', 'Atencion', response.data.message + " ---- " + response.data.data);
                return <Navigate to='/user/login-v1' />;
            }
        } catch (error) {
            addNotification('error', 'Error', 'Error: ' + error.response.data.message);
        }
    };
    function onChange(e) {
        if (e) {
            setCaptcha(e);
        }
    }
    function addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition, notificationContent) {
        if (notificationContent) {
            notificationContent = (
                <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
                    <div className="flex-1 ps-2">
                        <h6 className="mb-1">Christopher Struth</h6>
                        <p className="mb-0">Bank Transfer</p>
                    </div>
                </div>
            );
        }
        notificationPosition = notificationPosition != undefined ? notificationPosition : 'top-center';
        notificationContent = notificationContent != undefined ? notificationContent : false;
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: "top",
            container: notificationPosition,
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 4000 },
            dismissable: { click: true },
            content: notificationContent
        });
    }
    return (
        <div className="login login-v1">
            <div className="login-container">
                <div className="login-header">
                    <div className="brand">
                        <div className="d-flex align-items-center">
                            <span className="logo"></span> <b>Color</b> Admin
                        </div>
                        {/* <small>Bootstrap 5 Responsive Admin Template</small> */}
                    </div>
                    <div className="icon">
                        <i className="fa fa-lock"></i>
                    </div>
                </div>
                <div className="login-body">
                    <div className="login-content fs-13px">
                        <form onSubmit={handleSubmit}>
                            <div className="form-floating mb-20px">
                                <input type="email" className="form-control fs-13px h-45px" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                <label htmlFor="emailAddress" className="d-flex align-items-center py-0">Email Address</label>
                            </div>
                            <div className="form-floating mb-20px">
                                <input type="text" className="form-control fs-13px h-45px" value={company} onChange={(e) => setCompany(e.target.value)} required />
                                <label htmlFor="emailAddress" className="d-flex align-items-center py-0">Compani Name</label>
                            </div>
                            <div className="form-floating mb-20px">
                                <ReCAPTCHA sitekey="6LdObjUqAAAAAN8y8xtIzaaoxoNKon3mP9AGo60_" onChange={onChange} />
                            </div>
                            <div className="login-buttons">
                                <button type="submit" className="btn h-45px btn-theme d-block w-100 btn-lg">Verify</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ReactNotifications /> {/* Ensure ReactNotifications component is included */}

        </div>

    );
};

export default PasswordRecovery;
