import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications, Store } from "react-notifications-component";
import {
  FormsTypeusers,
  getAllCompanies,
  getRoles,
  getBranchDataByCompany,
  getAllBranch,
  CreateUser,
  UsersbyCompany,
} from "../../utils/urls.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";

function RegUsersElements() {
  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };
  const initSelec3 = { name: '', lastname: '', email: '', userId: '', rol: ''}
  let token = getFromLocalStorage("TK");

  const [isLoaded, setIsLoaded] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    lastname: "",
    email: "",
    password: "",
    confPassword: "", // Cambié Confpassword a confPassword
    role: "",
    company: "",
    branch: "",
  });
  const [data, setData] = useState([]);

  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsRoll, setslsRoll] = useState([initSelec2]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);
  const [slsUsersbyComp, setslsUsersbyComp] = useState([initSelec3]);

  const [tableValues, setTableValues] = useState({ company: '' });

  useEffect(() => {
    const fetchLoadCombos = async () => {
      try {
        if (!isLoaded) {
          if (token === undefined || token === null) {
            token = getFromLocalStorage("TK");
          }
          let selectCombos = [initSelec, ...(await fnGetCompanies())];
          let selectCombosRol = [initSelec2, ...(await fnGetRolls())];

          setslsCompany(selectCombos);
          setslsRoll(selectCombosRol);
        }
      } catch (error) {
        console.error("Error al cargar los combos ", error);
      }
    };
    fetchLoadCombos();
    setIsLoaded(true);
  }, [isLoaded]);

  useEffect(() => {
    if (formValues.company) {
      const fetchBranches = async () => {
        const branches = await fnGetAllBranch();
        setslsBranches([initSelec2, ...branches]);
      };
      fetchBranches();
    }
  }, [formValues.company]);

  useEffect(() => {
    if (tableValues) {
      const fetchUsers = async () => {
        const resutlinfo = await PostUserbyCompan(tableValues);

        setslsUsersbyComp([initSelec3, ...resutlinfo]);
      };
      fetchUsers();
    }
  }, [tableValues]);

  const fnGetCompanies = async () => {
    try {
      const responseJsonCompanies = await axios.get(getAllCompanies(token));
      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos  11", error);
    }
  };

  const fnGetRolls = async () => {
    try {
      const responseJsonCompanies = await axios.get(getRoles(token));
      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 22", error);
    }
  };

  const fnGetAllBranch = async () => {
    try {
      const requestData = {
        companyId: formValues.company,  
      };
  
      const responseJsonCompanies = await axios.post(getBranchDataByCompany(token), requestData);
      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const PostUserbyCompan = async (companyid) => {
    try {
      const requestData = {
        companyId: companyid,  
      };

      console.log('<<<<<<<requestData>>>>>>>')

      console.log(requestData)
  
      const responseJsonCompanies = await axios.post(UsersbyCompany(token), requestData);
      console.log(responseJsonCompanies.data.data)

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      ...(name === "company" && { branch: "" }), // Reiniciar branch si cambia la compañía
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, lastname, email, password, confPassword, role, company, branch } = formValues;
    console.log('name, lastname, email, password, confPassword, company')

    console.log(name, lastname, email, password, confPassword, company)

    if (!name || !lastname || !email || !password || !confPassword || !company) {
      addNotification("danger", "Error", "All fields are required", "top-right");
      return;
    }
  
    if (password !== confPassword) {
      addNotification("danger", "Error", "Passwords do not match", "top-right");
      return;
    }
  
    try {
      const response = await axios.post(CreateUser(), {
        name,
        lastname,
        email,
        pwd: password,
        id_rol: Number(role),
        compañía: company,
        id_sucursal: Number(branch),
      });
      console.log('User created successfully:', response.data);

      addNotification("success", "Success", "User created successfully", "top-right");
      setFormValues({
        name: '',
        lastname: '',
        email: '',
        password: '',
        confPassword: '',
        role: '',
        company: '',
        branch: ''
      });
    } catch (error) {
      console.error("Error creating user:", error);
      addNotification("danger", "Error", "Failed to create user", "top-right");
    }
  };
  

  function addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition) {
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
    });
  }

  const handleSelectChange = (event) => {
    const selectedCompany = event.target.value;

    console.log('<<<<<<<<<<<selectedCompany>>>>>>>>>>>')
    console.log(selectedCompany)

    setTableValues(selectedCompany)
    //  setTableValues((prevValues) => ({ ...prevValues, company: selectedCompany }));
    
    // Llama a la función con el companyId seleccionado
    // PostUserbyCompan(selectedCompany);
  };


  console.log("<<<<<<<<<<<<<<<<  slsUsersbyComp   >>>>>>>>>>>>>>>>");
  console.log(slsUsersbyComp);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-xl-6">
          <Panel>
            <PanelHeader>Registers New Users</PanelHeader>

            <PanelBody>
            <form onSubmit={handleSubmit}>
      <div className="row mb-3 d-flex align-items-center">
        <label className="form-label col-form-label col-md-3 mb-0" htmlFor="name">Name*</label>
        <div className="col-md-9">
          <input
            className="form-control"
            type="text"
            id="name"
            name="name"
            value={formValues.name}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="row mb-3 d-flex align-items-center">
        <label className="form-label col-form-label col-md-3 mb-0" htmlFor="lastname">Lastname*</label>
        <div className="col-md-9">
          <input
            className="form-control"
            type="text"
            id="lastname"
            name="lastname"
            value={formValues.lastname}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="row mb-3 d-flex align-items-center">
        <label className="form-label col-form-label col-md-3 mb-0" htmlFor="email">Email*</label>
        <div className="col-md-9">
          <input
            className="form-control"
            type="email"
            id="email"
            name="email"
            value={formValues.email}
            onChange={handleInputChange}
          />
        </div>
      </div>

 {/* Password */}
 <div className="row mb-3 d-flex align-items-center">
                <label className="form-label col-form-label col-md-3 mb-0" htmlFor="password">Password*</label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    type="password"
                    id="password"
                    name="password"
                    value={formValues.password}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {/* Confirm Password */}
              <div className="row mb-3 d-flex align-items-center">
                <label className="form-label col-form-label col-md-3 mb-0" htmlFor="confPassword">Confirm Password*</label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    type="password"
                    id="confPassword"
                    name="confPassword"
                    value={formValues.confPassword}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

      {/* Resto de select de roles, compañías y sucursales */}
      <div className="row md-15px">
        <label className="form-label col-form-label col-md-3" htmlFor="role">Types of role</label>
        <div className="col-md-9">
          <select
            className="form-select mb-5px"
            id="role"
            name="role"
            value={formValues.role}
            onChange={handleInputChange}
          >
            {slsRoll
              .filter((option) => option.value !== 8 && option.value !== 1)
              .map((option, index) => (
                <option key={"form_" + index} value={option.value}>{option.text}</option>
              ))}
          </select>
        </div>
      </div>

      <div className="row md-15px">
        <label className="form-label col-form-label col-md-3" htmlFor="company">Companies*</label>
        <div className="col-md-9">
          <select
            className="form-select mb-5px"
            id="company"
            name="company"
            value={formValues.company}
            onChange={handleInputChange}
          >
            {slsCompany.map((option, index) => (
              <option key={"form_" + index} value={option.value_option}>{option.name_option}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="row md-15px">
        <label className="form-label col-form-label col-md-3" htmlFor="branch">Branches</label>
        <div className="col-md-9">
          <select
            className="form-select mb-5px"
            id="branch"
            name="branch"
            value={formValues.branch}
            onChange={handleInputChange}
          >
            {slsBranch.map((option, index) => (
              <option key={"form_" + index} value={option.value}>{option.text}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="lrow md-15px" style={{ margin: 10 }}>
        <button type="submit" className="btn btn-green" style={{ float: "right" }}>
          Guardar
        </button>
      </div>
    </form>

            </PanelBody>
          </Panel>
        </div>

        <div className="col-xl-6">
          <Panel>
            <PanelHeader>List of users by company</PanelHeader>

            <PanelBody>
  <div className="row md-15px">
    <label
      className="form-label col-form-label col-md-3 small-text"
      htmlFor="slForms"
    >
      Companies
    </label>
    <div className="col-md-9">
      <select
        className="form-select mb-5px small-text"
        id="slForms"
        name="type"
        onChange={handleSelectChange}
      >
        {slsCompany.map((option, index) => (
          <option key={"form_" + index} value={option.value_option}>
            {option.name_option}
          </option>
        ))}
      </select>
    </div>
  </div>

  <table className="table table-bordered small-text">
    <thead className="thead-dark">
      <tr>
        <th>Name</th>
        <th>Lastname</th>
        <th>email</th>
        <th>UserId</th>
        <th>Rol</th>
      </tr>
    </thead>
    <tbody>
      {slsUsersbyComp.map((data, index) => (
        <tr key={index}>
          <td>{data.name}</td>
          <td>{data.lastname}</td>
          <td>{data.email}</td>
          <td>{data.userId}</td>
          <td>{data.rol}</td>
        </tr>
      ))}
    </tbody>
  </table>
</PanelBody>


          </Panel>
        </div>
      </div>
      <ReactNotifications />{" "}
    </div>
  );
}

export default RegUsersElements;
