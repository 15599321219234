import React, { useState } from "react";
import { Panel, PanelHeader } from "./../../components/panel/panel.jsx";
import {
  infoCityestrue,
  infoStatestrue,
  postInfoHotelsMaped,
} from "./../../utils/urls.js";
import { Countrys } from "../../components/arraysinfo.jsx";
import axios from "axios";

function ViewFiltersMaped() {
  const [arrayResult, setArrayResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  // const [currentPage, setCurrentPage] = useState(1);
  const [Countries, setCountries] = useState(Countrys);

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [Citys, setCitys] = useState([]);
  const [States, setStates] = useState([]);
  const [statusapi, setstatusapi] = useState(false);

  const closeModal = () => {
    setSelectedCity(null);
  };

  const harldClick = async () => {
    //console.log("iniciar busqueda de ciudades o stateprovince ");

    let data = {
      country: country,
      stateprovince: state,
      city: city,
    };

    //console.log("<<<<<<<<<<<<<<<<<<<<<<< data a mandar >>>>>>>>>>>>>>>>>>>>>>>");

    //console.log(data);

    try {
      setLoading(true);
      const response = await fetch(postInfoHotelsMaped(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseData = await response.json();
      //console.log(responseData);
      setArrayResult(responseData);
      setLoading(false);
    } catch (error) {
      console.error("Error during the request:", error);
    }
  };

  const handleCountry = async (value) => {
    //console.log(value);
    setCountry(value);

    const response = await axios.get(infoStatestrue(value));
    const arrayinfoStates = response.data;
    setStates(arrayinfoStates);
  };

  const handleStateProvince = async (value) => {
    setState(value);
    //console.log(value);

    const response = await axios.get(infoCityestrue(value));
    const arrayCities = response.data;
    setCitys(arrayCities);
  };

  const handleCity = (value) => {
    setCity(value);
    //console.log(value);
  };

  const openModal = (city) => {
    setSelectedCity(city);
  };

  return (
    <div>
      <div className="row mb-3">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader>Info mapped cities</PanelHeader>
            <br />
            <div
              className="filter-container"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                flexWrap: "wrap",
              }}
            >
              <select
                className="filter-container"
                value={country}
                onChange={(e) => handleCountry(e.target.value)}
              >
                <option value="" disabled>
                  Select a country
                </option>
                {Countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>

              <select
                className="filter-container"
                value={state}
                onChange={(e) => handleStateProvince(e.target.value)}
              >
                <option value="" disabled>
                  Select StateProvince
                </option>
                <option value="">-- All States --</option>
                {States.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>

              <select
                className="filter-container"
                value={city}
                onChange={(e) => handleCity(e.target.value)}
              >
                <option value="" disabled>
                  Select City
                </option>
                <option value="">-- All Cities --</option>
                {Citys.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>

              <button
                type="button"
                className="btn btn-secondary"
                onClick={harldClick}
              >
                Search
              </button>
            </div>

            <br />

            <div style={{ maxHeight: "1000px", overflowY: "auto" }}>
              {loading ? (
                <p style={{ textAlign: "center", fontSize: "2.5em" }}>
                  Loading...
                </p>
              ) : (
                <table className="table table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th>City</th>
                      <th>Id City</th>
                      <th>Country</th>
                      <th>N° hotels mapped</th>
                      <th>Full mapped</th>
                      <th>both providers</th>
                      <th>onlyNuitee</th>
                      <th>unmapped</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arrayResult.map((cityInfo, index) => (
                      <tr key={index}>
                        <td>{cityInfo.city}</td>
                        <td>{cityInfo.idcity}</td>
                        <td>{cityInfo.country}</td>
                        <td>
                          {cityInfo.countHotels}{" "}
                          <a href="#" onClick={() => openModal(cityInfo)}>
                            view.
                          </a>
                        </td>
                        <td>
                          {cityInfo.fullmapped ? (
                            <img
                              src="/assets/img/icons/approved.png"
                              width="25"
                              alt=""
                              className="rounded"
                            />
                          ) : (
                            <img
                              src="/assets/img/icons/rejected.png"
                              width="25"
                              alt=""
                              className="rounded"
                            />
                          )}
                        </td>
                        <td>{cityInfo.countHotelsComplete}</td>
                        <td>{cityInfo.countonlyNuitee}</td>
                        <td>{cityInfo.unmapped}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </Panel>
        </div>
      </div>

      {selectedCity && (
        <div
          className="modal"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div style={{ width: "85%", margin: "80px auto" }}>
            <div className="modal-content">
              <div
                className="modal-header"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h5 className="modal-title">Hotels in {selectedCity.city}</h5>
                <button type="button" className="close" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th>Hotel ID</th>
                        <th>Name</th>
                        <th>stateprovince</th>
                        <th>Address</th>
                        <th>Latitude</th>
                        <th>Longitude</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedCity.arrayinfohotels.map((hotel, index) => (
                        <tr key={index}>
                          <td>{hotel.hotelid}</td>
                          <td>{hotel.name}</td>
                          <td>{hotel.country}</td>
                          <td>{hotel.address1}</td>
                          <td>{hotel.latitude}</td>
                          <td>{hotel.longitude}</td>
                          <td>
                            {hotel.status === 1 ? (
                              <span style={{ color: "green" }}>🟢</span>
                            ) : hotel.status === 2 ? (
                              <span style={{ color: "yellow" }}>🟡</span>
                            ) : hotel.status === 3 ? (
                              <span style={{ color: "red" }}>🔴</span>
                            ) : (
                              "Unknown"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewFiltersMaped;
