const urlBackExperi = 'https://www.testunit00.co/';


export function getListhotelsview() {
    return `${urlBackExperi}bestvalueoffers/bvobyidclient?idcliente=12345`;
  
  }

  export function deleteHotelSelect(idbvo) {
    return `${urlBackExperi}bestvalueoffers/updatebvo?idbvo=${idbvo}`;
  
  }
