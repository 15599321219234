import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useOutletContext } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import {
  getListEventsAng,
  getloadeventsNews,
  postAllCompaniesRoll,
  getBranchDataByCompany,
  getrdallsouvenir,
  postaddsouvenir,
  getDeletesouvenir,
} from "../../utils/urls.js";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "../../components/helps.js";
import { ReactNotifications, Store } from "react-notifications-component";
import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";


function DataSouvenirs() {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");
  const { infoUser, permissions } = useOutletContext();
  const canRead = permissions?.read ?? false;
  const canWrite = permissions?.write ?? false;
  const canUpdate = permissions?.update ?? false;
  const canDelete = permissions?.delete ?? false;

  const initheader = [
    "banch_id",
    "compani_id",
    "name",
    "souvenirs_available",
  ];

  const initheaderMap = {
    banch_id: "ID Sucursal",
    compani_id: "ID Compañia",
    name: "Name Sucursal",
    souvenirs_available: "Souvenirs disponibles",

  };


  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };

  const [slsEvents, setslsEvents] = useState([]);
  const [header, setHeader] = useState(initheader);
  const [headerMap, setheaderMap] = useState(initheaderMap);
  const [tblvisible, settblvisible] = useState(true);
  const [info, setInfo] = useState([]);

  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);

  const [formvisible, setformvisible] = useState(false);
  const [formvisibleEdit, setformvisibleEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
  const [formValues, setFormValues] = useState({
    nombre: "",
    precio: 0,
    imagen: null,
    precio_publico: 0,
    id_proveedor: 0,
    cantidad: 0,
    talla: "",

    compania: 0,
    id_sucursal: 0,
    description_prod: "",
    activo: true,
    allbranch: false,
  });

  const [formValuesEdit, setFormValuesEdit] = useState({
    id: 0,
    nombre: "",
    precio: 0,
    imagen: null,
    precio_publico: 0,
    id_proveedor: 0,
    cantidad: 0,
    talla: 0,

    compania: 0,
    id_sucursal: 0,
    description_prod: "",
    activo: true,
  });

    const [slsValues, setslsValues] = useState([]);
    const [modalData, setModalData] = useState(null);
  const [isReLoaded, setIsReLoaded] = useState(false);
  

  //////////////////////// funciones base para peticiones <<<<<<<<<<<<<<<<<<<<<<<<

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol,
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch,
      };

      const response = await axios.post(
        postAllCompaniesRoll(token),
        requestData
      );
      return response.data.data || [];
    } catch (error) {
      console.error("Error al cargar las empresas", error);
      return [];
    }
  };

  const fnGetAllBranch = async () => {
    try {
      const requestData = {
        companyId: formValues.compania || idUserP.branch,
      };

      const responseJsonCompanies = await axios.post(
        getBranchDataByCompany(token),
        requestData
      );
      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      const companies = await fnPostCompaniesList();
      setslsCompany([
        initSelec,
        ...(Array.isArray(companies) ? companies : []),
      ]);
    };

    const fetchListInfo = async () => {
       await fnPostListSouvenirs();
    };

    fetchCompanies();
    fetchListInfo();

if(isReLoaded == true){
  fetchListInfo();
  setIsReLoaded(false);
}
  }, [isReLoaded]);

  // Función para actualizar la lista de sucursales cuando cambia la empresa seleccionada
  useEffect(() => {
    if (formValues.compania) {
      const fetchBranches = async () => {
        const branches = await fnGetAllBranch();
        if (Array.isArray(branches) && branches.length > 0) {
          setslsBranches([initSelec2, ...branches]);
        } else {
          setslsBranches([initSelec2]); // Si no hay datos, solo asigna initSelec2
        }
      };
      fetchBranches();
    }
  }, [formValues.compania]);

  /////////// termina grupo de funciones combo base <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const fnPostListSouvenirs = async () => {
    try {
      const requestData = {
        idrol: idUserP.rol,
        idcompany: idUserP.company,
        idsucursal: idUserP.branch,
      };

      const response = await axios.post(
        getrdallsouvenir(token),
        requestData
      );

      console.log('response.data.data <<<<<<<<<<<<<<<<<')
      console.log(response.data.data)
      setInfo(response.data.data);
      setslsValues(response.data.data);
      return response.data.data || [];
    } catch (error) {
      console.error("Error al cargar listado de souvenirs", error);
      return [];
    }
  };


  //////////// Botones de tabla <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // const fnViewDetail = (idRegister) => {
  //   console.log("informacion a mostrar en modal:  ", idRegister);
  //   const detailData = slsValues.find(
  //     (datainfo) => datainfo.banch_id === idRegister.banch_id
  //   );

  //   setModalData(detailData);
  //   setShowModal(true);
  // };

  const fnViewDetail = (idRegister) => {
    console.log("Información a mostrar en modal: ", idRegister);
    
    // Buscar la sucursal seleccionada en `slsValues`
    const detailData = slsValues.find((datainfo) => datainfo.banch_id === idRegister?.banch_id);

    if (!detailData) {
        console.warn("No se encontró información para el ID proporcionado.");
        return;
    }

    // Extraer souvenirs de la sucursal encontrada
    const souvenirs = detailData.arraySuvenirs || [];

    console.log("Souvenirs encontrados:", souvenirs);

    // Guardar en el estado para mostrar en el modal
    setModalData({ ...detailData, souvenirs });
    setShowModal(true);
};


  const fnEditRegister = async (idRegister) => {
    console.log("información para editar registro << ", idRegister);
    setformvisibleEdit(!formvisibleEdit)
    settblvisible(!tblvisible);

    const detailData = slsValues.find(
      (datainfo) => datainfo.company_id === idRegister.company_id
    );

    if (detailData) {
      setFormValuesEdit({
        company_id: detailData.company_id || 0,

        tax_regime_id: detailData.tax_regime_id || 0,
        cfdi_id: detailData.cfdi_id || 0,
        name: detailData.name || "",
        short_name: detailData.short_name || "",
        company_name: detailData.company_name || "",
        rfc: detailData.rfc || "",
        addres: detailData.addres || "",
        clabe: detailData.clabe || "",
        rnt: detailData.rnt || "",
        logo: detailData.logo || "",
      });
    }

  };

  const fnDeleteRegister = async (idRegister) => {
    console.log("información de registro << ", idRegister);
    console.log('getDeletesouvenir(idRegister.i_id_souvenir) <<<', getDeletesouvenir(idRegister.i_id_souvenir))
     try {
          const response = await fetch(getDeletesouvenir(idRegister.i_id_souvenir), {
            method: "PATCH",
          });


if(response.status == 200){
  Swal.fire({
    title: "¡successfully!",
    text: `"El producto con ID ${idRegister.id} ha sido desactivado!`,
    icon: "success",

  });



}else{
  Swal.fire({
    title: "¡Error!",
    text: `"Oops. An error occurred `,
    icon: "error",
  });
}

setShowModal(false);
setIsReLoaded(true);

} catch (error) {
          console.error("Error en la petición:", error);
          return { success: false, result: "error", message: "Error en la solicitud" };
        }
  };

  //////////// Terminan logica de botones  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const fnChangeView = () => {
    setformvisible(!formvisible);
    settblvisible(!tblvisible);
    // Swal.fire({
    //   title: "¡Caution!",
    //   text: "Running this process will affect your hotel search. Do you want to continue?",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonText: "Yes, proceed",
    //   cancelButtonText: "Cancel",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     // console.log("Process started...");
    //     fnGetStartProcesEvents();
    //   } else {
    //     // console.log("Process canceled.");
    //   }
    // });
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormValues((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
        ...(name === "company" && { branch: "" }),
      }));
    }
  };

  const handleInputChangeEdit = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormValuesEdit((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormValuesEdit((prev) => ({
        ...prev,
        [name]: value,
        ...(name === "company" && { branch: "" }),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("<<<<<<<<<<<<<<<<<<<<<<<< formValues");
    console.log(formValues);

    const requiredFields = [
      "nombre",
      "precio",
      "precio_publico",
      "id_proveedor",
      "compania",
      "id_sucursal",
      "description_prod"
    ];
  
    // Verificamos si alguno de los campos requeridos está vacío, null o undefined
    for (let field of requiredFields) {
      if (formValues[field] === null || formValues[field] === undefined || formValues[field] === '') {
        Swal.fire({
          title: "¡Error!",
          text: `El campo "${field}" es obligatorio y no puede estar vacío.`,
          icon: "error",
        });
        return; // Detenemos la ejecución si algún campo es inválido
      }
    }


    try {

      const PostDataUser = async () => {
        const formData = new FormData();
        formData.append("nombre", formValues.nombre );
        formData.append("precio", formValues.precio);
        formData.append("imagen", formValues.imagen);
        formData.append("precio_publico", formValues.precio_publico);
        formData.append("id_proveedor", formValues.id_proveedor);
        formData.append("compania", formValues.compania);
        formData.append("id_sucursal", formValues.id_sucursal);
        formData.append("description_prod", formValues.description_prod);
        formData.append("allbranch", formValues.allbranch);
        formData.append("cantidad", formValues.cantidad);
        formData.append("talla", formValues.talla);

      
        try {
          const response = await fetch(postaddsouvenir(token), {
            method: "POST",
            body: formData,
          });
      
          console.log('Dentro de la función <<<<<<<<<<<<<<<<<<<<<<<<<<<<<');
          console.log(response);
      
          const data = await response.json();  // <---- Asegúrate de convertir a JSON      
          return {response, data};
        } catch (error) {
          console.error("Error en la petición:", error);
          return { success: false, result: "error", message: "Error en la solicitud" };
        }
      };


      const response = await PostDataUser();

      console.log('response >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
      console.log(response)
    
          if (response.response.status === 201) {
            Swal.fire({
              title: "¡successfully!",
              text: response.data.message,
              icon: "success",
            });            

      settblvisible((prev) => !prev);
      setformvisible((prev) => !prev);
      setIsReLoaded((prev) => !prev);

      setFormValues({
        nombre: "",
        precio: 0,
        imagen: null,
        precio_publico: 0,
        id_proveedor: 0,
        cantidad: 0,
        talla: 0,
    
        compania: 0,
        id_sucursal: 0,
        description_prod: "",
        // activo: true,
        allbranch: false,
      });

          } else {

            Swal.fire({
              title: "Oops. An error occurred!",
              text: response.data.message,
              icon: "error",
            });
          }

    } catch (error) {
      Swal.fire({
        title: "¡Error!",
        text: `"Oops. An error occurred `,
        icon: "error",
      });
    }
  };

  const handleSubmitEditCompany = async (e) => {
    e.preventDefault();

  
    //     try {
      //     const PostDataUser = async () => {
      //   const formData = new FormData();
      //   formData.append("company_id", formValuesEdit.company_id );
      //   formData.append("company_name", formValuesEdit.company_name);
      //   formData.append("short_name", formValuesEdit.short_name);
      //   formData.append("name", formValuesEdit.company_name);
      //   formData.append("rfc", formValuesEdit.rfc);
      //   formData.append("addres", formValuesEdit.addres);
      //   formData.append("clabe", formValuesEdit.clabe);
      //   formData.append("cfdi_id", formValuesEdit.cfdi_id);
      //   formData.append("tax_regime_id", formValuesEdit.tax_regime_id);
      //   formData.append("rnt", formValuesEdit.rnt);
      //   formData.append("logo", formValuesEdit.logo);
      
      //   try {
      //     const response = await fetch(putCompany(token), {
      //       method: "PUT",
      //       body: formData,
      //     });
      
      //     console.log('Dentro de la función <<<<<<<<<<<<<<<<<<<<<<<<<<<<<');
      //     console.log(response);
      
      //     const data = await response.json();  // <---- Asegúrate de convertir a JSON
      
      //     console.log("Respuesta del servidor:", data); // <---- Esto debería mostrar success, result y message
      
      //     return {response, data};
      //   } catch (error) {
      //     console.error("Error en la petición:", error);
      //     return { success: false, result: "error", message: "Error en la solicitud" };
      //   }
      // };
      
    
    //       const response = await PostDataUser();

    //   console.log('response >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
    //   console.log(response.response.status)
    
    //       if (response.response.status === 200) {
    //         addNotification("success", "Success", response.data.message, "top-center");
    //         // setformvisible(!formvisible);
    //         settblvisible(!tblvisible);
    //   setformvisibleEdit((prev) => !prev);
    //         setreload((prev) => !prev);
    //       } else {
    //         addNotification(
    //           "warning",
    //           "Error",
    //           response.data.message,
    //           "top-center"
    //         );
    //       }
    //     } catch (error) {}

};


  return (
    <div>
      <h1 className="page-header">Souvenirs</h1>
      <div className="row" style={!tblvisible ? { display: "none" } : {}}>
        <div className="col-12">
          <Panel>
            <PanelHeader>Souvenirs</PanelHeader>
            <PanelBody>
            {canWrite && (
              <div
                className="row col-md-3"
                style={{ float: "inline-end", marginBottom: "15px" }}
              >
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={fnChangeView}
                >
                  Agregar souvenir +
                </button>
              </div>
               )}

              <div className="row col-md-12">
                <DataTableConsulting
                  header={header}
                  headerMap={headerMap}
                  info={info}
                  onViewDetail={canRead ? fnViewDetail : null}
                  // onEditRegister={canUpdate ? fnEditRegister : null}
                  // onDelete={canDelete ? fnDeleteRegister : null}
                />
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> Souvenirs available for branch </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {modalData?.souvenirs?.length > 0 ? (
    <ul style={{ listStyle: "none", padding: 0 }}>
        {modalData.souvenirs.map((item) => (
            <li 
                key={item.i_id_souvenir} 
                style={{ 
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "space-between", 
                    padding: "10px", 
                    borderBottom: "1px solid #ccc"
                }}
            >
                {/* Contenedor de la información */}
                <div>
                    <p><strong>ID:</strong> {item.i_id_souvenir}</p>
                    <p><strong>ID producto:</strong> {item.id_producto}</p>
                    <p><strong>Nombre:</strong> {item.nombre_producto}</p>
                    <p><strong>N. Talla:</strong> {item.nombre_tamano}</p>
                    <p><strong>Medida:</strong> {item.measuresid}</p>
                    <p><strong>Cantidad Inicial:</strong> {item.i_cant_inicial}</p>
                    <p><strong>Inventario Actual:</strong> {item.i_can_actual}</p>
                    <p><strong>Precio:</strong> ${item.p_precio}</p>
                    <p><strong>Precio público:</strong> ${item.p_precio_publico}</p>
                    <p><strong>Descripción:</strong> {item.p_description_prod}</p>
                    <img 
                        src={item.p_imagen} 
                        alt={item.nombre_producto} 
                        width="50%" 
                        style={{ borderRadius: "5px", marginTop: "10px" }} 
                    />
                </div>

                {/* Botón alineado a la derecha y centrado en altura */}
                <button 
                    onClick={() => fnDeleteRegister(item)} 
                    className="btn btn-red"

                    style={{
                        padding: "8px 12px",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        height: "40px", // Ajusta altura si es necesario
                    }}
                >
                    Eliminar
                </button>
            </li>
        ))}
    </ul>
          ) : (
              <p>No hay souvenirs disponibles.</p>
          )}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>


      {/* //////// agregar  */}
      <div className="row" style={!formvisible ? { display: "none" } : {}}>
        <div className="col-12">
          <Panel>
            <PanelHeader>Form Add</PanelHeader>
            <PanelBody>
              <form onSubmit={handleSubmit}>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Nombre *
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      id="nombre"
                      name="nombre"
                      value={formValues.nombre}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Precio *
                  </label>
                  <div className="col-md-1">
                    <input
                      className="form-control"
                      type="number"
                      id="precio"
                      name="precio"
                      value={formValues.precio}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Imagen *
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="file"
                      id="imagen"
                      name="imagen"
                      //   value={formValues.nombre}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Precio publico *
                  </label>
                  <div className="col-md-1">
                    <input
                      className="form-control"
                      type="number"
                      id="precio_publico"
                      name="precio_publico"
                      value={formValues.precio_publico}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                   ID Proveedor *
                  </label>
                  <div className="col-md-1">
                    <input
                      className="form-control"
                      type="number"
                      id="id_proveedor"
                      name="id_proveedor"
                      value={formValues.id_proveedor}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Cantidad *
                  </label>
                  <div className="col-md-1">
                    <input
                      className="form-control"
                      type="number"
                      id="cantidad"
                      name="cantidad"
                      value={formValues.cantidad}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Talla *
                  </label>
                  <div className="col-md-1">
                    <input
                      className="form-control"
                      type="text"
                      id="talla"
                      name="talla"
                      value={formValues.talla}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Compañia*
                  </label>
                  <div className="col-md-3">
                    <select
                      className="form-select"
                      id="compania"
                      name="compania"
                      value={formValues.compania}
                      onChange={handleInputChange}
                    >
                      {slsCompany.map((option, index) => (
                        <option
                          key={"form_" + index}
                          value={option.value_option}
                        >
                          {option.name_option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

<div className="row mb-15px">
  <label className="form-label col-form-label col-md-2">
    {/* allbranch* */}
  </label>
  <div className="col-md-3">
  <label>
    <input
      className="form-check-input"
      type="checkbox"
      name="allbranch"
      checked={formValues.allbranch}
      onChange={(e) =>
        setFormValues((prev) => ({
          ...prev,
          allbranch: e.target.checked, 
        }))
      }
    />
          <span className="ms-2">

    Este souvenir se agregará a todas las sucursales de la compañia, pero su inventario será independiente y no se compartirá.
    </span>

  </label>
  </div>
</div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Sucursal
                  </label>
                  <div className="col-md-3">
                    <select
                      className="form-select"
                      id="id_sucursal"
                      name="id_sucursal"
                      value={formValues.id_sucursal}
                      onChange={handleInputChange}
                      disabled={formValues.allbranch} // Se deshabilita si allbranch es true

                    >
                      {slsBranch.map((option, index) => (
                        <option key={"form_" + index} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Descripcion art. *
                  </label>
                  <div className="col-md-4">
                    <textarea
                      className="form-control"
                      rows="3"
                      type="text"
                      id="description_prod"
                      name="description_prod"
                      value={formValues.description_prod}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div
                  className="form-label col-form-label col-md-6"
                  style={{ margin: 10 }}
                >
                  <button
                    type="submit"
                    className="btn btn-blue"
                    style={{ float: "right" }}
                  >
                    Guardar
                  </button>
                  <button
                    type="submit"
                    className="btn btn-red"
                    style={{ float: "left" }}
                    onClick={fnChangeView}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
      {/* //////// terminar funcionalidad para agregar */}

            {/* ////////////// Editar registro  */}
      
            <div className="row" style={!formvisibleEdit ? { display: "none" } : {}}>
              <div className="col-12">
                <Panel>
                  <PanelHeader>Edit Branch</PanelHeader>
                  <PanelBody>
                    <form onSubmit={handleSubmitEditCompany}>
      
                      <div className="row mb-15px">
                        <label className="form-label col-form-label col-md-2">
                          Name Company *
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="text"
                            id="company_name"
                            name="company_name"
                            value={formValuesEdit.company_name}
                            onChange={handleInputChangeEdit}
                            disabled
                          />
                        </div>
                      </div>
      
                    </form>
                  </PanelBody>
                </Panel>
              </div>
            </div>
      
            {/* ///////////// termina Edicion  */}

      <ReactNotifications />{" "}
    </div>
  );
}

export default DataSouvenirs;
