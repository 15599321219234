import React, { useState, useEffect, useRef } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import {
  getListEventsAng,
  getloadeventsNews,
} from "../../utils/urls.js";
import { ReactNotifications, Store } from "react-notifications-component";
import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";
import Swal from "sweetalert2";


function TableMappingEvents() {

  const initheader = [
    "id",
    "status",
    "hora_inicio",
    "hora_finalizado",
    "fecha",
    "total_eventos_proveedor1",
    "total_eventos_proveedor2",
    "activo",
  ];

  const initheaderMap = {
    id: "Destination ID",
    status: "Estado",
    hora_inicio: "Hora Inicio",
    hora_finalizado: "hora Fin",
    fecha: "Fecha",
    total_eventos_proveedor1: "Total Eventos Prov. 1",
    total_eventos_proveedor2: "Total Eventos Prov. 2",
    activo: "Active",
  };

  const [slsEvents, setslsEvents] = useState([]);


  const [header, setHeader] = useState(initheader);
  const [headerMap, setheaderMap] = useState(initheaderMap);
  const [tblvisible, settblvisible] = useState(true);

  
  useEffect(() => {

    const AxiosListEvents = async () => {
      const listevents = await fnGetListEventsTable();
      setslsEvents([
        ...(Array.isArray(listevents) ? listevents : []),
      ]);
    };

    AxiosListEvents();
  }, []);


  const fnGetListEventsTable = async () => {
    try {


      const response = await axios.get(getListEventsAng()      );

      console.log(' repuesta obtenida de get:  ', response )
      return response.data || [];

    } catch (error) {
      console.error("Error al cargar las empresas", error);
      return [];
    }
  };

  const fnGetStartProcesEvents = async () => {
    console.log('entro a funcion de mapeo ')
    try {


      const response = await axios.get(getloadeventsNews() );

      console.log(' repuesta obtenida de get Start Proces Eventos:  ', response )

      Swal.fire({
        title: "¡successfully!",
        text: `"Event mapping" process started!`,
        icon: "success",

      });

    } catch (error) {
      console.error("Error al cargar las empresas", error);

      Swal.fire({
        title: "¡Error!",
        text: `"Oops. An error occurred while mapping`,
        icon: "error",
      });

    }
  };

  const fnChangeView = () => {
    // setformvisible(!formvisible);
    // settblvisible(!tblvisible);
    Swal.fire({
      title: "¡Caution!",
      text: "Running this process will affect your hotel search. Do you want to continue?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, proceed",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log("Process started...");
        fnGetStartProcesEvents(); 
      } else {
        // console.log("Process canceled.");
      }
    });
  };


  return (
    <div>
      <h1 className='page-header'>Mapeo Eventos</h1>

      <div className="row" style={!tblvisible ? { display: "none" } : {}}>
        <div className="col-12">
          <Panel>
            <PanelHeader>Eventos</PanelHeader>
            <PanelBody>

                <div className="row col-md-3" style={{ float: "inline-end", marginBottom: "15px" }}>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={fnChangeView}
                  >
                    generar mapeo de eventos 
                  </button>
                </div>

              <div className="row col-md-12">
                <DataTableConsulting
                  header={header}
                  headerMap={headerMap}
                  info={slsEvents}
                />
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>

    <ReactNotifications />{" "}
      
    </div>
  );
}

export default TableMappingEvents;
