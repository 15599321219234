import axios from "axios";
import React, { useEffect, useState } from "react";
import { inputsByIdForm } from "../../utils/urls";

interface InputField {
    id_input_text: string;
    label: string;
    type: string;
    mandatory?: boolean;
    options?: { value_option: string; name_option: string }[];
    classname?: string;
    attributes?: string;
    name: string;
}

interface DataTableProps {
    id_form: number;
    onSubmit: (data: Record<string, any>) => void;
    dataValue: Record<string, any>;
    token: string;
    onCancel: () => void;
}

const FormsDinamic: React.FC<DataTableProps> = ({ dataValue, id_form, token, onSubmit, onCancel }) => {
    const [inputsData, setInputsData] = useState<InputField[]>([]);
    const [formValues, setFormValues] = useState<Record<string, any>>({});
    const [formToSend, setformToSend] = useState<Record<string, any>>({});

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fnGetInputs(id_form, token);

                // Aquí asignamos inputsData a partir de la respuesta
                console.log('respuesta de inputs>', response);
                setInputsData(response);

                // Inicializamos formValues con dataValue
                const initialValues = response.reduce((acc: Record<string, any>, field: InputField) => {
                    acc[field.id_input_text] = dataValue[field.id_input_text] || ""; // Prellenar si hay valores en dataValue
                    return acc;
                }, {});
                const initialValuesName = response.reduce((acc: Record<string, any>, field: InputField) => {
                    acc[field.name] = dataValue[field.id_input_text] || ""; // Prellenar si hay valores en dataValue
                    return acc;
                }, {});

                setFormValues(initialValues);
                setformToSend(initialValuesName);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id_form, token, dataValue]); // Dependencias ajustadas

    const fnGetInputs = async (id_form, token) => {
        const response = await axios.post(inputsByIdForm(token), {
            idForms: id_form 
        });
        const processedData = await Promise.all(response.data.data.map(async (field: InputField) => {
            console.log('arreglo de inputs', field);
            let arrayAtributes: string[];
            let name: string | undefined;
            console.log(" field.atributes", field.attributes);
            if (field.attributes != null && field.attributes.includes(",")) {
                arrayAtributes = field.attributes.split(",");
                console.log('array', arrayAtributes);
                name = arrayAtributes.find(x => x.includes("name="));
                console.log('name', name);
                name = name ? name.replace("name=", "") : undefined;
            } else if (field.attributes != null && field.attributes.includes("name=")) {
                name = field.attributes;
                console.log('name', name);
                name = name.replace("name=", "");
            }
            field.name = name != undefined ? name : field.id_input_text;

            return field;
        }));
        console.log('field', processedData);
        return processedData;
    }


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { id, name, type, value } = e.target;
        console.log('targuet->', e.target);

        setFormValues((prev) => ({
            ...prev,
            [id]: type === "checkbox" ? e.target.checked : value
        }));
        setformToSend((prev) => ({
            ...prev,
             [name]:( type === "checkbox" ? e.target.checked : value)
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        onSubmit(formToSend);
        //setFormValues({}); // Limpia el formulario
    };

    const handleCancel = () => {
        onCancel();
        setFormValues({}); // Limpia el formulario
    };

    return (
        <div className='row col-md-12'>
            <form onSubmit={handleSubmit}>
                {inputsData.map((field, index) => (
                    <div className='row mb-15px' key={index}>
                        <label className='form-label col-form-label col-md-3' htmlFor={field.id_input_text}>
                            {field.label}
                            {field.mandatory && <span style={{ color: "red" }}> *</span>}
                        </label>
                        <div className='col-md-9'>
                            {field.type === "select" ? (
                                <select
                                    id={field.id_input_text}
                                    className={field.classname}
                                    name={field.name}
                                    onChange={handleChange}
                                    value={formValues[field.id_input_text] || ""}
                                >
                                    <option value=''>Select an option</option>
                                    {field.options?.map((option, idx) => (
                                        <option key={idx} value={option.value_option}>
                                            {option.name_option}
                                        </option>
                                    ))}
                                </select>
                            ) : field.type === "checkbox" ? (
                                <input
                                    type={field.type}
                                    id={field.id_input_text}
                                    className={field.classname}
                                    name={field.name}
                                    onChange={handleChange}
                                    checked={formValues[field.id_input_text] || false}
                                />
                            ) : (
                                <input
                                    type={field.type}
                                    id={field.id_input_text}
                                    className={field.classname}
                                    name={field.name}
                                    onChange={handleChange}
                                    value={formValues[field.id_input_text] || ""}
                                />
                            )}
                        </div>
                    </div>
                ))}
                <div className='row' style={{ float: "inline-end" }}>
                    <div className='col-md-6'>
                        <button type='submit' className='btn btn-primary w-100px me-5px'>
                            Save
                        </button>
                    </div>
                    <div className='col-md-6'>
                        <button type='button' className='btn btn-danger w-100px me-5px' onClick={handleCancel}>
                            Cancelar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default FormsDinamic;


