import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import Highlight from "react-highlight";
import {
  getgetfiltersbyidtype,
  desactivivatefilter,
} from "./../../utils/urls.js";

function ViewFiltersElements() {
  const [code1, setCode1] = useState();
  const [code2, setCode2] = useState();
  const [code3, setCode3] = useState();
  const [code4, setCode4] = useState();
  const [code5, setCode5] = useState();
  const [code6, setCode6] = useState();
  const [code7, setCode7] = useState();
  const [code8, setCode8] = useState();
  const [code9, setCode9] = useState();

  const [eventInfo, setEventInfo] = useState([]);
  const [groupedEventInfo, setgroupedEventInfo] = useState([]);

  function handleOnChange(idfiltro) {
   //console.log("entro en la funcion", idfiltro);

	fetch(desactivivatefilter(idfiltro))
      .then((response) => response.json())
      .then((data) => {
		console.log('respuesta de eliminar registro');
console.log(data);
      })
      .catch((error) => console.error(error));

  }


  useEffect(() => {
    fetch(getgetfiltersbyidtype())
      .then((response) => response.json())
      .then((data) => {
        setEventInfo(groupByTipofiltro(data));
      })
      .catch((error) => console.error(error));
  }, []);

  const groupByTipofiltro = (data) => {
    return data.reduce((acc, item) => {
      const { tipofiltro } = item;
      if (!acc[tipofiltro]) {
        acc[tipofiltro] = [];
      }
      acc[tipofiltro].push(item);
      return acc;
    }, {});
  };

 //console.log("ya en variables");
 //console.log(eventInfo);

  return (
    <div>
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <Link to="/table/basic">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/table/basic">Tables</Link>
        </li>
        <li className="breadcrumb-item active">Table Elements</li>
      </ol>
      <h1 className="page-header">
        Table Elements <small>header small text goes here...</small>
      </h1>

      <div className="row">
        {/* <Panel>
          <PanelHeader>
            UI Elements in Table{" "}
            <span className="badge bg-success ms-5px">NEW</span>
          </PanelHeader>

          <PanelBody>
            <div className="table-responsive">
              <table className="table table-striped mb-0 align-middle">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Username</th>
                    <th>Email Address</th>
                    <th>Email Address</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img
                        src="../assets/img/user/user-1.jpg"
                        alt=""
                        className="rounded h-30px"
                      />
                    </td>
                    <td>Nicky Almera</td>
                    <td>nicky@hotmail.com</td>
                    <td nowrap="true">
                      <button
                        className="btn btn-sm btn-white w-60px"
                        onClick={() => handleOnChange("idfiltro")}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <img
                        src="../assets/img/user/user-3.jpg"
                        alt=""
                        className="rounded h-30px"
                      />
                    </td>
                    <td>Harvinder Singh</td>
                    <td>harvinder@gmail.com</td>
                    <td className="with-btn" nowrap="true">
                      <button
                        className="btn btn-sm btn-white w-60px"
                        onClick={() => handleOnChange("someId")}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </PanelBody>

       
        </Panel> */}
		{Object.keys(eventInfo).map((tipofiltro) => (
              <div key={tipofiltro}>
                {/* <h3>Tipo de Filtro: {tipofiltro}</h3> */}
                <Panel>
                  <PanelHeader>
                    UI Elements in Table{" "}
                    <span className="badge bg-success ms-5px">
                      {tipofiltro}
                    </span>
                  </PanelHeader>

                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Comentario</th>
                        <th>Nombre</th>
                        <th>Activo</th>
                        <th>Evento</th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventInfo[tipofiltro].map((item) => (
                        <tr key={item.idfiltro}>
                          <td>{item.idfiltro}</td>
                          <td>{item.comentariotipe}</td>
                          <td>{item.namefiltro}</td>
                          <td>{item.activofiltro ? "Sí" : "No"}</td>
                          <td>{item.idevento}</td>
                          <button
                            className="btn btn-sm btn-white w-60px"
                            onClick={() => handleOnChange(item.idfiltro)}
                          >
                            Delete
                          </button>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Panel>
              </div>
            ))}
      </div>
    </div>
  );
}

export default ViewFiltersElements;
