import react, { ChangeEvent, FC, useState } from "react";
import styled from "styled-components";

import {
    AutoCompleteContainer,
    Input,
    AutoCompleteItem,
    AutoCompleteItemButton
} from "../../scss/styles/style.ts";
import React from "react";

const Root = styled.div`position: relative;`;

interface IData {
    text: string;
    value: number;
}
interface autoCompleteProps {
    iconColor?: string;
    inputStyle?: react.CSSProperties;
    optionsStyle?: react.CSSProperties;
    name?: string;
    id?: string;
    data: any[];
}
interface initSearching {
    text: string;
    suggestions: any[];
}

export const AutoComplete: FC<autoCompleteProps> = ({
    iconColor,
    inputStyle,
    optionsStyle,
    name,
    id,
    data
}) => {
    const initSerach: initSearching={
        text: "",
        suggestions: []
    };
    const [search, setSearch] = useState(initSerach);
    const [isComponentVisible, setIsComponentVisible] = useState(true);
    const onTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let suggestions :any[] =[];
        if (value.length > 0) {
            const regex = new RegExp(`^${value}`, "i");
            suggestions = data.sort().filter((v: IData) => regex.test(v.text));
        }
        setIsComponentVisible(true);
        setSearch({ suggestions, text: value });
    };

    const suggestionSelected = (value: IData) => {
        setIsComponentVisible(false);

        setSearch({
            text: value.text,
            suggestions: []
        });
    };

    const { suggestions } = search;

    return (
        <Root>
            <div
                onClick={() => setIsComponentVisible(false)}
                style={{
                    display: isComponentVisible ? "block" : "none",
                    
                    backgroundColor: "transparent",
                    position: "fixed",
                    zIndex: 0,
                    top: 0,
                    left: 0
                }}
            />
            <div>
                <Input
                    id={id}
                    autoComplete="off"
                    value={search.text}
                    onChange={onTextChanged}
                    type={"text"}
                    style={inputStyle}
                    name={name}

                />
            </div>
            {suggestions.length > 0 && isComponentVisible && (
                <AutoCompleteContainer style={optionsStyle}>
                    {suggestions.map((item: IData) => (
                        <AutoCompleteItem key={item.value}>
                            <AutoCompleteItemButton
                                key={item.value}
                                onClick={() => suggestionSelected(item)}
                            >
                                {item.text}
                            </AutoCompleteItemButton>
                        </AutoCompleteItem>
                    ))}
                </AutoCompleteContainer>
            )}
        </Root>
    );
};
