import React, { useState, useEffect, useRef } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications, Store } from "react-notifications-component";
import {
  getBranchDataByCompany,
  PostupHotelView,
  PostCreateCombosHotel,
  postAllCompaniesRoll,
  PostCreateExclusiveDeals,
  getInfortownGroupCitys,
  postFileGroupCitys,
} from "../../utils/urls.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";
import SearchForm from "../../components/header/search/form.jsx";
import Highlight from "react-highlight";
import DateTime from "react-datetime";

function Groupcitys({ idUser, infoUser, dataUser }) {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  const initInfo = {
    id_group: 40730,
    city: "",
    city_id: "",
    town: "",
    state: "",
    groupcode: ""
  };

  const initCountrys = {
    country: "",
    citys: [
      {
        city: "",
        citycode: 0,
      },
    ],
  };


  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };

  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false); // Estado de carga
  const [LoadingCreatecombo, setLoadingCreatecombo] = useState(false); // Estado de carga

  const [reload, setReload] = useState(false);

  const [isLoaded, setIsLoaded] = useState(true);

  const [formValues, setFormValues] = useState({
    file: null,
  });
  const [slsListSerch, setslsListSearch] = useState([
    initInfo,
  ]);
    const [searchInput, setSearchInput] = useState("");
  

  const editorRef = useRef(null);
  const fileInputRef = useRef(null);


  const toggleReload = () => {
    if (!reload) {
      setReload(true);
      setLoading(true); // Inicia la carga cuando se hace clic en recargar

      setTimeout(() => {
        setIsLoaded(true);

        setReload(false);
        setLoading(false); // Termina la carga
      }, 2000);
    }
  };

  const validateForm = () => {
    return formValues.file;
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormValues((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
        ...(name === "company" && { branch: "" }),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validateForm()) {
      addNotification("danger", "Error", "Fill all required fields", "top-center");
      return;
    }
  
    try {
      const PostData = async () => {
        const formData = new FormData();
        formData.append("fileup", formValues.file);
  
        console.log("FormData contenido:");
        for (let [key, value] of formData.entries()) {
          console.log(key, value);
        }
  
        const response = await fetch(postFileGroupCitys(), {
          method: "POST",
          body: formData,
        });
  
        return response;
      };
  
      const response = await PostData();
    //   console.log("response <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
      console.log(response.status);
  
      if (response.status === 201) {
        addNotification("success", "Success", "Proceso finalizado", "top-center");
      } else {
        addNotification("warning", "Error", "Error loading file", "top-center");
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  
    // Limpiar valores después del submit
    setFormValues({
      file: null,
    });
  
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    console.log("entro a funcion de buscar <<< ", searchInput);
    postSearchData(searchInput);
    let listHotelsInfo = [initInfo, ...(await postSearchData(searchInput))];

    setslsListSearch(listHotelsInfo);
  };

  const handleInputChangeSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const postSearchData = async (valuesearch) => {
    // console.log("Fetching permissions for user:", IdUserSelect);
    setLoading(true); // Activar el estado de carga

    try {
      const responseJson = await axios.get(getInfortownGroupCitys(valuesearch), {
        value: searchInput,
      });

      const DataResulthotels = responseJson.data.data;

      console.log(DataResulthotels);

      return DataResulthotels;
    } catch (error) {
      console.error("Error al cargar los módulos", error);
    } finally {
      setLoading(false); // Desactivar el estado de carga
    }
  };
  

  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }

  return (
    <div>
      <div className="row mb-3">
        <div className="col-xl-6">
          <Panel>
            <PanelHeader>
              &nbsp;&nbsp;
              <button
                className="btn btn-xs btn-icon btn-circle btn-success"
                onClick={toggleReload}
              >
                <i className="fa fa-redo"></i>
              </button>
              &nbsp;&nbsp; Capture of grouping of cities
            </PanelHeader>
            <PanelBody>
              {/* <div className="lrow md-7px" style={{ margin: 2 }}>
                *Capture all required fields
              </div> */}

              <div
                className="lrow md-8px"
                // style={{
                //   margin: 4,
                //   maxHeight: "950px", // Altura máxima del contenedor principal
                //   overflowY: "auto", // Barra de desplazamiento vertical
                //   border: "1px solid #ccc", // Opcional: agregar borde para resaltar
                //   padding: "10px", // Espaciado interno
                // }}
              >
                {LoadingCreatecombo ? (
                  <div>Loading...</div> // Muestra un mensaje de carga mientras se obtiene la información
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-3 d-flex align-items-center">
                      <label
                        className="form-label col-form-label col-md-3 mb-0"
                        htmlFor="urlimagen"
                      >
                        Select file *
                      </label>
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          type="file"
                          id="file"
                          name="file"
                          // value={formValues.file}
                          onChange={handleInputChange} // No es necesario pasar el value porque es un campo de tipo archivo
                        />
                      </div>
                    </div>

                    <div className="lrow md-15px" style={{ margin: 10 }}>
                      <button
                        type="submit"
                        className="btn btn-green"
                        style={{ float: "right" }}
                      >
                        + Upload file
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </PanelBody>
          </Panel>
        </div>


                <div className="col-xl-6">
                  <Panel>
                    <PanelHeader>Info city grouping</PanelHeader>
                    <PanelBody>
                      <div className="navbar-form">
                        <form action="" method="POST" name="search_form">
                          <div
                            className="form-group"
                            style={{ position: "relative", padding: "10px" }}
                          >
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter title of city grouping"
                              value={searchInput} // Muestra el valor del estado
                              onChange={handleInputChangeSearch} // Actualiza el estado al escribir
                              style={{ paddingRight: "40px" }}
                            />
                            <button
                              type="submit"
                              className="btn btn-search"
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                border: "none",
                                background: "none",
                                cursor: "pointer",
                              }}
                              onClick={handleSearch}
                            >
                              <i className="fa fa-search"></i>
                            </button>
                          </div>
        
                          {/* <div className="lrow md-7px" style={{ margin: 2 }}>
                            *Enter title of city grouping
                          </div> */}
        
                          <div
                            className="lrow md-8px"
                            style={{
                              margin: 5,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center", // Centra los elementos horizontalmente
                              maxHeight: "650px", // Altura máxima del contenedor principal
                              overflowY: "auto", // Barra de desplazamiento vertical
                              border: "1px solid #ccc", // Opcional: agregar borde para resaltar
                              padding: "10px", // Espaciado interno
                            }}
                          >
                            {loading ? (
                              <div>Loading...</div>
                            ) : (
                                slsListSerch
                                .slice(1) // Omite el inicial
                                .sort((a, b) => a.city.localeCompare(b.city)) // Ordena alfabéticamente por nombre
                                .map((data) => (
                                  <div
                                    key={data.id_group}
                                    // onClick={() => handleHotelSelect(hotel)} // Manejar clics
                                    style={{
                                      display: "flex",
                                      flexDirection: "column", // Ajusta flex para columnas
                                      alignItems: "flex-start", // Alinea contenido a la izquierda
                                      marginBottom: "10px",
                                      padding: "10px",
                                      border: "2px solid #ccc",
                                      borderRadius: "5px",
                                      backgroundColor: 
                                    //   isHotelSelected(hotel.hotelid)
                                    //     ? "#60adf6" // Azul para seleccionado
                                         "#fff", // Blanco para no seleccionado
                                      color: "#000",
                                      cursor: "pointer",
                                      transition: "background-color 0.3s",
                                      width: "700px", // Ancho deseado
                                      maxWidth: "90%", // Asegura que no sobrepase el contenedor
                                      alingc: "center",
                                    }}
                                  >
                                    <div>
                                      <strong>{data.groupcode}</strong>
                                      <p>
                                        {data.state} - {data.city} - {data.city_id}
                                      </p>
                                      {/* <p>Groupcode: {data.groupcode}</p> */}
                                    </div>
                                  </div>
                                ))
                            // <>mostrar resultados </>
                            )}
                          </div>
                        </form>
                      </div>
                    </PanelBody>
                  </Panel>
                </div>


      </div>
      <ReactNotifications />{" "}
    </div>
  );
}

export default Groupcitys;
