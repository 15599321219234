import React, { useEffect, useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import { validAccessLogn, getSession, getMenuacces, LoginNewAuth } from '../../utils/urls.js';
import axios from "axios";
import { saveToLocalStorage, getFromLocalStorage } from '../../components/helps.js';
import { ReactNotifications, Store } from 'react-notifications-component';
import CryptoJS from 'crypto-js';
import DropdownLanguage from '../../components/header/dropdown/language.jsx';


const secretKey = process.env.REACT_APP_SECRET_KEY;

function LoginV1() {
	const context = useContext(AppSettings);
	const [redirect, setRedirect] = useState(false);
	const [tipeuser, settipeuser] = useState(false);
	const [status, setStatus] = useState(0);

	const [credential, setCredential] = useState({
		user: "",
		password: ""
	});

	const handleChange = (e) => {
		setCredential({
			...credential,
			[e.target.name]: e.target.value
		});
	};

	const encryptData = (data) => {
		return CryptoJS.AES.encrypt(data, secretKey).toString();
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		//console.log('<<<<credential>>>>');

		//console.log(credential);

		const encryptedUser = encryptData(credential.user);
		const encryptedPassword = encryptData(credential.password);

		// console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<encryptedUser>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

		// console.log(encryptedUser)
		// console.log(encryptedPassword)


		// peticion validar acceso
		// const response = await axios.post(validAccessLogn(), {
		// 	user: encryptedUser,
		// 	password: encryptedPassword
		// });

		

		const response = await axios.post(LoginNewAuth(), {
			userid: credential.user,
			pwd: encryptedPassword,
		});

		// console.log('<<<<<<<<<<<<<<<<<<<<<<<<response>>>>>>>>>>>>>>>>>>>>>>>>')
		// console.log(response)
		

		let status = response.data
		// console.log(status.TK)
		//console.log(status);


		
		if (status.status === 200) {
			saveToLocalStorage('TK', status.TK);
			const response = await axios.get(getSession(status.TK));
			// console.log('respuesta de sesion->',response);
			if (response.status === 200 && response.data.success === true && response.data.data === true){
				const responseMenu = await axios.get(getMenuacces(status.TK));
				//console.log('getSession(token)->', getSession(status.TK));
				console.log('respons menus->', responseMenu);
				//console.log('respons validSesion.data->', response.data);
				saveToLocalStorage("Session", responseMenu.data.data.acces);
				saveToLocalStorage("menus", responseMenu.data.data.menu);
				setRedirect(true);
				setStatus(1);
				// settipeuser(status.admin)
				settipeuser(true);
			}else{
				saveToLocalStorage('TK', '');
				saveToLocalStorage('Session', '');
				saveToLocalStorage('menus', '');
				addNotification('warning', 'Error', 'Sesion Inactiva', 'top-center', false);
			}
			
		} else if (status.status === 201){
			saveToLocalStorage('tempUser', credential.user);
			setRedirect(true);
			setStatus(2);
		} else {
			addNotification('warning', 'Error', 'Incorrect email or password', 'top-center', false);
		}
		// set
	};

	useEffect(() => {
		context.handleSetAppHeaderNone(true);
		context.handleSetAppSidebarNone(true);
		context.handleSetAppContentClass('p-0');
		saveToLocalStorage('TK', '');
		saveToLocalStorage('Session', '');
		saveToLocalStorage('menus', '');
		return function cleanUp() {
			context.handleSetAppHeaderNone(false);
			context.handleSetAppSidebarNone(false);
			context.handleSetAppContentClass('');
		};

		// eslint-disable-next-line
	}, []);


	// function handleSubmitFa(event) {
	// 	event.preventDefault();

	// 	if (email == userSett && password == passuser) {
	// 		setRedirect(true);
	// 	}
	// 	// setRedirect(true);
	// }

	if (redirect) {
		// return <Navigate to='/dashboard/v0' />;
		//console.log(status);
		if(status === 2){
			return <Navigate to='/user/resetPws' />;
		}
		if(status === 0){
			addNotification('warning', 'Error', 'Usuario no valido', 'top-center', false);
			return;
		}
		if (tipeuser) {
			return <Navigate to='/dashboard/v0' />;
		} else {
			return <Navigate to='/detailEvents/viewcateg' />;
		}
	}



	function addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition, notificationContent) {
		if (notificationContent) {
			notificationContent = (
				<div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
					<div className="flex-1 ps-2">
						<h6 className="mb-1">Christopher Struth</h6>
						<p className="mb-0">Bank Transfer</p>
					</div>
				</div>
			);
		}
		Store.addNotification({
			title: notificationTitle,
			message: notificationMessage,
			type: notificationType,
			insert: "top",
			container: notificationPosition,
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: { duration: 2000 },
			dismissable: { click: true },
			content: notificationContent
		});
	}



	return (
		<div className="login login-v1">

			
			<div className="login-container">
				<div className="login-header">
					<div className="brand">
						<div className="d-flex align-items-center">
							<span className="logo"></span> <b> Tukmeint </b> Admin
						</div>
						{/* <small>Bootstrap 5 Responsive Admin Template</small> */}
					</div>
					<div className="icon">
						<i className="fa fa-lock"></i>
					</div>

				</div>
				<div className="login-body">
					<div className="login-content fs-13px">
						<form onSubmit={handleSubmit}>
							<div className="form-floating mb-20px">
								<input type="text" className="form-control fs-13px h-45px" name="user" placeholder="user" onChange={handleChange} />
								<label htmlFor="emailAddress" className="d-flex align-items-center py-0">Email Address</label>
							</div>
							<div className="form-floating mb-20px">
								<input type="password" className="form-control fs-13px h-45px" name="password" placeholder="password" onChange={handleChange} />
								<label htmlFor="password" className="d-flex align-items-center py-0">Password</label>
							</div>
							{/* <div className="form-check mb-20px">
								<input className="form-check-input" type="checkbox" value="" id="rememberMe" />
								<label className="form-check-label" htmlFor="rememberMe">
									Remember Mea
								</label>
							</div> */}
							<div className="login-buttons">
								<button type="submit" className="btn h-45px btn-theme d-block w-100 btn-lg">Sign me in</button>
							</div>
						</form>
						{/* <div className="form-floating mb-20px">
							<a href="/user/recoverPass"> Recover password</a>
						</div> */}
					</div>
				</div>
			</div>
			<ReactNotifications /> {/* Ensure ReactNotifications component is included */}

		</div>
	)
}

export default LoginV1;