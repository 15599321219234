import React, { useState } from "react";
import { Panel, PanelHeader, PanelBody } from "./../../components/panel/panel.jsx";
import { upFileprocesss } from './../../utils/urls.js';
import { ReactNotifications, Store } from 'react-notifications-component';

function NewFileUpElements() {
  const [CategoryName, setCategoryName] = useState("");
  const [DataFile, setDataFile] = useState(null); 

  const handleOnChangeCreateRegist = async () => {
    try {
      //console.log('entro a funcion para insert');
      const urlnew = upFileprocesss();

      const formdata = new FormData();
      formdata.append("file", DataFile);
      formdata.append("nameCategory", CategoryName);

      const response = await fetch(urlnew, {
        method: "POST",
        body: formdata,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      //console.log("respuesta de insert");
      //console.log(data);
      addNotification('success', 'Success', 'All your data has been successfully updated', 'top-center', false);
    } catch (error) {
      console.error("Error:", error);
      addNotification('warning', 'Error', 'File not saved', 'top-center', false);
      setDataFile(null);
      setCategoryName("");
    }
  };

  // Notificacion
  function addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition, notificationContent) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent
    });
  }

  return (
    <div>
      <div className="row mb-3">
        <div className="col-xl-6">
          <Panel>
            <PanelHeader>Up new File register</PanelHeader>
            <PanelBody>
              <form>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Name Category/File
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control mb-5px"
                      value={CategoryName}
                      onChange={(e) => setCategoryName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Select File
                  </label>
                  <div className="col-md-9">
                    <input
                      type="file"
                      className="form-control mb-5px"
                      onChange={(e) => setDataFile(e.target.files[0])} // Handle file input correctly
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-7 offset-md-3">
                    <button
                      type="button"
                      className="btn btn-primary w-100px me-5px"
                      onClick={handleOnChangeCreateRegist} // Pass the function reference correctly
                    >
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
      <ReactNotifications /> {/* Ensure ReactNotifications component is included */}
    </div>
  );
}

export default NewFileUpElements;
