// import React from 'react';

// function DropdownLanguage() {
// 	return (
// 		<div className="navbar-item dropdown">
// 			<a href="#/" className="navbar-link dropdown-toggle" data-bs-toggle="dropdown">
// 				<span className="fi fi-us" title="us"></span>
// 				<span className="d-none d-sm-inline ms-1">EN</span> <b className="caret"></b>
// 			</a>
// 			<div className="dropdown-menu dropdown-menu-end">
// 				<a href="#/" className="dropdown-item"><span className="fi fi-us me-2" title="us"></span> English</a>
// 				<a href="#/" className="dropdown-item"><span className="fi fi-cn me-2" title="cn"></span> Español</a>
// 				<a href="#/" className="dropdown-item"><span className="fi fi-jp me-2" title="jp"></span> Japanese</a>
// 				<a href="#/" className="dropdown-item"><span className="fi fi-be me-2" title="be"></span> Belgium</a>
// 				<div className="dropdown-divider"></div>
// 				{/* <a href="#/" className="dropdown-item text-center">more options</a> */}
// 			</div>
// 		</div>
// 	);
// };

// export default DropdownLanguage;


import React from 'react';

function DropdownLanguage() {
	const handleLanguageChange = (lang) => {
		const googleTranslateElement = document.querySelector('.goog-te-combo');
		if (googleTranslateElement) {
		  googleTranslateElement.value = lang;
		  googleTranslateElement.dispatchEvent(new Event('change'));
		}
	  };
	

	  return (
		<div className="navbar-item dropdown">
		  <a href="#/" className="navbar-link dropdown-toggle" data-bs-toggle="dropdown">
			<span className="fi fi-us" title="us"></span>
			<span className="d-none d-sm-inline ms-1">EN</span> <b className="caret"></b>
		  </a>
		  <div className="dropdown-menu dropdown-menu-end">
			<button className="dropdown-item" onClick={() => handleLanguageChange('en')}>
			  <span className="fi fi-us me-2" title="us"></span> English
			</button>
			<button className="dropdown-item" onClick={() => handleLanguageChange('es')}>
			  <span className="fi fi-es me-2" title="es"></span> Español
			</button>
			{/* <button className="dropdown-item" onClick={() => handleLanguageChange('zh-CN')}>
			  <span className="fi fi-cn me-2" title="cn"></span> Chinese
			</button>
			<button className="dropdown-item" onClick={() => handleLanguageChange('ja')}>
			  <span className="fi fi-jp me-2" title="jp"></span> Japanese
			</button> */}

			<div className="dropdown-divider"></div>
			{/* <a href="#/" className="dropdown-item text-center">more options</a> */}
		  </div>
		</div>
	  );

};

export default DropdownLanguage;
