import React, { useState, useEffect, useRef } from "react";
import { Link, useOutletContext } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import Highlight from "react-highlight";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { getFromLocalStorage } from "../../components/helps.js";
import {
  getAllBranch,
  getBranchDataByCompany,
  getModulesAndSubModules,
  getModulesByUser,
  getPermissionByUser,
  getUserBranch,
  getUserByRole,
  putModuleacces,
  putPermissionUser,
  PostRolesOrder,
  getPermissionByUserNewEndpoint,
  postAllCompaniesRoll,
} from "../../utils/urls.js";
import { ReactTags } from "react-tag-autocomplete";
import { fnValidUserPermission } from "../../components/objects/fnCommon.tsx";
import { ReactNotifications, Store } from "react-notifications-component";

function AllClients({ idUser, iduserSelect }) {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  const infoUser = useOutletContext();
  const dataUser = infoUser.infoUser;
  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };

  const initlst = [
    {
      idModule: 0,
      module: "",
      status: false,
      subModule: [{ idSubModule: 0, submodule: "", status: false }],
    },
  ];
  // const initlstPermission = [{ idModule: 0, module: "", read: false, write: false, update: false, delete: false, subModule: [{ idSubModule: 0, submodule: "", read: false, write: false, update: false, delete: false }] }];
  const initlstPermission = [
    {
      idModule: 0,
      module: "",
      read: false,
      write: false,
      update: false,
      delete: false,
      subModule: [
        {
          idSubModule: 0,
          submodule: "",
          read: false,
          write: false,
          update: false,
          delete: false,
        },
      ],
    },
  ];

  const initlstPermission2 = [
    {
      module_id: 0,
      subModule_id: 0,
      module: "",
      subModule: "",
      read: false,
      write: false,
      update: false,
      delete: false,
    },
  ];

  const [data, setData] = useState(null);
  const [validUser, setValidUser] = useState(false);

  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsBranch, setslsBranch] = useState([initSelec]);
  const [slsRoles, setslsRoles] = useState([initSelec2]);   ///////////
  const [slsBranchP, setslsBranchP] = useState([initSelec]);
  const [slsRolesP, setslsRolesP] = useState([initSelec]);
  const [lstModules, setlstModules] = useState([initlst]);
  const [lstPermission, setLstPermission] = useState(initlstPermission);
  const [viewModules, setViewModules] = useState(false);
  const [viewPermission, setViewPermission] = useState(false);

  const [tags, setTags] = useState([initSelec]);
  const [tagsP, setTagsP] = useState([initSelec]);
  const [suggestions, setSuggestions] = useState([{ value: 0, label: "" }]);
  const [suggestionsP, setSuggestionsP] = useState([{ value: 0, label: "" }]);
  const [permissionBranch, setPermissionBranch] = useState(0);
  const [permissionCompany, setPermissionCompany] = useState(0);
  const [permissionRol, setPermissionRol] = useState(0);
  const [IdUserSelect, setIdUserSelect] = useState(0);
  const [permissions, setPermissions] = useState(initlstPermission2);
//   const [initialPermissions, setInitialPermissions] = useState([]); // Estado para guardar los permisos iniciales
  const [loading, setLoading] = useState(false);

  const frmFormularios = useRef(null);
  const frmPermissions = useRef(null);
  const initAccesModuleData = {
    idUser: 0,
    idCompany: 0,
    idBranch: 0,
    idRol: 0,
    module: [
      {
        idModule: 0,
        module: "",
        status: false,
        subModule: [
          {
            idSubModule: 0,
            status: false,
            submodule: "",
          },
        ],
      },
    ],
  };
  const [accesModuleData, setAccesModuleData] = useState(initAccesModuleData);

  const handleDelete = (i) => {
    setTags([initSelec]);
    changeUser(null);
  };

  const handleAdd = (newTag) => {
    setTags([newTag]);
    //console.log(newTag.value);
    changeUser(newTag.value);
  };
  const handleDeleteP = (i) => {
    setTagsP([initSelec]); // Reinicia el estado de tagsP
    changeUserP(null); // Limpia el usuario seleccionado
    setLoading(true)
    // fetchModules(); // Llama a fetchModules cuando se elimina una etiqueta
  };

  const handleAddP = (newTag) => {
    setTagsP([newTag]); // Actualiza el estado con la nueva etiqueta
    changeUserP(newTag.value); // Actualiza el usuario seleccionado
    // fetchModules(); // Llama a fetchModules cuando se agrega una nueva etiqueta
  };

  const fetchLoadCombos = async () => {
    try {
      if (token === undefined || token === null) {
        token = getFromLocalStorage("TK");
      }
      let selectCombos = [initSelec, ...(await fnPostCompaniesList())];
      setslsCompany(selectCombos);
      // selectCombos = [initSelec2, ...(await fnPostRollsOrder())];
      // setslsRoles(selectCombos);
      // setslsRolesP(selectCombos);

      let selectCombosRol = [initSelec2, ...(await fnPostRollsOrder())];

      setslsRolesP(selectCombosRol)
      setslsRoles(selectCombosRol)

      await fetchModules(); // Llama a fetchModules aquí
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  useEffect(() => {
    if (dataUser != null && dataUser != undefined) {
      setValidUser(fnValidUserPermission(dataUser));
      setData(dataUser);
      fetchLoadCombos();
      setAccesModuleData((preventAcces) => ({
        ...preventAcces,
        idCompany: dataUser.id_company,
        idBranch: dataUser.banch_id,
      }));
    }
  }, []);

  const fetchModules = async () => {
    try {
      const listaModulos = await fnGetModules();
      setlstModules(listaModulos);
      console.log("modulos->", listaModulos);

      setAccesModuleData((preventAcces) => ({
        ...preventAcces,
        module: listaModulos,
      }));
      console.log("<<<<<<listaModulos>>>>>>");

      console.log(listaModulos);

      const listPermissionModule = listaModulos.map((item) => {
        return {
          idModule: item.idModule,
          module: item.module,
          read: false,
          write: false,
          update: false,
          delete: false,
          subModule: item.subModule
            ? item.subModule.map((subm) => ({
                idSubModule: subm.idSubModule,
                submodule: subm.submodule,
                read: false,
                write: false,
                update: false,
                delete: false,
              }))
            : [
                {
                  idSubModule: 0,
                  submodule: "",
                  read: false,
                  write: false,
                  update: false,
                  delete: false,
                },
              ],
        };
      });

      setLstPermission(listPermissionModule);
    } catch (error) {
      console.error("Error al cargar los módulos", error);
    }
  };

  const fetchPermisosUsers = async (iduser) => {
    console.log("Fetching permissions for user:", IdUserSelect);
    setLoading(true); // Activar el estado de carga

    try {
      const responseJson = await axios.post(
        getPermissionByUserNewEndpoint(token),
        {
          user_id: iduser,
        }
      );

      const arrayPermisos = responseJson.data.data.permissions;

      // Agrupar permisos por module_id
      const groupedPermissions = arrayPermisos.reduce((acc, item) => {
        const {
          module_id,
          subModule_id,
          module,
          subModule,
          read,
          write,
          update,
          delete: del,
        } = item;

        if (!acc[module_id]) {
          acc[module_id] = {
            moduleName: module,
            subModules: [],
            globalStates: {
              read: false,
              write: false,
              update: false,
              delete: false,
            },
          };
        }

        // Agregar submódulo con subModule_id
        acc[module_id].subModules.push({
          subModule_id, // Añadir subModule_id aquí
          subModuleName: subModule,
          read,
          write,
          update,
          delete: del,
        });

        return acc;
      }, {});

      // Transformar el resultado agrupado en un array
      const processedPermissions = Object.entries(groupedPermissions).map(
        ([module_id, moduleData]) => ({
          module_id,
          ...moduleData,
        })
      );

      console.log("<<<<<<<<<<<processedPermissions>>>>>>>>>>>");

      console.log(processedPermissions);

      // Actualizar los permisos
      setPermissions(processedPermissions);
    //   setInitialPermissions(processedPermissions); // Guardar copia de los permisos iniciales
    } catch (error) {
      console.error("Error al cargar los módulos", error);
    } finally {
      setLoading(false); // Desactivar el estado de carga
    }
  };

  //////////////////////
  useEffect(() => {
    console.log(tagsP.length)

    console.log(tagsP.length)
    if (tagsP.length > 0) {

      // O cualquier otra lógica para validar
      fetchPermisosUsers(0);
    }
  }, [tagsP]);

  useEffect(() => {
    if (tagsP.length > 0) {
      // O cualquier otra lógica para validar
      fetchModules();
    }
  }, [tagsP]);

  ////////////////////////

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol, 
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch
      };
  
      const responseJsonCompanies = await axios.post(postAllCompaniesRoll(token), requestData);

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const fnPostRollsOrder = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol,  
      };
  
      const responseJsonCompanies = await axios.post(PostRolesOrder(token), requestData);

      console.log('responseJsonCompanies.data.data<<<<<<<<<<<<<<<<<<<<<<<<<<')
      console.log(responseJsonCompanies.data.data)
      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };
  const fnGetBranch = async (idCompany) => {
    try {
      const responseJsonBranch = await axios.post(
        getBranchDataByCompany(token),
        {
          companyId: idCompany,
        }
      );
      setAccesModuleData((preventAcces) => ({
        ...preventAcces,
        idCompany: idCompany,
      }));
      // console.log(responseJsonBranch.data);
      const dataResult = responseJsonBranch.data.data.map((item) => ({
        value_option: item.value,
        name_option: item.text,
      }));
      return dataResult;
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const fnGetUserByBranch = async (idBranch) => {
    try {
      setAccesModuleData((preventAcces) => ({
        ...preventAcces,
        idBranch: idBranch,
      }));
      //console.log('dataAccess->', accesModuleData);
      const responseJson = await axios.post(getUserBranch(token), {
        branchId: idBranch,
      });
      //console.log("respuesta de usuarios->", responseJson.data.data);
      return responseJson.data.data;
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };
  const fnGetUserByRol = async (idRole) => {
    try {
      //if ()
      setAccesModuleData((preventAcces) => ({
        ...preventAcces,
        idRole: idRole,
      }));
      const responseJson = await axios.post(getUserByRole(token), {
        company_id: accesModuleData.idCompany,
        branch_id: accesModuleData.idBranch,
        rol_id: idRole,
      });
      console.log("dataAccess2-> <<<<<", accesModuleData);
      console.log("respuesta de usuarios-> <<<<<<", responseJson.data.data);

      const dataResult = responseJson.data.data.map((item) => ({
        value: item.value_option,
        label: item.name_option,
      }));
      return dataResult;
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };
  const fnGetModulesByUser = async (idUser) => {
    try {
      setAccesModuleData((preventAcces) => ({
        ...preventAcces,
        idUser: idUser,
      }));

      const responseJson = await axios.post(getModulesByUser(token), {
        userId: idUser,
      });
      //console.log("respuesta del modulo por usuario ", responseJson);
      if (responseJson.status == 201) {
        const responseDadat =
          responseJson.data != undefined ? responseJson.data : null;
        if (
          responseDadat != null &&
          responseDadat.success != undefined &&
          responseDadat.success != null &&
          responseDadat.success == true
        ) {
          if (
            responseDadat.result != undefined &&
            responseDadat.result != null &&
            responseDadat.result == "success" &&
            (responseDadat.message == undefined ||
              responseDadat.message == null) &&
            responseDadat.data != undefined &&
            responseDadat.data != null
          ) {
            //console.log("entro a la condicion donde si hay respuesta de modulos");
            let lstModulesResult = responseJson.data.data;
            //console.log("respuesta data data", lstModulesResult);
            if (
              lstModulesResult !== undefined &&
              lstModulesResult != null &&
              lstModulesResult.length > 0
            ) {
              // console.log('lista de modulos SWICHES->', lstModules);
              // console.log('lista de respuesta ->', lstModulesResult);
              const newMod = lstModules.map((item) => {
                const itemModule = lstModulesResult.filter(
                  (x) => x.id_module == item.idModule
                );
                // console.log("modules->", item);
                // console.log("resultado de busqueda por modulo ==== ", itemModule);
                if (
                  itemModule != undefined &&
                  itemModule != null &&
                  itemModule.length > 0
                ) {
                  // console.log('tiene mas de un elemento el areglo');
                  if (item.subModule.length > 0) {
                    // console.log('el submodulo de los swiches tiene mas de un elemento');
                    const newSubMod = item.subModule.map((itemSubM) => {
                      // console.log('lista de submodulos?? ->', itemSubM);
                      const statusSubModule = itemModule.find(
                        (x) => x.id_submodule === itemSubM.idSubModule
                      );
                      const statusSb =
                        statusSubModule != undefined && statusSubModule != null
                          ? statusSubModule.status
                          : false;
                      // console.log('statusSubModule ->', statusSubModule);
                      // console.log('statusSb ->', statusSb);
                      return { ...itemSubM, status: statusSb };
                    });
                    // console.log('resultado de submodulo nuevo?  ->', newSubMod);
                    if (newSubMod.some((x) => x.status == true)) {
                      return { ...item, status: true, subModule: newSubMod };
                    } else {
                      return { ...item, status: false, subModule: newSubMod };
                    }
                  } else {
                    //console.log('statusModulo =>', itemModule[0]);
                    return { ...item, status: itemModule[0].status };
                  }
                } else {
                  return { ...item, status: false };
                }
              });
              // console.log('newMod->', newMod);
              setlstModules(newMod);
            } else {
              const newMod = lstModules.map((item) => {
                return { ...item, status: false };
              });
              setlstModules(newMod);
            }
          } else {
            const newMod = lstModules.map((item) => {
              return { ...item, status: false };
            });
            setlstModules(newMod);
          }
        } else {
          const newMod = lstModules.map((item) => {
            return { ...item, status: false };
          });
          setlstModules(newMod);
        }
      } else {
        const newMod = lstModules.map((item) => {
          return { ...item, status: false };
        });
        setlstModules(newMod);
      }
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const fnGetModules = async () => {
    try {
      const responseJson = await axios.get(getModulesAndSubModules(token, idUserP.rol));
      // console.log(responseJson);
      return responseJson.data.data;
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };
  const fnGetPermissionModule = async (idUser) => {
    try {
      const responseJson = await axios.post(getPermissionByUser(token), {
        company_id: permissionCompany,
        branch_id: permissionBranch,
        user_id: idUser,
      });
      const result = responseJson.data.data.permissions;
      console.log(result);

      const group = [];
      for (let i = 0; i < result.length; i++) {
        const item = result[i];
        console.log(item);
        if (!group[item.module_id]) {
          group[item.module_id] = {
            idModule: item.module_id,
            module: item.module,
            read: item.read,
            write: item.write,
            update: item.update,
            delete: item.delete,
            subModule: [],
          };
        }
        const subModule = {
          idSubModule: item.subModule_id,
          submodule: item.subModule,
          read: item.read,
          write: item.write,
          update: item.update,
          delete: item.delete,
        };

        // Agregamos el submódulo al módulo correspondiente
        group[item.module_id].subModule.push(subModule);
      }

      return group;
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const changeCompany = async (e) => {
    try {
      const selectedValue = e.target.value;

      setslsBranch([initSelec, ...(await fnGetBranch(selectedValue))]);
      //console.log("Selec Branch", slsBranch);
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const changeCompanyP = async (e) => {
    try {
      const selectedValue = e.target.value;

      setslsBranchP([initSelec, ...(await fnGetBranch(selectedValue))]);
      setPermissionCompany(selectedValue);
      //console.log("Selec Branch", slsBranch);
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };
  const changeBranche = async (e) => {
    try {
      const selectedValue = e.target.value;

      setSuggestions(await fnGetUserByBranch(selectedValue));
      //console.log("Selec Branch", slsBranch);
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };
  const changeRol = async (e) => {
    try {
      const selectedValue = e.target.value;

      setSuggestions(await fnGetUserByRol(selectedValue));
      //console.log("Selec Branch", slsBranch);
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };
  const changeUser = async (selectedValue) => {
    try {
      //const selectedValue = e.target.value;
      if (selectedValue == null) {
        let listaModulos = await fnGetModules();
        setlstModules(listaModulos);
      } else {
        await fnGetModulesByUser(selectedValue);
      }
      //console.log("Selec Branch", slsBranch);
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };
  const changeBrancheP = async (e) => {
    try {
      const selectedValue = e.target.value;

      setSuggestionsP(await fnGetUserByBranch(selectedValue));
      setPermissionBranch(selectedValue);
      //console.log("Selec Branch", slsBranch);
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };
  const changeRolP = async (e) => {
    try {
      const selectedValue = e.target.value;

      //   console.log("<<<<<<<<<<<<<<<selectedValue>>>>>>>>>>>>>>>");

      //   console.log(selectedValue);

      setSuggestionsP(await fnGetUserByRol(selectedValue));
      setPermissionRol(selectedValue);
      //console.log("Selec Branch", slsBranch);
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };
  const changeUserP = async (selectedValue) => {
    try {
      //const selectedValue = e.target.value;
      console.log("changeP-> <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<", selectedValue);
      if (selectedValue == null) {
        let listaModulos = await fnGetModules();
        setLstPermission(listaModulos);
        setIdUserSelect();
        fetchPermisosUsers(0);


      } else {
        const result = await fnGetPermissionModule(selectedValue);
        console.log(result);
        setLstPermission(result);
        setIdUserSelect(selectedValue);
        fetchPermisosUsers(selectedValue);

      }


      //console.log("Selec Branch", slsBranch);
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const hbdSubmitModules = async (e) => {
    e.preventDefault();
    const formData = new FormData(frmFormularios.current);
    // console.log(formData.entries());
    const resultArray = Array.from(formData.entries()).map(([name, value]) => ({
      name,
      value,
    }));

    console.log(resultArray);
    console.log(lstModules);
    let jsonData = {
      company_id: 0,
      branch_id: 0,
      rol_id: 0,
      users: [],
      accesModules: [],
    };
    //console.log(tags);
    resultArray.push({ name: "idUser", value: tags[0].value });
    resultArray.forEach((value) => {
      if (value.name == "idUser") {
        jsonData.users.push({ user_id: value.value });
      } else if (
        value.name == "company_id" ||
        value.name == "branch_id" ||
        value.name == "rol_id"
      ) {
        jsonData[value.name] = parseInt(value.value);
      }
    });
    let modulesList = [];
    lstModules.forEach((element) => {
      console.log(element.subModule.length, element.subModule);
      if (element.subModule.length == 0) {
        modulesList.push({
          module_id: element.idModule,
          submodule_id: null,
          status: element.status,
        });
      } else {
        element.subModule.forEach((item) => {
          modulesList.push({
            module_id: element.idModule,
            submodule_id: item.idSubModule,
            status: item.status,
          });
        });
      }
    });
    jsonData.accesModules = modulesList;
    console.log("envio del data  <<<<<<<<<<<<<<<<<<<<<", jsonData);
    //console.log("envio del data 2->", { body: jsonData });
    const responseJson = await axios.put(putModuleacces(token), jsonData);
    console.log(responseJson);
    if (responseJson.status === 200) {
      addNotification(
        "success",
        "Success",
        "Updated modules for user",
        "top-center"
      );
    } else {
      addNotification(
        "warning",
        "Error",
        "Error updating modules for users",
        "top-center",
        false
      );
    }
  };

  const handSubmiPermissions = async (e) => {
    e.preventDefault();

    const formData = new FormData(frmPermissions.current);
    const companyId = formData.get("company_id"); // Obtener el ID de la Empresa
    const branchId = formData.get("branch_id");

    const updatedPermissions = [];

    permissions.forEach((module) => {
      // Recorrer todos los submódulos del módulo
      module.subModules.forEach((subModule) => {
        updatedPermissions.push({
          module_id: module.module_id,
          subModule_id: subModule.subModule_id, // Usamos subModule_id en lugar de subModuleName
          read: subModule.read,
          write: subModule.write,
          update: subModule.update,
          delete: subModule.delete,
        });
      });
    });

    const body = {
      company_id: Number(companyId),
      branch_id: Number(branchId),
      user_id: IdUserSelect,
      permissions: updatedPermissions, // Array de permisos
    };

    const responseJson = await axios.put(putPermissionUser(token), body);
    console.log("<<<<<<<Respuesta en Actualizacion de información. >>>>>>>");

    console.log(responseJson);
    if (responseJson.status === 200) {
      addNotification(
        "success",
        "Success",
        "Updated permissions for user",
        "top-center"
      );
    } else {
      addNotification(
        "warning",
        "Error",
        "Error updating permissions for users",
        "top-center",
        false
      );
    }
  };
  // const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChangeModule = (idModule) => {
    // console.log("entro a cambiar el check???", idModule);
    setlstModules((prevModules) =>
      prevModules.map((mod) =>
        mod.idModule === idModule ? { ...mod, status: !mod.status } : mod
      )
    );
    setAccesModuleData((preventAcces) => ({
      ...preventAcces,
      module: lstModules,
    }));
    //console.log('change module acces->', accesModuleData);
  };
  const handleCheckboxChangeSubModule = (idModule, idSubModule) => {
    // console.log("entro a cambiar el check???", idSubModule);
    setlstModules((prevModules) =>
      prevModules.map((mod) =>
        mod.idModule === idModule
          ? {
              ...mod,
              subModule: mod.subModule.map((sub) =>
                sub.idSubModule === idSubModule
                  ? { ...sub, status: !sub.status }
                  : sub
              ),
            }
          : mod
      )
    );
    //console.log('change module lstModules->', lstModules);

    setAccesModuleData((preventAcces) => ({
      ...preventAcces,
      module: lstModules,
    }));
    //console.log('change module acces->', accesModuleData);
  };

  const handleCheckboxChangeModuleP = (e, moduleIndex, subModuleIndex) => {
    const { name, checked } = e.target;
    console.log("Before update:", lstPermission);

    setLstPermission((prevState) => {
      const updatedModules = prevState.map((module, i) => {
        if (i === moduleIndex) {
          if (subModuleIndex !== undefined && subModuleIndex !== null) {
            const updatedSubModules = module.subModule.map((submodule, j) => {
              if (j === subModuleIndex) {
                return { ...submodule, [name]: checked };
              }
              return submodule;
            });
            return { ...module, subModule: updatedSubModules };
          }
          return { ...module, [name]: checked };
        }
        return module;
      });

      console.log("After update:", updatedModules);
      return updatedModules;
    });
  };

  const habdleViewModule = () => {
    setViewModules(!viewModules);
    setViewPermission(false);
  };

  const habdleViewPermission = () => {
    setViewPermission(!viewPermission);
    setViewModules(false);
  };

//   const resetPermissions = () => {
//     // Restablecer los permisos al estado inicial
//     setPermissions(initialPermissions); // `initialPermissions` debe ser un estado inicial guardado al cargar la página
//   };

  const habdleViewPermissionPrev = () => {
    setslsCompany([initSelec]);
    setslsBranchP([initSelec]);
    setslsRolesP([initSelec]);
    setTagsP([initSelec]); // Restablecer los tags de usuario

    fetchLoadCombos();

    // resetPermissions(); // Llamar a la función de reinicio
    setViewPermission(!viewPermission);
    setViewModules(false);
  };

  if (!data) {
    return <div>Loading Dashboard...</div>;
  }

  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }

  //////////////////////////////////////////////////////////////////

  const handleGlobalSwitchChange = (moduleId, permissionType, value) => {
    setPermissions((prevPermissions) =>
      prevPermissions.map((module) =>
        module.module_id === moduleId
          ? {
              ...module,
              globalStates: { ...module.globalStates, [permissionType]: value },
              subModules: module.subModules.map((sub) => ({
                ...sub,
                [permissionType]: value,
              })),
            }
          : module
      )
    );
  };

  const handleSubModuleSwitchChange = (
    moduleId,
    subModuleName,
    permissionType,
    value
  ) => {
    setPermissions((prevPermissions) =>
      prevPermissions.map((module) =>
        module.module_id === moduleId
          ? {
              ...module,
              subModules: module.subModules.map((sub) =>
                sub.subModuleName === subModuleName
                  ? { ...sub, [permissionType]: value }
                  : sub
              ),
            }
          : module
      )
    );
  };

  const PermissionRow = ({ label, states, onChange }) => (
    <div className="col-md-9">
      {["read", "write", "update", "delete"].map((permissionType) => (
        <label key={permissionType} className="form-label text-center col-md-2">
          <input
            type="checkbox"
            checked={states[permissionType]}
            onChange={(e) => onChange(permissionType, e.target.checked)}
          />
          {permissionType}
        </label>
      ))}
    </div>
  );

  //   console.log(
  //     "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<lstPermission>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>"
  //   );
  //   console.log(lstPermission);

  return (
    <div>
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <Link to="/form/elements">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/form/elements">Form</Link>
        </li>
        <li className="breadcrumb-item active">Acces</li>
      </ol>
      <h1 className="page-header">Permisos de acceso</h1>
      <div className="row">
        <div className="col-12">
          <Panel>
            <PanelHeader>Administrador de Modulos por usuario </PanelHeader>
            <PanelBody>
              <div className="lrow md-15px">
                <button
                  type="button"
                  className="btn btn-green"
                  style={{ float: "left" }}
                  onClick={habdleViewModule}
                >
                  Gestion de Modulos
                </button>
                <button
                  type="button"
                  className="btn btn-blue"
                  style={{ float: "right" }}
                  onClick={habdleViewPermission}
                >
                  Gestion de Permisos por modulo
                </button>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
      <div className="row" style={!viewModules ? { display: "none" } : {}}>
        <div className="col-12">
          <Panel>
            <PanelHeader>Administrador de Modulos </PanelHeader>
            <PanelBody>
              <form
                id="frmFormularios"
                ref={frmFormularios}
                onSubmit={hbdSubmitModules}
              >
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Empresa
                  </label>
                  <div
                    className="col-md-9"
                    style={validUser ? {} : { display: "none" }}
                  >
                    <select
                      className="form-select"
                      onChange={changeCompany}
                      name="company_id"
                    >
                      {slsCompany.map((option) => (
                        <option value={option.value_option}>
                          {option.name_option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    className="col-md-9"
                    style={!validUser ? {} : { display: "none" }}
                  >
                    <input
                      type="text"
                      disabled={true}
                      name="company_id"
                      value={data.company_name}
                    ></input>
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Sucursal
                  </label>
                  <div
                    className="col-md-9"
                    style={validUser ? {} : { display: "none" }}
                  >
                    <select
                      className="form-select"
                      onChange={changeBranche}
                      name="branch_id"
                    >
                      {slsBranch.map((option) => (
                        <option value={option.value_option}>
                          {option.name_option}
                        </option>
                      ))}
                    </select>
                    <div
                      className="col-md-9"
                      style={!validUser ? {} : { display: "none" }}
                    >
                      <input
                        type="text"
                        disabled={true}
                        name="company_id"
                        value={data.branch_name}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Tipo de Usuari/Rol
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select"
                      name="rol_id"
                      onChange={changeRol}
                    >
                      {slsRoles.map((option, index) => (
                        <option value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Usuario
                  </label>
                  <div className="col-md-9" style={{ position: "relative" }}>
                    <ReactTags
                      className="form-control"
                      id="autoCompleteUser"
                      name="user_id"
                      selected={tags}
                      suggestions={suggestions}
                      onDelete={handleDelete}
                      onAdd={handleAdd}
                    />
                    <span
                      style={{
                        position: "absolute",
                        display: "block",
                        bottom: ".5rem",
                        right: "1rem",
                      }}
                    >
                      <i className="fas fa-search"></i>
                    </span>
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Modulo/Submodulo
                  </label>

                  <label className="form-label col-form-label col text-center">
                    Estatus
                  </label>
                </div>
                <div className="row mb-15px">
                  <div>
                    {lstModules.map((option) => (
                      <div key={option.idModule} className="row mb-15px">
                        <label
                          className="form-label col-form-label col-md-3 "
                          htmlFor={"sw" + option.idModule}
                        >
                          {option.module}
                        </label>
                        <div
                          className="col-md-9 align-middle form-check form-switch"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={option.status}
                            onChange={() =>
                              handleCheckboxChangeModule(option.idModule)
                            }
                            id={"sw" + option.idModule}
                            name={"idModule_" + option.idModule}
                          />
                        </div>

                        {option.status && option.subModule.length > 0 && (
                          <div key={"key_" + option.idSubModule}>
                            {option.subModule.map((submodule) => (
                              <div
                                key={submodule.idSubModule}
                                className="row mb-15px"
                              >
                                <label
                                  className="form-label col-form-label col-md-3"
                                  htmlFor={"sw" + submodule.idSubModule}
                                  style={{ textAlign: "center" }}
                                >
                                  {submodule.submodule}
                                </label>
                                <div
                                  className="col-md-9 align-middle form-check form-switch"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={"sw" + submodule.idSubModule}
                                    checked={submodule.status}
                                    onChange={() =>
                                      handleCheckboxChangeSubModule(
                                        option.idModule,
                                        submodule.idSubModule
                                      )
                                    }
                                    name={
                                      "idSubModule_" + submodule.idSubModule
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="lrow md-15px">
                  <button
                    type="submit"
                    className="btn btn-green"
                    style={{ float: "right" }}
                  >
                    Guardar
                  </button>
                  <button
                    type="button"
                    className="btn btn-red"
                    style={{ float: "right" }}
                    onClick={habdleViewModule}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
      <div className="row" style={!viewPermission ? { display: "none" } : {}}>
        <div className="col-12">
          <Panel>
            <PanelHeader>Permisos por usuario </PanelHeader>
            <PanelBody>
              <form
                id="frmPermissions"
                ref={frmPermissions}
                onSubmit={handSubmiPermissions}
              >
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Empresa
                  </label>
                  <div
                    className="col-md-9"
                    style={data == null ? { display: "none" } : {}}
                  >
                    <select
                      className="form-select"
                      onChange={changeCompanyP}
                      name="company_id"
                    >
                      {slsCompany.map((option) => (
                        <option value={option.value_option}>
                          {option.name_option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    className="col-md-9"
                    style={data != null ? { display: "none" } : {}}
                  >
                    <input
                      type="text"
                      disabled={true}
                      name="company_id"
                      value={data.company_name}
                    ></input>
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Sucursal
                  </label>
                  <div
                    className="col-md-9"
                    style={data == null ? { display: "none" } : {}}
                  >
                    <select
                      className="form-select"
                      onChange={changeBrancheP}
                      name="branch_id"
                    >
                      {slsBranchP.map((option) => (
                        <option value={option.value_option}>
                          {option.name_option}
                        </option>
                      ))}
                    </select>
                    <div
                      className="col-md-9"
                      style={data != null ? { display: "none" } : {}}
                    >
                      <input
                        type="text"
                        disabled={true}
                        name="branch_id"
                        value={data.branch_name}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Tipo de Usuari/Rol
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select"
                      name="rol_id"
                      onChange={changeRolP}
                    >
                      {slsRolesP.map((option, index) => (
                        <option value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">
                    Usuario
                  </label>
                  <div className="col-md-9" style={{ position: "relative" }}>
                    <ReactTags
                      className="form-control"
                      id="autoCompleteUser"
                      name="user_id"
                      selected={tagsP}
                      suggestions={suggestionsP}
                      onDelete={handleDeleteP}
                      onAdd={handleAddP}
                    />
                    <span
                      style={{
                        position: "absolute",
                        display: "block",
                        bottom: ".5rem",
                        right: "1rem",
                      }}
                    >
                      <i className="fas fa-search"></i>
                    </span>
                  </div>
                </div>

                <div id="Info-permissions" className="row mb-15px">
                  {loading ? (
                    <div className="text-center">.........</div>
                  ) : (
<table className="table table-bordered">
  <thead>
    <tr>
      <th style={{ width: "20%" }}>Modulo / Submodulo</th>
      <th>Read</th>
      <th>Write</th>
      <th>Update</th>
      <th>Delete</th>
    </tr>
  </thead>
  
  <tbody>
    {/* Validación antes de iterar sobre permissions */}
    {permissions && permissions.length > 0 ? (
      permissions.map((module) => (
        <React.Fragment key={module.module_id}>
          {/* Fila del módulo principal */}
          <tr className="module-row">
            <td className="fw-bold text-uppercase text-start">
              {module.moduleName}
            </td>
            {["read", "write", "update", "delete"].map((permissionType) => (
              <td key={permissionType} className="text-center">
                <div className="form-check form-switch d-flex justify-content-center">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    role="switch"
                    checked={module.globalStates[permissionType]}
                    onChange={(e) =>
                      handleGlobalSwitchChange(
                        module.module_id,
                        permissionType,
                        e.target.checked
                      )
                    }
                  />
                </div>
              </td>
            ))}
          </tr>
          {/* Filas de submódulos */}
          {module.subModules.map((subModule) => (
            <tr key={subModule.subModuleName} className="submodule-row">
              <td className="text-capitalize text-end">
                {subModule.subModuleName.toLowerCase()}
              </td>
              {["read", "write", "update", "delete"].map((permissionType) => (
                <td key={permissionType} className="text-center">
                  <div className="form-check form-switch d-flex justify-content-center">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      role="switch"
                      checked={subModule[permissionType]}
                      onChange={(e) =>
                        handleSubModuleSwitchChange(
                          module.module_id,
                          subModule.subModuleName,
                          permissionType,
                          e.target.checked
                        )
                      }
                    />
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </React.Fragment>
      ))
    ) : (
      <tr>
        <td colSpan="5" className="text-center">
        No permits available.
        </td>
      </tr>
    )}
  </tbody>
</table>

                  )}
                  <div className="lrow md-15px">
                    <button
                      type="submit"
                      className="btn btn-green"
                      style={{ float: "right" }}
                    >
                      Guardar
                    </button>
                    <button
                      type="button"
                      className="btn btn-red"
                      style={{ float: "right" }}
                      onClick={habdleViewPermissionPrev}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
      <ReactNotifications />{" "}
    </div>
  );
}

export default AllClients;
