import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications, Store } from "react-notifications-component";
import { postUpdatecity,} from "../../utils/urls.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";

function EditupdateCity({ id, infoRegister }) {
    const [isEditMode, setIsEditMode] = React.useState(false); // Control de visibilidad del componente

  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  const [formValues, setFormValues] = useState({
    nameGoogle: "",
    namepr:  "",
  });

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const { nombredestino, urlimagen, statename, place } = formValues;

//     if (!nombredestino || !urlimagen || !statename || !place) {
//       Store.addNotification({
//         title: "Warning",
//         message: "All fields are required!",
//         type: "warning",
//         insert: "top",
//         container: "top-right",
//         animationIn: ["animate__animated", "animate__fadeIn"],
//         animationOut: ["animate__animated", "animate__fadeOut"],
//         dismiss: {
//           duration: 3000,
//           onScreen: true,
//         },
//       });
//       return;
//     }

//     // console.log("Form data submitted:", formValues);

//     const formData = new FormData();
//     formData.append("id_destino", id);
//     formData.append("nombre", formValues.nombredestino);
//     formData.append("urlimagen", formValues.urlimagen); // Agrega el archivo
//     formData.append("place", formValues.place);
//     formData.append("statename", formValues.statename);
//     formData.append("id_empresa", formValues.idcliente);
//     formData.append("id_sucursal", formValues.branch);

//     // console.log('<<<<<<<<<<<<<<<<<<formData>>>>>>>>>>>>>>>>>>')

//     // console.log("FormData contenido:");
//     // for (let [key, value] of formData.entries()) {
//     //   console.log(key, value);
//     // }

//     const response = await fetch(PostEditinfoDestin(token), {
//       method: "POST",
//       body: formData,
//     });

//     console.log('<<<<<<<<response>>>>>>>>')

//     console.log(response)
//     if(response.status == 201){

//         addNotification("success", "Success", "Destination updated successfully.", "top-center");
//     }else{
//         addNotification(
//             "warning",
//             "Partial Success",
//             "Oops, there was an error during the update.",
//             "top-center"
//           );
//     }
//   };

const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        var body = {
            id: id,
            name_google: formValues.nameGoogle,
            name_pr: formValues.namepr
            // id: "688114",
            // name_google: "Google Name Example",
            // name_pr: "PR Name Example"
        }

        const request = await fnpostUpdateRegister(body);
        addNotification("success", "Success", request, "top-center");

        
    } catch (error) {
        
        addNotification(
            "warning",
            "Partial Success",
            "Oops, there was an error during the update.",
            "top-center"
            );
    }

};

const fnpostUpdateRegister = async (body) => {
    try {
        const responseTEXT = await axios.post(postUpdatecity(),body);
        return responseTEXT.data;
    } catch (error) {
        console.error("Error al cargar los combos 333", error);
        return [];
    }
    };

    function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
    ) {
    if (notificationContent) {
        notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
            <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
            </div>
        </div>
        );
    }
    Store.addNotification({
        title: notificationTitle,
        message: notificationMessage,
        type: notificationType,
        insert: "top",
        container: notificationPosition,
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 2000 },
        dismissable: { click: true },
        content: notificationContent,
    });
    }


  console.log('<<<<<<<<<<<<<<<<<<<<< formValues >>>>>>>>>>>>>>>>>>')
  console.log(id)
  console.log(formValues)


  return (
    <Panel>
      <PanelBody>
        <form onSubmit={handleSubmit}>

        <div className="row mb-3 d-flex align-items-center">
  <label
    className="form-label col-form-label col-md-3 mb-0"
    htmlFor="CountryName"
  >
    Country Name
  </label>
  <div className="col-md-9">
    <input
      className="form-control"
      type="text"
      placeholder={infoRegister.country_name}
      readOnly // Aquí la corrección
    />
  </div>
</div>




          <div className="row mb-3 d-flex align-items-center">
            <label
              className="form-label col-form-label col-md-3 mb-0"
              htmlFor="nombredestino"
            >
              Name Google*
            </label>
            <div className="col-md-9">
              <input
                className="form-control"
                type="text"
                id="nameGoogle"
                name="nameGoogle"
                value={formValues.nameGoogle}
                onChange={handleInputChange}
                placeholder={infoRegister.country_name_google}

              />
            </div>
          </div>

          <div className="row mb-3 d-flex align-items-center">
            <label
              className="form-label col-form-label col-md-3 mb-0"
              htmlFor="nombredestino"
            >
              Name PR*
            </label>
            <div className="col-md-9">
              <input
                className="form-control"
                type="text"
                id="namepr"
                name="namepr"
                value={formValues.namepr}
                onChange={handleInputChange}
                placeholder={infoRegister.country_name_pr}

              />
            </div>
          </div>

          <div className="lrow md-15px" style={{ margin: 10 }}>
            <button
              type="submit"
              className="btn btn-green"
              style={{ float: "right" }}
            >
              Update record
            </button>
          </div>
        </form>


        
      </PanelBody>
    </Panel>
  );
}

export default EditupdateCity;
