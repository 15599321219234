import React, { useState, useEffect, useRef } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications, Store } from "react-notifications-component";
import {
  ListContryCitys,
  postAllCompaniesRoll,
  getBranchDataByCompany,
  GetInfoDestinos,
  PostEditinfoDestin,
  PostEditupdateOferta,
  PostCombosDetailsHotels,
  PostHotelsSearch,
  PostEditCombosHotel,
} from "../../utils/urls.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";
import DateTime from "react-datetime";

function EditComboHotels({ id, infoRegister, infocombo }) {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");
  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };
  const initInfoHotels = {
    hotelid: 0,
    name: "",
    address1: "",
    city: "",
    stateprovince: "",
    postalcode: "",
    country: "",
    nomach: false,
  };
  

  const [slsListHotelsSerch, setslsListHotelsSerch] = useState([
    initInfoHotels,
  ]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedHotels, setSelectedHotels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editinfoRegister, setEditinfoRegister] = useState([]);

  const [draggedIndex, setDraggedIndex] = useState(null);
  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);

      const [formValues, setFormValues] = useState({
          combo_id: infocombo.combo_id,
          name: infocombo.name,
          description: infocombo.description,
          available: infocombo.available,
          company: infocombo.id_empresa,
          branch: infocombo.id_sucursal,

        });

  // Efecto para inicializar editinfoRegister con infoRegister cuando el componente se monta o infoRegister cambia
  useEffect(() => {
    if (Array.isArray(infoRegister) && infoRegister.length > 0) {
      setEditinfoRegister([...infoRegister]);
    }
  }, [infoRegister]);

  ////////////////

  const handleInputChangeform = (e) => {
    const { name, value } = e.target; // Obtén el nombre y valor del input
    setFormValues((prevState) => ({
      ...prevState, // Mantén los valores anteriores
      [name]: value, // Actualiza solo el campo correspondiente
    }));
  };

  const handleInputChangeCompany = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      ...(name === "company" && { branch: "" }), // Reiniciar branch si cambia la compañía
    }));
  };

  const handleRemoveHotel = (index) => {
    const updatedHotels = [...editinfoRegister];

    // Eliminar el hotel en la posición `index`
    updatedHotels.splice(index, 1);

    // Actualizar el orden después de la eliminación
    const reorderedHotels = updatedHotels.map((hotel, idx) => ({
      ...hotel,
      orden: idx + 1, // Se reasigna el orden de manera secuencial
    }));

    setEditinfoRegister(reorderedHotels);
  };

  // Función cuando el arrastre inicia
  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  // Permitir soltar el elemento en otra posición
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Cuando se suelta el elemento, reordena la lista
  const handleDrop = (index) => {
    if (draggedIndex === null) return;

    const updatedList = [...editinfoRegister];
    const movedItem = updatedList.splice(draggedIndex, 1)[0];
    updatedList.splice(index, 0, movedItem);

    // Actualiza el orden en base al nuevo índice
    const updatedHotels = updatedList.map((hotel, idx) => ({
      ...hotel,
      orden: idx + 1,
    }));

    setEditinfoRegister(updatedHotels);
    setDraggedIndex(null);
  };

  ////////////////

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add logic for form submission here

    console.log(' nuevo combo creado ya editado.   ')
    console.log(editinfoRegister)

    const comboBodyBase = {
        combo: {
            id: id,
            name: formValues.name,
            description: formValues.description,
            id_empresa: Number(infocombo.id_empresa),
            id_sucursal: Number(infocombo.id_sucursal),
            available: true,
        },
        hotels: [
            // Iteramos sobre infoRegister, pero si el hotel está en editinfoRegister, tomamos la versión actualizada
            ...infoRegister.map((hotel) => {
                const updatedHotel = editinfoRegister.find(e => e.hotel_id === hotel.hotel_id);
                return {
                    hotel_id: hotel.hotel_id,
                    orden: updatedHotel ? updatedHotel.orden : hotel.orden,
                    banner: updatedHotel ? updatedHotel.banner : hotel.banner,
                    available: !!updatedHotel, // true si existe en editinfoRegister, false si no
                };
            }),
    
            // Agregamos los hoteles que solo están en editinfoRegister (es decir, los nuevos añadidos)
            ...editinfoRegister
                .filter(hotel => !infoRegister.some(e => e.hotel_id === hotel.hotel_id)) // Solo los nuevos
                .map((hotel) => ({
                    hotel_id: hotel.hotel_id,
                    orden: hotel.orden,
                    banner: hotel.banner || false,
                    available: true, // Los nuevos añadidos deben estar disponibles
                }))
        ]
    };
    
    

      console.log(' <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<comboBodyBase>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
console.log(comboBodyBase)

try {
    const result = await fnUpdateComboHotels(comboBodyBase);
    // console.log(`Combo actualizado para la sucursal:`, result);


  } catch (error) {
    console.error(`Error guardando combo para la sucursal :`, error);
  }

  };

  const fnUpdateComboHotels = async (body) => {
    console.log("Fetching permissions body: <<<<<<<<<<<<<<<<<<", body);
    try {
      const responseJson = await axios.post(PostEditCombosHotel(token), body);

       console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<responseJson>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
     console.log(responseJson)

      const DataResulthotels = responseJson.data.data;
    //   console.log('<<<<<<<<<<<<<<<<<<<<<<<<DataResulthotels>>>>>>>>>>>>>>>>>>>>>>>>');
    //   console.log(DataResulthotels);

    if(responseJson.status == 201){

        addNotification("success", "Success", "Destination updated successfully.", "top-center");
    }else{
        addNotification(
            "warning",
            "Partial Success",
            "Oops, there was an error during the update.",
            "top-center"
          );
    }

      return DataResulthotels;
    } catch (error) {
      console.error("Error al cargar los módulos", error);
    } finally {
      setLoading(false); // Desactivar el estado de carga
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setEditinfoRegister([...infoRegister]); // Restablece al valor original
    setslsListHotelsSerch([initInfoHotels]); // <- Asegurar que sigue siendo un array
};

  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          {/* <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div> */}
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }

  const handleInputChangeSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    console.log("entro a funcion de buscar <<< ", searchInput);
    postSearchData();
    let listHotelsInfo = [initInfoHotels, ...(await postSearchData())];

    setslsListHotelsSerch(listHotelsInfo);
  };

  const handleHotelSelect = (hotel) => {
    setSelectedHotels((prev) => {
      const isAlreadySelected = prev.some(
        (selected) => selected.hotelid === hotel.hotelid
      );

      if (isAlreadySelected) {
        return prev.filter((selected) => selected.hotelid !== hotel.hotelid);
      } else {
        if (prev.length >= 13) {
          addNotification(
            "warning",
            "Error",
            "You can only select up to 13 hotels.",
            "top-center",
            false
          );

          return prev;
        }
        return [...prev, hotel];
      }
    });
  };

  const postSearchData = async () => {
    setLoading(true);

    try {
      const responseJson = await axios.post(PostHotelsSearch(token), {
        value: searchInput,
      });

      const DataResulthotels = responseJson.data.data;

      console.log(DataResulthotels);

      return DataResulthotels;
    } catch (error) {
      console.error("Error al cargar los módulos", error);
    } finally {
      setLoading(false);
    }
  };

  const isHotelSelected = (hotelid) =>
    selectedHotels.some((selected) => selected.hotelid === hotelid);

//   const handleAddHotel = (hotel) => {
//     if (editinfoRegister.length >= 13) {
//       addNotification(
//         "warning",
//         "Error",
//         "You can only select up to 13 hotels.",
//         "top-center",
//         false
//       );
//       return;
//     }
  
//     // Verifica si el hotel ya está en la lista
//     const isAlreadyAdded = editinfoRegister.some((selected) => {
//         console.log(`Comparando <<<<<<<<< ${selected.hotel_id} con ${hotel.hotelid}`);
//         return Number(selected.hotel_id) === Number(hotel.hotel_id);
//       });
  
//     if (isAlreadyAdded) {
//       addNotification(
//         "warning",
//         "Error",
//         `This hotel is already selected.`,
//         "top-center",
//         false
//       );
//       return;
//     }
  
//     // Calcula el siguiente número de orden basado en los valores existentes
//     const maxOrden = editinfoRegister.reduce(
//       (max, item) => Math.max(max, item.orden || 0),
//       0
//     );
  
//     const newHotel = {
//       ...hotel,
//       orden: maxOrden + 1, // Asigna el orden siguiente
//     };
  
//     setEditinfoRegister((prev) => [...prev, newHotel]);
//   };
  
const handleAddHotel = (hotel) => {
    if (editinfoRegister.length >= 13) {
      addNotification(
        "warning",
        "Error",
        "You can only select up to 13 hotels.",
        "top-center",
        false
      );
      return;
    }
  
    // Normalizar el ID del hotel a número
    const hotelIdToCompare = Number(hotel.hotelid);
    if (isNaN(hotelIdToCompare)) {
      console.error("Error: hotelid no es un número válido:", hotel.hotelid);
      return;
    }
  
    // Validar si el hotel ya está en la lista
    const isAlreadyAdded = editinfoRegister.some((selected) =>
      Number(selected.hotel_id) === hotelIdToCompare
    );
  
    if (isAlreadyAdded) {
      addNotification(
        "warning",
        "Error",
        "This hotel is already selected.",
        "top-center",
        false
      );
      return;
    }
  
    // Obtener el siguiente número de orden basado en los hoteles disponibles
    const maxOrden = editinfoRegister
      .filter((hotel) => hotel.available)
      .reduce((max, item) => Math.max(max, item.orden || 0), 0);
  
    const newHotel = {
      ...hotel,
      hotel_id: hotelIdToCompare,
      orden: maxOrden + 1, // Asigna el siguiente orden disponible
      available: true, // Asegura que el hotel agregado esté disponible
    };
  
    setEditinfoRegister((prev) => [...prev, newHotel]);
  };
  
  

  const handleSelectHotelBanner = (index) => {

    console.log(' index <<<<<<<<<<<<<<<<<<<')
    console.log(index)


    setEditinfoRegister((prevHotels) =>
      prevHotels.map((hotel, i) => ({
        ...hotel,
        banner: i === index ? true : false, // Solo el seleccionado es true, los demás false
      }))
    );
  };

  console.log('infocombo <<<<<<<<<<<<')
console.log(infocombo)


  return (
    <Panel>
      <PanelBody>

        <div className="lrow md-7px" style={{ margin: 2, display: "block", marginBottom: "10px" }}>
            *perform editing for order, selected banner, and change hotels
        </div>

        <form>
      {/* Inputs para capturar datos del combo */}
      <div className="row mb-3 d-flex align-items-center">
        <label
          className="form-label col-form-label col-md-3 mb-0"
          htmlFor="nombredestino"
        >
          Name combo*
        </label>
        <div className="col-md-9">
          <input
            className="form-control"
            type="text"
            id="name"
            name="name" // Nombre debe coincidir con la clave en el estado
            value={formValues.name}
            onChange={handleInputChangeform}
          />
        </div>
      </div>

      <div className="row mb-3 d-flex align-items-center">
        <label
          className="form-label col-form-label col-md-3 mb-0"
          htmlFor="urlimagen"
        >
          Description*
        </label>
        <div className="col-md-9">
          <input
            className="form-control"
            type="text"
            id="description"
            name="description" // Nombre debe coincidir con la clave en el estado
            value={formValues.description}
            onChange={handleInputChangeform}
          />
        </div>
      </div>


      <div>

</div>

    </form>


        <div
    style={{
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
      justifyContent: "flex-start",
    }}
  >
    {editinfoRegister.map((hotel, index) => (
      <div
        key={hotel.hotel_id}
        draggable
        onDragStart={() => handleDragStart(index)}
        onDragOver={handleDragOver}
        onDrop={() => handleDrop(index)}
        onClick={() => handleSelectHotelBanner(index)} // Agregamos evento para cambiar el banner
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          padding: "10px",
          border: "3px solid #ccc",
          borderRadius: "5px",
          color: "#000",
          cursor: "pointer", // Cambia a pointer para indicar que es seleccionable
          transition: "background-color 0.3s",
          width: "32%",
          maxWidth: "90%",
          backgroundColor: hotel.banner ? "#e0f7fa" : "#fff", // Aplica color dinámico
        }}
      >
        <img
          src={
            hotel.imagen_hotel ||
            "https://img.freepik.com/vector-premium/no-hay-foto-disponible-icono-vector-simbolo-imagen-predeterminado-imagen-proximamente-sitio-web-o-aplicacion-movil_87543-10615.jpg"
          }
          alt={hotel.name}
          style={{
            width: "150px",
            height: "100px",
            objectFit: "cover",
            marginRight: "15px",
          }}
        />
        <div style={{ flex: 1 }}>
          <strong>{hotel.name}</strong>
          <p>Orden: {hotel.orden}</p>
          <p>Banner: {hotel.banner ? "Active" : ""}</p>

          <button onClick={() => handleRemoveHotel(index)}>Remove</button>
        </div>
      </div>
    ))}
  </div>

        <div className="navbar-form">
          <form action="" method="POST" name="search_form">
            <div
              className="form-group"
              style={{ position: "relative", padding: "10px" }}
            >
              <input
                type="text"
                className="form-control"
                placeholder="Enter city or hotel name"
                value={searchInput}
                onChange={handleInputChangeSearch}
                style={{ paddingRight: "40px" }}
              />
              <button
                type="submit"
                className="btn btn-search"
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
                onClick={handleSearch}
              >
                <i className="fa fa-search"></i>
              </button>
            </div>

            <div className="lrow md-7px" style={{ margin: 2 }}>
              *Select the hotels to display, the limit is 13
            </div>

            <div
              className="lrow md-8px"
              style={{
                margin: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxHeight: "650px",
                overflowY: "auto",
                border: "1px solid #ccc",
                padding: "10px",
              }}
            >
              {loading ? (
                <div>Loading...</div>
              ) : (
                slsListHotelsSerch
                  .slice(1)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((hotel) => (
                    <div
                      key={hotel.hotelid}
                      onClick={() => handleAddHotel(hotel)}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginBottom: "10px",
                        padding: "10px",
                        border: "2px solid #ccc",
                        borderRadius: "5px",
                        backgroundColor: isHotelSelected(hotel.hotelid)
                          ? "#60adf6"
                          : "#fff",
                        color: isHotelSelected(hotel.hotelid) ? "#fff" : "#000",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                        width: "700px",
                        maxWidth: "90%",
                        alingc: "center",
                      }}
                    >
                      <div>
                        <strong>{hotel.name}</strong>
                        <p>
                          {hotel.country} - {hotel.city}
                        </p>
                        <p>Address: {hotel.address1}</p>
                      </div>
                    </div>
                  ))
              )}
            </div>


          </form>
        </div>

        <div className="lrow md-15px" style={{ margin: 10 }}>
          <button
            type="button"
            className="btn btn-red"
            style={{ float: "left" }}
            onClick={handleCancel}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-green"
            style={{ float: "right" }}
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>


      </PanelBody>
    </Panel>
  );
}

export default EditComboHotels;
