import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useOutletContext } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "../../components/helps.js";
import {
  getInfoCompanies,
  addCompany,
  postAllCompaniesRoll,
  putCompany,
} from "../../utils/urls.js";
import { ReactNotifications, Store } from "react-notifications-component";
import {
  fnGetTable,
  fnValidUserPermission,
} from "../../components/objects/fnCommon.tsx";
import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";
import FormsDinamic from "../../components/objects/formsDinamic.tsx";
import InfoUsers from "./dataUsers-allusers.js";
import { Modal, Button } from "react-bootstrap";

function TableCompanies() {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  // const infoUser = useOutletContext();
  // Acceder al contexto
  const { infoUser, permissions } = useOutletContext();

  // Ahora puedes acceder a los permisos y la información del usuario
  const canRead = permissions?.read ?? false;
  const canWrite = permissions?.write ?? false;
  const canUpdate = permissions?.update ?? false;
  const canDelete = permissions?.delete ?? false;

  const dataUser = infoUser;

  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };
  const initValues = {
    company_id: 2,
    tax_regime_id: null,
    name: "",
    cfdi_id: null,
    short_name: "",
    company_name: "",
    rfc: "",
    line_id: 2,
    addres: "",
    clabe: null,
    rnt: null,
    logo: null,
    status: true,
    date_register: "",
    date_unsubscribe: null,
    date_update: "",
    tax_regimen: "",
    cfdi: "",
  };

  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);

  const [info, setInfo] = useState([]);
  const [header, setHeader] = useState([]);
  const [headerMap, setheaderMap] = useState();
  const [formvisible, setformvisible] = useState(false);
  const [formvisibleEdit, setformvisibleEdit] = useState(false);
  const [tblvisible, settblvisible] = useState(true);
  const [formValues, setFormValues] = useState({
    tax_regime_id: 0,
    cfdi_id: 0,
    line_id: 0,
    name: "",
    short_name: "",
    company_name: "",
    rfc: "",
    addres: "",
    clabe: "",
    rnt: "",
    logo: null,
  });

  const [formValuesEdit, setFormValuesEdit] = useState({
    company_id: 0,
    tax_regime_id: 0,
    cfdi_id: 0,
    line_id: 0,
    name: "",
    short_name: "",
    company_name: "",
    rfc: "",
    addres: "",
    clabe: "",
    rnt: "",
    logo: null,
  });
  const [idRegistro, setidRegistro] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(true);
  /// variables para mostrar modal
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [slsValues, setslsValues] = useState([initValues]);
  const [reload, setreload] = useState(false);
  

  //////////////////////// funciones base para peticiones <<<<<<<<<<<<<<<<<<<<<<<<

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol,
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch,
      };

      const response = await axios.post(
        postAllCompaniesRoll(token),
        requestData
      );
      return response.data.data || [];
    } catch (error) {
      console.error("Error al cargar las empresas", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      const companies = await fnPostCompaniesList();
      setslsCompany([
        initSelec,
        ...(Array.isArray(companies) ? companies : []),
      ]);
    };
    fetchCompanies();
  }, []);

  // Función para actualizar la lista de sucursales cuando cambia la empresa seleccionada
  useEffect(() => {
    if (formValues.company) {
      const fetchBranches = async () => {
        try {
          setslsBranches([initSelec2]); // Resetea antes de cargar
        } catch (error) {
          console.error("Error obteniendo sucursales:", error);
          setslsBranches([initSelec2]);
        }
      };
      fetchBranches();
    }
  }, [formValues.company]);

  /////////// termina grupo de funciones combo base <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // useEffect(() => {
  //   const fetchTable = async () => {
  //     try {
  //       if (token === undefined || token === null) {
  //         token = getFromLocalStorage("TK");
  //       }
  //       let idCompany = dataUser.id_company;
  //       if (fnValidUserPermission(dataUser)) {
  //         idCompany = 0;
  //       }
  //       //// traer compañias por rol
  //       const url = getInfoCompanies(idCompany, token, idUserP.rol);
  //       console.log(
  //         "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<url>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>"
  //       );

  //       console.log(url);
  //       const infoResult = await fnGetTable(url);
  //       console.log("<<<<<<<<<<<<<<<<< empresas a mostrar >>>>>>>>>>>>>>>>>");

  //       console.log(infoResult);
  //       setInfo(infoResult.data);
  //       setHeader(infoResult.head);
  //       setheaderMap(infoResult.headerMap);

  //       const renameValues = infoResult.data.map((arraydata) => ({
  //         ...arraydata,
  //         id: arraydata.company_id,
  //       }));

  //       setslsValues(renameValues);
  //     } catch (error) {
  //       console.error("error de fetchTable", error);
  //     }
  //   };

  //   if (infoUser != null) {
  //     fetchTable();
  //   }

  //   if (reload) {
  //     fetchTable();
  //     setreload((prev) => !prev);

  //   }
  // }, [infoUser, reload]);


  useEffect(() => {
    const fetchTable = async () => {
      try {
        if (token === undefined || token === null) {
          token = getFromLocalStorage("TK");
        }
        let idCompany = dataUser.id_company;
        if (fnValidUserPermission(dataUser)) {
          idCompany = 0;
        }
  
        //// traer compañias por rol
        const url = getInfoCompanies(idCompany, token, idUserP.rol);
        console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<url>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
        console.log(url);
  
        const infoResult = await fnGetTable(url);
        console.log("<<<<<<<<<<<<<<<<< empresas a mostrar >>>>>>>>>>>>>>>>>");
        console.log(infoResult);
  
        setInfo(infoResult.data);
        setHeader(infoResult.head);
        setheaderMap(infoResult.headerMap);
  
        const renameValues = infoResult.data.map((arraydata) => ({
          ...arraydata,
          id: arraydata.company_id,
        }));
  
        setslsValues(renameValues);
      } catch (error) {
        console.error("error de fetchTable", error);
      } finally {
        // Si reload es true, cambiarlo a false después de ejecutar fetchTable
        if (reload) {
          setreload(false);
        }
      }
    };
  
    if (infoUser !== null) {
      fetchTable();
    }
  }, [infoUser, reload]); // Se ejecuta cuando infoUser o reload cambian
  


  const fnEditRegister = async (idRegister) => {
    console.log("información para editar registro << ", idRegister);
    setformvisibleEdit(!formvisibleEdit)
    settblvisible(!tblvisible);

    const detailData = slsValues.find(
      (datainfo) => datainfo.company_id === idRegister.company_id
    );

    if (detailData) {
      setFormValuesEdit({
        company_id: detailData.company_id || 0,

        tax_regime_id: detailData.tax_regime_id || 0,
        cfdi_id: detailData.cfdi_id || 0,
        name: detailData.name || "",
        short_name: detailData.short_name || "",
        company_name: detailData.company_name || "",
        rfc: detailData.rfc || "",
        addres: detailData.addres || "",
        clabe: detailData.clabe || "",
        rnt: detailData.rnt || "",
        logo: detailData.logo || "",
      });
    }

  };

  const fnChangeView = () => {
    setformvisible(!formvisible);
    settblvisible(!tblvisible);
  };

  const fnDeleteRegister = async (idRegister) => {
    console.log("información de registro << ", idRegister);

    try {

        const requestData = new FormData();
        requestData.append("company_id", idRegister.company_id);
        requestData.append("status", false);
        // const requestData = {
        //   company_id: idRegister.company_id,
        //   status: false
        // }
 
      // console.log("requestData delete registro <<<<<<<<<<<<<<<<<<<<", requestData);

      const response = await axios.put(putCompany(token), requestData);

      // console.log("<<<<<<<<<<<<<<<<<<<<<<<response>>>>>>>>>>>>>>>>>>>>>>>");
      // console.log(response);
      // console.log(response.status);

      if (response.status == 200) {
        addNotification(
          "success",
          "Success",
          "Destination updated successfully.",
          "top-center"
        );

        setreload((prev) => !prev); // volver a cargar informacion de tabla
      } else {
        addNotification(
          "warning",
          "Partial Success",
          "Oops, there was an error during the update.",
          "top-center"
        );
      }
    } catch (error) {
      addNotification(
        "warning",
        "Partial Success",
        "Oops, there was an error during the update.",
        "top-center"
      );
    }
  };

  const fnCancelAddcompany = () => {
    setformvisible(false);
    settblvisible(true);
  };

  const fnViewDetail = (idRegister) => {
    console.log("informacion a mostrar en modal:  ", idRegister);
    const detailData = slsValues.find(
      (datainfo) => datainfo.company_id === idRegister.company_id
    );

    setModalData(detailData);
    setShowModal(true);
  };

  const handleCancel = () => {
    fnChangeView();
  };
  //////Formulario

  const handleSubmit = async (e) => {
	e.preventDefault();
	console.log("<<<<<<<<<<<<<<<<<<<<<<<< formValues");
	console.log(formValues);
		try {
		  const PostDataUser = async () => {
        const formData = new FormData();
        formData.append("company_name", formValues.company_name);
        formData.append("short_name", formValues.short_name);
        formData.append("name", formValues.company_name);
        formData.append("rfc", formValues.rfc);
        formData.append("addres", formValues.addres);
        formData.append("clabe", formValues.clabe);
        formData.append("cfdi_id", formValues.cfdi_id);
        formData.append("tax_regime_id", formValues.tax_regime_id);
        formData.append("rnt", formValues.rnt);
        formData.append("logo", formValues.logo);
      
        try {
          const response = await fetch(addCompany(token), {
            method: "POST",
            body: formData,
          });
      
          console.log('Dentro de la función <<<<<<<<<<<<<<<<<<<<<<<<<<<<<');
          console.log(response);
      
          const data = await response.json();  // <---- Asegúrate de convertir a JSON
      
          console.log("Respuesta del servidor:", data); // <---- Esto debería mostrar success, result y message
      
          return {response, data};
        } catch (error) {
          console.error("Error en la petición:", error);
          return { success: false, result: "error", message: "Error en la solicitud" };
        }
      };
      
	
		  const response = await PostDataUser();

      console.log('response >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
      console.log(response.response.status)
	
		  if (response.response.status === 201) {
			addNotification("success", "Success", response.data.message, "top-center");
			setformvisible(!formvisible);
			settblvisible(!tblvisible);
			setreload((prev) => !prev);
		  } else {
			addNotification(
			  "warning",
			  "Error",
			  response.data.message,
			  "top-center"
			);
		  }
		} catch (error) {}

};

const handleSubmitEditCompany = async (e) => {
	e.preventDefault();

  
		try {
		  const PostDataUser = async () => {
        const formData = new FormData();
        formData.append("company_id", formValuesEdit.company_id );
        formData.append("company_name", formValuesEdit.company_name);
        formData.append("short_name", formValuesEdit.short_name);
        formData.append("name", formValuesEdit.company_name);
        formData.append("rfc", formValuesEdit.rfc);
        formData.append("addres", formValuesEdit.addres);
        formData.append("clabe", formValuesEdit.clabe);
        formData.append("cfdi_id", formValuesEdit.cfdi_id);
        formData.append("tax_regime_id", formValuesEdit.tax_regime_id);
        formData.append("rnt", formValuesEdit.rnt);
        formData.append("logo", formValuesEdit.logo);
      
        try {
          const response = await fetch(putCompany(token), {
            method: "PUT",
            body: formData,
          });
      
          console.log('Dentro de la función <<<<<<<<<<<<<<<<<<<<<<<<<<<<<');
          console.log(response);
      
          const data = await response.json();  // <---- Asegúrate de convertir a JSON
      
          console.log("Respuesta del servidor:", data); // <---- Esto debería mostrar success, result y message
      
          return {response, data};
        } catch (error) {
          console.error("Error en la petición:", error);
          return { success: false, result: "error", message: "Error en la solicitud" };
        }
      };
      
	
		  const response = await PostDataUser();

      console.log('response >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
      console.log(response.response.status)
	
		  if (response.response.status === 200) {
			addNotification("success", "Success", response.data.message, "top-center");
			// setformvisible(!formvisible);
			settblvisible(!tblvisible);
      setformvisibleEdit((prev) => !prev);
			setreload((prev) => !prev);
		  } else {
			addNotification(
			  "warning",
			  "Error",
			  response.data.message,
			  "top-center"
			);
		  }
		} catch (error) {}

};

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormValues((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
        ...(name === "company" && { branch: "" }),
      }));
    }
  };

  const handleInputChangeEdit = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormValuesEdit((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormValuesEdit((prev) => ({
        ...prev,
        [name]: value,
        ...(name === "company" && { branch: "" }),
      }));
    }
  };

    function addNotification(
	  notificationType,
	  notificationTitle,
	  notificationMessage,
	  notificationPosition,
	  notificationContent
	) {
	  if (notificationContent) {
		notificationContent = (
		  <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
			<div className="flex-1 ps-2">
			  <h6 className="mb-1">Christopher Struth</h6>
			  <p className="mb-0">Bank Transfer</p>
			</div>
		  </div>
		);
	  }
	  Store.addNotification({
		title: notificationTitle,
		message: notificationMessage,
		type: notificationType,
		insert: "top",
		container: notificationPosition,
		animationIn: ["animated", "fadeIn"],
		animationOut: ["animated", "fadeOut"],
		dismiss: { duration: 2000 },
		dismissable: { click: true },
		content: notificationContent,
	  });
	}

  console.log(
    "formValues <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<"
  );
  console.log(formValues);

  console.log("grupo de permisos <<<<");
  console.log(canRead);
  console.log(canWrite);
  console.log(canUpdate);
  console.log(canDelete);

  return (
    <div>
      <h1 className='page-header'>Companies</h1>

      <div className="row" style={!tblvisible ? { display: "none" } : {}}>
        <div className="col-12">
          <Panel>
            <PanelHeader>Companies</PanelHeader>
            <PanelBody>
              {canWrite && (
                <div className="row col-md-3" style={{ float: "inline-end" }}>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={fnChangeView}
                  >
                    Agregar Empresa +
                  </button>
                </div>
              )}

              <div className="row col-md-12">
                {/* <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}> */}
                <DataTableConsulting
                  header={header}
                  headerMap={headerMap}
                  info={info}
                  onViewDetail={canRead ? fnViewDetail : null}
                  onEditRegister={canUpdate ? fnEditRegister : null}
                  onDelete={canDelete ? fnDeleteRegister : null}
                />
                {/* </div> */}
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> Company details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalData ? (
            <div
              // key={index}
              style={{
                borderBottom: "2px solid #ccc",
                paddingBottom: "15px",
                marginBottom: "15px",
              }}
            >
              <p>
                {" "}
                <strong>Company ID:</strong> {modalData.company_id}{" "}
              </p>
              <p>
                {" "}
                <strong>Name Company:</strong> {modalData.company_name}{" "}
              </p>
              <p>
                {" "}
                <strong>abbreviation:</strong> {modalData.short_name}{" "}
              </p>
              <p>
                {" "}
                <strong>Name:</strong> {modalData.name}{" "}
              </p>
              <p>
                {" "}
                <strong>RFC:</strong> {modalData.rfc}{" "}
              </p>
              <p>
                {" "}
                <strong>Address:</strong> {modalData.addres}{" "}
              </p>
              <p>
                {" "}
                <strong>Clave:</strong> {modalData.clabe}{" "}
              </p>
              <p>
                {" "}
                <strong>RNT:</strong> {modalData.rnt}{" "}
              </p>
              <p>
                {" "}
                <strong>Fecha de registro:</strong> {modalData.date_register}{" "}
              </p>
              <p>
                {" "}
                <strong>Fecha de baja:</strong> {modalData.date_unsubscribe}{" "}
              </p>
              <p>
                {" "}
                <strong>Regimen:</strong> {modalData.tax_regimen}{" "}
              </p>
              <p>
                {" "}
                <strong>CFDI:</strong> {modalData.cfdi}{" "}
              </p>

              {modalData.logo && (
                <img
                  src={modalData.logo}
                  alt={modalData.name}
                  width="100%"
                  style={{ borderRadius: "5px", marginTop: "10px" }}
                />
              )}
            </div>
          ) : (
            <p>Cargando...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* //////// agregar compañia  */}

      <div className="row" style={!formvisible ? { display: "none" } : {}}>
        <div className="col-12">
          <Panel>
            <PanelHeader>Form Branch</PanelHeader>
            <PanelBody>
              <form onSubmit={handleSubmit}>
                {/* <div className="row md-15px">
				  <label
					className="form-label col-form-label col-md-3"
					htmlFor="company"
				  >
					Companies*
				  </label>
				  <div className="col-md-9">
					<select
					  className="form-select mb-5px"
					  id="company"
					  name="company"
					  value={formValues.company}
					  onChange={handleInputChange}
					>
					  {slsCompany.map((option, index) => (
						<option
						  key={"form_" + index}
						  value={option.value_option}
						>
						  {option.name_option}
						</option>
					  ))}
					</select>
				  </div>
				</div> */}

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Name Company *
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      id="company_name"
                      name="company_name"
                      value={formValues.company_name}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Short name *
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      id="short_name"
                      name="short_name"
                      value={formValues.short_name}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    RFC *
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      id="rfc"
                      name="rfc"
                      value={formValues.rfc}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Address *
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      id="addres"
                      name="addres"
                      value={formValues.addres}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Clave *
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      id="clabe"
                      name="clabe"
                      value={formValues.clabe}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    RNT *
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      id="rnt"
                      name="rnt"
                      value={formValues.rnt}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    CFDI *
                  </label>
                  <div className="col-md-2">
                    <input
                      className="form-control"
                      type="number"
                      id="cfdi_id"
                      name="cfdi_id"
                      value={formValues.cfdi_id}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Tax regime *
                  </label>
                  <div className="col-md-2">
                    <input
                      className="form-control"
                      type="number"
                      id="tax_regime_id"
                      name="tax_regime_id"
                      value={formValues.tax_regime_id}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Select logo
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="file"
                      id="logo"
                      name="logo"
                      onChange={handleInputChange} // No es necesario pasar el value porque es un campo de tipo archivo
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  {/* <div className="lrow md-15px" style={{ margin: 10 }}> */}
				  <div className="form-label col-form-label col-md-6" style={{ margin: 10 }}>
                    <button
                      type="submit"
                      className="btn btn-blue"
                      style={{ float: "right" }}
                    >
                      Save
                    </button>
					{/* <button
                      type="submit"
                      className="btn btn-red"
                      style={{ float: "left" }}
					  onClick={fnCancelAddcompany}
                    >
                      Cancel
                    </button> */}
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>

      {/* //////// terminar funcionalidad para agregar compañia */}

      {/* ////////////// Editar registro de Compañia  */}

      <div className="row" style={!formvisibleEdit ? { display: "none" } : {}}>
        <div className="col-12">
          <Panel>
            <PanelHeader>Edit Branch</PanelHeader>
            <PanelBody>
              <form onSubmit={handleSubmitEditCompany}>
                {/* <div className="row md-15px">
				  <label
					className="form-label col-form-label col-md-3"
					htmlFor="company"
				  >
					Companies*
				  </label>
				  <div className="col-md-9">
					<select
					  className="form-select mb-5px"
					  id="company"
					  name="company"
					  value={formValues.company}
					  onChange={handleInputChange}
					>
					  {slsCompany.map((option, index) => (
						<option
						  key={"form_" + index}
						  value={option.value_option}
						>
						  {option.name_option}
						</option>
					  ))}
					</select>
				  </div>
				</div> */}

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Name Company *
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      id="company_name"
                      name="company_name"
                      value={formValuesEdit.company_name}
                      onChange={handleInputChangeEdit}
                      disabled
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Short name *
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      id="short_name"
                      name="short_name"
                      value={formValuesEdit.short_name}
                      onChange={handleInputChangeEdit}
                      disabled
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    RFC *
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      id="rfc"
                      name="rfc"
                      value={formValuesEdit.rfc}
                      onChange={handleInputChangeEdit}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Address *
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      id="addres"
                      name="addres"
                      value={formValuesEdit.addres}
                      onChange={handleInputChangeEdit}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Clave *
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      id="clabe"
                      name="clabe"
                      value={formValuesEdit.clabe}
                      onChange={handleInputChangeEdit}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    RNT *
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      id="rnt"
                      name="rnt"
                      value={formValuesEdit.rnt}
                      onChange={handleInputChangeEdit}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    CFDI *
                  </label>
                  <div className="col-md-2">
                    <input
                      className="form-control"
                      type="number"
                      id="cfdi_id"
                      name="cfdi_id"
                      value={formValuesEdit.cfdi_id}
                      onChange={handleInputChangeEdit}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Tax regime *
                  </label>
                  <div className="col-md-2">
                    <input
                      className="form-control"
                      type="number"
                      id="tax_regime_id"
                      name="tax_regime_id"
                      value={formValuesEdit.tax_regime_id}
                      onChange={handleInputChangeEdit}
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-2">
                    Update logo
                  </label>

                  <div className="col-md-4">
                    {formValuesEdit.logo && (
                      <div>
                        <img
                          src={formValuesEdit.logo}
                          alt="Image not found"
                          style={{
                            width: "100px",
                            height: "auto",
                            marginBottom: "10px",
                          }}
                        />
                      </div>
                    )}

                    <input
                      className="form-control"
                      type="file"
                      id="logo"
                      name="logo"
                      onChange={handleInputChangeEdit} // No es necesario pasar el value porque es un campo de tipo archivo
                    />
                  </div>
                </div>

                <div className="row mb-15px">
                  {/* <div className="lrow md-15px" style={{ margin: 10 }}> */}
				  <div className="form-label col-form-label col-md-6" style={{ margin: 10 }}>
                    <button
                      type="submit"
                      className="btn btn-blue"
                      style={{ float: "right" }}
                    >
                      Save
                    </button>
					{/* <button
                      type="submit"
                      className="btn btn-red"
                      style={{ float: "left" }}
					  onClick={fnCancelAddcompany}
                    >
                      Cancel
                    </button> */}
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>

      {/* ///////////// termina Edicion  */}
            <ReactNotifications />{" "}
      
    </div>
  );
}

export default TableCompanies;
