import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications, Store } from "react-notifications-component";
import {
  getBranchDataByCompany,
  PostHotelsSearch,
  PostCreateCombosHotel,
  postAllCompaniesRoll,
} from "../../utils/urls.js";
import {
  getListhotelsview,
  deleteHotelSelect,
} from "../../utils/urlExperience.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";
import SearchForm from "../../components/header/search/form.jsx";

function OffersAccom({ idUser, infoUser, dataUser }) {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  const initInfoHotels = {
    hotelid: 0,
    name: "",
    address1: "",
    city: "",
    stateprovince: "",
    postalcode: "",
    country: "",
    nomach: false,
  };
  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };

  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false); // Estado de carga
  const [LoadingCreatecombo, setLoadingCreatecombo] = useState(false); // Estado de carga

  const [reload, setReload] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [slsListHotelsSerch, setslsListHotelsSerch] = useState([
    initInfoHotels,
  ]);
  const [isLoaded, setIsLoaded] = useState(true);

  const [selectedHotels, setSelectedHotels] = useState([]); // Estado para los hoteles seleccionados
  const [FormValuesCombo, setFormValuesCombo] = useState({
    name: "",
    description: "",
  });
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [slsBranch, setslsBranches] = useState([initSelec2]);
  const [formValues, setFormValues] = useState({
    company: "",
    branch: "",
  });

  const [slsCompany, setslsCompany] = useState([initSelec]);



  useEffect(() => {
    console.log("<<<<<<isLoaded>>>>>>");
    console.log(isLoaded);

    const executeFetchLoadCombos = async () => {
      try {
        if (isLoaded === true) {
          // Ejecutar fetchLoadCombos y esperar a que termine
          await fetchLoadCombos();
          console.log("fetchLoadCombos ejecutado con éxito");
        }

        let selectCombos = [initSelec, ...(await fnPostCompaniesList())];

        setslsCompany(selectCombos);

        const fetchBranches = async () => {
          const branches = await fnGetAllBranch();
          setslsBranches([initSelec2, ...branches]);
        };

        // if ( idUserP.rol != 4 && idUserP.rol != 6)
        //   {
          fetchBranches();
        // }

      } catch (error) {
        console.error("Error en fetchLoadCombos:", error);
      } finally {
        // Cambiar el estado a false, independientemente de si hubo error o no
        setIsLoaded(false);
      }
    };

    executeFetchLoadCombos();
  }, [isLoaded]);

  useEffect(() => {
    if (formValues.company) {
      const fetchBranches = async () => {
        const branches = await fnGetAllBranch();
        setslsBranches([initSelec2, ...branches]);
      };
      fetchBranches();
    }
  }, [formValues.company]);

  const fetchLoadCombos = async () => {
    console.log("en funcion de combos <<<<<<<<<<<<<<");

    console.log(!isLoaded);
    try {
      //   if (!isLoaded) {
      if (token === undefined || token === null) {
        token = getFromLocalStorage("TK");
      }

      /// ejecucion de codigo para precargar en caso de ser necesario

      //   }
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol, 
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch
      };
  
      const responseJsonCompanies = await axios.post(postAllCompaniesRoll(token), requestData);

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const handleCheckboxChange = (hotelid, isChecked) => {
    setSelectedIds((prev) =>
      isChecked ? [...prev, hotelid] : prev.filter((id) => id !== hotelid)
    );
  };

  const fnGetAllBranch = async () => {
    try {
      const requestData = {
        companyId: formValues.company || idUserP.company,  // Si formValues.company es vacío o no existe, usa idUserP.company
      };
  
      const responseJsonCompanies = await axios.post(
        getBranchDataByCompany(token),
        requestData
      );
      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };
  

  const handleSaveCombo = async () => {
    // Validar si al menos uno de los elementos tiene flag en true
    const isSelected = selectedHotels.some((hotel) => hotel.flag);
  
    if (!isSelected) {
      console.log("Error: Ningún hotel está seleccionado.");
      addNotification(
        "warning",
        "Error",
        "You must select a hotel to display as primary",
        "top-center",
        false
      );
      return; // Detener la ejecución si no hay ningún hotel seleccionado
    }
  
    // Construir el cuerpo de la petición
    const comboBody = {
      combo: {
        name: FormValuesCombo.name,
        description: FormValuesCombo.description,
        id_empresa: Number(formValues.company), // Reemplaza con el valor dinámico si es necesario
        id_sucursal: Number(formValues.branch),  // Reemplaza con el valor dinámico si es necesario
      },
      hotels: selectedHotels.map((hotel, index) => ({
        hotel_id: hotel.hotelid, // Cambia según tu propiedad real en el objeto `hotel`
        orden: index + 1, // El índice determina el orden
        banner: hotel.flag, // La bandera `flag` de cada hotel
        available: true, // Cambia según tu lógica; aquí es estático como en tu ejemplo
      })),
    };
  
    console.log("Body para la petición:", comboBody);


    const resultcreate = await saveComboHotels(comboBody);

  
    addNotification(
      "success",
      "Success",
      "Creating hotel combo to display",
      "top-center"
    );

    setSelectedHotels([]);
  
    // Realizar la petición
    // try {
    //   const response = await fetch("/your-endpoint", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(comboBody),
    //   });
  
    //   if (!response.ok) {
    //     throw new Error("Error al crear el combo de hoteles");
    //   }
  
    //   const data = await response.json();
    //   console.log("Respuesta del servidor:", data);
    // } catch (error) {
    //   console.error("Error:", error);
    //   addNotification(
    //     "error",
    //     "Error",
    //     "Failed to create hotel combo",
    //     "top-center"
    //   );
    // }
  };

  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }

  const toggleReload = () => {
    if (!reload) {
      setReload(true);
      setLoading(true); // Inicia la carga cuando se hace clic en recargar

      setTimeout(() => {
        setIsLoaded(true);

        setReload(false);
        setLoading(false); // Termina la carga
      }, 2000);
    }
  };

  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
  };
  const handleSearch = async (e) => {
    e.preventDefault();

    console.log("entro a funcion de buscar <<< ", searchInput);
    postSearchData();
    let listHotelsInfo = [initInfoHotels, ...(await postSearchData())];

    setslsListHotelsSerch(listHotelsInfo);
  };

  const postSearchData = async () => {
    // console.log("Fetching permissions for user:", IdUserSelect);
    setLoading(true); // Activar el estado de carga

    try {
      const responseJson = await axios.post(PostHotelsSearch(token), {
        value: searchInput,
      });

      // addNotification(
      //   "success",
      //   "Success",
      //   "Records deleted",
      //   "top-center"
      // );

      // addNotification(
      //   "warning",
      //   "Error",
      //   "Error updating one or more recordss",
      //   "top-center",
      //   false
      // );

      const DataResulthotels = responseJson.data.data;

      console.log(DataResulthotels);

      return DataResulthotels;
    } catch (error) {
      console.error("Error al cargar los módulos", error);
    } finally {
      setLoading(false); // Desactivar el estado de carga
    }
  };

  const handleHotelSelect = (hotel) => {
    setSelectedHotels((prev) => {
      const isAlreadySelected = prev.some(
        (selected) => selected.hotelid === hotel.hotelid
      );

      if (isAlreadySelected) {
        // Si ya está seleccionado, lo eliminamos
        return prev.filter((selected) => selected.hotelid !== hotel.hotelid);
      } else {
        // Si no está seleccionado, verificamos el límite
        if (prev.length >= 13) {
          // alert("You can only select up to 13 hotels.");

          addNotification(
            "warning",
            "Error",
            "You can only select up to 13 hotels.",
            "top-center",
            false
          );

          return prev; // No agregar si ya alcanzó el límite
        }
        return [...prev, hotel];
      }
    });
  };

  const saveComboHotels = async (body) => {
    // console.log("Fetching permissions for user:", IdUserSelect);
    try {
      const responseJson = await axios.post(PostCreateCombosHotel(token), body);

      console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<responseJson>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

      console.log(responseJson)

      const DataResulthotels = responseJson.data.data;
      console.log('<<<<<<<<<<<<<<<<<<<<<<<<DataResulthotels>>>>>>>>>>>>>>>>>>>>>>>>');

      console.log(DataResulthotels);

      return DataResulthotels;
    } catch (error) {
      console.error("Error al cargar los módulos", error);
    } finally {
      setLoading(false); // Desactivar el estado de carga
    }
  };

  // Función para verificar si un hotel está seleccionado
  const isHotelSelected = (hotelid) =>
    selectedHotels.some((selected) => selected.hotelid === hotelid);

  const selectHotel = (hotelid) => {
    setSelectedHotels((prevHotels) =>
      prevHotels.map((hotel) => ({
        ...hotel,
        flag: hotel.hotelid === hotelid, // Solo selecciona el hotel con el hotelid dado
      }))
    );
  };


  
  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };
  
  const handleDragOver = (event) => {
    event.preventDefault(); // Permitir soltar
  };
  
  const handleDrop = (index) => {
    setSelectedHotels((prevHotels) => {
      const updatedHotels = [...prevHotels];
      const [movedHotel] = updatedHotels.splice(draggedIndex, 1); // Elimina el hotel arrastrado
      updatedHotels.splice(index, 0, movedHotel); // Inserta en la nueva posición
      return updatedHotels;
    });
    setDraggedIndex(null); // Restablecer el índice arrastrado
  };





  const handleInputChangeform = (e) => {
    const { name, value } = e.target; // Obtén el nombre y valor del input
    setFormValuesCombo((prevState) => ({
      ...prevState, // Mantén los valores anteriores
      [name]: value, // Actualiza solo el campo correspondiente
    }));
  };


  const handleInputChangeCompany = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      ...(name === "company" && { branch: "" }), // Reiniciar branch si cambia la compañía
    }));
  };

  // console.log('<<<<<<< slsBranch   >>>>>>> <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<')
  // console.log(slsBranch)

  return (
    <div>
      <div className="row mb-3">
        <div className="col-xl-6">
          <Panel>
            <PanelHeader>Add hotels to display</PanelHeader>
            <PanelBody>
              <div className="navbar-form">
                <form action="" method="POST" name="search_form">
                  <div
                    className="form-group"
                    style={{ position: "relative", padding: "10px" }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter city or hotel name"
                      value={searchInput} // Muestra el valor del estado
                      onChange={handleInputChange} // Actualiza el estado al escribir
                      style={{ paddingRight: "40px" }}
                    />
                    <button
                      type="submit"
                      className="btn btn-search"
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                      }}
                      onClick={handleSearch}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>

                  <div className="lrow md-7px" style={{ margin: 2 }}>
                    *Select the hotels to display, the limit is 13
                  </div>

                  <div
                    className="lrow md-8px"
                    style={{
                      margin: 5,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center", // Centra los elementos horizontalmente
                      maxHeight: "650px", // Altura máxima del contenedor principal
                      overflowY: "auto", // Barra de desplazamiento vertical
                      border: "1px solid #ccc", // Opcional: agregar borde para resaltar
                      padding: "10px", // Espaciado interno
                    }}
                  >
                    {loading ? (
                      <div>Loading...</div>
                    ) : (
                      slsListHotelsSerch
                        .slice(1) // Omite el inicial
                        .sort((a, b) => a.name.localeCompare(b.name)) // Ordena alfabéticamente por nombre
                        .map((hotel) => (
                          <div
                            key={hotel.hotelid}
                            onClick={() => handleHotelSelect(hotel)} // Manejar clics
                            style={{
                              display: "flex",
                              flexDirection: "column", // Ajusta flex para columnas
                              alignItems: "flex-start", // Alinea contenido a la izquierda
                              marginBottom: "10px",
                              padding: "10px",
                              border: "2px solid #ccc",
                              borderRadius: "5px",
                              backgroundColor: isHotelSelected(hotel.hotelid)
                                ? "#60adf6" // Azul para seleccionado
                                : "#fff", // Blanco para no seleccionado
                              color: isHotelSelected(hotel.hotelid)
                                ? "#fff"
                                : "#000",
                              cursor: "pointer",
                              transition: "background-color 0.3s",
                              width: "700px", // Ancho deseado
                              maxWidth: "90%", // Asegura que no sobrepase el contenedor
                              alingc: "center",
                            }}
                          >
                            <div>
                              <strong>{hotel.name}</strong>
                              <p>
                                {hotel.country} - {hotel.city}
                              </p>
                              <p>Address: {hotel.address1}</p>
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                </form>
              </div>
            </PanelBody>
          </Panel>
        </div>

        <div className="col-xl-6">
          <Panel>
            <PanelHeader>
              &nbsp;&nbsp;
              <button
                className="btn btn-xs btn-icon btn-circle btn-success"
                onClick={toggleReload}
              >
                <i className="fa fa-redo"></i>
              </button>
              &nbsp;&nbsp; List of available hotels
            </PanelHeader>
            <PanelBody>
              <div className="lrow md-7px" style={{ margin: 2 }}>
                *Select the hotel for your banner
              </div>
              {/* <div> */}
              <div
  className="lrow md-8px"
  style={{
    margin: 5,
    maxHeight: "650px", // Altura máxima del contenedor principal
    overflowY: "auto", // Barra de desplazamiento vertical
    border: "1px solid #ccc", // Opcional: agregar borde para resaltar
    padding: "10px", // Espaciado interno
  }}
>
  {LoadingCreatecombo ? (
    <div>Loading...</div> // Muestra un mensaje de carga mientras se obtiene la información
  ) : (
    <form>
      {/* Inputs para capturar datos del combo */}
      <div className="row mb-3 d-flex align-items-center">
        <label
          className="form-label col-form-label col-md-3 mb-0"
          htmlFor="nombredestino"
        >
          Name combo*
        </label>
        <div className="col-md-9">
          <input
            className="form-control"
            type="text"
            id="nombredestino"
            name="name" // Nombre debe coincidir con la clave en el estado
            value={FormValuesCombo.name}
            onChange={handleInputChangeform}
          />
        </div>
      </div>

      <div className="row mb-3 d-flex align-items-center">
        <label
          className="form-label col-form-label col-md-3 mb-0"
          htmlFor="urlimagen"
        >
          Description*
        </label>
        <div className="col-md-9">
          <input
            className="form-control"
            type="text"
            id="urlimagen"
            name="description" // Nombre debe coincidir con la clave en el estado
            value={FormValuesCombo.description}
            onChange={handleInputChangeform}
          />
        </div>
      </div>


      <div>
  {/* Condición para mostrar el div de Companies y Branches si rol es 1 o 2 */}
  {(idUserP.rol === 1 || idUserP.rol === 2) && (
    <div className="row md-15px">
      <label className="form-label col-form-label col-md-3" htmlFor="company">Companies*</label>
      <div className="col-md-9">
        <select
          className="form-select mb-5px"
          id="company"
          name="company"
          value={formValues.company}
          onChange={handleInputChangeCompany}
        >
          {slsCompany.map((option, index) => (
            <option key={"form_" + index} value={option.value_option}>{option.name_option}</option>
          ))}
        </select>
      </div>
    </div>
  )}

  {/* Condición para mostrar el div de Branches si rol es 1, 2, 4 o 6 */}
  {(idUserP.rol === 1 || idUserP.rol === 2 || idUserP.rol === 4 || idUserP.rol === 6) && (
    <div className="row md-15px">
      <label className="form-label col-form-label col-md-3" htmlFor="branch">Branches</label>
      <div className="col-md-9">
        <select
          className="form-select mb-5px"
          id="branch"
          name="branch"
          value={formValues.branch}
          onChange={handleInputChangeCompany}
        >
          {slsBranch.map((option, index) => (
            <option key={"form_" + index} value={option.value}>{option.text}</option>
          ))}
        </select>
      </div>
    </div>
  )}
</div>



      {/* Iteración sobre los hoteles */}
      <div className="row mb-3 d-flex align-items-center">
  {selectedHotels.map((hotel, index) => (
    <div
      key={index}
      draggable // Permitir arrastrar el elemento
      onDragStart={() => handleDragStart(index)} // Inicio del arrastre
      onDragOver={handleDragOver} // Arrastre sobre un elemento
      onDrop={() => handleDrop(index)} // Soltar en una nueva posición
      onClick={() => selectHotel(hotel.hotelid)} // Seleccionar hotel al hacer clic
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        padding: "5px",
        border: `1px solid ${hotel.flag ? "blue" : "#ccc"}`,
        borderRadius: "5px",
        cursor: "pointer",
        backgroundColor: hotel.flag ? "#e0f7fa" : "#fff",
        opacity: draggedIndex === index ? 0.5 : 1, // Cambia la opacidad mientras se arrastra
      }}
    >
      <div>
        <strong>{hotel.name}</strong>
        <p>
          {hotel.country} - {hotel.stateprovince} - {hotel.city} -{" "}
          {hotel.address1}
        </p>
        <p>{hotel.address1}</p>
      </div>
    </div>
  ))}
</div>





      <div className="lrow md-15px" style={{ margin: 10 }}>
                <button
                  type="button"
                  className="btn btn-green"
                  style={{ float: "right" }}
                  onClick={handleSaveCombo}
                >
                  Save combo
                </button>
              </div>


    </form>
  )}
</div>



            </PanelBody>
          </Panel>{" "}
        </div>
      </div>
      <ReactNotifications />{" "}
    </div>
  );
}

export default OffersAccom;
