import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import {
  getMappinEvents,
} from "./../../utils/urls.js";

const events = [
  // Datos iniciales de eventos locales
];

function InfoSalesTickets() {
  const [data, setData] = useState(events);
  const [dataEventsGet, setDataEventsGet] = useState([]);
  const [filteredCount, setFilteredCount] = useState(0);
  const [datas, setDatas] = useState([
    { saleId: 1, nameEvent: 'Event 1', address: 'Address 1', dateEvent: '2024-08-01', saleDate: '2024-08-02', nameVenue: 'Venue 1', complet: false },
    { saleId: 2, nameEvent: 'Event 2', address: 'Address 2', dateEvent: '2024-08-03', saleDate: '2024-08-04', nameVenue: 'Venue 2', complet: true },
    { saleId: 3, nameEvent: 'Event 3', address: 'Address 3', dateEvent: '2024-08-05', saleDate: '2024-08-06', nameVenue: 'Venue 3', complet: false },
    { saleId: 4, nameEvent: 'Event 4', address: 'Address 4', dateEvent: '2024-08-07', saleDate: '2024-08-08', nameVenue: 'Venue 4', complet: true },
    { saleId: 5, nameEvent: 'Event 5', address: 'Address 5', dateEvent: '2024-08-09', saleDate: '2024-08-10', nameVenue: 'Venue 5', complet: false },
    { saleId: 6, nameEvent: 'Event 6', address: 'Address 6', dateEvent: '2024-08-11', saleDate: '2024-08-12', nameVenue: 'Venue 6', complet: true },
    { saleId: 7, nameEvent: 'Event 7', address: 'Address 7', dateEvent: '2024-08-13', saleDate: '2024-08-14', nameVenue: 'Venue 7', complet: false },
    { saleId: 8, nameEvent: 'Event 8', address: 'Address 8', dateEvent: '2024-08-15', saleDate: '2024-08-16', nameVenue: 'Venue 8', complet: true },
    { saleId: 9, nameEvent: 'Event 9', address: 'Address 9', dateEvent: '2024-08-17', saleDate: '2024-08-18', nameVenue: 'Venue 9', complet: false },
    { saleId: 10, nameEvent: 'Event 10', address: 'Address 10', dateEvent: '2024-08-19', saleDate: '2024-08-20', nameVenue: 'Venue 10', complet: true }
  ]);

  const [filters, setFilters] = useState({
    nameEvent: "",
    venueName: "",
    venueAddressLine1: "",
    categoryName: "",
    datetime: "",
  });

  const consultInfoEvents = () => {
    fetch(getMappinEvents(), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())
    .then((data) => {
     //console.log('Información a mostrar:', data);
      setDataEventsGet(data);
      filterEvents(data, filters); // Filtrar los datos obtenidos
    })
    .catch((error) => console.error(error));
  };

  useEffect(() => {
    consultInfoEvents();
  }, []);

  useEffect(() => {
    filterEvents(dataEventsGet, filters);
  }, [filters]);

  const filterEvents = (eventsData, filters) => {
    const filteredData = eventsData.filter((event) => {
      return (
        event.nameEvent.toLowerCase().includes(filters.nameEvent.toLowerCase()) &&
        event.venueName.toLowerCase().includes(filters.venueName.toLowerCase()) &&
        event.categoryName.toLowerCase().includes(filters.categoryName.toLowerCase()) &&
        event.venueAddressLine1.toLowerCase().includes(filters.venueAddressLine1.toLowerCase()) &&
        (filters.datetime === "" || event.datetime.includes(filters.datetime))
      );
    });
    setData(filteredData);
    setFilteredCount(filteredData.length);
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <div className="row">
        <div>
          <Panel>
            <PanelHeader>
              Tickets Sold {" "}
              <span className="badge bg-success ms-5px">{dataEventsGet.length}</span>
              <span className="badge bg-info ms-5px">Filtered: {filteredCount}</span>
            </PanelHeader>

            <PanelBody>
              <div className="filter-container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                <input
                  id="input1"
                  type="text"
                  className="bg-background border border-input rounded-lg py-2 px-4 text-foreground focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary block w-full"
                  placeholder="Name Event"
                  name="nameEvent"
                  value={filters.nameEvent}
                  onChange={handleFilterChange}
                />

                <input
                  id="input2"
                  type="text"
                  className="bg-background border border-input rounded-lg py-2 px-4 text-foreground focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary block w-full"
                  placeholder="Venue"
                  name="venueName"
                  value={filters.venueName}
                  onChange={handleFilterChange}
                />

                <input
                  id="input3"
                  type="text"
                  className="bg-background border border-input rounded-lg py-2 px-4 text-foreground focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary block w-full"
                  placeholder="Category"
                  name="categoryName"
                  value={filters.categoryName}
                  onChange={handleFilterChange}
                />
                <input
                  id="input4"
                  type="text"
                  className="bg-background border border-input rounded-lg py-2 px-4 text-foreground focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary block w-full"
                  placeholder="Address"
                  name="venueAddressLine1"
                  value={filters.venueAddressLine1}
                  onChange={handleFilterChange}
                />

                <input
                  id="input5"
                  type="date"
                  className="bg-background border border-input rounded-lg py-2 px-4 text-foreground focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary block w-full"
                  name="datetime"
                  value={filters.datetime}
                  onChange={handleFilterChange}
                />
              </div>

              <div style={{ maxHeight: "700px", overflowY: "auto" }}>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Sale id </th>
                      <th>Name Event</th>
                      <th>Address</th>
                      <th>Date Event</th>
                      <th>Sale date</th>
                      <th>Name Venue</th>
                      <th>Complet</th>


                    </tr>
                  </thead>
                  <tbody>
                    {datas.map((event, index) => (
                      <tr key={index}>
                        <td>{event.saleId}</td>
                        <td>{event.nameEvent}</td>
                        <td>{event.address}</td>
                        <td>{event.dateEvent}</td>
                        <td>{event.saleDate}</td>
                        <td>{event.nameVenue}</td>
                        <td>{event.complet}</td>


                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
}

export default InfoSalesTickets;
